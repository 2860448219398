import { Fragment } from "react/jsx-runtime";
import NotificationCard from "../../UI/notification-card";
import ModalPageHeader from "../../global-layout/modal-page-header";
import ModalPageWrapper from "../../global-layout/modal-page-wrapper";
import { useTranslation } from "react-i18next";
import { LoginBtn, TextBtn } from "../../form/text-btn";
import { useState } from "react";
interface NotificationCardProps {
  title: string;
  description: string;
  recievedTime: string;
  image: string;
}
interface NotificationProps {
  handleClose: () => void;
  handleChangePassword: () => void;
}

function Notification({
  handleClose,
  handleChangePassword,
}: NotificationProps) {
  const { t } = useTranslation();
  const notificationData: NotificationCardProps[] = [
    // {
    //   title: "Lorem ipsum",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
    //   recievedTime: "2 hrs ago",
    //   image: "notification-1.png",
    // },
    // {
    //   title: "Lorem ipsum",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    //   recievedTime: "2 hrs ago",
    //   image: "notification-2.png",
    // },
    // {
    //   title: "Lorem ipsum",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
    //   recievedTime: "2 hrs ago",
    //   image: "notification-3.png",
    // },
    // {
    //   title: "Lorem ipsum",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
    //   recievedTime: "2 hrs ago",
    //   image: "notification-4.png",
    // },
  ];

  const [remainingDays, setRemainingDays] = useState<number | null>(null);
  const [titleData, setTitle] = useState("");
  const days = sessionStorage.getItem("passwordRemainingDays");

  if (days !== null) {
    const remaining = parseInt(days, 10);
    console.log(remaining);

    // Check if remaining days have changed before updating state
    if (remaining !== remainingDays) {
      setRemainingDays(remaining);

      // Set titleData based on remainingDays
      if (remaining < 15) {
        setTitle(`Your password is going to expire in ${remaining} days.`);
      }
    }
  }
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-2xl:w-[50%] max-md:w-[85%]">
        <div className="w-full h-full flex flex-col">
          <ModalPageHeader
            title={t("web.label.common.notification")}
            notificationCount={titleData ? 1 : 0}
            handleClose={() => handleClose()}
          />
          <div className="w-full h-full flex-auto overflow-auto">
            <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
              <div className="w-full h-auto">
                {titleData !== "" &&
                  remainingDays !== null &&
                  remainingDays < 15 && (
                    <div className="w-full h-auto border-b border-io-gray-c8 dark:border-io-black-48">
                      <div className="w-full h-auto flex justify-center items-start gap-7 py-[2.8rem]">
                        <div className="w-[4rem] h-[4rem]">
                          <img
                            src={require("../../../assets/images/changepassword.png")}
                            alt="Change Password"
                          />
                        </div>
                        <div className="flex-1 h-auto">
                          <div className="w-full h-auto flex justify-between items-center">
                            <div className="w-auto">
                              <h4 className="text-[2.2rem] font-inter font-medium text-io-black dark:text-io-white inline-block">
                                Time to Renew
                              </h4>
                            </div>
                            <div className="w-auto">
                              <div className="w-[1.1rem] h-[1.1rem] rounded-full bg-io-green"></div>
                            </div>
                          </div>
                          <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 mb-5">
                            {titleData}
                          </p>
                          <div className="w-auto inline-block">
                            <LoginBtn
                              label="Change Password"
                              btnType="button"
                              btnStyle="fill-primary"
                              handleClick={handleChangePassword}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {notificationData.map((item, i) => (
                  <Fragment key={i}>
                    <NotificationCard data={item} />
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default Notification;
