import { ReactNode, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Option {
  id: string;
  label: string;
}
function ActionPills({
  onSelectOption,
  sideSelectedTab,
  actionId,
  formHandle,
  error,
}: any) {
  const { t } = useTranslation();
  const options: Option[] = [
    { id: "next", label: t("web.common.button.next") },
    { id: "retake", label: t("web.common.button.retake") },
    { id: "complete", label: t("web.common.button.complete") },
  ];

  return (
    <div className="w-auto h-auto flex justify-start items-center max-xl:w-full max-xl:mb-[3%] gap-[1.2rem] max-3xl:gap-[0.8rem] relative">
      {options.map((option) => (
        <div key={option.id}>
          <input
            type="radio"
            id={option.id}
            value={option.id}
            className="hidden peer"
            {...formHandle}
          />
          <label
            htmlFor={option.id}
            className={`w-auto h-auto flex justify-center items-center cursor-pointer text-[1.4rem] px-[2rem] py-[0.4rem] rounded-full font-inter font-normal bg-io-gray-f7 text-io-black peer-checked:bg-io-primary peer-checked:text-io-white peer-checked:dark:bg-io-black-1a peer-checked:dark:text-io-white`}
          >
            {option.label}
          </label>
          {error && (
            <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">
              {error}
            </p>
          )}
        </div>
      ))}
    </div>
  );
}
export default ActionPills;
