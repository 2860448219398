import { ReactNode } from "react";
interface CreateFloatingBtnProps {
  icon: ReactNode;
  handleClick: () => void;
}

function CreateFloatingButton({ icon, handleClick }: CreateFloatingBtnProps) {
  return (
    <button
      type="button"
      className="w-[7rem] h-[7rem] border-none bg-none fixed bottom-[5%] right-[7%] max-3xl:right-[8%] max-2xl:right-[8%]"
      onClick={handleClick}
    >
      {icon}
    </button>
  );
}
export default CreateFloatingButton;
