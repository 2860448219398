import { useState } from "react";
import Steps from "../../steps";
import CustomMagicStepOne from "../custom-magic-step-one";
import CustomMagicStepTwo from "../custom-magic-step-two";
import CustomMagicStepThree from "../custom-magic-step-three";
import CustomMagicStepFour from "../custom-magic-step-four";
import { useTranslation } from "react-i18next";




function CreateCustomMagicSteps(){
    const [currentStep, setCurrentStep] = useState<number>(1);

    const steps = [
        {label:"Define your magic"},
        {label:"Define your Key words for Insight generation"},
        {label:"Upload training Dataset"},
        {label:"Review and Submit "},
    ];
    const { t } = useTranslation();
    const renderStepContent = () => {
        switch (currentStep) {
          case 1:
            return (
              <CustomMagicStepOne setCurrentStep={(step) => setCurrentStep(step)} title={t("web.magix.label.defineyourmagic")} />
            );
          case 2:
            return (
             <CustomMagicStepTwo setCurrentStep={(step) => setCurrentStep(step)} title={t("web.magix.label.defineyourkey")} />
            );
          case 3:
            return (
            <CustomMagicStepThree setCurrentStep={(step) => setCurrentStep(step)} title={t("web.magix.label.uploadtrainingdataset")} />
            );
          case 4:
            return (
            <CustomMagicStepFour setCurrentStep={(step) => setCurrentStep(step)} title={t("web.magix.label.reviewandsubmit")} />
            );
          default:
            return null;
        }
      };

    return(
        <div className="w-full h-full flex flex-col">
            <Steps currentStep={currentStep} steps={steps} />   
            <div className="w-full h-full flex-auto">
            {renderStepContent()}
            </div>
        </div>
        
    )
}
export default CreateCustomMagicSteps