import ModalPageHeader from "../../../global-layout/modal-page-header";
import CreateMasterDetailsDetailForm from "../create-master-details-detail-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

function CreateMasterDetailsDetail({ userColumnUpdated }: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams(); // Ensure id is correctly typed as string
  const [token, setToken] = useState("");
  useEffect(() => {
    if (id !== "new") {
      setToken(t("web.master.title.editcolumndetails"));
    } else {
      setToken(t("web.master.title.columndetails"));
    }
  }, [id, t]);
  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex flex-col">
        <ModalPageHeader title={token} handleClose={() => navigate(-1)} />
        <div className="w-full h-full flex-auto">
          <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
            <CreateMasterDetailsDetailForm
              userColumnUpdated={userColumnUpdated}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateMasterDetailsDetail;
