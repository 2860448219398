import ModalPageFooter from "../../../global-layout/modal-page-footer";
import { InputField, TextAreaField } from "../../../form/input-fields";
import { SelectInput } from "../../../form/select-input";
import { ToggleButton } from "../../../form/toggle-btn";
import ResponseValue from "../../../form/response-value";
import ResponseSettingsChoices from "../response-settings-choices";
import { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import QuestionMoreInfo from "../../../UI/question-more-info";
import { useTranslation } from "react-i18next";
import ResponseValueForm from "../response-value-form";

interface ResponseSettingsFormProps {
  selectedScreen: any;
  // goToResponseValue: () => void;
  responseSettingsType: any;
  handleReponseSettingsSubmit: (data: any, type: any) => void;
  handleFormCancel: () => void;
  selectedResponseItem: any;
  responseMultipleChoiceOptions: any;
  responseDateTimeOptions: any;
  responseScannerOptions: any;
  responseImageOptions: any;
  responseTextOptions: any;
  responseMagicContainerOptions: any;
  selectedChoice: any;
  handleSaveResponse: (data: any) => void;
  bindingoptions: any;
  handleImport: (file: File) => void;
  selectedResponseValue: (data: any) => void;
  responseValueOptions: any;
  passUpdatedResponseval: (data: any) => void;
}

interface ResponseComponentProps {
  selectedScreen: any;
  responseStyleOptions?: ResponseStyleOptionsItem[];
  responseDateTimeOptions?: ResponseStyleOptionsItem[];
  responseScannerOptions?: ResponseStyleOptionsItem[];
  responseImageOptions?: ResponseStyleOptionsItem[];
  responseTextOptions?: ResponseStyleOptionsItem[];
  responseMagicContainerOptions?: ResponseStyleOptionsItem[];
  register: any;
  errors: any;
  setValue: any;
  watchQuestionName?: any;
  watchIsMandatory: any;
  watchIsEvidence: any;
  watchNotes: any;
  watchApiRequired: any;
  watchMasterTableOne: any;
  control?: any;
  fields?: any;
  masterFields?: any;
  appendApiRequiredFields: () => void;
  appendMasterFields: () => void;
  handleAddBindParameter: () => void;
  handleAddMasterBindParameter: () => void;
  handleBinParameterDelete: (index: number) => void;
  handleMasterBinParameterDelete: (index: number) => void;
  selectedCheckboxOptions: (options: any) => void;
  bindingoptions: any;
  responseSettingsType: any;
  setAPIAndMasterSchema?: (options: any) => void;
  setAPIRequiredDefault?: (options: any) => void;
  setMasterDefault?: (options: any) => void;
  setServiceBindParamval?: (option: any) => void;
  setMasterBindParamval?: (option: any) => void;
  replaceAPIRequired?: () => void;
  replaceMasterTable?: () => void;
  questionMoreInfoOptions?: any;
  updatedQuestionDetails?: any;
}
interface ResponseMultipleChoiceProps {
  fields: any;
  masterFields?: any;
  appendApiRequiredFields: () => void;
  appendMasterFields: () => void;
  selectedScreen: any;
  responseMultipleChoiceOptions: ResponseStyleOptionsItem[];
  goToResponseValue: () => void;
  selectedResVal: ResponseValueItem[];
  handleAddChoice: () => void;
  handleDeleteChoice: (val: string) => void;
  register: any;
  errors: any;
  setValue: any;
  watchIsMandatory: any;
  watchIsEvidence: any;
  watchNotes: any;
  watchApiRequired: any;
  watchMasterTableOne: any;
  control?: any;
  selectedResChoice: any;
  handleSaveResponse: (data: any) => void;
  handleAddBindParameter: () => void;
  handleAddMasterBindParameter: () => void;
  handleBinParameterDelete: (index: number) => void;
  handleMasterBinParameterDelete: (index: number) => void;
  selectedCheckboxOptions: (options: any) => void;
  bindingoptions: any;
  responseSettingsType: any;
  setAPIAndMasterSchema?: (options: any) => void;
  setAPIRequiredDefault?: (options: any) => void;
  setMasterDefault?: (options: any) => void;
  setServiceBindParamval?: (option: any) => void;
  setMasterBindParamval?: (option: any) => void;
  replaceAPIRequired?: () => void;
  replaceMasterTable?: () => void;
  questionMoreInfoOptions?: any;
  updatedQuestionDetails?: any;
}
interface ResponseStyleOptionsItem {
  value: string;
  label: string;
}
interface ResponseValueItem {
  id: string;
  value: string;
  color: string;
}

function ResponseSettingsForm({
  selectedScreen,
  // goToResponseValue,
  responseSettingsType,
  handleReponseSettingsSubmit,
  handleFormCancel,
  selectedResponseItem,
  selectedChoice,
  responseMultipleChoiceOptions,
  responseDateTimeOptions,
  responseScannerOptions,
  responseImageOptions,
  responseTextOptions,
  responseMagicContainerOptions,
  handleSaveResponse,
  bindingoptions,
  handleImport,
  selectedResponseValue,
  responseValueOptions,
  passUpdatedResponseval
}: ResponseSettingsFormProps) {
  const { t } = useTranslation();
  const responseTenantNameSchema = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
  });
  const responseSiteNameSchema = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
  });
  const responsePersonNameSchema = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
  });
  const responseLocationSchema = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
  });
  let responseMultipleChoiceSchema = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
    responseStyle: yup
      .object()
      .required(t("web.responsivesetting.label.stylerequired")),
  });
  const responseTextSchema = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
    responseStyle: yup
      .object()
      .required(t("web.responsivesetting.label.stylerequired")),
  });
  const responseDateTimeSchema = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
    responseStyle: yup
      .object()
      .required(t("web.responsivesetting.label.stylerequired")),
  });
  const responseSignatureSchema = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
  });
  const responseScannerSchema = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
    responseStyle: yup
      .object()
      .required(t("web.responsivesetting.label.stylerequired")),
  });
  const responseNumberSchema = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
  });
  const responseImageSchema = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
  });
  const responseMagicContainerSchema = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
    responseStyle: yup
      .object()
      .required(t("web.responsivesetting.label.stylerequired")),
  });
  const responseAcknowledgementSchema = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
  });
  const responseInformationSchema = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
  });
  const responseVideo = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
  });
  const responseImageWithInput = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
  });
  const responseAudio = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
  });
  const responseToggle = yup.object().shape({
    questionName: yup
      .string()
      .required(t("web.responsivesetting.label.plsquestionname")),
    isMandatory: yup.bool(),
    isEvidence: yup.bool(),
    notes: yup.bool(),
  });
  const [currentSchema, setCurrentSchema] = useState<any>(
    responseTenantNameSchema
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(currentSchema),
  });

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "serviceBindParameter",
  });
  const {
    fields: masterFields,
    append: masterAppend,
    remove: masterRemove,
    replace: masterReplace,
  } = useFieldArray({
    control,
    name: "masterBindParameter",
  });
  const [selectedResVal, setSelectedResVal] = useState<any>([]);
  const [selectedResChoice, setSelectedResChoice] = useState<any>([]);
  const [schemaUpdated, setSchemaUpdated] = useState<boolean>(false);

  const [responseOption, setResponseOption] = useState<any>([]);
  const [questionMoreInfoOptions, setQuestionMoreInfoOptions] = useState<any>(
    []
  );
  const [updatedQuestionDetails, setUpdatedQuestionDetails] = useState<any>([]);
  const [currentStep, setCurrentStep] = useState<string>("settings");

  const watchQuestionName = watch("questionName");
  const watchIsMandatory = watch("isMandatory");
  const watchIsEvidence = watch("isEvidence");
  const watchNotes = watch("notes");

  const watchApiRequired = watch("apiRequired");
  const watchMasterTableOne = watch("masterTableOne");

  const handleFormSubmit = (data: any) => {
    console.log(errors);

    data.isApiRequiredKey = 0;
    data.isMasterRequiredKey = 0;
    data.selectedMultiOptionChoice = 0;
    responseOption.map((option: any, i: number) => {
      if (option?.id === "apirequired") {
        data.isApiRequiredKey = 1;
      } else if (option?.id === "mastertable") {
        data.isMasterRequiredKey = 1;
      }
    });
    const validResponseStyles = [
      "multipleChoice",
      "radioButton",
      "dropDown",
      "dropdownMultiSelect",
    ];

    if (
      data.responseStyle &&
      validResponseStyles.includes(data.responseStyle.value)
    ) {
      data.selectedMultiOptionChoice = selectedResponseItem?.groupId;
    } else {
      data.selectedMultiOptionChoice = null;
    }

    handleReponseSettingsSubmit(data, responseSettingsType);
  };

  useEffect(() => {
    if (selectedResponseItem?.groupId) {
      setSelectedResVal(selectedResponseItem?.options);
    }
  }, [selectedResponseItem]);
  useEffect(() => {
    if (selectedChoice?.groupId) {
      setSelectedResChoice(selectedChoice?.options);
    }
  }, [selectedChoice]);

  const handleAddChoice = () => {
    const newValue = "choice";
    if (!selectedResChoice.some((choice: any) => choice.value === newValue)) {
      const newChoice = { id: newValue, value: newValue, color: "red" };
      setSelectedResChoice((prevState: any) => [...prevState, newChoice]);
    }
  };

  const handleDeleteChoice = (val: string) => {
    setSelectedResChoice((prevState: any) =>
      prevState.filter((choice: any) => choice.id !== val)
    );
  };

  useEffect(() => {
    console.log(selectedResChoice);
  }, [selectedResChoice]);

  const componentSchema: { [key: string]: any } = {
    1: responseTenantNameSchema,
    2: responseSiteNameSchema,
    3: responsePersonNameSchema,
    4: responseLocationSchema,
    6: responseMultipleChoiceSchema,
    7: responseTextSchema,
    9: responseDateTimeSchema,
    12: responseSignatureSchema,
    5: responseScannerSchema,
    8: responseNumberSchema,
    13: responseImageSchema,
    16: responseMagicContainerSchema,
    10: responseAcknowledgementSchema,
    11: responseInformationSchema,
    15: responseVideo,
    14: responseImageWithInput,
    17: responseAudio,
    18: responseToggle,
  };

  const appendApiRequiredFields = () => {
    append({
      serviceBindParameterOne: undefined,
      serviceBindParameterTwo: undefined,
    });
  };
  const appendMasterFields = () => {
    masterAppend({
      masterBindParameterOne: undefined,
      masterBindParameterTwo: undefined,
    });
  };

  const handleAddBindParameter = () => {
    append({
      serviceBindParameterOne: undefined,
      serviceBindParameterTwo: undefined,
    });
  };
  const handleAddMasterBindParameter = () => {
    masterAppend({
      masterBindParameterOne: undefined,
      masterBindParameterTwo: undefined,
    });
  };
  const handleBinParameterDelete = (index: number) => {
    remove(index);
  };
  const handleMasterBinParameterDelete = (index: number) => {
    masterRemove(index);
  };

  const selectedCheckboxOptions = (options: any) => {
    setResponseOption(options);
    let newSchema = componentSchema[responseSettingsType.type];
    options.map((option: any, i: number) => {
      if (option?.id === "apirequired") {
        newSchema = newSchema.shape({
          apiRequired: yup
            .object()
            .required(t("web.responsivesetting.label.apirequired")),
          serviceBindParameter: yup.array().of(
            yup.object().shape({
              serviceBindParameterOne: yup
                .mixed()
                .required(t("web.responsivesetting.label.bindparameter")),
              serviceBindParameterTwo: yup
                .mixed()
                .required(t("web.responsivesetting.label.bindparameter")),
            })
          ),
        });
      } else if (option?.id === "mastertable") {
        newSchema = newSchema.shape({
          masterTableOne: yup
            .object()
            .required(t("web.responsivesetting.label.stylerequired")),
          masterBindParameter: yup.array().of(
            yup.object().shape({
              masterBindParameterOne: yup
                .mixed()
                .required(t("web.responsivesetting.label.bindparameter")),
              masterBindParameterTwo: yup
                .mixed()
                .required(t("web.responsivesetting.label.bindparameter")),
            })
          ),
        });
      }
    });
    console.log(newSchema);

    setCurrentSchema(newSchema);
  };

  const setAPIRequiredDefault = (option: any) => {
    setValue("apiRequired", option);
  };
  const setMasterDefault = (option: any) => {
    setValue("masterTableOne", option);
  };
  const setServiceBindParamval = (options: any) => {
    append(options);
  };
  const setMasterBindParamval = (options: any) => {
    masterAppend(options);
  };
  const replaceAPIRequired = () => {
    replace({
      serviceBindParameterOne: undefined,
      serviceBindParameterTwo: undefined,
    });
  };
  const replaceMasterTable = () => {
    masterReplace({
      masterBindParameterOne: undefined,
      masterBindParameterTwo: undefined,
    });
  };

  useEffect(() => {
    console.log(selectedScreen.questionDetails, responseSettingsType);
    const updatedQuestionDetails = selectedScreen.questionDetails.map((item:any) => {
      if (item.id === responseSettingsType.id) {
        return { ...item, title: watchQuestionName };
      }
      return item;
    });
    setUpdatedQuestionDetails(updatedQuestionDetails)
    console.log(updatedQuestionDetails);
    
  },[watchQuestionName])

  const renderResponseComponent = (item: any) => {
    if (item.type === 16) {
      responseMagicContainerOptions.map((option: any, i: number) => {
        if (option.id === item.magicContainerId) {
          setValue("responseStyle", option);
        }
      });
    }


    const componentMap: { [key: string]: any } = {
      1: (
        <ResponseTenantName
          selectedScreen={selectedScreen}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      2: (
        <ResponseSiteName
          selectedScreen={selectedScreen}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      3: (
        <ResponsePersonName
          selectedScreen={selectedScreen}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      4: (
        <ResponseLocation
          selectedScreen={selectedScreen}
          responseStyleOptions={responseMultipleChoiceOptions}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      6: (
        <ResponseMultipleChoice
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          appendMasterFields={appendMasterFields}
          selectedScreen={selectedScreen}
          responseMultipleChoiceOptions={responseMultipleChoiceOptions}
          goToResponseValue={() => setCurrentStep("responsevalue")}
          selectedResVal={selectedResVal}
          handleAddChoice={handleAddChoice}
          handleDeleteChoice={handleDeleteChoice}
          register={register}
          setValue={setValue}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          selectedResChoice={selectedResChoice}
          handleSaveResponse={(data: any) => handleSaveResponse(data)}
          handleAddBindParameter={handleAddBindParameter}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      7: (
        <ResponseText
          selectedScreen={selectedScreen}
          responseTextOptions={responseTextOptions}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      9: (
        <ResponseDateTime
          selectedScreen={selectedScreen}
          responseDateTimeOptions={responseDateTimeOptions}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      12: (
        <ResponseSignature
          selectedScreen={selectedScreen}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      5: (
        <ResponseScanner
          selectedScreen={selectedScreen}
          responseScannerOptions={responseScannerOptions}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      8: (
        <ResponseNumber
          selectedScreen={selectedScreen}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      13: (
        <ResponseImage
          selectedScreen={selectedScreen}
          responseImageOptions={responseImageOptions}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      16: (
        <ResponseMagicContainer
          selectedScreen={selectedScreen}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          responseMagicContainerOptions={responseMagicContainerOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      10: (
        <ResponseAcknowledgement
          selectedScreen={selectedScreen}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      11: (
        <ResponseInformation
          selectedScreen={selectedScreen}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      15: (
        <ResponseVideo
          selectedScreen={selectedScreen}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      14: (
        <ResponseImageWithInputs
          selectedScreen={selectedScreen}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      17: (
        <ResponseAudio
          selectedScreen={selectedScreen}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          handleBinParameterDelete={handleBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
      18: (
        <ResponseToggle
          selectedScreen={selectedScreen}
          register={register}
          errors={errors}
          watchIsMandatory={watchIsMandatory}
          watchIsEvidence={watchIsEvidence}
          watchNotes={watchNotes}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          control={control}
          setValue={setValue}
          fields={fields}
          masterFields={masterFields}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={questionMoreInfoOptions}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      ),
    };

    return componentMap[item.type] || <p>{t("web.label.Notfound")}</p>;
  };

  useEffect(() => {
    if (responseSettingsType?.id) {
      setValue("questionName", responseSettingsType.title);
      setValue("isMandatory", responseSettingsType.isMandatory);
      setValue("isEvidence", responseSettingsType.isEvidence);
      setValue("notes", responseSettingsType.isNotes);

      if (responseSettingsType.type === 7) {
        responseTextOptions.map((option: any, i: number) => {
          if (option.id === responseSettingsType.subType) {
            setValue("responseStyle", option);
          }
        });
      }
      if (responseSettingsType.type === 5) {
        responseScannerOptions.map((option: any, i: number) => {
          if (option.id === responseSettingsType.subType) {
            setValue("responseStyle", option);
          }
        });
      }
      if (responseSettingsType.type === 6) {
        responseMultipleChoiceOptions.map((option: any, i: number) => {
          if (option.id === responseSettingsType.subType) {
            setValue("responseStyle", option);
          }
        });
      }
      if (responseSettingsType.type === 9) {
        responseDateTimeOptions.map((option: any, i: number) => {
          if (option.id === responseSettingsType.subType) {
            setValue("responseStyle", option);
          }
        });
      }
      // if (responseSettingsType.type === 16) {
      //   responseMagicContainerOptions.map((option: any, i: number) => {
      //     if (option.id === responseSettingsType.subType) {
      //       setValue("responseStyle", option);
      //     }
      //   });
      // }
    }
  }, [responseSettingsType]);
  console.log(errors);

  return (
    <>
    {currentStep === "settings" ? 
     <form
     className="w-full h-full flex flex-col"
     onSubmit={handleSubmit((data) => handleFormSubmit(data))}
   >
     <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large pr-[4rem]">
       <div className="w-full h-auto pt-[1rem]">
         {renderResponseComponent(responseSettingsType)}
       </div>
     </div>
     <ModalPageFooter
       footerType="form"
       handleCancel={() => handleFormCancel()}
     />
   </form>
   :
   <ResponseValueForm
       goToSettings={() => setCurrentStep("settings")}
       handleImport={handleImport}
       handleFormCancel={handleFormCancel}
       selectedResponseValue={selectedResponseValue}
       responseValueOptions={responseValueOptions}
       selectedResponseItem={selectedResponseItem}
       passUpdatedResponseval={passUpdatedResponseval}
     />
    }
      </>
  );
}

const ResponseTenantName = ({
  selectedScreen,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  updatedQuestionDetails
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponseSiteName = ({
  selectedScreen,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleAddMasterBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponsePersonName = ({
  selectedScreen,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleAddMasterBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponseLocation = ({
  selectedScreen,
  responseStyleOptions,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  handleAddMasterBindParameter,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponseMultipleChoice = ({
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  appendMasterFields,
  selectedScreen,
  responseMultipleChoiceOptions,
  goToResponseValue,
  selectedResVal,
  handleAddChoice,
  handleDeleteChoice,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  responseSettingsType,
  watchMasterTableOne,
  control,
  selectedResChoice,
  handleSaveResponse,
  handleAddBindParameter,
  handleAddMasterBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
  updatedQuestionDetails
}: ResponseMultipleChoiceProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
            <div className="w-full h-auto mb-[3.8rem]">
              <SelectInput
                labelName={t("web.logic.label.responsestyle")}
                error={errors?.responseStyle?.message}
                idName="responseStyle"
                name="responseStyle"
                control={control}
                isRequired={true}
                options={responseMultipleChoiceOptions}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <ResponseValue
          goToResponseValue={() => goToResponseValue()}
          selectedResVal={selectedResVal.filter(
            (response: any) => response.isDeleted === 0
          )}
        />
        <QuestionMoreInfo
          fields={fields}
          masterFields={masterFields}
          setValue={setValue}
          errors={errors}
          control={control}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          bindingoptions={bindingoptions}
          selectedScreen={selectedScreen}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={[
            { id: "apirequired", label: "API Required" },
            { id: "mastertable", label: "Master Table" },
          ]}
          updatedQuestionDetails={updatedQuestionDetails}
        />

        {/* <ResponseSettingsChoices
          handleAddChoice={handleAddChoice}
          handleDeleteChoice={handleDeleteChoice}
          selectedResChoice={selectedResChoice}
          handleSaveResponse={(data) => handleSaveResponse(data)}
        /> */}
      </div>
    </div>
  );
};
const ResponseText = ({
  selectedScreen,
  responseTextOptions,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  handleAddMasterBindParameter,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
  updatedQuestionDetails
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
            <div className="w-full h-auto ">
              <SelectInput
                labelName={t("web.logic.label.responsestyle")}
                error={errors?.responseStyle?.message}
                idName="responseStyle"
                name="responseStyle"
                control={control}
                isRequired={true}
                options={responseTextOptions}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <QuestionMoreInfo
          fields={fields}
          masterFields={masterFields}
          setValue={setValue}
          errors={errors}
          control={control}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          selectedScreen={selectedScreen}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={[
            { id: "apirequired", label: "API Required" },
          ]}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      </div>
    </div>
  );
};
const ResponseDateTime = ({
  selectedScreen,
  responseDateTimeOptions,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  handleAddMasterBindParameter,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
  
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
            <div className="w-full h-auto">
              <SelectInput
                labelName={t("web.logic.label.responsestyle")}
                error={errors?.responseStyle?.message}
                idName="responseStyle"
                name="responseStyle"
                control={control}
                isRequired={true}
                options={responseDateTimeOptions}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponseSignature = ({
  selectedScreen,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  handleAddMasterBindParameter,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponseScanner = ({
  selectedScreen,
  responseScannerOptions,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  handleAddMasterBindParameter,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
  updatedQuestionDetails
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
            <div className="w-full h-auto ">
              <SelectInput
                labelName={t("web.logic.label.responsestyle")}
                error={errors?.responseStyle?.message}
                idName="responseStyle"
                name="responseStyle"
                control={control}
                isRequired={true}
                options={responseScannerOptions}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <QuestionMoreInfo
          fields={fields}
          masterFields={masterFields}
          setValue={setValue}
          errors={errors}
          control={control}
          appendApiRequiredFields={appendApiRequiredFields}
          handleAddMasterBindParameter={handleAddMasterBindParameter}
          appendMasterFields={appendMasterFields}
          handleAddBindParameter={handleAddBindParameter}
          handleBinParameterDelete={handleBinParameterDelete}
          handleMasterBinParameterDelete={handleMasterBinParameterDelete}
          selectedCheckboxOptions={selectedCheckboxOptions}
          bindingoptions={bindingoptions}
          selectedScreen={selectedScreen}
          watchApiRequired={watchApiRequired}
          watchMasterTableOne={watchMasterTableOne}
          responseSettingsType={responseSettingsType}
          setAPIAndMasterSchema={selectedCheckboxOptions}
          setAPIRequiredDefault={setAPIRequiredDefault}
          setMasterDefault={setMasterDefault}
          setServiceBindParamval={setServiceBindParamval}
          setMasterBindParamval={setMasterBindParamval}
          replaceAPIRequired={replaceAPIRequired}
          replaceMasterTable={replaceMasterTable}
          questionMoreInfoOptions={[
            { id: "apirequired", label: "API Required" },
            { id: "mastertable", label: "Master Table" },
          ]}
          updatedQuestionDetails={updatedQuestionDetails}
        />
      </div>
    </div>
  );
};
const ResponseNumber = ({
  selectedScreen,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  handleAddMasterBindParameter,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponseImage = ({
  selectedScreen,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  handleAddMasterBindParameter,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
}: ResponseComponentProps) => {
  console.log("errors", errors);
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponseMagicContainer = ({
  selectedScreen,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  handleAddMasterBindParameter,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  responseMagicContainerOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
            <div className="w-full h-auto ">
              <SelectInput
                labelName={t("web.logic.label.responsestyle")}
                error={errors?.responseStyle?.message}
                idName="responseStyle"
                name="responseStyle"
                control={control}
                isRequired={true}
                options={responseMagicContainerOptions}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponseAcknowledgement = ({
  selectedScreen,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  handleAddMasterBindParameter,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponseInformation = ({
  selectedScreen,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  handleAddMasterBindParameter,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponseVideo = ({
  selectedScreen,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  handleAddMasterBindParameter,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponseImageWithInputs = ({
  selectedScreen,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  handleAddMasterBindParameter,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponseAudio = ({
  selectedScreen,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  handleAddMasterBindParameter,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResponseToggle = ({
  selectedScreen,
  register,
  errors,
  watchIsMandatory,
  watchIsEvidence,
  watchNotes,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  fields,
  setValue,
  masterFields,
  appendApiRequiredFields,
  handleAddMasterBindParameter,
  appendMasterFields,
  control,
  handleAddBindParameter,
  handleBinParameterDelete,
  selectedCheckboxOptions,
  handleMasterBinParameterDelete,
  bindingoptions,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
}: ResponseComponentProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-between items-start gap-[2.5rem]">
          <div className="w-1/2 h-auto">
            <div className="w-full h-auto mb-[3.8rem]">
              <InputField
                labelName={t("web.logic.label.questionname")}
                type="text"
                id="questionName"
                isRequired={true}
                formHandle={register("questionName")}
                error={errors?.questionName?.message}
              />
            </div>
          </div>
          <div className="w-1/2 h-auto ">
            <div className="w-full h-auto flex justify-start items-center gap-[3.5rem]">
              <ToggleButton
                id="madatory"
                label={t("web.logic.label.mandatory")}
                size="large"
                formHandle={register("isMandatory")}
                value={watchIsMandatory}
              />
              {selectedScreen?.flowId !== "getstarted" && (
                <>
                  <ToggleButton
                    id="evidence"
                    label={t("web.logic.label.evidence")}
                    size="large"
                    formHandle={register("isEvidence")}
                    value={watchIsEvidence}
                  />
                  <ToggleButton
                    id="notes"
                    label={t("web.logic.label.notes")}
                    size="large"
                    formHandle={register("notes")}
                    value={watchNotes}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponseSettingsForm;
