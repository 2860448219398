import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { Autoplay, Pagination } from "swiper/modules";
import {
  LoginSlideFour,
  LoginSlideOne,
  LoginSlideThree,
  LoginSlideTwo,
} from "../../svg";

function LoginSlider() {
  const sliderData = [
    {
      title: "Inspection One - Vision",
      description:
        "Our Vision pillar automates visual checks, enhancing accuracy and speeding up inspections. By leveraging advanced AI, it can identify defects, anomalies, and quality issues in real-time, reducing human error and significantly improving efficiency. Use cases include automated quality control in manufacturing and rapid assessment in construction sites",
      image: <LoginSlideOne />,
    },
    {
      title: "Inspection One - Document",
      description:
        "Our Document pillar automates the extraction of details from documents and builds intelligence using domain-specific custom logic. This eliminates manual and error-prone document-based operations and workflows, ensuring accuracy and compliance. Use cases include automated invoice processing, contract analysis, and regulatory compliance checks.",
      image: <LoginSlideTwo />,
    },
    {
      title: "Inspection One - Speech",
      description:
        "Our Speech pillar synthesizes speech and generates comprehensive analytics from audio files. It can assess customer sentiment, transcribe conversations, and provide actionable insights from spoken interactions. Use cases include customer service optimization, call center analytics, and enhancing user experiences with voice-activated systems.",
      image: <LoginSlideThree />,
    },
    {
      title: "Inspection One - Text",
      description:
        "Our Text pillar understands human language and translates it into actions, while also performing advanced text analytics. It can automate customer inquiries, generate insights from textual data, and provide real-time language translation. Use cases include chatbots for customer support, sentiment analysis from social media, and multilingual communication solutions.",
      image: <LoginSlideFour />,
    },
  ];

  return (
    <div className="w-full h-auto flex-1">
      <div className="w-full h-full">
        <Swiper
          effect={"fade"}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={true}
          modules={[Pagination, Autoplay]}
          slidesPerView={1}
          speed={1000}
        >
          {sliderData.map((slider, index) => (
            <SwiperSlide key={index}>
              <div className="w-full h-auto mb-[5%] max-md:mb-[3%] max-md:my-[4%]">
                <div className="w-[45vh] max-3xl:w-[40vh] h-auto mx-auto mb-[5%] max-md:w-[35%] max-md:mb-[2%] block max-md:hidden">
                  {slider.image}
                </div>
                <div className="w-full h-auto text-center">
                  <h2 className="text-[3.6rem] font-inter font-bold text-io-white mb-[1.6rem]">
                    “{slider.title}”
                  </h2>
                  <p className="w-[80%] mx-auto text-[1.8rem] font-inter font-normal text-io-white text-opacity-70">
                    {slider.description}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
export default LoginSlider;
