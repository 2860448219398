import { ReactNode, useEffect, useState } from "react";
import { ExploreCustomIcon, BluePrintIcon } from "../../svg";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface Option {
  id: string;
  label: string;
  count: number;
  icon: ReactNode;
}

type DataItem = {
  count: number;
  icon: string;
  // Other properties if present
};

function PillarTypes({
  isIconOnly,
  data,
  handleSelectionType,
  isLoading,
  magixType,
  actionType,
}: any) {
  const { t } = useTranslation();
  const { magicType } = useParams();
  const [selectedOption, setSelectedOption] = useState<string>(
    magixType ? "4" : "3"
  );

  const findIconCount = (data: DataItem[], iconName: string): number => {
    if (!data) return 0;
    const iconItem = data.find((index: DataItem) => index.icon === iconName);
    return iconItem ? iconItem.count : 0;
  };

  const options: Option[] = [
    {
      id: "3",
      label: t("web.label.common.custom"),
      count: findIconCount(data, "ExploreCustomIcon"),
      icon: <ExploreCustomIcon selectedOption={selectedOption} />,
    },
    {
      id: "4",
      label: t("web.label.common.blueprint"),
      count: findIconCount(data, "BluePrintIcon"),
      icon: <BluePrintIcon selectedOption={selectedOption} />,
    },
  ];

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    console.log("Selected option:", event.target.value);
    handleSelectionType(event.target.value);
  };

  useEffect(() => {
    setSelectedOption(magicType || "3");
  }, []);

  return (
    <div
      className={`w-auto h-auto flex justify-start items-center max-xl:w-full max-xl:mb-[3%] ${
        isIconOnly
          ? "gap-[0.8rem] max-3xl:gap-[0.4rem]"
          : "gap-[1.2rem] max-3xl:gap-[0.8rem]"
      }`}
    >
      {options.map((option) => {
        const shouldDisable =
          actionType === "magicFlow" &&
          ((!magixType && option.id === "4") ||
            (magixType && option.id === "3"));
        return (
          <div key={option.id}>
            <input
              type="radio"
              id={option.id}
              value={option.id}
              checked={selectedOption === option.id}
              onChange={handleOptionChange}
              className="hidden"
              disabled={isLoading || shouldDisable}
            />
            <label
              htmlFor={option.id}
              className={`w-auto h-auto flex justify-center items-center cursor-pointer rounded-full font-inter font-normal ${
                isIconOnly
                  ? "text-[1.1rem] px-6 max-3xl:px-4 py-2 gap-1"
                  : "text-[1.6rem] px-9 max-3xl:px-6 py-3 gap-2"
              } ${
                selectedOption === option.id
                  ? "bg-io-primary text-io-white"
                  : "bg-io-gray-f7 text-io-black dark:bg-io-black-1a dark:text-io-white"
              }`}
            >
              <div
                className={`${
                  isIconOnly ? "w-[1.8rem] h-[1.8rem]" : "w-[2.4rem] h-[2.4rem]"
                }`}
              >
                {option.icon}
              </div>
              {(isIconOnly && selectedOption === option.id) || !isIconOnly ? (
                <>
                  {option.label}
                  <span
                    className={`${
                      selectedOption === option.id
                        ? "text-io-white"
                        : "text-io-gray-66"
                    }`}
                  >
                    &#10098;{option.count}&#10099;
                  </span>
                </>
              ) : null}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export default PillarTypes;
