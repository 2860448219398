import { useTranslation } from "react-i18next";
import IconTextBtn from "../../../form/icon-text-btn";
import { AddTextBtnIcon, PreviewIcon, RefreshIcon } from "../../../svg";

interface Option {
  id: string;
  label: string;
}
interface ScreenListHeaderProps {
  screensList: any;
  selectedScreen: any;
  addNewScreen: () => void;
  refreshScreen: () => void;
  showPreview: () => void;
  setShowPreviewProcessModal: () => void;
  hideAddBtn: boolean;
}

function ScreenListHeader({
  screensList,
  selectedScreen,
  addNewScreen,
  refreshScreen,
  showPreview,
  setShowPreviewProcessModal,
  hideAddBtn,
}: ScreenListHeaderProps) {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto flex justify-between items-center mb-[2rem]">
      <div className="w-auto h-auto">
        <h4 className="text-[2.4rem] font-inter font-semibold text-io-black dark:text-io-white">
          {t("web.section.ailogicstudio.screenlist")} (
          {screensList?.length > 0 ? screensList?.length : "0"})
        </h4>
      </div>
      <div className="w-auto h-auto">
        <div className="w-auto flex justify-end items-center gap-[3.4rem]">
          {selectedScreen?.flowId !== "getstarted" && (
            <div className="w-auto">
              <IconTextBtn
                label={t("web.section.ailogicstudio.addscreen")}
                btnSize="large"
                icon={<AddTextBtnIcon />}
                handleClick={() => addNewScreen()}
              />
            </div>
          )}

          <div className="w-auto">
            <IconTextBtn
              label={t("web.toolbar.label.refresh")}
              btnSize="large"
              icon={<RefreshIcon />}
              handleClick={() => refreshScreen()}
            />
          </div>
          {/* <div className="w-auto block max-md:hidden">
                        <IconTextBtn label="Preview" btnSize="large" icon={<PreviewIcon />} handleClick={showPreview} />
                    </div>
                    <div className="w-auto hidden max-md:block">
                        <IconTextBtn label="Preview" btnSize="large" icon={<PreviewIcon />} handleClick={setShowPreviewProcessModal} />
                    </div> */}
        </div>
      </div>
    </div>
  );
}
export default ScreenListHeader;
