function PasteYourCode() {
  return (
    <div className="w-full h-auto mb-8">
      {/* <p className="text-[2rem] font-inter font-semibold text-io-black mb-4">
        Paste Your code Below
      </p>
      <textarea
        name="pasteYourCode"
        id="pasteYourCode"
        cols={30}
        rows={15}
        className="text-[1.2rem] font-mono font-normal text-io-black w-full border border-io-gray-c8 p-6 rounded-[0.3rem]"
        placeholder="Paste your Code here.."
      ></textarea> */}
    </div>
  );
}
export default PasteYourCode;
