import { useEffect, useRef, useState } from "react";
import { CameraIcon, DeleteIcon } from "../../svg";
import uploadFile from "../../../services/uploadFile";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import defaultIconImage from "../../../assets/images/upload-image-thumbnail.png";
import IconBtn from "../icon-btn";
function UploadImage({ formData, setFormData, isReadOnly }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState<string>(
    formData?.imageUrl
  );
  const uploadFiles = useRef<HTMLInputElement>(null);

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsLoading(true);
    const file = event.target.files && event.target.files[0];

    if (file) {
      try {
        const extension = file.name.split(".").pop() || "";
        const { response } = await uploadFile(file, extension);
        const url = response.data.fileUrl;
        setFormData((prevData: any) => ({
          ...prevData,
          imageUrl: url,
        }));
        setSelectedImage(url);
        setIsLoading(false);
      } catch (error) {
        console.error("Error uploading file:", error);
        setIsLoading(false);
      }
    } else {
      console.error("No file selected");
    }
  };

  useEffect(() => {
    setSelectedImage(formData?.imageUrl);
  }, [formData?.imageUrl]);
  const handleImageError = () => {
    setSelectedImage("");
  };
  const handleError = (e: any) => {
    e.target.src = defaultIconImage;
  };
  const handleDelete = () => {
    setFormData((prevData: any) => ({
      ...prevData,
      profileUrl: "",
    }));
    setSelectedImage("");
  };
  return (
    <div className="w-full h-auto">
      <p className="text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 mb-2">
        {t("web.common.label.uploadimage")}
      </p>
      <div className="w-full h-[19.4rem]">
        {isLoading ? (
          <Skeleton width="100%" height={150} borderRadius={3} />
        ) : (
          <>
            <input
              type="file"
              id="uploadNewUserProfile"
              onChange={handleFileInputChange}
              ref={uploadFiles}
              className="hidden w-0 h-0"
            />

            {selectedImage ? (
              <div className="w-full h-full relative">
                <div className="w-full h-full rounded-[0.5rem] overflow-hidden border border-io-gray-c8">
                  <img
                    src={formData?.imageUrl}
                    alt="uploadedImage"
                    className="w-auto h-full mx-auto object-contain object-center"
                    onError={(e) => handleError(e)}
                  />
                </div>
                <div className="w-auto absolute bottom-[2%] right-[2%]">
                  <div className="w-auto flex justify-end items-center gap-[1rem]">
                    <div className="w-auto h-auto bg-io-white p-3 rounded-full">
                      <IconBtn icon={<DeleteIcon />} size="small" handleClick={handleDelete}/>
                    </div>
                    <div className="w-[3rem]">
                      <label
                        htmlFor="uploadNewUserProfile"
                        className={`w-full h-auto ${isReadOnly
                            ? "opacity-50 pointer-events-none"
                            : "opacity-100 pointer-events-auto"
                          }`}
                      >
                        <CameraIcon />
                      </label>
                    </div>


                  </div>
                </div>

              </div>
            ) : (
              <label
                htmlFor="uploadNewUserProfile"
                className={`w-full h-full block border border-dashed border-io-primary rounded-[0.5rem] `}
              >
                <div className="w-full h-full flex justify-center items-center">
                  <div className="w-[7.2rem] h-auto mx-auto">
                    <img
                      src={require("../../../assets/images/upload-image-thumbnail.png")}
                      alt="uploadimagethumbnail"
                    />
                  </div>
                </div>
              </label>
            )}
          </>
        )}
      </div>
    </div>
  );
}
export default UploadImage;
