import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { InputField } from "../input-fields";
import { TextBtn } from "../text-btn";
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../select-input";
import { useState } from "react";
import { CalendarIcon } from "../../svg";

interface GridFilterOptionsProps {
  handleClose: () => void;
}

function GridFilterOptions({ handleClose }: GridFilterOptionsProps) {
  const { t } = useTranslation();
  const createFormSchema = yup.object().shape({
    name: yup.string(),
    status: yup.string(),
    status2: yup.string(),
    createdDate: yup.string(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(createFormSchema),
  });

  const [RangeDateValue, setRangeDateValue] = useState(undefined)

  const handleFormSubmit = (data: any) => {
    handleClose();
  };
  const handleCancel = () => {
    handleClose();
  };
  const filterSelectOptions = [
    { id: 0, value: "radioButton", label: "Radio Button" },
    { id: 1, value: "multipleChoice", label: "Multiple Choice" },
    { id: 2, value: "dropDown", label: "DropDown" },
    { id: 3, value: "dropdownMultiSelect", label: "Dropdown Multi Select" },
  ]
  return (
    <div className="relative">
      <div className="w-[35rem] px-[2rem] py-[2rem] bg-io-white dark:bg-io-black-1a shadow-xl rounded-[0.6rem] ">
        <form
          className="w-full"
          onSubmit={handleSubmit((data) => handleFormSubmit(data))}
        >
          <div className="w-full h-auto mb-8">
            <InputField
              labelName={t("web.master.label.name")}
              type="text"
              id="name"
              isRequired={true}
              formHandle={register("name")}
              error={errors?.name?.message}
            />
          </div>
          
          <div className="w-full h-auto mb-8">
          <SelectInput
                labelName={"Status"}
                error={errors?.status?.message}
                idName="status"
                name="status"
                control={control}
                isRequired={true}
                isSearchable={true}
                options={filterSelectOptions}
              />
          </div>
          <div className="w-full h-auto mb-8">
          <SelectInput
                labelName={"Status 2"}
                error={errors?.status2?.message}
                idName="status2"
                name="status2"
                control={control}
                isRequired={true}
                isSearchable={false}
                options={filterSelectOptions}
              />
          </div>
          <div className="w-full h-auto mb-8 relative">
            <DatePicker
                value={RangeDateValue}
                onChange={(newRange: any) =>
                  setRangeDateValue(newRange)
                }
                range
                rangeHover
                dateSeparator=" to "
                portal
                inputClass="cus-date-range"
                format="YYYY-MM-DD"
                placeholder="Select Date Range"
                
              />
             <div className="w-[2rem] h-auto absolute top-[50%] translate-y-[-50%] right-[5%] pointer-events-none"> 
                <CalendarIcon />
             </div>
            {/* <InputField
              labelName={t("web.common.label.createddate")}
              type="date"
              id="createdDate"
              isRequired={true}
              formHandle={register("createdDate")}
              error={errors?.createdDate?.message}
            /> */}
          </div>
          <div className="w-full h-auto flex justify-end items-center gap-4">
            <TextBtn
              label={t("web.common.button.cancel")}
              btnSize="small"
              btnStyle="fill-secondary"
              btnType="button"
              handleClick={() => handleCancel()}
            />
            <TextBtn
              label={t("web.common.button.submit")}
              btnSize="small"
              btnStyle="fill-primary"
              btnType="submit"
            />
          </div>
        </form>
      </div>
      <div className="absolute top-[-2rem] right-4 w-auto h-auto border-b-[1rem] border-b-io-white dark:border-b-io-black-1a border-t-[1rem] border-t-transparent border-s-[1rem] border-s-transparent border-e-[1rem] border-e-transparent shadow-2xl"></div>
    </div>
  );
}
export default GridFilterOptions;
