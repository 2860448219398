import { Fragment } from "react/jsx-runtime";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import PreviewCreatedLabel from "../../preview-created-label";
import StepsHeader from "../../steps-header";
import UploadTrainingDataSet from "../../upload-training-dataset";
import KeywordCreationChip from "../../../UI/keyword-creation-chip";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CustomMagicStepFour({
  setCurrentStep,
  title,
  data,
  setData,
  handleSubmit,
  isLoading,
}: any) {
  const { id, magicType } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPreview, setShowPreview] = useState(false);
  const [dynamicHeight, setDynamicHeight] = useState(0);
  const elementRef = useRef<any>(null);

  useEffect(() => {
    if (elementRef.current) {
      const height = elementRef.current.clientHeight - 20;

      setDynamicHeight(height);
    }
  }, [title]);
  const [activeTabUniqueId, setActiveTabUniqueId] = useState(
    data.labels.length > 0 ? data.labels[0].uniqueId : null
  );

  useEffect(() => {
    if (data.labels.length > 0) {
      setActiveTabUniqueId(data.labels[0].uniqueId);
    }
  }, []);
  setData(data);
  // Handle label click
  const handleLabelClick = (uniqueId: any) => {
    setActiveTabUniqueId(uniqueId);
  };

  return (
    <div className="w-full h-full flex flex-col">
      <StepsHeader title={title} />
      <div className="w-full h-auto my-3">
        <div className="w-full h-auto flex justify-start items-center my-3">
          <p className="w-[10%] max-md:w-[15%] text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white dark:text-opacity-50">
            {t("web.magix.label.magicname")}
          </p>
          <p className="w-[90%] max-md:w-[85%] text-[1.6rem] font-inter font-normal text-io-black dark:text-io-white">
            {data.name}
          </p>
        </div>
        <div className="w-full h-auto flex justify-start items-center my-3">
          <p className="w-[10%] max-md:w-[15%] text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white dark:text-opacity-50">
            {t("web.magix.label.description")}
          </p>
          <p className="w-[90%] max-md:w-[85%] text-[1.6rem] font-inter font-normal text-io-black dark:text-io-white">
            {data.description}
          </p>
        </div>
      </div>
      <div
        className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large"
        ref={elementRef}
      >
        <div className="w-full relative">
          <div className="w-full h-full p-4">
            <div className="w-full h-full flex">
              <div className="w-[21.26%] h-full sticky top-4 max-md:w-[35%]">
                <div
                  className="w-full bg-io-gray-f8 dark:bg-io-black-1c pt-3 flex flex-col"
                  style={{ height: dynamicHeight }}
                >
                  <div className="w-full flex-auto basis-0 pl-2 overflow-y-auto">
                    {data.labels.map((label: any, i: any) => {
                      if (label.isDeleted === 0) {
                        return (
                          <Fragment key={i}>
                            <KeywordCreationChip
                              labelName={label.name}
                              isActive={activeTabUniqueId === label.uniqueId}
                              isCheckbox={false}
                              currentStatus={false}
                              hasDeleteOption={false}
                              onClick={() => handleLabelClick(label.uniqueId)}
                            />
                          </Fragment>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="w-[78.74%] h-auto ml-auto max-md:w-[65%]">
                <div className="w-full h-auto px-[4.5rem] py-[3.5rem] max-md:p-[3rem]">
                  <PreviewCreatedLabel
                    isEditable={false}
                    fullScreenOption={false}
                    data={data}
                    activeTabUniqueId={activeTabUniqueId}
                  />
                  {Number(magicType) === 3 ? (
                    <UploadTrainingDataSet
                      isViewOnly={true}
                      data={data}
                      setData={setData}
                      activeTabUniqueId={activeTabUniqueId}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="steps"
        goToPrevious={() => setCurrentStep(Number(magicType) === 3 ? 3 : 2)}
        goToNext={() => navigate("/ai-magic-studio/vision")}
        isFinalStep={true}
        handleSaveDraft={handleSubmit}
        setData={setData}
        data={data}
        isLoading={isLoading}
      />
    </div>
  );
}
export default CustomMagicStepFour;
