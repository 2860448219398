
import { Outlet } from "react-router-dom"
import NoticeBar from "../../UI/notice-bar";
import { useState } from "react";

function MainArea(){
    return(
        <div className="flex-1 main-area bg-io-white dark:bg-io-black-15 overflow-auto">
        
            <div className="w-full h-full px-20 py-12 ">
                <div className="w-full h-full">                    
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
export default MainArea;