import ModalPageHeader from "../../../global-layout/modal-page-header";
import InputParametersForm from "../input-parameters-form";
import { useTranslation } from "react-i18next";

function InputParameters({ inputParameterParams, closeInputParameter }: any) {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex flex-col">
        <ModalPageHeader
          title={t("web.magix.label.inputparameters")}
          handleClose={closeInputParameter}
        />
        <div className="w-full h-full flex-auto">
          <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
            <InputParametersForm
              inputParameterParams={inputParameterParams}
              closeInputParameter={closeInputParameter}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default InputParameters;
