import { Fragment, useEffect, useRef, useState } from "react";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import StepsHeader from "../../steps-header";
import { DeleteIcon } from "../../../svg";
import PreviewCreatedLabel from "../../preview-created-label";
import UploadTrainingDataSet from "../../upload-training-dataset";
import KeywordCreationChip from "../../../UI/keyword-creation-chip";

interface CustomMagicStepOneProps {
  setCurrentStep: (step: number) => void;
  title: string;
}

function CustomMagicStepThree({ setCurrentStep, title }: CustomMagicStepOneProps) {
  const [dynamicHeight, setDynamicHeight] = useState(0);
  const elementRef = useRef<any>(null);

  useEffect(() => {
    if (elementRef.current) {
      const height = elementRef.current.clientHeight - 20;

      setDynamicHeight(height);
    }
  }, [title]);

  const labelCreation = [
    { labelName: "Pass", isActive: true },
    { labelName: "Fail", isActive: false },
    { labelName: "Spell Check Vehicle Spectra", isActive: false },
  ]

  return (
    <div className="w-full h-full flex flex-col">
      <StepsHeader title={title} />
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large" ref={elementRef}>
        <div className="w-full relative">
          <div className="w-full h-full p-4">
            <div className="w-full h-full flex">
              <div className="w-[21.26%] h-full sticky top-4 max-md:w-[35%]">
                <div className="w-full bg-io-gray-f8 dark:bg-io-black-1c pt-3 flex flex-col" style={{ height: dynamicHeight }}>
                  <div className="w-full flex-auto basis-0 pl-2 overflow-y-auto">
                    {labelCreation.map((label, i) => (
                      <Fragment key={i}>
                        <KeywordCreationChip labelName={label.labelName} isActive={label.isActive} />
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-[78.74%] h-auto ml-auto max-md:w-[65%]">
                <div className="w-full h-auto px-[4.5rem] py-[3.5rem]">
                  <UploadTrainingDataSet isViewOnly={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
      footerType="steps"
        goToPrevious={() => setCurrentStep(2)}
        goToNext={() => setCurrentStep(4)}
      />
    </div>
  )
}
export default CustomMagicStepThree