import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import Authentication from "../../../auth/Authentication";
import { azureLogin } from "../../../services/authFunctions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AccountInfo } from "@azure/msal-common"; // Adjust the import path as per your setup
interface Account {
  username: string;
  email: string;
}
function LoginWithAzure({ setPageLoader }: any) {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const authService = Authentication();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loginRequest = {
    scopes: ["user.read"],
    prompt: "select_account",
  };
  const mapAccountInfoToAccount = (accountInfo: AccountInfo): Account => {
    // Implement logic to extract or construct email from AccountInfo
    const email = getEmailForAccountInfo(accountInfo); // Replace with your logic
    return {
      username: accountInfo.username,
      email: email,
    };
  };
  const getEmailForAccountInfo = (accountInfo: AccountInfo): string => {
    // Replace with your logic to fetch or construct email
    return accountInfo.username.toLowerCase();
  };
  const handleLogin = async () => {
    try {
      await instance.loginPopup(loginRequest);
      const accountInfos: AccountInfo[] = instance.getAllAccounts();
      const accounts: Account[] = accountInfos.map(mapAccountInfoToAccount);
      if (accounts.length > 0) {
        const selectedAccount = await selectAccount(accounts);
        setIsLoading(true);
        setPageLoader(true);
        const result = await azureLogin(selectedAccount.email);
        if (result.success) {
          authService.login(2);
          authService.encryptData(result.data?.loginDetails, "loginDetails", 2);
          authService.encryptData(result.data?.sideMenu, "sideBarDetails", 2);
          navigate(result.data?.activeMenu.routerLink);
          setIsLoading(false);
          setPageLoader(false);
        } else {
          authService.login(2);
          setIsLoading(false);
          setPageLoader(false);
        }
      } else {
        setIsLoading(false);
        setPageLoader(false);
        console.error("No accounts found after login.");
      }
    } catch (error) {
      setIsLoading(false);
      setPageLoader(false);
      console.error("Login failed: ", error);
    }
  };
  const selectAccount = async (accounts: Account[]): Promise<Account> => {
    // Implement your logic to select an account here
    // For simplicity, let's assume we select the first account
    return accounts[0];
  };
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto text-center">
        <p className="text-[1.8rem] font-inter font-normal text-io-gray-66 my-[1.3rem] relative or-line">
          {t("web.domain.label.or")}
        </p>
        <p className="text-[1.8rem] font-inter font-normal text-io-gray-66">
          {t("web.domain.label.loginwith")}{" "}
          <span
            className="uppercase font-semibold text-io-primary cursor-pointer"
            onClick={handleLogin}
          >
            MICROSOFT
          </span>
        </p>
      </div>
    </div>
  );
}

export default LoginWithAzure;
