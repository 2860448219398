import { Outlet } from "react-router-dom";

function AIMagicStudio({ visionUpdated }: any) {
  return (
    <>
      <Outlet />
    </>
  );
}
export default AIMagicStudio;
