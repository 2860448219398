import { useEffect, useState } from "react";
import LoginWithAzure from "../../UI/login-with-azure";
import { InputFieldWithoutForm } from "../../form/input-fields";
import { LoginBtn } from "../../form/text-btn";
import { useNavigate } from "react-router";
import Authentication from "../../../auth/Authentication";
import { useTranslation } from "react-i18next";
import { isValidEmailAddress, validatePassword } from "../../../utils/common";
import {
  changePassword,
  domainSubmit,
  loginSubmit,
} from "../../../services/authFunctions";
import {
  domainValidation,
  loginValidation,
} from "../../../services/validation";
import ReCAPTCHA from "react-google-recaptcha";
function LoginSteps({ setPageLoader }: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authService = Authentication();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  //#region Domain
  const [tenantLogoUrl, setTenantLogoUrl] = useState("");
  const [domainValue, setDomain] = useState<string>("");
  const [errorDomainMessage, setDomainErrorMessage] = useState<string>("");
  const handleDomainChange = (value: string) => {
    setDomain(value);
  };
  const handleDomainValidation = () => {
    var errorMessage = domainValidation(domainValue);
    setDomainErrorMessage(errorMessage);
    return !errorMessage;
  };

  const handleDomainSubmit = async () => {
    if (handleDomainValidation()) {
      setIsLoading(true);
      const result = await domainSubmit(domainValue);
      if (result.success) {
        authService.login(1);
        setTenantLogoUrl(result.response.tenantLogo);
        authService.encryptData(result.data, "serviceDetails", 1);
        setCurrentStep(2);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  //#endregion
  //#region Login
  const [emailValue, setEmail] = useState("");
  const [passwordValue, setPassword] = useState("");
  const [errorEmailMessage, setEmailError] = useState<string>("");
  const [errorPasswordMessage, setPasswordMessage] = useState<string>("");
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [captchaError, setCaptchaError] = useState<string>("");
  const [captcha, setCaptcha] = useState<boolean>(false);

  const captchaSiteKey = "6LcG5v0pAAAAAHGfx_tKiMINcy0HnV4oQkGXNM3p";

  const handleEmailChange = (email: any) => {
    setEmail(email.trim());
    setEmailError(
      email.trim().length > 5 && !isValidEmailAddress(email)
        ? "web.login.alert.invalidemail"
        : ""
    );
  };
  const handlePasswordChange = (password: any) => {
    setPassword(password);
    setPasswordMessage("");
  };
  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe);
  };
  const handleLoginValidation = () => {
    const { emailError, passwordError } = loginValidation(
      emailValue,
      passwordValue
    );

    setEmailError(emailError);
    setPasswordMessage(passwordError);

    return !emailError && !passwordError;
  };
  const handleLoginSubmit = async () => {
    if (!captchaValue && captcha) {
      setCaptchaError("Please complete the reCAPTCHA");
      return;
    }
    if (handleLoginValidation()) {
      setIsLoading(true);
      setPageLoader(true);
      const result = await loginSubmit(emailValue, passwordValue);
      if (result.success) {
        authService.login(2);
        authService.encryptData(result.data?.loginDetails, "loginDetails", 2);
        authService.encryptData(result.data?.sideMenu, "sideBarDetails", 2);
        authService.encryptData(
          result.data?.userSiteDetails,
          "userSiteDetails",
          2
        );
        if (
          result.data?.freshlogin === 1 ||
          result.data?.isPasswordExpired === 1
        ) {
          setCurrentStep(4);
        } else if (result.data?.loginAttempts) {
          setCaptcha(true);
          setIsLoading(false);
          setPageLoader(false);
        } else {
          if (rememberMe) {
            rememberMeCache();
          }

          navigate(result.data?.activeMenu.routerLink);
        }
        setIsLoading(false);
        setPageLoader(false);
      } else {
        if (result?.isPasswordExpired === 1) {
          setCurrentStep(4);
        }
        authService.login(2);
        setIsLoading(false);
        setPageLoader(false);
      }
    }
  };
  const rememberMeCache = () => {
    authService.encryptData(emailValue.trim(), "rememberedEmail", 1);
    authService.encryptData(passwordValue, "rememberedPassword", 1);
  };
  //#region UseEffect
  //#region
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const clearFormData = () => {
    setFormData({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };
  const [errors, setErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const handleInputChange = (field: string, value: string) => {
    const updatedFormData = { ...formData, [field]: value };
    setFormData(updatedFormData);
    const errors = validatePassword(updatedFormData);
    setErrors(errors);
  };
  const isFormValid = () => {
    return (
      errors.currentPassword.length === 0 &&
      errors.newPassword.length === 0 &&
      errors.confirmPassword.length === 0
    );
  };

  const handleChangePassword = async () => {
    clearFormData();
    if (isFormValid()) {
      setIsLoading(true);
      const response = await changePassword(
        formData.currentPassword,
        formData.newPassword
      );
      if (response.success) {
        setIsLoading(false);
        setTimeout(() => {
          setCurrentStep(1);
          authService.logout();
        }, 1000);
      } else {
        setIsLoading(false);
      }
    }
  };
  //#endregion
  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    if (rememberedEmail) {
      const email = authService.decryptData("rememberedEmail", 1) || "";
      const password = authService.decryptData("rememberedPassword", 1) || "";
      setEmail(email);
      setPassword(password);
      setRememberMe(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  const handleCaptchaChange = (value: string | null) => {
    console.log("Captcha value:", value);
    setCaptchaValue(value);
    setCaptchaError("");
  };
  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="w-full h-auto mx-auto">
            <div className="w-full h-auto text-center">
              <div className="w-full h-auto mb-[5.4rem]">
                <h1 className="text-[4.2rem] font-inter font-bold text-io-black-1a mb-[0.7rem]">
                  {t("web.domain.label.title")}
                </h1>
                <p className="text-[1.8rem] font-inter font-normal text-io-gray-66">
                  {t("web.domain.label.subtitle")}
                </p>
              </div>
              <div className="w-[55.5%] h-auto mx-auto">
                <div className="w-full h-auto">
                  <div className="mb-[5rem]">
                    <InputFieldWithoutForm
                      labelName={t("web.domain.label.domain")}
                      type="text"
                      id="domain"
                      isRequired={true}
                      value={domainValue}
                      onChange={handleDomainChange}
                      error={t(errorDomainMessage)}
                      isLightThemeOnly={true}
                      textColor="text-io-black"
                    />
                  </div>
                  <div className="w-full mb-[3rem]">
                    <LoginBtn
                      label={t("web.login.button.login")}
                      btnType="button"
                      handleClick={handleDomainSubmit}
                      isLoading={isLoading}
                    />
                  </div>
                  {/* <div className="formGroup">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      ref={captchaRef}
                    />
                  </div> */}
                  {/* <LoginWithAzure /> */}
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="w-full h-auto mx-auto">
            <div className="w-full h-auto">
              <div className="w-[32rem] h-auto max-3xl:w-[25rem] max-2xl:w-[20rem] mx-auto">
                <img src={tenantLogoUrl} alt="AI Magix Logo" />
              </div>
            </div>
            <div className="w-full h-auto mx-auto">
              <div className="w-full h-auto text-center">
                <div className="w-full h-auto mb-[5.4rem]">
                  <h1 className="text-[4.2rem] font-inter font-bold text-io-black-1a mb-[0.7rem]">
                    {t("web.login.label.title")}
                  </h1>
                  <p className="text-[1.8rem] font-inter font-normal text-io-gray-66">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
                <div className="w-[55.5%] h-auto mx-auto">
                  <div className="w-full h-auto">
                    <div className="mb-[5rem]">
                      <InputFieldWithoutForm
                        labelName={t("web.login.text.email")}
                        type="text"
                        id="email"
                        isRequired={true}
                        error={t(errorEmailMessage)}
                        value={emailValue}
                        onChange={handleEmailChange}
                        isLightThemeOnly={true}
                        textColor="text-io-black"
                      />
                    </div>
                    <div className="mb-[3rem]">
                      <InputFieldWithoutForm
                        labelName={t("web.login.text.password")}
                        type="password"
                        id="password"
                        isRequired={true}
                        error={t(errorPasswordMessage)}
                        value={passwordValue}
                        onChange={handlePasswordChange}
                        isLightThemeOnly={true}
                        textColor="text-io-black"
                      />
                    </div>
                    <div className="w-full h-auto mb-[5rem]">
                      <div className="w-full h-auto flex justify-between items-center">
                        <div className="w-auto flex justify-start items-center gap-4">
                          <input
                            type="checkbox"
                            name={t("web.login.text.rememberme")}
                            id="rememberme"
                            onChange={handleCheckboxChange}
                            checked={rememberMe}
                          />
                          <label
                            htmlFor="rememberme"
                            className="text-[1.4rem] font-inter font-normal text-io-gray-66"
                          >
                            {t("web.login.text.rememberme")}
                          </label>
                        </div>
                        {/* <p className="text-[1.4rem] font-inter font-semibold text-io-primary uppercase">
                          <a>{t("web.login.text.forgot")}?</a>
                        </p> */}
                      </div>
                    </div>
                    {captcha && (
                      <div className="captcha-containter">
                        <ReCAPTCHA
                          // sitekey="6LcIGP0pAAAAABM_OpMIJ94b1nmXw3N8XbX_9SCb"
                          sitekey={captchaSiteKey}
                          onChange={handleCaptchaChange}
                        />
                        {captchaError && (
                          <span className="captcha-error">{captchaError}</span>
                        )}
                      </div>
                    )}
                    <div className="w-full mb-[3rem]">
                      <LoginBtn
                        label={t("web.login.button.login")}
                        btnType="button"
                        isLoading={isLoading}
                        handleClick={handleLoginSubmit}
                      />
                    </div>
                    <LoginWithAzure setPageLoader={setPageLoader} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="w-full h-auto">
            <div className="w-full h-auto">
              <div className="w-[32rem] h-auto max-3xl:w-[25rem] max-2xl:w-[20rem] mx-auto">
                <img src={tenantLogoUrl} alt="AI Magix Logo" />
              </div>
            </div>
            <div className="w-full h-auto mx-auto">
              <div className="w-full h-auto text-center">
                <div className="w-full h-auto mb-[5.4rem]">
                  {/* <h1 className="text-[4.2rem] font-inter font-bold text-io-black-1a mb-[0.7rem]">
                     {t("web.login.text.forgot")}
                  </h1> */}
                  <p className="text-[1.8rem] font-inter font-normal text-io-gray-66">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
                <div className="w-[55.5%] h-auto mx-auto">
                  <div className="w-full h-auto">
                    <div className="mb-[5rem]">
                      <InputFieldWithoutForm
                        labelName={t("web.profile.label.email")}
                        type="text"
                        id="forgetEmail"
                        isRequired={true}
                        isLightThemeOnly={true}
                        textColor="text-io-black"
                      />
                    </div>
                    <div className="w-full mb-[3rem]">
                      <LoginBtn
                        label={t("web.section.lable.send")}
                        btnType="button"
                        handleClick={() => setCurrentStep(4)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="w-full h-auto">
          <div className="w-full h-auto">
              <div className="w-[32rem] h-auto max-3xl:w-[25rem] max-2xl:w-[20rem] mx-auto">
                <img src={tenantLogoUrl} alt="AI Magix Logo" />
              </div>
            </div>
            <div className="w-full h-auto mx-auto">
              <div className="w-full h-auto text-center">
                <div className="w-full h-auto mb-[5.4rem]">
                  <h1 className="text-[4.2rem] font-inter font-bold text-io-black-1a mb-[0.7rem]">
                    {t("web.login.text.reset")}
                  </h1>
                  <p className="text-[1.8rem] font-inter font-normal text-io-gray-66">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
                <div className="w-[55.5%] h-auto mx-auto">
                  <div className="w-full h-auto">
                    <div className="mb-[4rem]">
                      <InputFieldWithoutForm
                        labelName={t("web.settings.label.currentpassword")}
                        type="password"
                        id="currentPassword"
                        isRequired={true}
                        value={formData.currentPassword}
                        error={t(errors.currentPassword)}
                        onChange={(e) =>
                          handleInputChange("currentPassword", e)
                        }
                        isLightThemeOnly={true}
                        textColor="text-io-black"
                      />
                    </div>
                    <div className="mb-[4rem]">
                      <InputFieldWithoutForm
                        labelName={t("web.settings.label.newpassword")}
                        type="password"
                        id="newPassword"
                        isRequired={true}
                        value={formData.newPassword}
                        error={t(errors.newPassword)}
                        onChange={(e) => handleInputChange("newPassword", e)}
                        isLightThemeOnly={true}
                        textColor="text-io-black"
                      />
                    </div>
                    <div className="mb-[3rem]">
                      <InputFieldWithoutForm
                        labelName={t("web.settings.label.confirmpassword")}
                        type="password"
                        id="confirmPassword"
                        isRequired={true}
                        value={formData.confirmPassword}
                        error={t(errors.confirmPassword)}
                        onChange={(e) =>
                          handleInputChange("confirmPassword", e)
                        }
                        isLightThemeOnly={true}
                        textColor="text-io-black"
                      />
                    </div>
                    <div className="w-full mb-[3rem]">
                      <LoginBtn
                        label={t("web.section.lable.send")}
                        btnType="button"
                        isLoading={isLoading}
                        handleClick={handleChangePassword}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className="w-full flex-1 overflow-y-auto">
      <div className="w-full h-full relative flex justify-center items-center ">
        {renderStepContent()}
      </div>
    </div>
  );
}
export default LoginSteps;
