import { useNavigate, useParams } from "react-router-dom";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import CreateCustomMagicSteps from "../create-custom-magic-steps";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function CreateCustomMagic({ visionUpdated, setIsConfetti }: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, magicType } = useParams();
  const [title, setTitle] = useState("");
  useEffect(() => {
    const isBlueprint = Number(magicType) === 4;
    const action = id !== "new" ? "edit" : "create";
    const magicTypeLabel = isBlueprint ? "blueprintmagic" : "custommagic";
    setTitle(t(`web.magix.label.${action}${magicTypeLabel}`));
  }, [id, magicType]);

  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex flex-col">
        <ModalPageHeader
          title={title}
          handleClose={() => navigate(`/ai-magic-studio/vision/${magicType}`)}
        />
        <div className="w-full h-full flex-auto">
          <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
            <CreateCustomMagicSteps
              visionUpdated={visionUpdated}
              setIsConfetti={setIsConfetti}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateCustomMagic;
