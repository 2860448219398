import { useNavigate } from "react-router-dom";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import CreateCustomMagicSteps from "../create-custom-magic-steps";
import { useTranslation } from "react-i18next";
function CreateCustomMagic() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex flex-col">
        <ModalPageHeader
          title={t("web.magix.label.createcustommagic")}
          handleClose={() => navigate("/ai-magic-studio/document")}
        />
        <div className="w-full h-full flex-auto">
          <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
            <CreateCustomMagicSteps />
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateCustomMagic;
