import ModalPageWrapper from "../../../components/global-layout/modal-page-wrapper";
import CreateCustomMagic from "../../../components/section/document-create/create-custom-magic";

function DocumentCreate() {
  
  return (
    <ModalPageWrapper>
      <div className="w-[66.25%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <CreateCustomMagic />
      </div>
    </ModalPageWrapper>
  );
}
export default DocumentCreate;
