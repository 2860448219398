import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '../../assets/locales/en.json';
import esTranslation from '../../assets/locales/es.json';
import filTranslation from '../../assets/locales/fil.json';
import frTranslation from '../../assets/locales/fr.json';
import deTranslation from '../../assets/locales/de.json';
import itTranslation from '../../assets/locales/it.json';
import jaTranslation from '../../assets/locales/ja.json';
import koTranslation from '../../assets/locales/ko.json';
import ptTranslation from '../../assets/locales/pt.json';
import ruTranslation from '../../assets/locales/ru.json';
import taTranslation from '../../assets/locales/ta.json';
import teTranslation from '../../assets/locales/te.json';
import zhTranslation from '../../assets/locales/zh.json';

i18n.use(initReactI18next).init({
  resources: {
    en : { translation: enTranslation },
    es : { translation: esTranslation },
    fil : { translation: filTranslation },
    fr : { translation: frTranslation },
    de : { translation: deTranslation },
    it : { translation: itTranslation },
    ja : { translation: jaTranslation },
    ko : { translation: koTranslation },
    pt : { translation: ptTranslation },
    ru : { translation: ruTranslation },
    ta : { translation: taTranslation },
    te : { translation: teTranslation },
    zh : { translation: zhTranslation },
  },
  lng: 'en', // Set the default language
  fallbackLng: 'en', // Fallback language if translation is missing for the current language
  interpolation: {
    escapeValue: false, // Allows using HTML tags in translations
  },
});

 
export default i18n;
