import { useTranslation } from "react-i18next";
import VoiceSearchInput from "../../form/voice-search-input";
import ModalPageHeader from "../../global-layout/modal-page-header";
import ModalPageWrapper from "../../global-layout/modal-page-wrapper";

interface HelpAndSupportProps {
  handleClose: () => void;
}

function HelpAndSupport({ handleClose }: HelpAndSupportProps) {
  const { t } = useTranslation();
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-2xl:w-[50%] max-md:w-[85%]">
        <div className="w-full h-full flex flex-col">
          <ModalPageHeader
            title={t("web.common.title.helpandsupport")}
            handleClose={() => handleClose()}
          />
          <div className="w-full h-full flex-auto overflow-auto">
            <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
              <div className="w-full h-auto mb-[4.5rem]">
                <h3 className="text-[2.2rem] font-inter font-medium text-io-black dark:text-io-white">
                  {t("web.helpsupport.label.helpmsg1")}
                </h3>
                <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                  {t("web.helpsupport.label.helpmsg2")}
                </p>
              </div>
              <div className="w-full h-auto">
                {/* <div className="w-full h-auto p-4 flex justify-between items-center rounded-[0.3rem] border border-io-gray-c8">
                                <div className="w-[5%] h-auto ">
                                    <div className="w-[1.6rem] h-auto">
                                    <img src={require("../../../assets/images/search.png")} alt="Search" />
                                    </div>
                                </div>
                                <input type="text" name="helpAndSupportSearch" id="helpAndSupportSearch" className="flex-1 border-none bg-none text-[1.6rem] font-inter font-normal text-io-black" placeholder="Search" />
                                <div className="w-[5%] h-auto ">
                                    <div className="w-[1.6rem] h-auto">
                                    <img src={require("../../../assets/images/mic.png")} alt="Search" />
                                    </div>
                                </div>
                            </div> */}
                <VoiceSearchInput inputSize="large" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default HelpAndSupport;
