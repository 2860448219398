import { useNavigate } from "react-router-dom";
import ModalPageFooter from "../../global-layout/modal-page-footer";
import IconBtn from "../../form/icon-btn";
import { LeftArrowIcon } from "../../svg";
import { InputFieldWithoutForm } from "../../form/input-fields";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { validatePassword } from "../../../utils/common";
import Authentication from "../../../auth/Authentication";
import { ToastError, ToastSuccess, ToastWarning } from "../../UI/toast";
import ToastifyMessage from "../../../lib/toastify";
import { userServices } from "../../../services/InspectionOneServices";
import { changePassword } from "../../../services/authFunctions";

interface PasswordSettingsProps {
  goToSettings: () => void;
  handleClose: () => void;
}

function PasswordSettings({
  goToSettings,
  handleClose,
}: PasswordSettingsProps) {
  const authService = Authentication();
  const { t } = useTranslation();
  let parsedLoginDetails: any;
  parsedLoginDetails = authService.decryptData("loginDetails", 2);
  parsedLoginDetails = parsedLoginDetails || "{}";
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const handleInputChange = (field: string, value: string) => {
    const updatedFormData = { ...formData, [field]: value };
    setFormData(updatedFormData);
    const errors = validatePassword(updatedFormData);
    setErrors(errors);
  };
  const isFormValid = () => {
    const errors = validatePassword(formData);
    setErrors(errors);
    return (
      errors.currentPassword.length === 0 &&
      errors.newPassword.length === 0 &&
      errors.confirmPassword.length === 0
    );
  };

  const handleChangePassword = async () => {
    if (isFormValid()) {
      setIsLoading(true);
      const response = await changePassword(
        formData.currentPassword,
        formData.newPassword
      );
      if (response.success) {
        setIsLoading(false);
        setTimeout(() => {
          authService.logout();
        }, 1000);
      } else {
        setIsLoading(false);
      }
    }
  };
  const handleFormCancel = () => {
    handleClose();
  };
  //#region Alert

  const [isLoading, setIsLoading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);

  //#endregion

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-full h-auto flex justify-start items-center gap-4 mb-[2.5rem]">
          <IconBtn
            size="w-[1.9rem]"
            icon={<LeftArrowIcon />}
            handleClick={() => goToSettings()}
          />
          <p className="text-[2.2rem] font-inter font-medium text-io-black dark:text-io-white">
            {t("web.subtitle.label.changepassword")}
          </p>
        </div>
        <div className="w-[70%] h-auto">
          <div className="mb-[5rem]">
            <InputFieldWithoutForm
              labelName={t("web.settings.label.currentpassword")}
              type="password"
              id="settingsCurrentPassword"
              isRequired={true}
              value={formData.currentPassword}
              error={t(errors.currentPassword)}
              onChange={(e) => handleInputChange("currentPassword", e)}
            />
          </div>
          <div className="mb-[5rem]">
            <InputFieldWithoutForm
              labelName={t("web.settings.label.newpassword")}
              type="password"
              id="settingsNewPassword"
              isRequired={true}
              value={formData.newPassword}
              error={t(errors.newPassword)}
              onChange={(e) => handleInputChange("newPassword", e)}
            />
          </div>
          <div className="mb-[3rem]">
            <InputFieldWithoutForm
              labelName={t("web.settings.label.confirmpassword")}
              type="password"
              id="settingsConfirmPassword"
              isRequired={true}
              value={formData.confirmPassword}
              error={t(errors.confirmPassword)}
              onChange={(e) => handleInputChange("confirmPassword", e)}
            />
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={() => handleFormCancel()}
        handleSubmit={() => handleChangePassword()}
        isLoading={isLoading}
      />
    </div>
  );
}
export default PasswordSettings;
