import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../../components/global-layout/page-header";
import DeleteUser from "../../components/section/user-management/delete-user";
import GridColumn from "../../components/section/user-management/grid-columns";
import { AILogicStudioPageHeaderIcon } from "../../components/svg";
import AILogicStudioGrid from "../../components/section/ai-logic-studio/ai-logic-studio-grid";
import GridToolBar from "../../components/UI/grid-toolbar";
import AILogicStudioCards from "../../components/section/ai-logic-studio/ai-logic-studio-cards";
import excelExport from "../../services/excelExport";
import ErrorPopup from "../../components/UI/error-popup";
import PageLoader from "../../components/UI/page-loader";
import { useTranslation } from "react-i18next";
import { logicDelete, logicList } from "../../services/authFunctions";
import { useMyContext } from "../../components/my-context/MyContext";

function AILogicStudio({ logicUpdated, setLogicUpdated }: any) {
  const { isExternalUser } = useMyContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [startIndex, setStartIndex] = useState(0);
  const [logicData, setLogicData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeletonLoader, setIsSkeletonLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(35);
  const [initialLoading, setIntialLoading] = useState(true);
  const [finalFetch, setFinalFetch] = useState(false);
  const scrollGroundRef = useRef<any>(null);
  const [sorting, setSorting] = useState<number>(0);
  const [searchedInputVal, setSearchedInputVal] = useState("");
  const [sortingColumnName, setSortingColumnName] = useState<string>("");
  const [isServerError, setIsServerError] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [isGridView, setIsGridView] = useState(false);
  const [toolItems, setToolItems] = useState<any>([]);
  const [searchedQuery, setSearchedQuery] = useState<any>(undefined);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<any>(false);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [isRefreshed, setIsRefreshed] = useState<any>(false);
  const [isFetchMore, setIsFetchMore] = useState<any>(false);
  const [deleteData, setDeleteData] = useState<any>(null);
  const [resetSearch, setResetSearch] = useState<any>(false);

  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    "name",
    "description",
    "createdAt",
  ]);
  const columnsData = [
    { label: "Logic Name", token: "web.logic.label.name", key: "name" },
    {
      label: "Logic Description",
      token: "web.logic.label.desc",
      key: "description",
    },
    {
      label: "Created Date",
      token: "web.common.label.createddate",
      key: "createdAt",
    },
  ];
  //#region Common Function
  const createNewUser = () => {
    navigate("/ai-logic-studio/new-process-logic/new");
  };
  const handleEdit = (items: any) => {
    navigate(`/ai-logic-studio/new-process-logic/${items.uniqueId}`);
  };
  const handleNavigate = (cardData: any) => {
    navigate(`process-logic/${cardData.uniqueId}`);
  };
  const handleRefresh = () => {
    if (!isLoading) {
      setLogicData([]);
      setStartIndex(0);
      setResetSearch(true);
      setSortingColumnName("");
      setSorting(0);
      setIsRefreshed((pre: any) => !pre);
      setPageLoader(false);
    }
  };

  const handleDelete = async (items: any) => {
    setDeleteData(items);
    setShowDeletePopup(true);
  };
  const handleDataDelete = async (items: any) => {
    setShowDeletePopup(false);
    setPageLoader(true);

    const response = await logicDelete(items);
    if (response.success) {
      setLogicData([]);
      setStartIndex(0);
      setIsDeleted((prev) => !prev);
      setPageLoader(false);
    } else {
      setPageLoader(false);
    }
  };
  const handleCheckboxChange = (event: any, key: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedColumns((prevSelected) => [...prevSelected, key]);
    } else {
      if (selectedColumns.length === 1) return;
      setSelectedColumns((prevSelected) =>
        prevSelected.filter((item) => item !== key)
      );
    }
  };
  //#endregion
  //#region Use Effect
  useEffect(() => {
    const baseTools = ["search", "export", "refresh"];
    const tools = isGridView
      ? isExternalUser
        ? baseTools
        : ["create", ...baseTools]
      : isExternalUser
      ? [...baseTools, "columns"]
      : ["create", ...baseTools, "columns"];

    setToolItems(tools);
  }, [isGridView]);

  useEffect(() => {
    if (searchedQuery !== undefined) {
      setLogicData([]);
      setStartIndex(0);
      setSearchedInputVal(searchedQuery);
    }
  }, [searchedQuery]);

  useEffect(() => {
    if (logicUpdated === true) {
      setLogicData([]);
      setStartIndex(0);
      setIsUpdated((pre: any) => !pre);
    }
  }, [logicUpdated]);

  useEffect(() => {
    if (!isLoading) {
      logicListFetch(searchedInputVal);
      setLogicUpdated(undefined);
      setResetSearch(false);
    }
  }, [
    isFetchMore,
    sortingColumnName,
    sorting,
    searchedInputVal,
    isDeleted,
    isUpdated,
    isRefreshed,
  ]);

  //#endregion
  //#region list Fetch
  const logicListFetch = async (query: any) => {
    setIsLoading(true);
    setIsSkeletonLoader(true);
    setFinalFetch(false);
    setNoDataFound(false);

    const response: any = await logicList(
      query,
      startIndex,
      limit,
      sorting,
      sortingColumnName
    );
    if (response.success) {
      const responseData = response.data.listDetails;
      const totalCount = response.data.count;
      setLogicData((prevData: any) => [...prevData, ...responseData]);
      setTotalCount(totalCount);
      setIsLoading(false);
      setIsSkeletonLoader(false);
      if (responseData.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
      if (startIndex < limit) {
        setIntialLoading(false);
      }
    }
  };
  //#endregion
  //#region Scroll

  const loadMore = (limit: any) => {
    if (!finalFetch) {
      setStartIndex((prevIndex) => prevIndex + limit);
      setIsFetchMore((pre: any) => !pre);
    }
  };

  const handleScroll = () => {
    const chatGround = scrollGroundRef.current;
    if (
      chatGround.scrollTop + 1 + chatGround.clientHeight >=
      chatGround.scrollHeight
    ) {
      console.log("Scroll", startIndex, totalCount);
      if (startIndex + limit < totalCount) {
        loadMore(limit);
      } else {
        setFinalFetch(true);
      }
    }
  };
  //#endregion
  //#region Sorting
  const handleSort = (column: any, value: number, columnName: string) => {
    console.log(column.field);
    setLogicData([]);
    setStartIndex(0);
    setSorting(value);
    setSortingColumnName(columnName);
  };
  //#endregion
  //#region  Excel Export
  const [isExcelLoading, setExcelLoading] = useState(false);
  const handleExcel = async () => {
    setExcelLoading(true);
    const screenName = "logic";
    const fileName = "Logic List";
    const sheetName = "Logic_List";
    try {
      await excelExport.excelExport(
        screenName,
        fileName,
        sheetName,
        columnsData
      );
      setExcelLoading(false);
      console.log("Export successful");
    } catch (error) {
      console.error("Export failed:", error);
      setExcelLoading(false);
    }
  };

  //#endregion
  //#region  Render Page

  const checkLocation = () => {
    if (!pathname.includes("ai-logic-studio/process-logic")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {checkLocation() ? (
        <div className="w-full h-full flex flex-col">
          <PageHeader
            title={t("web.title.label.logic")}
            icon={<AILogicStudioPageHeaderIcon />}
          />
          <div className="w-full flex-1">
            <div className="w-full h-full flex flex-col">
              <GridToolBar
                columnCount={totalCount}
                gridType={t("web.toolbar.label.logic")}
                selectView={true}
                tools={toolItems}
                handleAddRow={createNewUser}
                handleDelete={() => setShowDeletePopup(true)}
                handleColumns={() => setShowColumns(true)}
                isGridView={(isChecked: any) => setIsGridView(isChecked)}
                fetchData={(query: any) => setSearchedQuery(query)}
                getSearchedQuery={(query: any) => setSearchedQuery(query)}
                handleRefresh={handleRefresh}
                handleExcel={handleExcel}
                isExcelLoading={isExcelLoading}
                resetSearch={resetSearch}
                isSkeletonLoader={isSkeletonLoader}
              />
              <div className="w-full flex-1">
                {isGridView ? (
                  <AILogicStudioCards
                    handleNavigate={handleNavigate}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    rowData={logicData}
                    scrollGroundRef={scrollGroundRef}
                    handleScroll={handleScroll}
                    initialLoading={initialLoading}
                    isLoading={isLoading}
                    limit={limit}
                    finalFetch={finalFetch}
                    noDataFound={noDataFound}
                  />
                ) : (
                  <AILogicStudioGrid
                    selectedColumns={selectedColumns}
                    rowSelection={false}
                    moreOptions={!isExternalUser}
                    handleNavigate={handleNavigate}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    rowData={logicData}
                    scrollGroundRef={scrollGroundRef}
                    handleScroll={handleScroll}
                    initialLoading={initialLoading}
                    isLoading={isLoading}
                    limit={limit}
                    finalFetch={finalFetch}
                    sorting={sorting}
                    sortingColumnName={sortingColumnName}
                    handleSort={handleSort}
                    handleCheckboxChange={handleCheckboxChange}
                    noDataFound={noDataFound}
                  />
                )}
              </div>
            </div>
          </div>
          {/* <CreateFloatingButton
            icon={<CreateNewUserButtonIcon />}
            handleClick={createNewUser}
          /> */}
        </div>
      ) : null}
      {showDeletePopup && (
        <DeleteUser
          handleCancel={() => setShowDeletePopup(false)}
          rowData={deleteData}
          handleDelete={handleDataDelete}
          actionFlag="aiLogicStudio"
        />
      )}
      {showColumns && (
        <GridColumn
          handleClose={() => setShowColumns(false)}
          handleCheckboxChange={handleCheckboxChange}
          columnsData={columnsData}
          selectedColumns={selectedColumns}
        />
      )}
      {isServerError && (
        <ErrorPopup handleClose={() => setIsServerError(false)} />
      )}
      {pageLoader && <PageLoader />}
      <Outlet />
    </>
  );
  //#endregion
}
export default AILogicStudio;
