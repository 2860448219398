import { containerServices, startupScreenServices, logicServices, screenMapping, screenCollectionServices, userServices,processServices } from "./InspectionOneServices";
import generateExcelFile from "./generateExcelFile";

interface ApiResponse {
  success: boolean;
  data?: any;
  message?: string;
}

const handleApiRequest = async (
  requestJson: any,
  apiService: (req: any) => Promise<ApiResponse>
): Promise<any> => {
  try {
    const response = await apiService(requestJson);
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}; 

const excelExport = {
  excelExport: async (screenName: string, fileName: string, sheetName: string, columnsData: any, logicId: string = ""): Promise<any> => {
    const getRequestJson = (screenName: string) => {
    if (screenName === 'user') {
        return {
          searchString: "",
          startIndex: 0,
          limit: 100 * 10000000,
        };
      } else {
        return {
          logicUniqueId: logicId,
          filterDetails: {
            searchValue: "",
            startIndex: 0,
            limit: 100 * 10000000,
            startDate: null,
            endDate: null,
            sorting: 0,
            columnName: "",
          }
        };
      }
    };

    const getApiService = (screenName: string) => {
      switch (screenName) {
        case 'user':
          return userServices.UserLists;
        case 'logic':
          return logicServices.LogicList;
        case 'screencollection':
          return screenCollectionServices.ScreenCollectionList;
        case 'startupscreen':
          return startupScreenServices.StartupScreenList;
        case 'screenmapping':
          return screenMapping.ScreenMappingList;
          case 'container':
          return containerServices.ContainerLists;
          case 'ailogicresults':
            return processServices.ProcessLists;
        default:
          throw new Error(`Unknown screenName: ${screenName}`);
      }
    };
    
    const extractData = (screenName: string, responseData: any) => {
      switch (screenName) {
        case 'logic':
          return responseData.listDetails;
        case 'screencollection':
          return responseData.listDetails;
        case 'startupscreen':
          return responseData.listDetails;
        case 'screenmapping':
          return responseData.listDetails;
        case 'container':
            return responseData.listDetails;
        case 'user':
          return responseData.userListDetails;
          case 'ailogicresults':
            const newArray = responseData.processDetails.map((item: any) => ({
              processLogicId: item.processLogicId,
              subProcessLogicId: item.subProcessLogicId,
              processName: item.title,
              processLogic: item.processLogicName,
              subProcessLogic: item.subProcessLogicName,
              inspectedBy: item.createdBy,
              verificationStatus: item.displayStatus,
              createdDate: item.createdAt,
              moreDetails: item.moreDetails,
              statusId: item.statusId,
              processLogicUniqueId: item.processLogicUniqueId,
              logicIcon: item.logicIcon,
              uniqueId: item.uniqueId,
              id: item.id,
            }));
            return newArray;
        default:
          return responseData;
      }
    };

    try {
      const requestJson = getRequestJson(screenName);
      const apiService = getApiService(screenName);
      const responseData = await handleApiRequest(requestJson, apiService);
      const extractedData = extractData(screenName, responseData);
      generateExcelFile(extractedData, fileName, sheetName, columnsData);
    } catch (error) {
      console.error("Error exporting data:", error);
      return null;
    }
  },
};

export default excelExport;
