import { useNavigate, useParams } from "react-router-dom";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import CreateProcessForm from "../create-process-form";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

function CreateProcess({ logicUpdated }: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (id !== "new") {
      setTitle(t("web.subtitle.label.editlogic"));
    } else {
      setTitle(t("web.subtitle.label.addlogic"));
    }
  }, [id, t]);

  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex flex-col">
        <ModalPageHeader
          title={title}
          handleClose={() => navigate("/ai-logic-studio")}
        />
        <div className="w-full h-full flex-auto">
          <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
            <CreateProcessForm logicUpdated={logicUpdated} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateProcess;
