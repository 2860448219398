import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function GridSearch({ onSearch, getSearchedQuery, reset }: any) {
  const [query, setQuery] = useState<any>(undefined);
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query !== undefined) {
        onSearch(query);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  useEffect(() => {
    getSearchedQuery?.(query);
  }, []);

  useEffect(() => {
    if (reset) {
      setQuery("");
    }
  }, [reset]);

  return (
    <div className="w-auto h-auto">
      <input
        type="text"
        className="w-[10rem] max-2xl:w-[10.5rem] h-auto bg-transparent text-[1.7rem] font-inter placeholder:text-io-black dark:placeholder:text-io-black-d1 text-io-black dark:text-white font-normal py-[0.8rem] pl-[2.8rem] pr-6 border border-transparent rounded-[0.7rem] transition-all duration-300 focus:w-[28rem] max-3xl:focus:w-[28rem] focus:border-io-gray-c8 focus:dark:border-io-black-48 focus:placeholder:text-io-gray-66 relative search-icon"
        placeholder={t("web.toolbar.label.search")}
        onChange={handleChange}
        value={query}
      />
    </div>
  );
}
export default GridSearch;
