import ModalPageFooter from "../../global-layout/modal-page-footer";
import IconBtn from "../../form/icon-btn";
import { LeftArrowIcon } from "../../svg";
import Authentication from "../../../auth/Authentication";
import i18n from "../../../utils/localization/i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { languageDetails } from "../../../utils/common";
interface LanguageSettingsProps {
  goToSettings: () => void;
  handleClose: () => void;
}

function LanguageSettings({
  goToSettings,
  handleClose,
}: LanguageSettingsProps) {
  const { t } = useTranslation();
  const authService = Authentication();
  // const languageDetails = authService.decryptData("languageDetails", 2) || "";
  const selectedLanguageDetails = authService.decryptData(
    "selectedLanguageDetails",
    2
  ) || { id: 1, code: "en", value: "English" };
  const languageData = languageDetails;
  const [selectedLanguage, setSelectedLanguage] = useState(
    selectedLanguageDetails.code
  );
  const [isLoading, setIsLoading] = useState(false);
  const handleFormSubmit = () => {
    setIsLoading(true);
    const selectedLanguageDetails = languageDetails.find(
      (lang: any) => lang.code === selectedLanguage
    );
    authService.encryptData(
      selectedLanguageDetails,
      "selectedLanguageDetails",
      2
    );
    i18n.changeLanguage(selectedLanguage);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    handleClose();
  };
  const handleLanguageChange = (item: any) => {
    setSelectedLanguage(item);
  };
  const handleFormCancel = () => {
    handleClose();
  };
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-full h-auto flex justify-start items-center gap-4 mb-[2.5rem]">
          <IconBtn
            size="w-[1.9rem]"
            icon={<LeftArrowIcon />}
            handleClick={() => goToSettings()}
          />
          <p className="text-[2.2rem] font-inter font-medium text-io-black dark:text-io-white">
            {t("web.settings.label.language")}
          </p>
        </div>
        <div className="w-full h-auto">
          {languageData &&
            languageData.map((item: any, i: any) => (
              <div
                key={i}
                className="w-full h-auto flex justify-start items-center gap-4 mb-6"
              >
                <input
                  type="radio"
                  name="languageSettings"
                  id={item.code}
                  value={item.code}
                  checked={selectedLanguage === item.code}
                  onChange={() => handleLanguageChange(item.code)}
                  className="accent-io-primary cursor-pointer"
                />
                <label
                  htmlFor={item.code}
                  className="text-[1.8rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1"
                >
                  {item.value}
                </label>
              </div>
            ))}
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={() => handleFormCancel()}
        handleSubmit={handleFormSubmit}
        isLoading={isLoading}
      />
    </div>
  );
}
export default LanguageSettings;
