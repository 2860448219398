import React from "react";
import EncryptionKey from "../../../auth/EncryptionKey";
import Authentication from "../../../auth/Authentication";
import { UserManagementIcon } from "../../svg";
import { useTranslation } from "react-i18next";

const primaryKey = EncryptionKey();
interface LoginDetails {
  otherDetails: {
    firstName: string;
    lastName: string;
    email: string;
    imageUrl?: string;
  };
}

function Banner() {
  let parsedLoginDetails = null;
  const authService = Authentication();
  parsedLoginDetails = authService.decryptData("loginDetails", 2);
  parsedLoginDetails = parsedLoginDetails || "{}";
  const otherDetails = parsedLoginDetails?.otherDetails;
  const firstName = otherDetails?.firstName ?? "";
  const lastName = otherDetails?.lastName ?? "";
  const email = otherDetails?.email ?? "";
  const { t } = useTranslation();
  return (
    <div className="w-full h-[18rem] mb-8 rounded-[2rem] overflow-hidden bg-[url('/src/assets/images/banner.webp')] bg-no-repeat bg-right-top ">
      <div className="w-full h-full banner-gradient dark:banner-gradient-dark">
        <div className="w-full h-full flex flex-col justify-center items-start px-14 star-pattern">
          <div className="w-full h-auto">
            <div className="w-full h-auto flex justify-start items-center gap-5 mb-4">
              <div className="w-[4.2rem] h-auto">
                <img
                  src={require("../../../assets/images/waving-hand.png")}
                  alt="Waving Hand"
                />
              </div>
              <h1 className="text-[5rem] font-inter text-io-black font-light dark:text-io-white">
                {t("web.label.common.hello")}{" "}
                <span className="font-semibold">
                  {firstName} &nbsp; {lastName} !
                </span>
              </h1>
            </div>
            <div className="w-3/5 max-md:w-[90%] h-auto">
              <p className="text-[1.6rem] font-inter font-normal text-io-gray-33 dark:text-io-black-a3">
                {email}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Banner;
