import { Fragment, useEffect, useRef, useState } from "react";
import ImageEditor from "../../../UI/image-editor";
import {
  InputFieldWithoutForm,
  TextAreaField,
} from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import StepsHeader from "../../steps-header";
import KeywordCreationChip from "../../../UI/keyword-creation-chip";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import IconBtn from "../../../form/icon-btn";
import { AddIcon } from "../../../svg";
import EnterTagsInput from "../../../form/enter-tags-input";
import { useParams } from "react-router-dom";

function CustomMagicStepTwo({
  setCurrentStep,
  title,
  data,
  setData,
  handleSubmit,
  isLoading,
  setImageEditorUpdatedData,
  imageEditorUpdatedData,
}: any) {
  const { t } = useTranslation();
  const { id, magicType } = useParams();
  const generateGuid = () => uuidv4();
  const [dynamicHeight, setDynamicHeight] = useState(0);
  const [currentTab, setCurrentTab] = useState("part");
  const [tags, setTags] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const elementRef = useRef<any>(null);

  const handleAddLabel = () => {
    const newLabelName =
      data.labels.length === 0 ? "Part 1" : `Part ${data.labels.length + 1}`;
    const newLabel = {
      isDeleted: 0,
      labelId: 0,
      uniqueId: generateGuid(),
      name: newLabelName,
      statusId: "status" + newLabelName.replace(/\s+/g, "").toLowerCase(),
      description: "",
      color: "#FFCCFF",
      tags: [],
      magicUrl: "",
      annotationDetails: [],
      uploadDetails: [],
    };
    const updatedLabelData = {
      ...data,
      labels: [...data.labels, newLabel],
    };
    setData(updatedLabelData);
  };

  if (data.labels.length === 0 && Number(magicType) === 3) {
    const passLabel = {
      isDeleted: 0,
      labelId: 0,
      uniqueId: generateGuid(),
      statusId: "1",
      name: "Pass",
      description: "",
      color: "#FFCCFF",
      tags: [],
      magicUrl: "",
      annotationDetails: [],
      uploadDetails: [],
    };
    const failLabel = {
      isDeleted: 0,
      labelId: 0,
      uniqueId: generateGuid(),
      statusId: "2",
      name: "Fail",
      description: "",
      color: "#FFCCFF",
      tags: [],
      magicUrl: "",
      annotationDetails: [],
      uploadDetails: [],
    };
    setData({ ...data, labels: [passLabel, failLabel] });
  } else if (data.labels.length === 0) {
    handleAddLabel();
  }
  const [activeTabUniqueId, setActiveTabUniqueId] = useState(
    data.labels.length > 0 ? data.labels[0].uniqueId : null
  );

  useEffect(() => {
    setTags(
      data.outputs.map((label: any) => ({
        uniqueId: label.uniqueId,
        name: label.name,
        isInputLabel: label.isInputLabel,
      }))
    );
  }, []);

  useEffect(() => {
    if (
      data.labels.length === 1 ||
      (data.labels.length === 2 && activeTabUniqueId === null)
    ) {
      setActiveTabUniqueId(data.labels[0].uniqueId);
    }
  }, [data.labels]);

  // Effect to calculate dynamic height
  useEffect(() => {
    if (elementRef.current) {
      const height = elementRef.current.clientHeight - 20;
      setDynamicHeight(height);
    }
  }, [title]);

  const handleChange = (
    inputName: string,
    valueOrEvent: string | React.ChangeEvent<HTMLInputElement>
  ) => {
    const value =
      typeof valueOrEvent === "string"
        ? valueOrEvent
        : valueOrEvent.target.value;

    const updatedData = {
      ...data,
      labels: data.labels.map((label: any) => {
        if (label.uniqueId === activeTabUniqueId) {
          return {
            ...label,
            [inputName]: value,
          };
        }
        return label;
      }),
    };
    // Update the state with the updated data
    setData(updatedData);
  };
  // Handle label click
  const handleLabelClick = (uniqueId: any) => {
    setCurrentTab("part");
    if (imageEditorUpdatedData?.labels) {
      const particularLabel = imageEditorUpdatedData.labels.find(
        (label: any) => label.uniqueId === activeTabUniqueId
      );
      if (particularLabel) {
        const updatedLabels = data.labels.map((label: any) =>
          label.uniqueId === activeTabUniqueId
            ? { ...label, ...particularLabel }
            : label
        );
        setData({ ...data, labels: updatedLabels });
      }
    }
    setActiveTabUniqueId(uniqueId);
    // validateFields(magicType);
  };

  const objectDataChange = (annotationData: any, uniqueId: any) => {
    console.log(annotationData, uniqueId);

    const objects = annotationData.getObjects();
    const objectData = objects.map((object: any, i: number) => {
      let data: any;
      if (object.type === "path" && object) {
        data = {
          annotationId: i,
          uniqueId: object.uniqueId,
          isDeleted: object.isDeleted,
          type: object.type,
          color: object.stroke,
          coordinates: [object.path],
        };
      } else {
        data = {
          annotationId: i,
          uniqueId: object.uniqueId,
          isDeleted: object.isDeleted,
          type: object.type,
          color: object.stroke,
          coordinates: [
            {
              left: object.left,
              top: object.top,
              width: object.width,
              height: object.height,
              fill: object.fill,
              stroke: object.stroke,
              strokeWidth: object.strokeWidth,
              type: object.type,
              color: object.stroke,
            },
          ],
        };
      }
      return data;
    });

    console.log("objectData", objectData);

    const updatedData = {
      ...data,
      labels: data.labels.map((label: any) => {
        if (label.uniqueId === activeTabUniqueId) {
          return {
            ...label,
            annotationDetails: objectData,
          };
        }
        return label;
      }),
    };
    setImageEditorUpdatedData(updatedData);
  };

  const updateAnnotationDetails = () => {
    if (imageEditorUpdatedData?.labels) {
      const updatedLabels = data.labels.map((label: any) => {
        const updatedLabel = imageEditorUpdatedData.labels.find(
          (updatedLabel: any) => updatedLabel.uniqueId === label.uniqueId
        );
        if (updatedLabel) {
          return {
            ...label,
            annotationDetails:
              updatedLabel.annotationDetails || label.annotationDetails,
          };
        }
        return label;
      });
      setData({ ...data, labels: updatedLabels });
    }
    setData({ ...data, outputs: tags });
  };

  const handlePrevious = () => {
    updateAnnotationDetails();
    setCurrentStep(1);
  };

  const handleNext = () => {
    if (validateFields(magicType)) {
      updateAnnotationDetails();
      setCurrentStep(3);
    }
  };

  const handleSaveDraft = () => {
    if (validateFields(magicType)) {
      updateAnnotationDetails();
      handleSubmit();
    }
  };

  const handleAddOutput = () => {
    setCurrentTab("output");
    setActiveTabUniqueId(null);
  };

  const setTagsAndUpdateData = (updatedTags: any) => {
    setTags(updatedTags);
  };
  const handleDelete = (index: any) => {
    const updatedData = {
      ...data,
      labels: data.labels.map((label: any) => {
        if (label.uniqueId === index) {
          return {
            ...label,
            isDeleted: 1,
          };
        }
        return label;
      }),
    };
    setData(updatedData);
  };
  const validateFields = (magicType: any) => {
    const newErrors: any = {};

    data.labels.forEach((label: any, index: number) => {
      const labelErrors: any = {};
      if (!label.name || label.name.trim() === "") {
        labelErrors.name =
          magicType === 3 ? "Label name is required" : "Part name is required";
      }
      if (Number(magicType) === 3) {
        if (!label.magicUrl || label.magicUrl.trim() === "") {
          labelErrors.magicUrl = "Please upload the image";
        }
      }
      if (Object.keys(labelErrors).length > 0) {
        newErrors[label.uniqueId] = {
          ...labelErrors,
          index,
          isError: true,
        };
      } else {
        newErrors[label.uniqueId] = {
          index,
          isError: false,
        };
      }
    });
    console.log("newErrors", newErrors);
    setErrors(newErrors);
    const hasErrors = Object.values(newErrors).some(
      (error: any) => error.isError
    );
    return !hasErrors;
    // return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="w-full h-full flex flex-col">
      <StepsHeader title={title} />
      <div
        className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large"
        ref={elementRef}
      >
        <div className="w-full relative ">
          <div className="w-full h-full p-4">
            <div className="w-full h-full flex ">
              <div className="w-[21.26%] h-full sticky top-4 max-md:w-[35%]">
                <div
                  className="w-full bg-io-gray-f8 dark:bg-io-black-1c pt-3 flex flex-col"
                  style={{ height: dynamicHeight }}
                >
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <div className="w-full h-auto py-[1.5rem] px-6 flex justify-between items-center">
                      <p className="text-[1.4rem] font-inter font-normal text-io-gray-33 dark:text-io-white dark:text-opacity-50">
                        {Number(magicType) === 3
                          ? t("web.magix.label.outputcreation")
                          : t("web.magix.label.partcreation")}
                        (
                        {data.labels
                          ? data.labels.filter((label: any) => !label.isDeleted)
                              .length
                          : 0}
                        )
                      </p>
                      {Number(magicType) === 4 && (
                        <IconBtn
                          size="w-[1.6rem]"
                          icon={<AddIcon />}
                          title={t("web.vision.title.addnewlabel")}
                          handleClick={() => handleAddLabel()}
                        />
                      )}
                    </div>
                  )}
                  <div className="w-full flex-auto basis-0 pl-2 overflow-y-auto">
                    {isLoading ? (
                      <Skeleton width="100%" height="100%" borderRadius={3} />
                    ) : (
                      data.labels.map((label: any, i: any) => {
                        if (label.isDeleted === 0) {
                          return (
                            <Fragment key={i}>
                              <KeywordCreationChip
                                labelName={label.name}
                                isActive={activeTabUniqueId === label.uniqueId}
                                isCheckbox={false}
                                currentStatus={false}
                                onClick={() => handleLabelClick(label.uniqueId)}
                                chipIndex={label.uniqueId}
                                hasDeleteOption={
                                  Number(magicType) === 4 ? true : false
                                }
                                handleDelete={() =>
                                  handleDelete(label.uniqueId)
                                }
                                hasErrorOption={true}
                                errorIcon={errors[label.uniqueId]?.isError}
                              />
                            </Fragment>
                          );
                        }

                        return null;
                      })
                    )}
                  </div>
                  <div className="w-full h-auto px-2 ">
                    <div
                      className={`w-full h-auto py-7 text-center border-t border-io-gray-c8 dark:border-io-black-48 ${
                        currentTab === "output" ? "bg-[#ECF4FB]" : ""
                      }`}
                    >
                      {isLoading ? (
                        <Skeleton width="100%" height={47} borderRadius={3} />
                      ) : (
                        <button
                          type="button"
                          className="text-[1.4rem] font-inter font-medium text-io-primary"
                          onClick={handleAddOutput}
                        >
                          {t("web.common.addaditionaloutput")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[78.74%] h-auto ml-auto max-md:w-[65%]">
                <div className="w-full h-auto p-[4.5rem] max-md:p-[3rem]">
                  {currentTab === "part" && (
                    <div className="w-[60%] h-auto max-md:w-full">
                      <div className="mb-[3.8rem]">
                        {isLoading ? (
                          <Skeleton width="100%" height={47} borderRadius={3} />
                        ) : (
                          <>
                            <InputFieldWithoutForm
                              labelName={
                                Number(magicType) === 3
                                  ? t("web.magix.label.outputname")
                                  : t("web.magix.label.partname")
                              }
                              type="text"
                              id="LabelName"
                              value={
                                data?.labels.find(
                                  (label: { uniqueId: any }) =>
                                    label.uniqueId === activeTabUniqueId
                                )?.name || ""
                              }
                              onChange={(e) => handleChange("name", e)}
                              isRequired={true}
                              error={errors[activeTabUniqueId]?.name}
                            />
                            {errors.name && (
                              <div className="text-red-500">{errors.name}</div>
                            )}
                          </>
                        )}
                      </div>
                      {isLoading ? (
                        <Skeleton width="100%" height={47} borderRadius={3} />
                      ) : (
                        <>
                          <ImageEditor
                            data={data}
                            defaultUniqueId={activeTabUniqueId}
                            onObjectDataChange={objectDataChange}
                            error={errors[activeTabUniqueId]?.magicUrl}
                          />
                          {/* {errors[activeTabUniqueId]?.magicUrl && (
                            <div className="text-red-600">
                              {errors[activeTabUniqueId].magicUrl}
                            </div>
                          )} */}
                        </>
                      )}
                      {isLoading ? (
                        <Skeleton width="100%" height={47} borderRadius={3} />
                      ) : (
                        <TextAreaField
                          labelName={t("web.vision.label.tellme")}
                          value={
                            data?.labels.find(
                              (label: { uniqueId: any }) =>
                                label.uniqueId === activeTabUniqueId
                            )?.description || ""
                          }
                          onChange={(e) => handleChange("description", e)}
                          type="text"
                          id="moreAbout"
                        />
                      )}
                    </div>
                  )}
                  {currentTab === "output" && (
                    <div className="w-[60%] h-auto max-md:w-full">
                      {isLoading ? (
                        <Skeleton width="100%" height={47} borderRadius={3} />
                      ) : (
                        <EnterTagsInput
                          labelName={t("web.common.addaditionaloutput")}
                          idName="visionEnterOutput"
                          tags={tags}
                          setTags={setTagsAndUpdateData}
                          type={2}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="steps"
        goToPrevious={handlePrevious}
        goToNext={handleNext}
        handleSaveDraft={handleSaveDraft}
        setData={setData}
        data={data}
      />
    </div>
  );
}

export default CustomMagicStepTwo;
