import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteIcon, EditIcon, MoreIcon } from "../../svg";
import CardMoreOptions from "../../form/card-more-options";
import { useMyContext } from "../../my-context/MyContext";

interface CardProps {
  item: any;
  handleEdit: (item: any) => void;
  handleDelete: (item: any) => void;
}
interface Option {
  label: string;
  icon: ReactNode;
  token: string;
}

function ExploreCard({ item, handleEdit, handleDelete }: CardProps) {
  const { isExternalUser } = useMyContext();
  const { t } = useTranslation();
  const [isContentVisible, setContentVisible] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const options: Option[] = [
    { label: "Edit", token: "web.title.common.edit", icon: <EditIcon /> },
    { label: "Delete", token: "web.title.common.delete", icon: <DeleteIcon /> },
    // { label: "Make a Copy", icon: <MakeAnCopyIcon /> },
    // { label: "Copy Link", icon: <CopyLinkIcon /> },
  ];
  const handleMouseLeave = (event: any) => {
    setContentVisible(false);
  };

  const handleOptionSelect = (option: any) => {
    if (option.label === "Edit") {
      handleEdit(item);
    } else {
      handleDelete(item);
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        contentRef.current &&
        !contentRef.current.contains(event.target as Node)
      ) {
        setContentVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="w-full h-auto group"
      onMouseLeave={handleMouseLeave}
      onClick={() => handleEdit(item)}
    >
      <div className="w-full h-auto rounded-[0.738rem] bg-io-gray-fa dark:bg-io-black-1a group-hover:bg-io-white group-hover:dark:bg-io-black-29 group-hover:shadow-[0_3px_21.1px_0_rgba(0,0,0,0.11)]">
        <div className="w-full h-auto p-[0.7rem] pb-[1.6rem] relative">
          <div className="w-full h-[12.6rem] mb-7 rounded-[1.07rem] relative overflow-hidden ">
            <div className="w-full h-full">
              <img
                className="w-full h-full object-contain object-center"
                src={`${
                  item.icon
                    ? item.icon
                    : require("../../../assets/images/ai-magic-no-image.png")
                }`}
                alt="Thumbnail"
              />
            </div>

            <div className="w-auto h-auto absolute left-0 bottom-0">
              {item.isCompleted === 0 ? (
                <div className="w-auto px-[0.6rem] py-[0.3rem] bg-io-yellow rounded-[0.2rem]">
                  <span className="text-[1.1rem] font-inter font-normal text-io-black">
                    {t("web.common.button.draft")}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className="w-auto h-auto absolute top-[5%] right-[5%] hidden group-hover:block max-md:block"
            ref={contentRef}
          >
            <div className="w-auto h-auto relative">
              {!isExternalUser && (
                <button
                  type="button"
                  className="w-[2rem] h-[2rem] rounded-full bg-io-primary flex justify-center items-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    setContentVisible(!isContentVisible);
                  }}
                >
                  <div className="w-[1.2rem] h-auto">
                    <MoreIcon />
                  </div>
                </button>
              )}
              {isContentVisible && (
                <div className="w-auto h-auto absolute top-full right-[-1rem] pt-5">
                  <CardMoreOptions
                    options={options}
                    onSelectOption={handleOptionSelect}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="w-full h-auto pl-[0.6rem]">
            <h4 className="text-[1.32rem] font-inter font-semibold text-io-black dark:text-io-white">
              {`${item.name}`}
            </h4>
            <div className="w-full h-auto flex justify-between items-center">
              <p title={item.description} className=" cursor-default text-[1.1rem] font-inter font-normal line-clamp-1 text-io-gray-66 dark:text-io-black-a3">
                {`${item.description}`}
              </p>
              <p className="text-[1.1rem] font-inter font-normal whitespace-nowrap text-io-gray-66 dark:text-io-black-a3">
                {`${item.createdAt}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ExploreCard;
