import ModalPageFooter from "../../../global-layout/modal-page-footer";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { statusAction } from "../../../../services/authFunctions";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../../my-context/MyContext";
const ProcessMoreInfoFormSchema = yup.object().shape({
  status: yup.string(),
});

function ProcessMoreInfoForm({
  statusDetail,
  selectedItem,
  handleClose,
  processUpdated,
}: any) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(ProcessMoreInfoFormSchema),
    defaultValues: { status: selectedItem[0]?.statusId },
  });
  const [isLoading, setIsLoading] = useState(false);
  const watchStatus = watch("status");
  const moreDetailsArray = selectedItem.map(
    (item: { moreDetails: any }) => item.moreDetails
  );
  const moreInfo = moreDetailsArray.flat();
  const { isExternalUser } = useMyContext();
  let optionData = [];
  if (
    statusDetail &&
    selectedItem &&
    selectedItem[0] && // Ensure selectedItem[0] is checked to avoid undefined errors
    selectedItem[0].processLogicId &&
    selectedItem[0].subProcessLogicId
  ) {
    optionData =
      (statusDetail[selectedItem[0].processLogicId] &&
        statusDetail[selectedItem[0].processLogicId][
          selectedItem[0].subProcessLogicId
        ]) ||
      [];
  }
  const filteredStatuses = optionData.filter((status: any) => {
    return status.availableStatus.includes(selectedItem[0].statusId);
  });

  const handleFormSubmit = async (data: any) => {
    const selectedOption = filteredStatuses.find(
      (option: any) => option.id === data.status
    );
    setIsLoading(true);
    var uniqueId = selectedItem[0]?.id;
    const statusId = selectedOption.id;
    const statusText = selectedOption.name;

    const response = await statusAction(uniqueId, statusId, statusText);
    if (response.success) {
      setTimeout(() => {
        setIsLoading(false);
        handleClose();
      }, 1000);
    } else {
      setIsLoading(false);
    }
  };

  //#region Alert

  const [isServerError, setIsServerError] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const { t } = useTranslation();
  //#endregion
  return (
    <form
      className="w-full h-full flex flex-col"
      onSubmit={handleSubmit((data) => handleFormSubmit(data))}
    >
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-full h-auto">
          <div className="w-full h-auto flex justify-between items-center gap-4 pb-[3rem] mb-[3rem] border-b border-dashed border-io-gray-c8">
            <div className="flex-1 h-auto">
              <p className="text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 mb-4">
                {t("web.common.label.status")}
              </p>
              <div
                className={`w-auto h-auto flex justify-start items-center gap-4`}
              >
                {filteredStatuses.map((option: any) => (
                  <div key={option.id}>
                    <input
                      type="radio"
                      id={option.id}
                      value={option.id}
                      {...register("status")}
                      className="hidden"
                      disabled={option.availableStatus.length === 0}
                    />
                    <label
                      key={option.id}
                      htmlFor={option.id}
                      className={`w-auto h-auto flex justify-center items-center cursor-pointer rounded-full text-[1.2rem] font-inter font-normal px-6 py-2 bg-opacity-10 
                        ${
                          watchStatus === option.id
                            ? "border"
                            : "border-io-white"
                        } ${
                        option.availableStatus.length === 0
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`} // Adjust styling for disabled state
                      style={{
                        color: option.textColor,
                        backgroundColor: option.fillColor,
                        borderColor:
                          watchStatus === option.id
                            ? option.textColor
                            : "white",
                      }}
                    >
                      {option.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-[6.3rem] h-auto">
              <img
                src={
                  selectedItem[0]?.logicIcon
                    ? selectedItem[0]?.logicIcon
                    : require("../../../../assets/images/card-default-small.png")
                }
                alt="logic icon"
              />
            </div>
          </div>
          <div className="w-full h-auto">
            {moreInfo.map(
              ({
                labelKey,
                labelValue,
              }: {
                labelKey: string;
                labelValue: string;
              }) => (
                <div className="flex justify-start items-center mb-[1.2rem]">
                  <div className="w-[25%] h-auto">
                    <p className="text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 capitalize">
                      {labelKey} :
                    </p>
                  </div>
                  <div className="w-[75%] h-auto">
                    <p className="text-[1.6rem] font-inter font-medium text-io-black dark:text-io-white">
                      {labelValue}
                    </p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {!isExternalUser && (
        <ModalPageFooter
          footerType="form"
          isLoading={isLoading}
          handleCancel={() => handleClose()}
        />
      )}
    </form>
  );
}
export default ProcessMoreInfoForm;
