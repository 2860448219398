import ModalPageWrapper from "../../../global-layout/modal-page-wrapper";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import CreateScreenForm from "../create-screen-form";
import { useTranslation } from "react-i18next";

interface CreateScreenWrapperProps {
  handleClose: () => void;
  handleCreateNewScreen: (data: any) => void;
}

function CreateScreenWrapper({
  handleClose,
  handleCreateNewScreen,
}: CreateScreenWrapperProps) {
  const { t } = useTranslation();
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-1a ml-auto max-md:w-[85%]">
        <div className="w-full h-full">
          <div className="w-full h-full flex flex-col">
            <ModalPageHeader
              title={t("web.logic.label.newscreen")}
              handleClose={() => handleClose()}
            />
            <div className="w-full h-full flex-auto">
              <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
                <CreateScreenForm
                  handleClose={() => handleClose()}
                  handleCreateNewScreen={handleCreateNewScreen}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default CreateScreenWrapper;
