import { Fragment } from "react/jsx-runtime";
import CardsViewCard from "../../../UI/cards-view-card";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../../../UI/no-data-found";

function AIMagixContainerCards({
  handleNavigate,
  handleEdit,
  handleDelete,
  rowData,
  scrollGroundRef,
  handleScroll,
  initialLoading,
  isLoading,
  limit,
  finalFetch,
  noDataFound
}: any) {
  return (
    <div className="w-full h-full flex flex-col">
      <div
        className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large py-4 pr-4"
        ref={scrollGroundRef}
        onScroll={handleScroll}
      >
        <div className="grid grid-cols-fit gap-[3rem] max-2xl:gap-[2.5rem]">
          {rowData?.length > 0 && !initialLoading
            ? rowData?.map((item: any, i: number) => (
                <Fragment key={i}>
                  <CardsViewCard
                    item={item}
                    handleNavigate={handleNavigate}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    isLoading={isLoading}
                  />
                </Fragment>
              ))
            : initialLoading &&
              Array.from({ length: limit }).map((row: any, i: number) => (
                <Fragment key={i}>
                  <Skeleton width="100%" height={110} borderRadius={7} />
                </Fragment>
              ))}
          {!finalFetch && !noDataFound &&
            Array.from({ length: 6 }).map((row: any, i: number) => (
              <Fragment key={i}>
                <div className="w-full h-[11rem]">
                  {isLoading && (
                    <Skeleton width="100%" height={110} borderRadius={7} />
                  )}
                </div>
              </Fragment>
            ))}
        </div>
        {noDataFound && !isLoading && !initialLoading && (
          <NoDataFound />
        )}
      </div>
    </div>
  );
}
export default AIMagixContainerCards;
