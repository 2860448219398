import { Fragment, useEffect, useRef, useState } from "react";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import StepsHeader from "../../steps-header";
import PreviewCreatedLabel from "../../preview-created-label";
import UploadTrainingDataSet from "../../upload-training-dataset";
import KeywordCreationChip from "../../../UI/keyword-creation-chip";
import IconBtn from "../../../form/icon-btn";
import { ImagePreviewCloseIcon } from "../../../svg";
import PreviewImage from "../../../UI/preview-image";
import Skeleton from "react-loading-skeleton";

function CustomMagicStepThree({
  setCurrentStep,
  title,
  data,
  setData,
  handleSubmit,
  isLoading,
}: any) {
  const [showPreview, setShowPreview] = useState(false);
  const [dynamicHeight, setDynamicHeight] = useState(0);
  const elementRef = useRef<any>(null);

  useEffect(() => {
    if (elementRef.current) {
      const height = elementRef.current.clientHeight - 20;
      setDynamicHeight(height);
    }
  }, [title]);

  const [activeTabUniqueId, setActiveTabUniqueId] = useState(
    data.labels.length > 0 ? data.labels[0].uniqueId : null
  );

  useEffect(() => {
    if (data.labels.length > 0) {
      setActiveTabUniqueId(data.labels[0].uniqueId);
    }
  }, []);
  setData(data);
  // Handle label click
  const handleLabelClick = (uniqueId: any) => {
    setActiveTabUniqueId(uniqueId);
  };

  return (
    <div className="w-full h-full flex flex-col relative">
      <StepsHeader title={title} />
      <div
        className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large"
        ref={elementRef}
      >
        <div className="w-full relative">
          <div className="w-full h-full p-4">
            <div className="w-full h-full flex">
              <div className="w-[21.26%] h-full sticky top-4 max-md:w-[35%]">
                <div
                  className="w-full bg-io-gray-f8 dark:bg-io-black-1c pt-3 flex flex-col"
                  style={{ height: dynamicHeight }}
                >
                  <div className="w-full flex-auto basis-0 pl-2 overflow-y-auto">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      data.labels.map((label: any, i: any) => {
                        if (label.isDeleted === 0) {
                          return (
                            <Fragment key={i}>
                              <KeywordCreationChip
                                labelName={label.name}
                                isActive={activeTabUniqueId === label.uniqueId}
                                isCheckbox={false}
                                currentStatus={false}
                                onClick={() => handleLabelClick(label.uniqueId)}
                                hasDeleteOption={false}
                              />
                            </Fragment>
                          );
                        }
                      })
                    )}
                  </div>
                </div>
              </div>
              <div className="w-[78.74%] h-auto ml-auto max-md:w-[65%]">
                <div className="w-full h-auto px-[4.5rem] py-[3.5rem] max-md:p-[3rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <PreviewCreatedLabel
                      isEditable={false}
                      fullScreenOption={false}
                      data={data}
                      activeTabUniqueId={activeTabUniqueId}
                    />
                  )}
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <UploadTrainingDataSet
                      isViewOnly={false}
                      data={data}
                      setData={setData}
                      activeTabUniqueId={activeTabUniqueId}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="steps"
        goToPrevious={() => setCurrentStep(2)}
        goToNext={() => setCurrentStep(4)}
        handleSaveDraft={handleSubmit}
        setData={setData}
        data={data}
        isLoading={isLoading}
      />
      {showPreview && (
        <PreviewImage handleClose={() => setShowPreview(false)} />
      )}
    </div>
  );
}
export default CustomMagicStepThree;
