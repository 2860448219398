import { Fragment, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  AILogicStudioIcon,
  AIMagicStudioIcon,
  AIMagixContainerIcon,
  DashboardIcon,
  DocumentIcon,
  DropDownIcon,
  HamburgerIcon,
  MagicsIcon,
  MasterDetailsIcon,
  PlanAndBillingIcon,
  ProcessIcon,
  SiteManagementIcon,
  SpeechIcon,
  SubMenuLine,
  SubMenuLineWithDot,
  TextIcon,
  UserManagementIcon,
  UserMasterIcon,
  VisionIcon,
} from "../../svg";
import { ReactNode } from "react";
import EncryptionKey from "../../../auth/EncryptionKey";
import Authentication from "../../../auth/Authentication";
import { useTranslation } from "react-i18next";
interface MenuLink {
  id: string;
  name: string;
  isExpanded?: boolean;
  children?: MenuLink[];
  icon?: ReactNode;
  link?: string;
}

interface AccordionsProps {
  // children: ReactNode;
  data: MenuLink;
  toggleHandle: (id: string) => void;
  toggleChildHandle: (id: string) => void;
  open: boolean;
  isSideNavExpanded: boolean;
  linkChildId: string | null;
}
interface SideNavProps {
  sideNavState: (isSideNavExpanded: boolean) => void;
}

function SideNav({ sideNavState }: SideNavProps) {
  const { t } = useTranslation();
  const [linkId, setLinkId] = useState<string | null>("");
  const [linkChildId, setLinkChilId] = useState<string | null>("");
  const [isSideNavExpanded, setIsSideNavExpanded] = useState<boolean>(false);
  const [menuLinks, setMenuLinks] = useState<MenuLink[]>([]);
  const authService = Authentication();
  useEffect(() => {
    let parsedSideBarDetails: any;
    parsedSideBarDetails = authService.decryptData("sideBarDetails", 2);
    parsedSideBarDetails = parsedSideBarDetails || {};
    let menuArray = generateMenuArray(parsedSideBarDetails);
    setMenuLinks([]);
    setMenuLinks(menuArray);
  }, []);
  const generateMenuArray = (items: any, parentId = 0) => {
    const menuArray: any = [];
    if (Array.isArray(items)) {
      items?.forEach((item: any) => {
        if (item.parentId === parentId && item.isVisible) {
          let menuItem: any = {
            id: item.idName,
            name: item.name,
            icon: null,
            link: item.routerLink,
          };

          switch (item.iconName) {
            case "DashboardIcon":
              menuItem.icon = <DashboardIcon />;
              break;
            case "SiteManagementIcon":
              menuItem.icon = <SiteManagementIcon />;
              break;
            case "UserManagementIcon":
              menuItem.icon = <UserManagementIcon />;
              break;
            case "AIMagicStudioIcon":
              menuItem.icon = <AIMagicStudioIcon />;
              break;
            case "MagicsIcon":
              menuItem.icon = <MagicsIcon />;
              break;
            case "VisionIcon":
              menuItem.icon = <VisionIcon />;
              break;
            case "DocumentIcon":
              menuItem.icon = <DocumentIcon />;
              break;
            case "SpeechIcon":
              menuItem.icon = <SpeechIcon />;
              break;
            case "TextIcon":
              menuItem.icon = <TextIcon />;
              break;
            case "AIMagixContainerIcon":
              menuItem.icon = <AIMagixContainerIcon />;
              break;
            case "AILogicStudioIcon":
              menuItem.icon = <AILogicStudioIcon />;
              break;
            case "ProcessIcon":
              menuItem.icon = <ProcessIcon />;
              break;
            case "PlanAndBillingIcon":
              menuItem.icon = <PlanAndBillingIcon />;
              break;
            case "MasterDetailsIcon":
              menuItem.icon = <MasterDetailsIcon />;
              break;
            default:
              menuItem.icon = null;
          }

          if (item.havingChild === 1) {
            menuItem.isExpanded = true;
            menuItem.children = generateMenuArray(items, item.id);
          }

          menuArray.push(menuItem);
        }
      });
    }
    return menuArray;
  };

  const toggleHandle = (id: string) => {
    setIsSideNavExpanded(true);
    setTimeout(() => {
      setLinkId(linkId !== id ? id : null);
    }, 300);
  };
  const toggleChildHandle = (id: string) => {
    setIsSideNavExpanded(true);
    setTimeout(() => {
      setLinkChilId(linkChildId !== id ? id : null);
    }, 300);
  };

  const handleToggleSideNav = () => {
    if (linkId !== null) {
      setLinkId(null);
      setTimeout(() => {
        setIsSideNavExpanded(!isSideNavExpanded);
      }, 300);
    } else {
      setIsSideNavExpanded(!isSideNavExpanded);
    }
  };

  useEffect(() => {
    sideNavState(isSideNavExpanded);
    adjustNumColumns();
    window.addEventListener("resize", adjustNumColumns);

    return () => {
      window.removeEventListener("resize", adjustNumColumns);
    };
  }, [isSideNavExpanded]);

  function adjustNumColumns() {
    if (window.innerWidth > 1500) {
      if (!isSideNavExpanded) {
        document.documentElement.style.setProperty("--num-columns", "6");
      } else {
        document.documentElement.style.setProperty("--num-columns", "5");
      }
    } else if (window.innerWidth <= 1500 && window.innerWidth >= 1280) {
      if (!isSideNavExpanded) {
        document.documentElement.style.setProperty("--num-columns", "5");
      } else {
        document.documentElement.style.setProperty("--num-columns", "4");
      }
    } else if (window.innerWidth < 1279 && window.innerWidth >= 992) {
      if (!isSideNavExpanded) {
        document.documentElement.style.setProperty("--num-columns", "4");
      } else {
        document.documentElement.style.setProperty("--num-columns", "3");
      }
    } else if (window.innerWidth < 991) {
      if (!isSideNavExpanded) {
        document.documentElement.style.setProperty("--num-columns", "3");
      } else {
        document.documentElement.style.setProperty("--num-columns", "2");
      }
    }
  }

  return (
    <>
      <div
        className={`${
          isSideNavExpanded
            ? "w-[18.2%] max-md:w-[27%]"
            : "w-[3.5%] max-md:w-[5%]"
        } side-nav bg-io-gray-e9 dark:bg-io-black-1a`}
      >
        <div className="w-full h-full">
          <div className="w-full h-full flex flex-col">
            <div className="w-full h-auto px-8">
              <div className="w-full h-auto py-8 border-b border-io-gray-c8 dark:border-io-black-48">
                <div className="w-full h-auto">
                  <button
                    type="button"
                    className="w-[2.2rem] h-auto"
                    onClick={handleToggleSideNav}
                  >
                    <HamburgerIcon />
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-medium py-8">
              {menuLinks.map((data, i) => (
                <Accordions
                  key={i}
                  data={data}
                  toggleHandle={toggleHandle}
                  toggleChildHandle={toggleChildHandle}
                  open={linkId === data.id}
                  isSideNavExpanded={isSideNavExpanded}
                  linkChildId={linkChildId}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const Accordions = ({
  data,
  toggleHandle,
  toggleChildHandle,
  open,
  isSideNavExpanded,
  linkChildId,
}: AccordionsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full">
        {data.children ? (
          <div
            className={`w-full h-auto ${
              isSideNavExpanded && "flex justify-between items-center"
            } py-6 px-8 cursor-pointer select-none `}
            onClick={() => toggleHandle(data.id)}
          >
            <div
              className={`w-full h-auto ${
                isSideNavExpanded && "flex justify-start items-center gap-4"
              }`}
            >
              <div className="w-[2.2rem] h-auto fill-io-gray-32 dark:fill-io-white">
                {data.icon}
              </div>
              {isSideNavExpanded && (
                <span className="text-[1.8rem] text-io-gray-32 dark:text-io-white dark:bg-io-black-active-menu leading-none text-nowrap">
                  {/* {data.name} */}
                  {t(data.name)}
                </span>
              )}
            </div>
            {isSideNavExpanded && (
              <div
                className={`w-[2rem] h-auto transition-all duration-300 ${
                  open === true ? "rotate-180" : "rotate-0"
                }`}
              >
                <DropDownIcon />
              </div>
            )}
          </div>
        ) : (
          <NavLink
            to={data.id}
            className={({ isActive }) =>
              isActive
                ? "bg-io-active-menu block relative active-menu text-io-primary fill-io-primary dark:bg-io-black-active-menu"
                : "bg-none text-io-gray-32 dark:text-io-white"
            }
          >
            <div
              className={`w-full h-auto ${
                isSideNavExpanded && "flex justify-start items-center gap-4"
              }  py-6 px-8 select-none`}
            >
              <div className="w-[2.2rem] h-auto fill-io-gray-32 dark:fill-io-white">
                {data.icon}
              </div>
              {isSideNavExpanded && (
                <span className="text-[1.8rem] leading-none text-nowrap ">
                  {/* {data.name} */}
                  {t(data.name)}
                </span>
              )}
            </div>
          </NavLink>
        )}
        {
          <div
            className={`overflow-hidden transition-all duration-300 ${
              open === true ? "max-h-[500px]" : "max-h-0"
            }`}
          >
            {data?.children?.map((item, j) => (
              <Fragment key={j}>
                {item.children ? (
                  <>
                    {" "}
                    <div
                      className={`w-full h-auto ${
                        isSideNavExpanded && "flex justify-between items-center"
                      } py-6 px-8 cursor-pointer select-none  fill-io-gray-32 dark:fill-io-white text-io-black dark:text-io-white`}
                      onClick={() => toggleChildHandle(item.id)}
                    >
                      <div
                        className={`w-full h-auto ${
                          isSideNavExpanded &&
                          "flex justify-start items-center gap-4"
                        }`}
                      >
                        <div className="w-[2.1rem] h-auto mt-[-2rem]">
                          <SubMenuLine />
                        </div>
                        <div className="w-[2.2rem] h-auto fill-io-gray-32 dark:fill-io-white">
                          {item.icon}
                        </div>
                        {isSideNavExpanded && (
                          <span className="text-[1.8rem] leading-none text-nowrap">
                            {/* {item.name} */}
                            {t(item.name)}
                          </span>
                        )}
                      </div>
                      {isSideNavExpanded && (
                        <div
                          className={`w-[2rem] h-auto transition-all duration-300 ${
                            linkChildId === item.id ? "rotate-180" : "rotate-0"
                          }`}
                        >
                          <DropDownIcon />
                        </div>
                      )}
                    </div>
                    <div
                      className={`overflow-hidden transition-all duration-300 ${
                        linkChildId === item.id ? "max-h-[500px]" : "max-h-0"
                      }`}
                    >
                      {item.children && (
                        <div className="w-full h-auto">
                          {item.children.map((child, k) => (
                            <NavLink
                              key={k}
                              to={`${child.link}`}
                              className={({ isActive }) =>
                                isActive
                                  ? "bg-io-active-menu block relative active-menu dark:bg-io-black-active-menu text-io-primary fill-io-gradient-to dark:fill-io-gradient-to"
                                  : "bg-none text-io-gray-32 dark:text-io-white fill-io-gray-33 dark:fill-io-white"
                              }
                            >
                              <div className="w-full h-auto flex justify-start items-center gap-4 py-4 px-8 cursor-pointer">
                                <div className="w-[1.9rem] h-auto mt-[-2rem]">
                                  <SubMenuLineWithDot />
                                </div>
                                <div className="w-[2.2rem] h-auto">
                                  {child.icon}
                                </div>
                                <span className="text-[1.7rem]  text-inherit">
                                  {/* {child.name} */}
                                  {t(child.name)}
                                </span>
                              </div>
                            </NavLink>
                          ))}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <NavLink
                    to={`${item.link}`}
                    className={({ isActive }) =>
                      isActive
                        ? "bg-io-active-menu block relative active-menu dark:bg-io-black-active-menu activemenu-bg-text"
                        : "bg-none text-io-black dark:text-io-white fill-io-gray-33 dark:fill-io-white"
                    }
                  >
                    <div className="w-full h-auto flex justify-start items-center gap-4 py-4 px-8 cursor-pointer">
                      <div className="w-[2.1rem] h-auto mt-[-2rem]">
                        <SubMenuLine />
                      </div>
                      <div className="w-[2.2rem] h-auto active-inner-icon">
                        {item.icon}
                      </div>
                      <span className="text-[1.7rem] active-inner-text">
                        {/* {item.name} */}
                        {t(item.name)}
                      </span>
                    </div>
                  </NavLink>
                )}
              </Fragment>
            ))}
          </div>
        }
      </div>
    </>
  );
};

export default SideNav;
