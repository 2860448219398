import { useNavigate, useParams } from "react-router-dom";
import { InputFieldWithoutForm, SelectField } from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import { ToggleButtonWithoutForm } from "../../../form/toggle-btn";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { masterAccess } from "../../../../services/InspectionOneServices";
import UploadImage from "../../../form/upload-image";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

interface CreateMasterDetailsFormProps {
  masterDetailsUpdated: (val: boolean) => void;
}

function CreateMasterDetailsForm({
  masterDetailsUpdated,
}: CreateMasterDetailsFormProps) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [mode, setMode] = useState("insert");
  const generateGuid = () => {
    return uuidv4();
  };
  const [formData, setFormData] = useState({
    name: "",
    accessMode: "",
    isGrouping: false,
    imageUrl: "",
  });
  useEffect(() => {
    if (id !== "new") {
      setMode("update");
      handleMasterEdit(id);
    }
  }, [id]);
  const [errors, setErrors] = useState({ name: "", accessMode: "" });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isChoiceSelected, setIsChoiceSelected] = useState<boolean>(false);

  const handleFormCancel = () => {
    navigate("/master-details");
  };

  const handleInputChange = (name: string, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleFormValidation = () => {
    const { name, accessMode } = formData;
    const isNameEmpty = !name.trim();
    const isAccessModeEmpty = !accessMode;
    console.log(isAccessModeEmpty);
    setErrors({
      name: isNameEmpty ? t("web.error.title.namereq") : "",
      accessMode: isAccessModeEmpty ? t("web.user.alert.accessmodereq") : "",
    });
    return !isNameEmpty && !isAccessModeEmpty;
  };

  const handleMasterEdit = async (uniqueId: any) => {
    try {
      setIsLoading(true);
      const requestJson = {
        masterAccessId: uniqueId.trim(),
      };
      const response = await masterAccess.MasterAccessActionFetch(requestJson);
      if (response.success) {
        setIsLoading(false);
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...response.data.masterAccessActionDetail,
          imageUrl: response.data.masterAccessActionDetail.logo,
        }));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleMasterAccessSubmit = async () => {
    try {
      const isValid = handleFormValidation();
      if (isValid) {
        setIsLoading(true);
        const requestJson = {
          actionMode: 1,
          masterAccessDetails: [
            {
              id: mode === "insert" ? generateGuid() : id,
              name: formData.name,
              logo: formData.imageUrl,
              accessMode: formData.accessMode,
              isGrouping: isChoiceSelected ? 1 : 0,
              statusId: 0,
            },
          ],
        };
        const response = await masterAccess.MasterAccessAction(requestJson);
        if (response.success) {
          masterDetailsUpdated(true);
          navigate("/master-details");
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error submitting master access details:", error);
      setIsLoading(false);
    }
  };

  const handleIsCheckedChange = (newState: any) => {
    setIsChoiceSelected(newState);
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[93%] h-auto">
          <div className="w-full h-auto flex justify-between items-start pt-4">
            <div className="w-[60%] ">
              <div className="w-full h-auto">
                <div className="w-full mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.master.label.name")}
                      type="text"
                      id="name"
                      isRequired={true}
                      value={formData.name}
                      error={t(errors.name)}
                      onChange={(e) => handleInputChange("name", e)}
                    />
                  )}
                </div>
                <div className="mb-[3rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <SelectField
                      labelName={t("web.master.label.accessmode")}
                      options={[
                        { label: "Global", value: "1" },
                        { label: "Site", value: "2" },
                      ]}
                      id="accessMode"
                      isRequired={true}
                      value={formData.accessMode}
                      error={t(errors.accessMode)}
                      onChange={(value) =>
                        handleInputChange("accessMode", value)
                      }
                    />
                  )}
                </div>
                <div className="w-full grid grid-cols-3 gap-4">
                  <div className="w-full mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={30} borderRadius={3} />
                    ) : (
                      <ToggleButtonWithoutForm
                        id="isGrouping"
                        size="large"
                        label={t("web.master.label.isgrouping")}
                        isButtonChecked={formData.isGrouping}
                        getIsChecked={handleIsCheckedChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[32%] h-auto">
              <div className="w-full h-auto">
                {isLoading ? (
                  <Skeleton width="100%" height={160} borderRadius={3} />
                ) : (
                  <UploadImage
                    formData={formData}
                    setFormData={setFormData}
                    isReadOnly={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={() => handleFormCancel()}
        handleSubmit={() => handleMasterAccessSubmit()}
        isLoading={isLoading}
      />
    </div>
  );
}
export default CreateMasterDetailsForm;
