import ModalPageWrapper from "../../components/global-layout/modal-page-wrapper";
import CreateUserMaster from "../../components/section/user-master/create-user-master";

function CreateUserMasterWrapper({ headerDetails, userMasterUpdated }: any) {
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <CreateUserMaster
          headerDetails={headerDetails}
          userMasterUpdated={userMasterUpdated}
        />
      </div>
    </ModalPageWrapper>
  );
}
export default CreateUserMasterWrapper;
