import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import TabThumbMoreOptions from "../tab-thumb-more-options";
import {
  DeleteIcon,
  EditIcon,
  RequiredErrorIcon,
  VerticalMoreWhiteIcon,
} from "../../svg";
import { ReactNode } from "react";
import { itemFromArray } from "@tsparticles/engine";
import { useTranslation } from "react-i18next";

interface SliderDataProps {
  id: string;
  label: string;
}

interface TabSliderProps {
  sliderData: any;
  handleTabSelect: (data: any) => void;
  selectedTab: string;
  slidersPerview: number;
  hasMoreOption: boolean;
  selectedValue?: (id: string, item: any) => void;
  hasError?: any;
  isContainer?: boolean;
}
interface Option {
  id: string;
  label: string;
  icon: ReactNode;
}

function TabSlider({
  sliderData,
  handleTabSelect,
  selectedTab,
  slidersPerview,
  hasMoreOption,
  selectedValue,
  hasError,
  isContainer,
}: TabSliderProps) {
  const { t } = useTranslation();
  const moreOptions: Option[] = [
    { id: "edit", label: t("web.title.common.edit"), icon: <EditIcon /> },
    { id: "delete", label: t("web.title.common.delete"), icon: <DeleteIcon /> },
  ];
  const normalizedSliderData = sliderData.map((item: any) => ({
    ...item,
    id: isContainer ? item.flowId : item.uniqueId || item.id,
  }));

  console.log(selectedTab, normalizedSliderData);
  return (
    <div className="w-full h-auto border-b border-io-gray-c8 dark:border-io-black-48 tab-slider">
      <Swiper speed={1000} spaceBetween={10} slidesPerView={"auto"}>
        {normalizedSliderData.length > 0 &&
          normalizedSliderData.map((slider: any, index: any) => (
            <SwiperSlide key={index}>
              <div
                className="w-auto h-auto"
                onClick={() => handleTabSelect(slider)}
              >
                <div
                  className={`w-auto h-auto px-[2.3rem] py-[0.7rem] cursor-pointer ${
                    selectedTab === slider.id
                      ? "bg-io-primary text-io-white"
                      : "bg-io-gray-f7 text-io-gray-66 dark:text-io-black-d1 dark:bg-io-black-23"
                  }  rounded-t-[1rem]`}
                >
                  {hasMoreOption &&
                  selectedTab === slider.id &&
                  selectedTab !== "getstarted" &&
                  // selectedTab !== "startupscreen" &&
                  selectedTab !== "screenmapping" ? (
                    <div className="w-auto h-auto flex justify-between items-center gap-4">
                      <p className="text-[1.8rem] font-inter font-normal  text-center text-nowrap">
                        {slider?.name}
                      </p>

                      <TabThumbMoreOptions
                        options={moreOptions}
                        selectedValue={(id) => selectedValue?.(id, slider)}
                        icon={<VerticalMoreWhiteIcon />}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="w-full flex justify-center items-center gap-4">
                        <p className="text-[1.8rem] font-inter font-normal  text-center text-nowrap">
                          {slider.name}
                        </p>
                        {hasError &&
                          Object.keys(hasError).length > 0 &&
                          Object.keys(hasError?.inputParameter)?.map(
                            (item: any) =>
                              item == index && (
                                <div className="w-[2.2rem] h-auto">
                                  <RequiredErrorIcon />
                                </div>
                              )
                          )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}
export default TabSlider;
