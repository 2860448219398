import { useEffect, useState } from "react";
import { SearchIcon } from "../../svg";

interface VoiceSearchInputProps {
  inputSize: string;
  onSearch?: (query: any) => void;
}

function VoiceSearchInput({ onSearch, inputSize }: VoiceSearchInputProps) {
  const [query, setQuery] = useState<any>(undefined);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query !== undefined) {
        onSearch?.(query);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  return (
    <div
      className={`w-full h-auto ${
        inputSize === "small" ? "px-4 py-3" : "p-4"
      } flex justify-between items-center rounded-[0.3rem] border border-io-gray-c8 dark:border-io-black-48`}
    >
      <div className="w-auto h-auto mr-4">
        <div
          className={`${
            inputSize === "small" ? "w-[1.3rem]" : "w-[1.6rem]"
          } h-auto`}
        >
          <SearchIcon />
        </div>
      </div>
      <input
        type="text"
        name="helpAndSupportSearch"
        id="helpAndSupportSearch"
        className={`flex-1 w-[70%] bg-transparent  border-none bg-none font-inter font-normal text-io-black dark:text-io-white mr-3 ${
          inputSize === "small" ? "text-[1.4rem]" : "text-[1.6rem]"
        } `}
        placeholder="Search"
        onChange={handleChange}
        value={query}
        onClick={(e) => e.stopPropagation()}
      />
      <div className="w-auto h-auto pl-3 relative before:content-[''] before:w-[1px] before:h-full before:bg-io-gray-c8 before:absolute before:left-0">
        <div
          className={`${
            inputSize === "small" ? "w-[1rem]" : "w-[1.6rem]"
          } h-auto`}
        >
          {/* <MicIcon /> */}
        </div>
      </div>
    </div>
  );
}
export default VoiceSearchInput;
