import { useNavigate } from "react-router-dom";
import { InputFieldWithoutForm, SelectField } from "../../form/input-fields";
import UploadImage from "../../form/upload-image";
import Authentication from "../../../auth/Authentication";
import { useTranslation } from "react-i18next";
import { useState } from "react";

function ViewAccountForm({ handleClose }: any) {
  const authService = Authentication();
  const { t } = useTranslation();
  let parsedLoginDetails: any;
  parsedLoginDetails = authService.decryptData("loginDetails", 2);
  parsedLoginDetails = parsedLoginDetails || "{}";
  const [formData, setFormData] = useState(parsedLoginDetails?.otherDetails);
  return (
    <form className="w-full h-full flex flex-col">
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[93%] h-auto">
          <div className="w-full h-auto flex justify-between items-start pt-4">
            <div className="w-[60%] ">
              <div className="w-full h-auto">
                <div className="mb-[3.8rem]">
                  <InputFieldWithoutForm
                    labelName={t("web.profile.label.firstname")}
                    type="text"
                    id="newUserFirstName"
                    isRequired={true}
                    value={formData?.firstName}
                    isReadOnly={true}
                  />
                </div>
                <div className="mb-[3.8rem]">
                  <InputFieldWithoutForm
                    labelName={t("web.profile.label.lastname")}
                    type="text"
                    id="newUserLastName"
                    isRequired={true}
                    value={formData?.lastName}
                    isReadOnly={true}
                  />
                </div>
                <div className="mb-[3.8rem]">
                  <InputFieldWithoutForm
                    labelName={t("web.profile.label.displayname")}
                    type="text"
                    id="newUserDisplayName"
                    isRequired={false}
                    value={formData?.displayName}
                    isReadOnly={true}
                  />
                </div>
                <div className="mb-[3.8rem]">
                  <InputFieldWithoutForm
                    labelName={t("web.profile.label.email")}
                    type="email"
                    id="newUserEmail"
                    isRequired={true}
                    value={formData?.email}
                    isReadOnly={true}
                  />
                </div>
                <div className="mb-[3.8rem]">
                  <InputFieldWithoutForm
                    labelName={t("web.profile.label.mobile")}
                    type="text"
                    id="newUserPhoneNumber"
                    isRequired={true}
                    value={formData?.mobile}
                    isReadOnly={true}
                  />
                </div>
                <div className="mb-[3rem]">
                  <SelectField
                    labelName={t("web.profile.label.role")}
                    options={[
                      { label: "Super Admin", value: "1" },
                      { label: "Tenant Admin", value: "2" },
                      { label: "Site Admin", value: "3" },
                      { label: "Site User", value: "4" },
                      { label: "Portal User", value: "5" },
                      { label: "Portal Guest User", value: "6" },
                      { label: "Mobile User", value: "7" },
                      { label: "Mobile Guest User", value: "8" },
                    ]}
                    id="newUserRole"
                    isRequired={true}
                    value={formData?.roleId}
                    isReadOnly={true}
                  />
                </div>
              </div>
            </div>
            <div className="w-[32%] h-auto">
              <div className="w-full h-auto">
                <UploadImage
                  formData={formData}
                  setFormData={setFormData}
                  isReadOnly={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
export default ViewAccountForm;
