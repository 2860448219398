import { FC, useEffect, useState } from "react";
import ReactConfetti, { Props as ConfettiProps } from "react-confetti";
interface Props extends ConfettiProps {
    numberOfPieces: number;
}
const customImages = ['#B12E0B', '#FD4E1F', '#2DCA82', "#1FFDCF", "#FF6339"];
const Confetti: FC<Props> = ({
    numberOfPieces,
    wind = 0.01,
    gravity = 0.1,
    colors = customImages,
    opacity = 1,
    tweenDuration = 5000

}) => {
    const [run, setRun] = useState(false);
    const [recycle, setRecycle] = useState(true);
    const [isReactConfetti, setIsReactConfetti] = useState(true);
    const confettiOptions = {
        numberOfPieces,
        wind,
        gravity,
        colors,
        opacity,
        tweenDuration,
        run,
        recycle
    };

    useEffect(() => {
        setRun(true)
        setRecycle(false)
        setTimeout(() => {
            setIsReactConfetti(false)
        }, 8000)
    }, []);

    return (
        <div className="confetti">
            {isReactConfetti && <ReactConfetti {...confettiOptions} />}
        </div>
    );
};

export default Confetti;