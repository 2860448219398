import ModalPageWrapper from "../../../components/global-layout/modal-page-wrapper";
import CreateCustomMagic from "../../../components/section/vision-create/create-custom-magic";

function VisionCreate({ visionUpdated, setIsConfetti }: any) {
  return (
    <ModalPageWrapper>
      <div className="w-[66.25%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <CreateCustomMagic
          visionUpdated={visionUpdated}
          setIsConfetti={setIsConfetti}
        />
      </div>
    </ModalPageWrapper>
  );
}
export default VisionCreate;
