import { useState } from "react";
import PageHeader from "../../components/global-layout/page-header";
import UserMasterGrid from "../../components/section/user-master/user-master-grid";
import DeleteUser from "../../components/section/user-management/delete-user";
import GridColumn from "../../components/section/user-management/grid-columns";
import { Outlet } from "react-router-dom";

function UserMaster({
  setHeaderDetails,
  userMasterUpdated,
  setUserMasterUpdated,
}: any) {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    "Name",
    "Color",
  ]);

  const [masterTableName, setMasterTableName] = useState<string>("");

  const [deleteData, setDeleteData] = useState<any>(null);
  const [handleDataDelete, setHandleDataDelete] = useState<any>(null);

  const columnsData = [
    { label: "Name", token: "web.master.label.name", key: "Name" },
    { label: "Color", token: "web.master.label.color", key: "Color" },
  ];

  const breadcrumbs = [
    { label: "Master Details", link: "/master-details" },
    { label: masterTableName, link: undefined },
  ];

  const handleCheckboxChange = (event: any, key: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedColumns((prevSelected) => [...prevSelected, key]);
    } else {
      if (selectedColumns.length === 1) return;
      setSelectedColumns((prevSelected) =>
        prevSelected.filter((item) => item !== key)
      );
    }
  };

  const handleDelete = (rowData: any) => {
    setDeleteData(rowData);
    setShowDeletePopup(true);
  };

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <PageHeader
          title={masterTableName}
          //icon={<UserMasterPageHeaderIcon />}
          breadcrumbs={breadcrumbs}
        />
        <UserMasterGrid
          handleDelete={handleDelete}
          handleColumns={() => setShowColumns(true)}
          selectedColumns={selectedColumns}
          rowSelection={false}
          moreOptions={true}
          userMasterUpdated={userMasterUpdated}
          setShowColumns={() => setShowColumns(true)}
          setUserMasterUpdated={setUserMasterUpdated}
          handleDataDelete={handleDataDelete}
          setShowDeletePopup={(val: boolean) => setShowDeletePopup(val)}
          setHeaderDetails={setHeaderDetails}
          setMasterTableName={setMasterTableName}
        />
      </div>
      {showDeletePopup && (
        <DeleteUser
          rowData={deleteData}
          handleCancel={() => setShowDeletePopup(false)}
          handleDelete={(rowData: any) => setHandleDataDelete(rowData)}
          actionFlag="masterValueDetails"
        />
      )}
      {showColumns && (
        <GridColumn
          handleClose={() => setShowColumns(false)}
          handleCheckboxChange={handleCheckboxChange}
          columnsData={columnsData}
          selectedColumns={selectedColumns}
        />
      )}
      <Outlet />
    </>
  );
}
export default UserMaster;
