import { useState } from "react";
import EnterTagsInput from "../../../form/enter-tags-input";
import {
  InputFieldWithoutForm,
  TextAreaField,
} from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import StepsHeader from "../../steps-header";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SelectInputWithoutForm } from "../../../form/select-input";
import { useMyContext } from "../../../my-context/MyContext";

function CustomMagicStepOne({
  setCurrentStep,
  title,
  data,
  setData,
  handleSubmit,
  isLoading,
}: any) {
  const { t } = useTranslation();
  const { magicType } = useParams();
  const [errors, setErrors] = useState<any>({});
  const { isExternalUser } = useMyContext();
  const setTagsAndUpdateData = (updatedTags: any) => {
    setData((prevData: any) => ({
      ...prevData,
      tags: updatedTags,
    }));
  };
  const setDefectsTypeData = (updatedTags: any) => {
    setData((prevData: any) => ({
      ...prevData,
      defectsType: updatedTags,
    }));
  };
  const handleChange = (
    inputName: string,
    valueOrEvent: string | React.ChangeEvent<HTMLInputElement>
  ) => {
    const value =
      typeof valueOrEvent === "string"
        ? valueOrEvent
        : valueOrEvent.target.value;
    const updatedData = {
      ...data,
      [inputName]: value,
    };
    setData(updatedData);
  };

  const validateFields = () => {
    const newErrors: any = {};

    if (!data.name) {
      newErrors.name = "Magic name is required";
    }
    // if (!data.description) {
    //   newErrors.description = "Description is required";
    // }
    if (magicType === "4" && !data.surfaceType) {
      newErrors.surfaceType = "Surface type is required";
    }
    // if (
    //   magicType === "4" &&
    //   (!data.defectsType || data.defectsType.length === 0)
    // ) {
    //   newErrors.defectsType = "At least one defect type is required";
    // }
    // if (!data.tags || data.tags.length === 0) {
    //   newErrors.tags = "At least one tag is required";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = () => {
    if (validateFields()) {
      setCurrentStep(2);
    }
  };

  const handleSelectionChange = (value: any, name: string) => {
    const updatedData = {
      ...data,
      [name]: value,
    };
    setData(updatedData);
  };

  const handlePrevious = () => setCurrentStep(1);

  const surfaceTypeOptions = [
    { value: "1", label: "Glossy Painted Surface" },
    { value: "2", label: "Matte painted Surface" },
    { value: "3", label: "Casted Surface" },
  ];
  const handleSaveDraft = () => {
    if (validateFields()) {
      handleSubmit();
    }
  };
  const getDefaultSurfaceType = (surfaceType: any) => {
    const defaultRole = surfaceTypeOptions.find(
      (option) => option.value === surfaceType
    );
    return defaultRole;
  };
  return (
    <div className="w-full h-full flex flex-col">
      <StepsHeader title={title} />
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[42.35%] h-auto py-[3rem] max-md:w-[60%]">
          <div className="w-full h-auto">
            {magicType === "4" ? (
              <form>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <>
                      <InputFieldWithoutForm
                        labelName={t("web.magix.text.magicname")}
                        type="text"
                        id="MagicName"
                        isRequired={true}
                        value={data.name}
                        onChange={(e) => handleChange("name", e)}
                        error={errors.name}
                      />
                    </>
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <>
                      <TextAreaField
                        labelName={t("web.magix.label.description")}
                        id="description"
                        value={data.description}
                        onChange={(e) => handleChange("description", e)}
                      />
                    </>
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <>
                      <SelectInputWithoutForm
                        labelName={t("web.label.common.surfacetype")}
                        id={`getStartedUniqueId`}
                        isRequired={true}
                        options={surfaceTypeOptions}
                        defaultValue={getDefaultSurfaceType(data.surfaceType)}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                        getSelection={(option: any) =>
                          handleSelectionChange(option.value, "surfaceType")
                        }
                      />
                    </>
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <>
                      <EnterTagsInput
                        labelName={t("web.magix.label.defectsType")}
                        idName="defectsType"
                        tags={data.defectsType}
                        setTags={setDefectsTypeData}
                        type={1}
                      />
                    </>
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <>
                      <EnterTagsInput
                        labelName={t("web.magix.label.entertags")}
                        idName="visionEnterTags"
                        tags={data.tags}
                        setTags={setTagsAndUpdateData}
                        type={1}
                      />
                    </>
                  )}
                </div>
              </form>
            ) : (
              <form>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <>
                      <InputFieldWithoutForm
                        labelName={t("web.magix.text.magicname")}
                        type="text"
                        id="MagicName"
                        isRequired={true}
                        value={data.name}
                        onChange={(e) => handleChange("name", e)}
                        error={errors.name}
                      />
                    </>
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <>
                      <TextAreaField
                        labelName={t("web.magix.label.description")}
                        id="description"
                        value={data.description}
                        onChange={(e) => handleChange("description", e)}
                      />
                    </>
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <>
                      <EnterTagsInput
                        labelName={t("web.magix.label.entertags")}
                        idName="visionEnterTags"
                        tags={data.tags}
                        setTags={setTagsAndUpdateData}
                        type={1}
                      />
                    </>
                  )}
                </div>
              </form>
            )}
          </div>
        </div>
      </div>

      <ModalPageFooter
        handleSaveDraft={handleSaveDraft}
        footerType="steps"
        backbtn={true}
        goToPrevious={handlePrevious}
        goToNext={handleNextStep}
        setData={setData}
        data={data}
        isLoading={isLoading}
      />
    </div>
  );
}

export default CustomMagicStepOne;
