import Tippy from "@tippyjs/react";
import IconBtn from "../../form/icon-btn";
import { ReactNode, useRef, useState } from "react";



interface TabThumbMoreOptionsProps{
  options: Option[];
  selectedValue: (id:string) => void;
  icon: ReactNode;
}

interface Option {
  id: string;
  label: string;
  icon: ReactNode;
}

function TabThumbMoreOptions({options, selectedValue, icon}:TabThumbMoreOptionsProps){
    const tippyRef = useRef<any>(null);
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);


  const dropDownContent = (
    <div className='relative'>
          <div className="w-auto px-[1.9rem] py-[0.5rem] bg-io-white dark:bg-io-black-1a shadow-lg rounded-[0.6rem] ">
        <ul className="w-full h-auto">
          {options.map((option, index) => (
            <li
              key={index}
              className="w-full h-auto flex justify-start items-center gap-4 group cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setVisible(false);
                selectedValue(option.id)}
              }
            >
              <div className="w-[2rem] h-auto">
                {option.icon}
              </div>
              <span className="text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-gray-e9 text-nowrap py-3">
                {option.label}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="absolute top-[-2rem] left-[50%] translate-x-[-50%] w-auto h-auto border-b-[1rem] border-b-io-white dark:border-b-io-black-1a border-t-[1rem] border-t-transparent border-s-[1rem] border-s-transparent border-e-[1rem] border-e-transparent"></div>
    </div>
  );

    return(
        <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="bottom"
      delay={0}
    >
      <div className='w-auto h-full'>
      <IconBtn size="w-auto h-[1.7rem]" icon={icon}  handleClick={(e) => {
        e.stopPropagation(); 
        visible ? hide() : show();
    }} /> 
      </div>
    </Tippy>
    )
}
export default TabThumbMoreOptions;