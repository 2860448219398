import { Fragment, useEffect, useState } from "react";
import { InputField } from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import KeywordCreationChip from "../../../UI/keyword-creation-chip";
import ActionPills from "../../../UI/action-pills";
import { MultiSelectInput, SelectInput } from "../../../form/select-input";
import TabSlider from "../../../UI/tab-slider";
import { containerServices } from "../../../../services/InspectionOneServices";
import { v4 as uuidv4 } from "uuid";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

interface Label {
  name: string;
  isActive: boolean;
}

interface Container {
  magicId: string;
  name: string;
  labels: Label[];
}

function InputParametersForm({
  inputParameterParams,
  closeInputParameter,
}: any) {
  const { t } = useTranslation();
  const [containerList, setContainerList] = useState<any[]>([]);
  const [editMagicData, setEditMagicData] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState(
    inputParameterParams.containerList[0].flowId
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState<any>(0);
  const [sideSelectedTab, setSideSelectedTab] = useState(
    inputParameterParams.containerList[0].labels[0]?.labelId || null
  );
  const [sideSelectedTabIndex, setSideSelectedTabIndex] = useState<any>(0);
  const [slidersPerview, setSlidesPerview] = useState(6);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedField, setSelectedField] = useState<any>(null);

  const InputParametersSchema = yup.object().shape({
    inputParameter: yup.array().of(
      yup.object().shape({
        uniqueId: yup.string(),
        flowId: yup.string(),
        stepLabel: yup.string().required("Display Name is required"),
        inpParaLabels: yup.array().of(
          yup.object().shape({
            uniqueId: yup.string(),
            actions: yup.string().required("Actions is required"),
            redirect: yup.object(),
          })
        ),
        labelNames: yup.array(),
      })
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
    watch,
  } = useForm<any>({
    resolver: yupResolver(InputParametersSchema),
  });

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "inputParameter",
  });

  useEffect(() => {
    if (inputParameterParams) {
      console.log("inputParameterParams", inputParameterParams);
      setContainerList(inputParameterParams.containerList);
      setEditMagicData(inputParameterParams.editMagicData);
    }
  }, [inputParameterParams]);

  const filteredContainers = containerList.filter((container) => {
    return container.flowId === selectedTab;
  });
  const options = containerList.map((options: any) => ({
    value: options.uniqueId,
    label: options.name,
  }));

  const outputOptions =
    filteredContainers.length > 0 && filteredContainers[0].outputs
      ? filteredContainers[0].outputs.map((label: any) => ({
          label: label.name,
          value: label.uniqueId,
          uniqueId: label.uniqueId,
        }))
      : [];

  const getRedirectionData = (redirectId: any) => {
    if (options.length > 0) {
      const selectedOption = options?.find(
        (option) => option.value === parseInt(redirectId)
      );
      if (selectedOption?.value) {
        return selectedOption;
      } else {
        return options[0];
      }
    }
  };
  const getOutputData = (outputValue: any) => {
    if (outputValue?.length > 0) {
      const result: any = [];
      outputValue.forEach((output: any) => {
        result.push({
          label: output.name,
          value: output.uniqueId,
        });
      });
      if (result?.length > 0) {
        return result;
      } else {
        return [];
      }
    }
  };

  useEffect(() => {
    if (containerList.length > 0) {
      containerList.forEach((container) => {
        append({
          stepLabel: container?.stepLabel
            ? container?.stepLabel
            : container?.name,
          uniqueId: container.uniqueId,
          flowId: container.flowId,
          inpParaLabels: container.labels.map((label: any) => ({
            uniqueId: label.uniqueId,
            actions: label.actionId ? label.actionId : "next",
            redirect: getRedirectionData(label.redirectId),
          })),
          labelNames: getOutputData(container?.containerOutput),
        });
      });
    }
  }, [containerList]);

  useEffect(() => {
    if (fields.length > 0) {
      setSelectedTabIndex(0);
      setSelectedField(fields[0]);

      if (!selectedTab && !sideSelectedTab) {
        setSelectedTab(containerList[0].flowId);
        setSideSelectedTab(containerList[0].labels[0]?.labelId || null);
      }
    }
  }, [fields]);

  const handleTabSelect = (tabId: any) => {
    const selectedContainer = containerList.find(
      (container) => container.flowId === tabId.flowId
    );
    const selectedContainerIndex = containerList.findIndex(
      (container) => container.flowId === tabId.flowId
    );

    setSelectedField(fields[selectedContainerIndex]);
    setSelectedTab(tabId.flowId);
    setSelectedTabIndex(selectedContainerIndex);
    setSideSelectedTab(selectedContainer.labels[0]?.labelId || null);
  };

  const handleFormSubmit = async (data: any) => {
    var container = data.inputParameter;
    const updatedMainObject = containerList.map((item) => {
      const newData = container.find(
        (data: any) => data.uniqueId === item.uniqueId
      );

      if (newData) {
        let selectedValues = [];
        if (newData.labelNames) {
          for (let i = 0; i < newData.labelNames.length; i++) {
            selectedValues.push({
              uniqueId: newData.labelNames[i].value,
              name: newData.labelNames[i].label,
            });
          }
        }

        const updatedLabels = item.labels.map((label: any) => {
          const newLabelData = newData.inpParaLabels.find(
            (l: any) => l.uniqueId === label.uniqueId
          );
          return newLabelData
            ? {
                ...label,
                actionId: newLabelData.actions,
                redirectId: newLabelData.redirect.value.toString(),
              }
            : label;
        });

        return {
          ...item,
          stepLabel: newData.stepLabel,
          flowId: newData.flowId,
          labels: updatedLabels,
          containerOutput: selectedValues,
        };
      }
      return item;
    });
    setIsLoading(true);
    try {
      const newData = {
        ...editMagicData,
        magicDetails: updatedMainObject,
      };
      const response = await containerServices.ContainerAction(newData);
      if (response.success) {
        setIsLoading(false);
        closeInputParameter();
      } else {
        console.error("Error fetching data:");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  return (
    <form
      className="w-full h-full flex flex-col"
      onSubmit={handleSubmit((data) => handleFormSubmit(data))}
    >
      <h2 className="text-[2.4rem] font-inter font-semibold text-io-black dark:text-io-white mb-6">
         {t("web.magix.label.modellsit")} ({containerList.length})
      </h2>
      <TabSlider
        sliderData={containerList}
        handleTabSelect={handleTabSelect}
        selectedTab={selectedTab}
        slidersPerview={slidersPerview}
        hasMoreOption={false}
        hasError={errors}
        isContainer={true}
      />
      {fields.map(
        (field: any, index: number) =>
          selectedField?.id === field?.id && (
            <div
              key={field.id}
              className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large"
            >
              <div className="w-full h-full flex justify-between flex-col">
                <div className="w-[50%] h-auto my-[3rem]">
                  <InputField
                    labelName={t("web.magix.label.steplabel")}
                    type="text"
                    id={`inputParameter[${index}].stepLabel`}
                    isRequired={true}
                    formHandle={register(`inputParameter[${index}].stepLabel`)}
                    error={
                      (errors.inputParameter as any)?.[selectedTabIndex]
                        ?.stepLabel?.message
                    }
                  />
                </div>

                <LabelFields
                  filteredContainers={filteredContainers}
                  sideSelectedTab={sideSelectedTab}
                  nestIndex={index}
                  {...{ control, register, errors }}
                  sideSelectedTabIndex={sideSelectedTabIndex}
                  selectedTabIndex={selectedTabIndex}
                  options={options}
                  selectedField={selectedField}
                  setSideSelectedTab={(id: any) => setSideSelectedTab(id)}
                  watch={watch}
                />

                <div className="w-full h-auto my-[2.5rem]">
                  <h5 className="text-[2rem] font-inter font-medium text-io-black dark:text-io-white mb-6">
                    {t("web.common.output")}
                  </h5>
                  <div className="w-1/2 h-auto">
                    <MultiSelectInput
                      labelName={t("web.magix.label.labelname")}
                      idName={`inputParameter[${index}].labelNames`}
                      key={selectedField?.id}
                      isMulti={true}
                      name={`inputParameter[${index}].labelNames`}
                      control={control}
                      error={
                        (errors.inputParameter as any)?.[selectedTabIndex]
                          ?.labelNames?.message
                      }
                      isSearchable={false}
                      options={outputOptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
      )}
      <ModalPageFooter
        footerType="form"
        handleCancel={closeInputParameter}
        isLoading={isLoading}
      />
    </form>
  );
}

const LabelFields = ({
  filteredContainers,
  sideSelectedTab,
  nestIndex,
  control,
  register,
  sideSelectedTabIndex,
  errors,
  selectedTabIndex,
  options,
  setSideSelectedTab,
  watch,
}: any) => {
  const { t } = useTranslation();
  const [sideSelectedField, setSideSelectedField] = useState<any>(null);

  const { fields, remove, append } = useFieldArray({
    control,
    name: `inputParameter[${nestIndex}].inpParaLabels`,
  });

  useEffect(() => {
    if (fields.length > 0) {
      setSideSelectedField(fields[sideSelectedTabIndex]);
    }
  }, [sideSelectedTabIndex, fields]);

  const handleLabelClick = (id: any) => {
    const selectedLabelIndex = filteredContainers[0]?.labels?.findIndex(
      (label: any) => label.labelId === id
    );
    setSideSelectedField(fields[selectedLabelIndex]);
    setSideSelectedTab(id);
  };
  return (
    <div className="w-full h-full">
      <div className="w-full h-full relative">
        <div className="w-full h-full">
          <div className="w-full h-full flex">
            <div className="w-[21.26%] flex-grow max-md:w-[35%]">
              <div className="w-full h-full bg-io-gray-f8 dark:bg-io-black-1c pt-3 flex flex-col">
                <div className="w-full h-full pl-2 overflow-y-auto">
                  {filteredContainers.length > 0 &&
                    filteredContainers[0].labels.map(
                      (
                        label: { name: string; labelId: string },
                        index: number
                      ) => (
                        <Fragment key={index}>
                          <KeywordCreationChip
                            labelName={label.name}
                            isActive={label.labelId === sideSelectedTab}
                            isCheckbox={false}
                            currentStatus={false}
                            onClick={() => handleLabelClick(label.labelId)}
                            hasDeleteOption={false}
                          />
                        </Fragment>
                      )
                    )}
                </div>
              </div>
            </div>
            <div className="w-[78.74%] flex-grow ml-auto max-md:w-[65%]">
              <div className="w-full h-auto px-[4rem] py-[2rem] max-md:p-[3rem]">
                {fields.map(
                  (field: any, index: number) =>
                    sideSelectedField?.id === field?.id && (
                      <div
                        className="w-[70%] h-auto max-md:w-full"
                        key={field.id}
                      >
                        <div className="w-full h-auto mb-[3.5rem]">
                          <p className="text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 mb-2">
                            {t("web.magix.label.actions")}
                          </p>
                          <Fragment>
                            <ActionPills
                              formHandle={register(
                                `inputParameter[${nestIndex}].inpParaLabels[${index}].actions`
                              )}
                              error={
                                (errors.inputParameter as any)?.[
                                  selectedTabIndex
                                ]?.inpParaLabels?.[index]?.actions?.message
                              }
                            />
                          </Fragment>
                        </div>
                        {watch({ nest: true }).inputParameter[nestIndex]
                          .inpParaLabels[index].actions !== "retake" &&
                          watch({ nest: true }).inputParameter[nestIndex]
                            .inpParaLabels[index].actions !== "complete" && (
                            <div className="mb-[3.8rem]">
                              <SelectInput
                                labelName={t("web.magix.label.redirect")}
                                error={
                                  (errors.inputParameter as any)?.[
                                    selectedTabIndex
                                  ]?.inpParaLabels?.[index]?.redirect?.message
                                }
                                idName={`inputParameter[${nestIndex}].inpParaLabels[${index}].redirect`}
                                name={`inputParameter[${nestIndex}].inpParaLabels[${index}].redirect`}
                                control={control}
                                isRequired={false}
                                options={options}
                              />
                            </div>
                          )}
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputParametersForm;
