import { ReactNode, useEffect, useRef, useState } from "react";
import { DeleteIcon, ModalListVisionIcon, MoreIcon } from "../../svg";
import CardMoreOptions from "../../form/card-more-options";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../my-context/MyContext";
interface Item {
  id: string;
  name: string;
  description: string;
  thumbnail: string;
  icon: string;
}
interface ModalListCardProps {
  type: string;
  item: Item;
  index?: any;
  sortedList?: any;
  handleDelete?: () => void;
}
interface Option {
  label: string;
  icon: ReactNode;
}

function ModalListCard({
  type,
  item,
  index,
  sortedList,
  handleDelete,
}: ModalListCardProps) {
  const options: Option[] = [
    { label: "Delete", icon: <DeleteIcon /> },
    // { label: "Copy Link", icon: <CopyLinkIcon /> },
  ];
  const [isContentVisible, setContentVisible] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [cardIndex, setCardIndex] = useState<any>(null);
  const { isExternalUser } = useMyContext();
  const handleMouseLeave = (event: any) => {
    setContentVisible(false);
  };

  const handleOptionSelect = (option: any) => {
    if (option.label === "Label") {
      handleDelete?.();
    } else if (option.label === "Delete") {
      handleDelete?.();
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        contentRef.current &&
        !contentRef.current.contains(event.target as Node)
      ) {
        setContentVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (item?.id && sortedList?.length > 0) {
      const foundIndex = sortedList.findIndex(
        (element: any) => element.id === item.id
      );
      setCardIndex(foundIndex);
    }
  }, [item, sortedList]);
  const { t } = useTranslation();

  return (
    <div
      className={`h-auto group mx-auto ${
        type === "container" ? "w-full mb-4" : "w-full"
      }`}
      onMouseLeave={handleMouseLeave}
    >
      <div className="w-full h-auto rounded-[0.5rem] bg-io-gray-fa dark:bg-io-black-1a group-hover:bg-io-white group-hover:dark:bg-io-black-29 group-hover:shadow-[0_3px_21.1px_0_rgba(0,0,0,0.11)]">
        <div className="w-full h-auto p-[0.7rem]  relative">
          <div
            className={`w-full  rounded-[0.5rem] relative overflow-hidden ${
              type === "container" ? "mb-6 h-[11.5rem]" : "mb-0 h-[6.5rem]"
            }`}
          >
            <div className="w-full h-full">
              <img
                className="w-full h-full object-contain object-center"
                src={
                  item?.icon
                    ? item?.icon
                    : require("../../../assets/images/ai-magic-no-image.png")
                }
                alt=""
              />
            </div>

            <div className="w-auto h-auto absolute left-0 bottom-0">
              <div
                className={`w-auto bg-io-yellow rounded-[0.2rem] ${
                  type === "container"
                    ? "text-[1.1rem] px-[0.6rem] py-[0.3rem]"
                    : "text-[0.9rem] px-[0.4rem] py-[0.1rem]"
                }`}
              >
                <span className="text-inherit font-inter font-normal text-io-black">
                  {t("web.card.title.predefined")}
                </span>
              </div>
            </div>
            <div className="w-auto h-auto absolute left-[0.4rem] top-[0.4rem]">
              <div
                className={`${
                  type === "container" ? "w-[3rem]" : "w-[1.7rem]"
                } h-auto`}
              >
                <ModalListVisionIcon />
              </div>
            </div>
          </div>
          {type === "container" && (
            <div
              className="w-auto h-auto absolute top-[5%] right-[5%] hidden group-hover:block max-md:block"
              ref={contentRef}
            >
              <div className="w-auto h-auto relative">
                {!isExternalUser && (
                  <button
                    type="button"
                    className="w-[2rem] h-[2rem] rounded-full bg-io-primary flex justify-center items-center"
                    onClick={() => setContentVisible(!isContentVisible)}
                  >
                    <div className="w-[1.2rem] h-auto">
                      <MoreIcon />
                    </div>
                  </button>
                )}
                {isContentVisible && (
                  <div className="w-auto h-auto absolute top-full right-[-1rem] pt-5">
                    <CardMoreOptions
                      options={options}
                      onSelectOption={(option) => handleOptionSelect(option)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="w-full h-auto py-3 flex justify-between items-center pl-[0.6rem]">
            <div className="flex-1">
              <p
                className={`${
                  type === "container" ? "text-[1.7rem]" : "text-[1.2rem]"
                }  font-inter font-semibold text-io-black dark:text-io-white leading-tight`}
              >
                {item?.name}
              </p>
              <p
                className={`${
                  type === "container" ? "text-[1.4rem]" : "text-[1rem]"
                }  font-inter line-clamp-1 text-ellipsis font-medium text-io-gray-66 leading-tight dark:text-io-black-d1`}
              >
                {item?.description ? item?.description : "-"}
              </p>
            </div>
            {type === "container" && (
              <p className="text-[4rem] font-inter font-medium text-[#D4D4D4] leading-tight pr-4">
                {index + 1}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalListCard;
