import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import EncryptionKey from "../../auth/EncryptionKey";
import CryptoJS from "crypto-js";
import i18n from "../../utils/localization/i18n";

interface MyContextType {
  language: string;
  theme: string;
  setLanguage: (lang: string) => void;
  setTheme: (theme: string) => void;
  isExternalUser: boolean;
}

const MyContext = createContext<MyContextType | undefined>(undefined);

interface MyContextProviderProps {
  children: ReactNode;
}

export const MyContextProvider: React.FC<MyContextProviderProps> = ({
  children,
}) => {
  const [language, setLanguage] = useState<string>("en");
  const [theme, setTheme] = useState<string>("light");
  const [isExternalUser] = useState<boolean>(false);

  const changeLanguage = (lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  const changeTheme = (theme: string) => {
    setTheme(theme);
  };

  useEffect(() => {
    loadProviderData();
  }, []);

  const loadProviderData = () => {
    try {
      const encryptedData: any = sessionStorage.getItem(
        "selectedLanguageDetails"
      );

      if (!encryptedData) {
        console.warn("No encrypted data found in sessionStorage.");
        return;
      }

      const primaryKey = EncryptionKey(); // Ensure this returns the correct key
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, primaryKey);
      const decryptedData: any = decryptedBytes.toString(CryptoJS.enc.Utf8);
      if (!decryptedData) {
        console.warn("Decryption failed or returned an empty result.");
        return;
      }

      const data = JSON.parse(decryptedData);
      if (data) {
        setLanguage(data.code);
        i18n.changeLanguage(data.code || "en");
      }

      setTheme("light");
    } catch (error) {
      console.error("Error loading provider data:", error);
    }
  };

  return (
    <MyContext.Provider
      value={{
        language,
        theme,
        setLanguage: changeLanguage,
        setTheme: changeTheme,
        isExternalUser,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = (): MyContextType => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};
