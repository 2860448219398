import { useEffect, useState } from "react";

interface Option {
  id: string;
  label: string;
}
interface CheckBoxGroupProps {
  options: Option[];
  checked: Option[];
  name: string;
  onOptionChange?: (selectedOption: any) => void;
}

function CheckBoxGroup({
  options,
  name,
  checked,
  onOptionChange,
}: CheckBoxGroupProps) {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const handleChange = (selectedOption: Option) => {
    const isChecked = selectedOptions.some(
      (option) => option.id === selectedOption.id
    );
    let updatedOptions: Option[];

    if (isChecked) {
      updatedOptions = selectedOptions.filter(
        (option) => option.id !== selectedOption.id
      );
    } else {
      updatedOptions = [...selectedOptions, selectedOption];
    }

    setSelectedOptions(updatedOptions);
    onOptionChange?.(updatedOptions);
  };
  useEffect(() => {
    if (checked.length > 0) {
      setSelectedOptions(checked);
    }
  }, [checked]);

  return (
    <div className="w-full h-auto flex justify-start items-center gap-8 flex-wrap">
      {options.map((option, i) => (
        <div key={i} className="w-auto flex justify-start items-center gap-4">
          <input
            type="checkbox"
            name={name}
            id={option.id}
            className="accent-io-primary"
            checked={selectedOptions.some(
              (selectedOption) => selectedOption.id === option.id
            )}
            onChange={() => handleChange(option)}
          />
          <label
            htmlFor={option.id}
            className="text-[1.4rem] font-inter font-normal text-io-black dark:text-io-white"
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
}
export default CheckBoxGroup;
