import { useNavigate, useParams } from "react-router-dom";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import CreateContainerForm from "../create-container-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function CreateContainer({ containerUpdated }: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    if (id !== "new") {
      setTitle(t("web.subtitle.label.editcontainer"));
    } else {
      setTitle(t("web.subtitle.label.addcontainer"));
    }
  }, [id, t]);
  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex flex-col">
        <ModalPageHeader
          title={title}
          handleClose={() => navigate("/ai-magix-container")}
        />
        <div className="w-full h-full flex-auto">
          <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
            <CreateContainerForm containerUpdated={containerUpdated} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateContainer;
