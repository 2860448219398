import { useState } from "react";
import IconBtn from "../../../form/icon-btn";
import {
  DropDownIcon,
  ExpandIcon,
  InfoIcon,
  PageModalCloseIcon,
  PreviewCameraIcon,
  PreviewNoteIcon,
  PreviewSignatureIcon,
  ResponseAudioIcon,
  ResponseDateIcon,
  ResponseDateTimeIcon,
  ResponseImageIcon,
  ResponseImageWithInputIcon,
  ResponseLocationIcon,
  ResponseMagicContainerIcon,
  ResponseNumberIcon,
  ResponsePersonNameIcon,
  ResponseRangeDateIcon,
  ResponseScannerIcon,
  ResponseSiteNameIcon,
  ResponseTenantNameIcon,
  ResponseTextIcon,
  ResponseTimeIcon,
  ResponseToggleIcon,
  ResponseVideoIcon,
  TabThumbDesktop,
  TabThumbMobile,
  TabThumbTablet,
} from "../../../svg";
import { useTranslation } from "react-i18next";
import { ResponseNumber } from "../question-type-renderer";
import { ToggleButtonWithoutForm } from "../../../form/toggle-btn";

interface ProcessPreviewProps {
  processData: any;
  questionDetails: any;
  selectedScreen: any;
  handleExpandPreview: () => void;
  handleHidePreview: () => void;
  pageTitle:string;
}

function ProcessPreviewNew({
  processData,
  questionDetails,
  selectedScreen,
  handleExpandPreview,
  handleHidePreview,
  pageTitle
}: ProcessPreviewProps) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("mobile");
  const tabs = [
    { id: "mobile", label: "Mobile", icon: <TabThumbMobile /> },
    { id: "tablet", label: "Tablet", icon: <TabThumbTablet /> },
    { id: "desktop", label: "Desktop", icon: <TabThumbDesktop /> },
  ];
  const handleTabChange = (tabId: string) => {
    setSelectedTab(tabId);
  };

  const renderResponseComponent = (item: any) => {
    const componentMap: { [key: string]: any } = {
      1: <PreviewTenantName item={item} />,
      2: <PreviewSiteName item={item} />,
      3: <PreviewPersonName item={item} />,
      4: <PreviewLocation item={item} />,
      5: <PreviewScanner item={item} />,
      6: <PreviewScanner item={item} />,
      7: <PreviewScanner item={item} />,
      8: <PreviewScanner item={item} />,
      9: <PreviewMultipleChoice item={item} />,
      10: <PreviewMultipleChoice item={item} />,
      11: <PreviewMultipleChoice item={item} />,
      12: <PreviewText item={item} />,
      13: <PreviewText item={item} />,
      14: <PreviewNumber item={item} />,
      15: <PreviewDateTime item={item} />,
      16: <PreviewDateTime item={item} />,
      17: <PreviewDateTime item={item} />,
      18: <PreviewAcknowledgement item={item} />,
      19: <PreviewInformation item={item} />,
      20: <PreviewSignature item={item} />,
      21: <PreviewImage item={item} />,
      22: <PreviewImageWithInputs item={item} />,
      23: <PreviewVideo item={item} />,
      24: <PreviewMagicContainer item={item} />,
      25: <PreviewAudio item={item} />,
      26: <PreviewToggle item={item} />,
      27: <PreviewDateTime item={item} />,
      28: <PreviewMultipleChoice item={item} />,
    }
    return (componentMap[item.type] || <p>{t("web.label.Notfound")}</p>);
  }

  console.log(processData);

  const renderSelectedDevice = () => {
    switch (selectedTab) {
      case "mobile":
        return (
          <div className="w-full h-auto">
            <div className="w-[25.3rem] h-[51.4rem] mx-auto relative">
              <div className="w-full h-full z-[0]">
                <div className="w-[94%] mx-auto h-[20%] bg-io-primary rounded-t-[5rem] rounded-b-[2rem]"></div>
              </div>

              <div className="w-full h-full absolute top-0 left-0  z-[2]">
                <img
                  src={require("../../../../assets/images/preview-mobile.png")}
                  alt="MobileImage"
                />
              </div>
              <div className="w-full h-full absolute top-0 left-0 z-[3]">
                <div className="w-[80%] h-full mx-auto pb-[1rem] flex justify-center items-end">
                  <div className="w-full h-[90%] bg-io-white rounded-[2rem] overflow-hidden">
                    <div className="w-full h-full flex flex-col">
                      <div className="w-full h-auto">
                        <div className="w-full h-auto px-3 py-3 border-b border-io-gray-c8">
                          <p className="text-[1rem] font-inter font-normal text-io-black">
                            {pageTitle}
                          </p>
                        </div>
                        <div className="w-full h-auto px-2 py-2">
                          <div className="w-full h-auto flex justify-between items-center">
                            <p className="text-[1rem] font-inter font-normal text-io-primary">
                              {selectedScreen?.orderNo}/{processData.length}{" "}
                              <span className="text-[0.8rem] text-io-black">
                                Questions
                              </span>
                            </p>
                            <p className="text-[1rem] font-inter font-normal text-io-primary">
                              &lt; &gt; &gt; &gt;
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-auto my-[1rem]">
                        <div className="w-full flex justify-between items-center gap-1">
                          {processData.map((item: any, index: number) => (
                            <div
                              key={index}
                              className={`w-full h-[2px] bg-io-primary ${index < selectedScreen?.orderNo ? 'opacity-100' : 'opacity-50'}`}
                            ></div>
                          ))}

                        </div>
                      </div>
                      <div className="w-full p-2 flex-auto basis-0 overflow-y-auto scrollbar-small">
                        <div className="w-full h-full">
                          {selectedScreen?.questionDetails.map((item: any, i: any) => (
                            renderResponseComponent(item)
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "tablet":
        return (
          <div className="w-full h-auto">
            <div className="w-[25rem] h-[34.5rem] mx-auto relative">
              <div className="w-full h-full z-[0]">
                <div className="w-[94%] mx-auto h-[20%] bg-io-primary rounded-t-[1rem] rounded-b-[1rem]"></div>
              </div>

              <div className="w-full h-full absolute top-0 left-0  z-[2]">
                <img
                  src={require("../../../../assets/images/preview-tablet.png")}
                  alt="MobileImage"
                />
              </div>
              <div className="w-full h-full absolute top-0 left-0 z-[3]">
                <div className="w-[80%] h-full mx-auto pb-[1.2rem] flex justify-center items-end">
                  <div className="w-full h-[90%] bg-io-white rounded-[2rem] overflow-hidden">
                    <div className="w-full h-full flex flex-col">
                      <div className="w-full h-auto">
                        <div className="w-full h-auto px-3 py-3 border-b border-io-gray-c8">
                          <p className="text-[1rem] font-inter font-normal text-io-black">
                          {pageTitle}
                          </p>
                        </div>
                        <div className="w-full h-auto px-2 py-2">
                          <div className="w-full h-auto flex justify-between items-center">
                          <p className="text-[1rem] font-inter font-normal text-io-primary">
                              {selectedScreen?.orderNo}/{processData.length}{" "}
                              <span className="text-[0.8rem] text-io-black">
                                Questions
                              </span>
                            </p>
                            <p className="text-[1rem] font-inter font-normal text-io-primary">
                              &lt; &gt; &gt; &gt;
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-auto my-[1rem]">
                        <div className="w-full flex justify-between items-center gap-1">
                          {processData.map((item: any, index: number) => (
                            <div
                              key={index}
                              className={`w-full h-[2px] bg-io-primary ${index < selectedScreen?.orderNo ? 'opacity-100' : 'opacity-50'}`}
                            ></div>
                          ))}

                        </div>
                      </div>
                      <div className="w-full p-2 flex-auto basis-0 overflow-y-auto scrollbar-small">
                        <div className="w-full h-full">
                          {selectedScreen?.questionDetails.map((item: any, i: any) => (
                            renderResponseComponent(item)
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "desktop":
        return (
          <>
            <div className="w-full h-auto">
            <div className="w-[38rem] h-[32.5rem] mx-auto relative">
              {/* <div className="w-full h-full z-[0]">
                <div className="w-[94%] mx-auto h-[20%] bg-io-primary rounded-t-[1rem] rounded-b-[1rem]"></div>
              </div> */}

              <div className="w-full h-full absolute top-0 left-0  z-[2]">
                <img
                  src={require("../../../../assets/images/preview-desktop.png")}
                  alt="MobileImage"
                />
              </div>
              <div className="w-full h-[24rem] absolute top-0 left-0 z-[3]">
                <div className="w-[90%] h-full mx-auto pb-[1.2rem] flex justify-center items-end">
                  <div className="w-full h-[93%] bg-io-white overflow-hidden">
                    <div className="w-full h-full flex flex-col">
                      <div className="w-full h-auto">
                        <div className="w-full h-auto px-2 py-2 border-b border-io-gray-c8">
                          <p className="text-[1rem] font-inter font-normal text-io-black">
                          {pageTitle}
                          </p>
                        </div>
                        <div className="w-full h-auto px-2 py-2">
                          <div className="w-full h-auto flex justify-between items-center">
                          <p className="text-[1rem] font-inter font-normal text-io-primary">
                              {selectedScreen?.orderNo}/{processData.length}{" "}
                              <span className="text-[0.8rem] text-io-black">
                                Questions
                              </span>
                            </p>
                            <p className="text-[1rem] font-inter font-normal text-io-primary">
                              &lt; &gt; &gt; &gt;
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-auto my-[0.3rem]">
                        <div className="w-full flex justify-between items-center gap-1">
                          {processData.map((item: any, index: number) => (
                            <div
                              key={index}
                              className={`w-full h-[2px] bg-io-primary ${index < selectedScreen?.orderNo ? 'opacity-100' : 'opacity-50'}`}
                            ></div>
                          ))}

                        </div>
                      </div>
                      <div className="w-full p-2 flex-auto basis-0 overflow-y-auto scrollbar-small">
                        <div className="w-full h-full">
                          {selectedScreen?.questionDetails.map((item: any, i: any) => (
                            renderResponseComponent(item)
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="w-full h-auto">
            <div className="w-[38rem] h-[21.5rem] mx-auto relative">
              <div className="w-full h-full overflow-y-auto scrollbar-none absolute top-0 left-0 px-10 py-[5%] z-[1] ">
              <div className="w-full h-full">
                          {selectedScreen?.questionDetails.map((item: any, i: any) => (
                            renderResponseComponent(item)
                          ))}
                        </div>
              </div>
              <div className="w-full h-auto  z-0">
                <img
                  src={require("../../../../assets/images/preview-desktop.png")}
                  alt="DesktopImage"
                />
              </div>
            </div>
          </div> */}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full h-full">
      {/* <div className="w-full h-auto flex justify-end items-center gap-6 px-6 mb-[2rem]">
        <IconBtn
          size="w-[1.6rem]"
          icon={<ExpandIcon />}
          handleClick={handleExpandPreview}
        />
        <IconBtn
          size="w-[1.6rem]"
          icon={<PageModalCloseIcon />}
          handleClick={handleHidePreview}
        />
      </div> */}
      <div className="w-full h-auto">
        <div className="w-auto flex justify-center items-center mb-[2rem]">
          {tabs.map((tab) => (
            <button
              type="button"
              key={tab.id}
              className={`w-auto h-auto px-[1rem] py-[0.6rem] first:rounded-l-[0.5rem] last:rounded-r-[0.5rem]  ${selectedTab === tab.id
                ? "bg-io-primary text-io-white fill-io-white"
                : "bg-io-white text-io-gray-66 fill-io-gray-66 dark:fill-io-white dark:bg-[#2d3445]"
                }`}
              onClick={() => handleTabChange(tab.id)}
            >
              <div className="w-auto flex justify-center items-center gap-3">
                <div className="w-auto h-[1.8rem]">{tab.icon}</div>
                <p className="text-[1.4rem] font-inter font-normal text-inherit">
                  {tab.label}
                </p>
              </div>
            </button>
          ))}
        </div>
      </div>
      {renderSelectedDevice()}
    </div>
  );
}

const PreviewTenantName = ({ item }: any) => {
  console.log(item);

  return (
    <div className="w-full h-auto">
      <div className="w-full flex justify-between items-center">
        <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
        <div className="w-auto flex justify-end items-center gap-4">
          {item.isEvidence && <div className="w-[1.5rem] h-auto">
            <PreviewNoteIcon />
          </div>}
          {item.isNotes && <div className="w-[1.5rem] h-auto">
            <PreviewCameraIcon />
          </div>}
        </div>
      </div>
      <div className="w-full py-[1rem]">
        <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
          <div className="flex-1">

          </div>
          <div className="w-[1.5rem] h-auto">
            <ResponseTenantNameIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
const PreviewSiteName = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
      <div className="w-full flex justify-between items-center">
        <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
        <div className="w-auto flex justify-end items-center gap-4">
          {item.isEvidence && <div className="w-[1.5rem] h-auto">
            <PreviewNoteIcon />
          </div>}
          {item.isNotes && <div className="w-[1.5rem] h-auto">
            <PreviewCameraIcon />
          </div>}
        </div>
      </div>
      <div className="w-full py-[1rem]">
        <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
          <div className="flex-1">

          </div>
          <div className="w-[1.5rem] h-auto">
            <ResponseSiteNameIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
const PreviewPersonName = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
      <div className="w-full flex justify-between items-center">
        <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
        <div className="w-auto flex justify-end items-center gap-4">
          {item.isEvidence && <div className="w-[1.5rem] h-auto">
            <PreviewNoteIcon />
          </div>}
          {item.isNotes && <div className="w-[1.5rem] h-auto">
            <PreviewCameraIcon />
          </div>}
        </div>
      </div>
      <div className="w-full py-[1rem]">
        <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
          <div className="flex-1">

          </div>
          <div className="w-[1.5rem] h-auto">
            <ResponsePersonNameIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
const PreviewLocation = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
      <div className="w-full flex justify-between items-center">
        <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
        <div className="w-auto flex justify-end items-center gap-4">
          {item.isEvidence && <div className="w-[1.5rem] h-auto">
            <PreviewNoteIcon />
          </div>}
          {item.isNotes && <div className="w-[1.5rem] h-auto">
            <PreviewCameraIcon />
          </div>}
        </div>
      </div>
      <div className="w-full py-[1rem]">
        <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
          <div className="flex-1">

          </div>
          <div className="w-[1.5rem] h-auto">
            <ResponseLocationIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
const PreviewScanner = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
      <div className="w-full flex justify-between items-center">
        <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
        <div className="w-auto flex justify-end items-center gap-4">
          {item.isEvidence && <div className="w-[1.5rem] h-auto">
            <PreviewNoteIcon />
          </div>}
          {item.isNotes && <div className="w-[1.5rem] h-auto">
            <PreviewCameraIcon />
          </div>}
        </div>
      </div>
      <div className="w-full py-[1rem]">
        <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
          <div className="flex-1">

          </div>
          <div className="w-[1.5rem] h-auto">
            <ResponseScannerIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
const PreviewMultipleChoice = ({ item }: any) => {
  console.log(item);

  return (
    <div className="w-full h-auto">
      <div className="w-full flex justify-between items-center">
        <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
        <div className="w-auto flex justify-end items-center gap-4">
          {item.isEvidence && <div className="w-[1.5rem] h-auto">
            <PreviewNoteIcon />
          </div>}
          {item.isNotes && <div className="w-[1.5rem] h-auto">
            <PreviewCameraIcon />
          </div>}
        </div>
      </div>
      <div className="w-full py-[1rem]">
        {item.subType === 0 &&
          <div className="w-full ">
            <div className="w-full flex justify-start items-center gap-4 mb-4">
              <input type="radio" name="previewradio" />
              <p>Yes</p>
            </div>
            <div className="w-full flex justify-start items-center gap-4">
              <input type="radio" name="previewradio" />
              <p>No</p>
            </div>
          </div>
        }
        {item.subType === 1 &&
          <div className="w-full ">
            <div className="w-full flex justify-start items-center gap-4 mb-4">
              <input type="checkbox" name="previewradio" />
              <p>All are working good</p>
            </div>
            <div className="w-full flex justify-start items-center gap-4">
              <input type="checkbox" name="previewradio" />
              <p>Power streeing</p>
            </div>
          </div>
        }
        {item.subType === 2 && <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.8rem]">
          <div className="flex-1">

          </div>
          <div className="w-[1.5rem] h-auto">
            <DropDownIcon />
          </div>
        </div>}
        {item.subType === 3 && <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.8rem]">
          <div className="flex-1">

          </div>
          <div className="w-[1.5rem] h-auto">
            <DropDownIcon />
          </div>
        </div>}

      </div>
    </div>
  )
}
const PreviewText = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
      <div className="w-full flex justify-between items-center">
        <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
        <div className="w-auto flex justify-end items-center gap-4">
          {item.isEvidence && <div className="w-[1.5rem] h-auto">
            <PreviewNoteIcon />
          </div>}
          {item.isNotes && <div className="w-[1.5rem] h-auto">
            <PreviewCameraIcon />
          </div>}
        </div>
      </div>
      <div className="w-full py-[1rem]">
        <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
          <div className="flex-1">

          </div>
          <div className="w-[1.5rem] h-auto">
            <ResponseTextIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
const PreviewNumber = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
      <div className="w-full flex justify-between items-center">
        <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
        <div className="w-auto flex justify-end items-center gap-4">
          {item.isEvidence && <div className="w-[1.5rem] h-auto">
            <PreviewNoteIcon />
          </div>}
          {item.isNotes && <div className="w-[1.5rem] h-auto">
            <PreviewCameraIcon />
          </div>}
        </div>
      </div>
      <div className="w-full py-[1rem]">
        <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
          <div className="flex-1">

          </div>
          <div className="w-[1.5rem] h-auto">
            <ResponseNumberIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
const PreviewDateTime = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
      <div className="w-full flex justify-between items-center">
        <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
        <div className="w-auto flex justify-end items-center gap-4">
          {item.isEvidence && <div className="w-[1.5rem] h-auto">
            <PreviewNoteIcon />
          </div>}
          {item.isNotes && <div className="w-[1.5rem] h-auto">
            <PreviewCameraIcon />
          </div>}
        </div>
      </div>
      <div className="w-full py-[1rem]">
        {item.subType === 0 &&
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1">

            </div>
            <div className="w-[1.5rem] h-auto">
              <ResponseDateIcon />
            </div>
          </div>
        }
        {item.subType === 1 &&
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1">

            </div>
            <div className="w-[1.5rem] h-auto">
              <ResponseTimeIcon />
            </div>
          </div>
        }
        {item.subType === 2 &&
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1">

            </div>
            <div className="w-[1.5rem] h-auto">
              <ResponseDateTimeIcon />
            </div>
          </div>
        }
        {item.subType === 3 &&
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1">

            </div>
            <div className="w-[1.5rem] h-auto">
              <ResponseRangeDateIcon />
            </div>
          </div>
        }
      </div>
    </div>
  )
}
const PreviewAcknowledgement = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
    <div className="w-full flex justify-between items-center">
      <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
      <div className="w-auto flex justify-end items-center gap-4">
        {item.isEvidence && <div className="w-[1.5rem] h-auto">
          <PreviewNoteIcon />
        </div>}
        {item.isNotes && <div className="w-[1.5rem] h-auto">
          <PreviewCameraIcon />
        </div>}
      </div>
    </div>
    <div className="w-full py-[1rem]">
      <div className="w-full flex justify-start items-start gap-4">
        <div className="w-[2rem]">
       <input type="checkbox" name="previewAcknowledgement" />
        </div>
       <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
      </div>
    </div>
  </div>
  )
}
const PreviewInformation = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
    <div className="w-full flex justify-between items-center">
      <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
      <div className="w-auto flex justify-end items-center gap-4">
        {item.isEvidence && <div className="w-[1.5rem] h-auto">
          <PreviewNoteIcon />
        </div>}
        {item.isNotes && <div className="w-[1.5rem] h-auto">
          <PreviewCameraIcon />
        </div>}
      </div>
    </div>
    <div className="w-full py-[1rem]">
      <div className="w-full flex justify-start items-start gap-4">
        <div className="w-[2rem]">
          <IconBtn icon={<InfoIcon />} size="w-[1.6rem]" />
          
        </div>
       <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
      </div>
    </div>
  </div>
  )
}
const PreviewSignature = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
    <div className="w-full flex justify-between items-center">
      <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
      <div className="w-auto flex justify-end items-center gap-4">
        {item.isEvidence && <div className="w-[1.5rem] h-auto">
          <PreviewNoteIcon />
        </div>}
        {item.isNotes && <div className="w-[1.5rem] h-auto">
          <PreviewCameraIcon />
        </div>}
      </div>
    </div>
    <div className="w-full py-[1rem]">
      <div className="w-full h-[15rem] bg-[#F5FAFD] flex justify-center items-center">
        <div className="w-[4rem]">
          <IconBtn icon={<PreviewSignatureIcon />} size="w-[4rem]" />
          
        </div>
       
      </div>
    </div>
  </div>
  )
}
const PreviewImage = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
    <div className="w-full flex justify-between items-center">
      <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
      <div className="w-auto flex justify-end items-center gap-4">
        {item.isEvidence && <div className="w-[1.5rem] h-auto">
          <PreviewNoteIcon />
        </div>}
        {item.isNotes && <div className="w-[1.5rem] h-auto">
          <PreviewCameraIcon />
        </div>}
      </div>
    </div>
    <div className="w-full py-[1rem]">
      <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
        <div className="flex-1">

        </div>
        <div className="w-[1.5rem] h-auto">
          <ResponseImageIcon />
        </div>
      </div>
    </div>
  </div>
  )
}
const PreviewImageWithInputs = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
      <div className="w-full flex justify-between items-center">
        <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
        <div className="w-auto flex justify-end items-center gap-4">
          {item.isEvidence && <div className="w-[1.5rem] h-auto">
            <PreviewNoteIcon />
          </div>}
          {item.isNotes && <div className="w-[1.5rem] h-auto">
            <PreviewCameraIcon />
          </div>}
        </div>
      </div>
      <div className="w-full py-[1rem]">
        <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
          <div className="flex-1">

          </div>
          <div className="w-[1.5rem] h-auto">
            <ResponseImageWithInputIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
const PreviewVideo = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
    <div className="w-full flex justify-between items-center">
      <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
      <div className="w-auto flex justify-end items-center gap-4">
        {item.isEvidence && <div className="w-[1.5rem] h-auto">
          <PreviewNoteIcon />
        </div>}
        {item.isNotes && <div className="w-[1.5rem] h-auto">
          <PreviewCameraIcon />
        </div>}
      </div>
    </div>
    <div className="w-full py-[1rem]">
      <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
        <div className="flex-1">

        </div>
        <div className="w-[1.5rem] h-auto">
          <ResponseVideoIcon />
        </div>
      </div>
    </div>
  </div>
  )
}
const PreviewMagicContainer = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
    <div className="w-full flex justify-between items-center">
      <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
      <div className="w-auto flex justify-end items-center gap-4">
        {item.isEvidence && <div className="w-[1.5rem] h-auto">
          <PreviewNoteIcon />
        </div>}
        {item.isNotes && <div className="w-[1.5rem] h-auto">
          <PreviewCameraIcon />
        </div>}
      </div>
    </div>
    <div className="w-full py-[1rem]">
      <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
        <div className="flex-1">

        </div>
        <div className="w-[1.5rem] h-auto">
          <ResponseMagicContainerIcon />
        </div>
      </div>
    </div>
  </div>
  )
}
const PreviewAudio = ({ item }: any) => {
  return (
    <div className="w-full h-auto">
      <div className="w-full flex justify-between items-center">
        <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
        <div className="w-auto flex justify-end items-center gap-4">
          {item.isEvidence && <div className="w-[1.5rem] h-auto">
            <PreviewNoteIcon />
          </div>}
          {item.isNotes && <div className="w-[1.5rem] h-auto">
            <PreviewCameraIcon />
          </div>}
        </div>
      </div>
      <div className="w-full py-[1rem]">
        <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
          <div className="flex-1">

          </div>
          <div className="w-[1.5rem] h-auto">
            <ResponseAudioIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
const PreviewToggle = ({ item }: any) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className="w-full h-auto">
      <div className="w-full flex justify-between items-center">
        <p className="text-[1.2rem] font-inter font-medium text-io-black">{item.orderNo}. {item.title} {item.isMandatory && <span className="text-io-red">*</span>}</p>
        <div className="w-auto flex justify-end items-center gap-4">
          {item.isEvidence && <div className="w-[1.5rem] h-auto">
            <PreviewNoteIcon />
          </div>}
          {item.isNotes && <div className="w-[1.5rem] h-auto">
            <PreviewCameraIcon />
          </div>}
        </div>
      </div>
      <div className="w-full py-[1rem]">
      <div
        className={`w-auto flex justify-start items-center gap-[0.7rem]`}>
          <p className={`text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 `}></p>

        <label
          htmlFor={"previewToggle"}
          className="w-auto inline-flex items-center cursor-pointer"
        >
          <input
            type="checkbox"
            id={"previewToggle"}
            className="sr-only"
            checked={!isChecked}
            onChange={handleToggle}
          />
          <div
            className={`w-[3rem] h-[1.5rem] rounded-full relative ${!isChecked ? "bg-io-gray-c8" : "bg-io-primary"}`}>
              <div
                className={`w-[1rem] h-[1rem] bg-white rounded-full shadow-md absolute top-[50%] translate-y-[-50%] transform transition-transform duration-300 ${
                  !isChecked ? "translate-x-[25%]" : "translate-x-[170%]"
                }`}
              ></div>
            
          </div>
        </label>
      </div>
      </div>
    </div>
  )
}


export default ProcessPreviewNew;
