import React, { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ThemeHandler = ({ setIsDarkMode, isThemeToggled }:any) => {
  const {pathname} = useLocation();

  useLayoutEffect(() => {
    const storedTheme = sessionStorage.getItem("isDarkMode");
    console.log("storedTheme", storedTheme);

    if (storedTheme !== null) {
      setIsDarkMode(JSON.parse(storedTheme));
    }else{
        setIsDarkMode(false)
    }
  }, [isThemeToggled, pathname]);

  return null; // This component does not render anything
};

export default ThemeHandler;