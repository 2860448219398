import Skeleton from "react-loading-skeleton";
import IconBtn from "../../form/icon-btn";
import { GridAscSortIcon, GridDescSortIcon } from "../../svg";
import NoDataFound from "../no-data-found";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
interface InfiniteScrollGridProps {
  children: any;
  columnDefs: any;
  rowData: any;
  initialLoading: boolean;
  searchedQuery: string;
  handleScroll: () => void;
  chatGroundRef: any;
  selectedColumns: string[];
  isLoading: boolean;
  finalFetch: boolean;
  rowSelection: boolean;
  moreOptions: boolean;
  handleSort: (column: any, value: number, columnName: string) => void;
  sorting: number;
  sortingColumnName: string;
  noDataFound?: boolean;
}
function InfiniteScrollGrid({
  children,
  columnDefs,
  rowData,
  initialLoading,
  searchedQuery,
  handleScroll,
  chatGroundRef,
  selectedColumns,
  isLoading,
  finalFetch,
  rowSelection,
  moreOptions,
  handleSort,
  sorting,
  sortingColumnName,
  noDataFound,
}: InfiniteScrollGridProps) {
  const { t } = useTranslation();
  const sortingFunc = useCallback(
    (column: any) => {
      if (sorting === 0) {
        return (
          <IconBtn
            size="w-[2rem]"
            icon={<GridAscSortIcon />}
            handleClick={() => handleSort(column, 1, column.field)}
          />
        );
      } else {
        return (
          <IconBtn
            size="w-[2rem]"
            icon={<GridDescSortIcon />}
            handleClick={() => handleSort(column, 0, column.field)}
          />
        );
      }
    },
    [sorting]
  );

  return (
    <div className="w-full h-full relative">
      <table className="w-full h-full flex flex-col">
        <thead className="w-full h-auto sticky top-0">
          <tr className="w-full flex justify-between bg-io-gray-e9 p-[0.65rem] rounded-t-[1rem] dark:bg-io-black-1a">
            {rowSelection && <th className="w-[5rem]"></th>}
            {columnDefs?.map((column: any, i: number) =>
              selectedColumns.map(
                (selected: any, j: number) =>
                  column.field === selected && (
                    <th
                      className={`${column.width} py-2 px-[1%] text-left `}
                      key={i}
                    >
                      <div className="w-full h-auto">
                        <div
                          className={`w-full h-auto flex 
                                        ${
                                          column.align === "left" &&
                                          "justify-start"
                                        }
                                        ${
                                          column.align === "right" &&
                                          "justify-end"
                                        }
                                        ${
                                          column.align === "center" &&
                                          "justify-center"
                                        } items-center gap-4`}
                        >
                          <p className="text-[1.8rem] font-inter font-semibold text-io-gray-33 dark:text-io-white">
                            {column.token === undefined
                              ? column.headerName
                              : t(column.token)}
                          </p>

                          {sortingColumnName === column.field ? (
                            sortingFunc(column)
                          ) : (
                            <IconBtn
                              size="w-[2rem]"
                              icon={<GridAscSortIcon />}
                              handleClick={() =>
                                handleSort(column, 1, column.field)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </th>
                  )
              )
            )}
            {moreOptions && <th className="w-[5rem]"></th>}
          </tr>
        </thead>
        <tbody
          className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large"
          ref={chatGroundRef}
          onScroll={handleScroll}
        >
          {rowData?.length > 0 && !initialLoading ? (
            children
          ) : noDataFound && !isLoading && !initialLoading ? (
            <tr className="w-full h-full flex justify-center items-center">
              <td rowSpan={3} className="w-full h-full">
                <NoDataFound />
              </td>
            </tr>
          ) : (
            initialLoading &&
            Array.from({ length: 20 }).map((row: any, i: number) => (
              <tr key={i} className="w-full h-auto flex justify-between">
                {columnDefs.map((column: any, j: number) => (
                  <td key={j} className={`flex-1 p-3 h-[5rem]`}>
                    <Skeleton width="100%" height="100%" />
                  </td>
                ))}
              </tr>
            ))
          )}
          {!finalFetch &&
            !noDataFound &&
            Array.from({ length: 2 }).map((row: any, i: number) => (
              <tr key={i} className="w-full h-auto flex justify-between">
                {columnDefs.map((column: any, j: number) => (
                  <td key={j} className={`flex-1 p-3 h-[5rem]`}>
                    {isLoading && <Skeleton width="100%" height="100%" />}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
export default InfiniteScrollGrid;
