import { flagText } from "../../../utils/common";
import IconBtn from "../../form/icon-btn";
import { ToggleButton, ToggleButtonWithoutForm } from "../../form/toggle-btn";
import PopupCellRenderer from "../../section/user-management/user-management-grid/popup-cellrenderer";
import { ProcessMoreInfoIcon } from "../../svg";
import defaultUserImage from "../../../assets/images/card-grdi-default.png";
import defaultIconImage from "../../../assets/images/card-default-small.png";
import { TextBtn } from "../../form/text-btn";
import { useEffect, useRef, useState } from "react";
import Select, { components, ActionMeta } from "react-select";
import DeletePopupCellRenderer from "../../section/master-details/master-details-detail-grid/delete-popup-cellrenderer";
interface NameCellRendererProps {
  cellKey: string;
  selectedColumns: string[];
  rowData: any;
  handleClick?: (rowData: any) => void;
  imageType?: string;
  actionType?: string;
  options?: any;
  onUpdate?: (rowData: any, cellKey: any, inputValue: any) => void;
}
interface MoreOptionsRendererProps {
  rowData: any;
  moreOptions: boolean;
  selectedMoreOption: (option: string, row: any) => void;
}
interface InfoRendererProps {
  rowData: any;
  moreOptions: boolean;
  handleOpenInfo: (rowData: any) => void;
}
interface RowSelectionRendererProps {
  rowData: any;
  rowSelection: boolean;
  handleCheckboxChange: (e: any, row: any) => void;
}
interface CommomRendererProps {
  cellKey: string;
  selectedColumns: string[];
  rowData: any;
  handleClick?: (rowData: any) => void;
  onUpdate?: (rowData: any, cellKey: any, inputValue: any) => void;
  isSelected?: any;
  onToggle?: any;
}

const NameCellRenderer = ({
  cellKey,
  selectedColumns,
  rowData,
  handleClick,
}: NameCellRendererProps) => {
  return (
    <>
      {selectedColumns.map(
        (column: string, index: number) =>
          column === cellKey && (
            <td
              key={index}
              className={`flex-1 px-[1%] text-left ${
                handleClick ? 'cursor-pointer' : 'cursor-default'
              }`}
              onClick={() => handleClick?.(rowData)}
            >
              <div className="w-full h-full flex justify-start items-center">
                <p className="text-[1.7rem] text-io-gray-66 line-clamp-1 dark:text-io-black-d1 font-inter font-normal">
                  {rowData[cellKey]}
                </p>
              </div>
            </td>
          )
      )}
    </>
  );
};
const ImageNameCellRenderer = ({
  cellKey,
  selectedColumns,
  rowData,
  handleClick,
  imageType,
  actionType,
}: NameCellRendererProps) => {
  const handleError = (e: any, actionType: any) => {
    e.target.src = actionType === "user" ? defaultUserImage : defaultIconImage;
  };
  return (
    <>
      {selectedColumns.map(
        (column: string, index: number) =>
          column === cellKey && (
            <td
              key={index}
              className={`flex-1 px-[1%] text-left ${
                handleClick ? 'cursor-pointer' : 'cursor-default'
              }`}
              onClick={() => handleClick?.(rowData)}
            >
              <div className="w-full h-full flex justify-start items-center gap-6">
                <div className="w-[4rem]">
                  <div className="w-[3.4rem] h-[3.4rem] overflow-hidden rounded-full flex justify-center items-center">
                    <img
                      className="object-cover object-center"
                      src={
                        actionType === "user"
                          ? rowData.profileImageUrl
                            ? rowData.profileImageUrl
                            : defaultUserImage
                          : rowData.icon
                          ? rowData.icon
                          : defaultIconImage
                      }
                      alt="MagicImage"
                      onError={(e) => handleError(e, actionType)}
                    />
                  </div>
                </div>

                <p className="text-[1.7rem] line-clamp-1 text-ellipsis break-all text-io-gray-66 dark:text-io-black-d1 font-inter font-normal">
                  {rowData[cellKey]}
                </p>
              </div>
            </td>
          )
      )}
    </>
  );
};
const StatusNameCellRenderer = ({
  cellKey,
  selectedColumns,
  rowData,
  handleClick,
}: NameCellRendererProps) => {
  var textColor = rowData?.verificationColour?.[0]?.textColor ?? "defaultColor";
  var fillColor = rowData?.verificationColour?.[0]?.fillColor ?? "defaultColor";
  return (
    <>
      {selectedColumns.map((column, index) =>
        column === cellKey ? (
          <td
            key={index}
            className={`flex-1 px-[1%] text-left ${
              handleClick ? 'cursor-pointer' : 'cursor-default'
            }`}
            onClick={() => handleClick?.(rowData)}
          >
            <div className="w-full h-full flex justify-start items-center gap-4">
              <div
                className={`w-[1.2rem] h-[1.2rem] rounded-full ${
                  textColor === "completed" ? "bg-io-green" : ""
                }`}
              ></div>
              <p
                className="text-[1.7rem] text-io-gray-66 dark:text-io-black-d1 font-inter font-normal p-1 px-6 rounded-full"
                style={{
                  color: textColor,
                  backgroundColor: fillColor,
                  borderColor: textColor,
                }}
              >
                {rowData[cellKey]}
              </p>
            </div>
          </td>
        ) : null
      )}
    </>
  );
};
const StudioStatusCellRenderer = ({
  cellKey,
  selectedColumns,
  rowData,
  handleClick,
}: NameCellRendererProps) => {

  return (
    <>
      {selectedColumns.map((column, index) =>
        column === cellKey ? (
          <td
            key={index}
            className={`flex-1 px-[1%] text-left ${
              handleClick ? 'cursor-pointer' : 'cursor-default'
            }`}
            onClick={() => handleClick?.(rowData)}
          >
            <div className="w-full h-full flex justify-start items-center gap-4">
              <div
                className={`w-[1.2rem] h-[1.2rem] rounded-full ${
                  rowData[cellKey] === 0  ? "bg-io-orange" : "bg-io-green"
                }`}
              ></div>
              <p className="text-[1.7rem] text-io-gray-66 dark:text-io-black-d1 font-inter font-normal p-1 px-6 rounded-full">
                {rowData[cellKey] === 0 ? "Draft": "Completed"}
              </p>
            </div>
          </td>
        ) : null
      )}
    </>
  );
};
const MoreOptionsRenderer = ({
  rowData,
  moreOptions,
  selectedMoreOption,
}: MoreOptionsRendererProps) => {
  return (
    <>
      {moreOptions && (
        <td className="w-[5rem]">
          <div className="w-full h-full flex justify-center items-center">
            <PopupCellRenderer
              rowData={rowData}
              selectedMoreOption={selectedMoreOption}
            />
          </div>
        </td>
      )}
    </>
  );
};
const DeleteOptionRenderer = ({
  rowData,
  moreOptions,
  selectedMoreOption,
}: MoreOptionsRendererProps) => {
  return (
    <>
      {moreOptions && (
        <td className="w-[5rem]">
          <div className="w-full h-full flex justify-center items-center">
            <DeletePopupCellRenderer
              rowData={rowData}
              selectedMoreOption={selectedMoreOption}
            />
          </div>
        </td>
      )}
    </>
  );
};
const RowSelectionRenderer = ({
  rowData,
  rowSelection,
  handleCheckboxChange,
}: RowSelectionRendererProps) => {
  return (
    <>
      {rowSelection && (
        <td className="w-[5rem]">
          <div className="w-full h-full flex justify-center items-center">
            <input
              type="checkbox"
              name={rowData.id}
              id={rowData.id}
              onChange={(e) => handleCheckboxChange(e, rowData)}
            />
          </div>
        </td>
      )}
    </>
  );
};
const DefaultNameCellRenderer = ({
  cellKey,
  selectedColumns,
  rowData,
}: NameCellRendererProps) => {
  return (
    <>
      {selectedColumns.map(
        (column: string, index: number) =>
          column === cellKey && (
            <td key={index} className="flex-1 px-[1%] text-left">
              <div className="w-full h-full flex justify-start items-center">
                <p className="text-[1.7rem] text-io-gray-66 dark:text-io-black-d1 font-inter font-normal">
                  {rowData[cellKey]}
                </p>
              </div>
            </td>
          )
      )}
    </>
  );
};
const NameCellEditorRenderer = ({
  cellKey,
  selectedColumns,
  rowData,
  onUpdate,
}: NameCellRendererProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(rowData[cellKey]);
  const [isEmpty, setIsEmpty] = useState(false);
  const inputRef = useRef<any>(null);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    if (inputValue.trim() === "") {
      setIsEmpty(true);
      setIsEditing(true); // Stay in edit mode if input is empty
    } else {
      setIsEmpty(false);
      setIsEditing(false);
      onUpdate?.(rowData, cellKey, inputValue);
    }
  };

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        handleBlur();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputValue]);

  return (
    <>
      {selectedColumns.map(
        (column, index) =>
          column === cellKey && (
            <td
              key={index}
              className="flex-1 px-[1%] text-left"
              onDoubleClick={handleDoubleClick}
            >
              <div className="w-full h-full flex justify-start items-center">
                {isEditing ? (
                  <input
                    ref={inputRef}
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`text-[1.7rem] text-io-gray-66 dark:text-io-black-d1 font-inter font-normal w-full px-[0.7rem] py-[0.4rem] rounded-[0.5rem] border ${
                      isEmpty ? "border-red-500" : "border-io-primary"
                    }`}
                  />
                ) : (
                  <p className="text-[1.7rem] text-io-gray-66 dark:text-io-black-d1 font-inter font-normal">
                    {inputValue}
                  </p>
                )}
              </div>
            </td>
          )
      )}
    </>
  );
};
const SelectCellEditorRenderer = ({
  cellKey,
  selectedColumns,
  rowData,
  options,
  onUpdate,
}: NameCellRendererProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const dataTypeOptions = [
    { value: 1, label: "Text" },
    { value: 2, label: "Dropdown" },
    { value: 3, label: "Date & time" },
  ];

  const selectedLabel =
    dataTypeOptions.find((option) => option.value === rowData[cellKey])
      ?.label || "";

  const [selectedOption, setSelectedOption] = useState({
    value: rowData[cellKey],
    label: selectedLabel,
  });

  const [isEmpty, setIsEmpty] = useState(false);
  const selectRef = useRef<any>(null);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    // if (!selectedOption || !selectedOption.value.trim()) {
    if (!selectedOption) {
      setIsEmpty(true);
      setIsEditing(true);
    } else {
      setIsEmpty(false);
      setIsEditing(false);

      onUpdate?.(rowData, cellKey, selectedOption.value);
    }
  };

  const handleChange = (option: any) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (isEditing) {
      selectRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (selectRef.current) {
        // if (selectRef.current && !selectRef.current.container.contains(event.target)) {
        handleBlur();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedOption]);

  return (
    <>
      {selectedColumns.map(
        (column, index) =>
          column === cellKey && (
            <td
              key={index}
              className="flex-1 px-[1%] text-left"
              onDoubleClick={handleDoubleClick}
            >
              <div className="w-full h-full flex justify-start items-center">
                {isEditing ? (
                  <Select
                    ref={selectRef}
                    value={selectedOption}
                    onChange={handleChange}
                    options={options}
                    onBlur={handleBlur}
                    classNamePrefix="react-select"
                    menuIsOpen={true}
                    styles={{
                      container: (base) => ({ ...base, width: "100%" }),
                      control: (base) => ({
                        ...base,
                        fontSize: "1.7rem",
                        borderColor: isEmpty ? "red" : "#3b82f6",
                        boxShadow: isEmpty ? "0 0 0 1px red" : "none",
                      }),
                      menu: (base) => ({ ...base, fontSize: "1.4rem" }),
                    }}
                  />
                ) : (
                  <p className="text-[1.7rem] text-io-gray-66 dark:text-io-black-d1 font-inter font-normal">
                    {selectedOption.label}
                  </p>
                )}
              </div>
            </td>
          )
      )}
    </>
  );
};
const AccessModeRenderer = ({
  cellKey,
  selectedColumns,
  rowData,
  handleClick,
}: CommomRendererProps) => {
  const findAccessMode = (accessMode: any) => {
    switch (accessMode) {
      case 1:
        return "Global";
      case 2:
        return "Site";
    }
  };

  return (
    <>
      {selectedColumns.map(
        (column: string, index: number) =>
          column === cellKey && (
            <td
              key={index}
              className={`flex-1 px-[1%] text-left ${
                handleClick ? 'cursor-pointer' : 'cursor-default'
              }`}
              onClick={() => handleClick?.(rowData)}
            >
              <div className="w-full h-full flex justify-start items-center">
                <p className="text-[1.7rem] text-io-gray-66 dark:text-io-black-d1 font-inter font-normal">
                  {findAccessMode(rowData[cellKey])}
                </p>
              </div>
            </td>
          )
      )}
    </>
  );
};
const FlagRenderer = ({
  cellKey,
  selectedColumns,
  rowData,
  handleClick,
}: CommomRendererProps) => {
  return (
    <>
      {selectedColumns.map(
        (column: string, index: number) =>
          column === cellKey && (
            <td
              key={index}
              className={`flex-1 px-[1%] text-left ${
                handleClick ? 'cursor-pointer' : 'cursor-default'
              }`}
              onClick={() => handleClick?.(rowData)}
            >
              <div className="w-full h-full flex justify-start items-center">
                <p className="text-[1.7rem] text-io-gray-66 dark:text-io-black-d1 font-inter font-normal">
                  {flagText(rowData[cellKey])}
                </p>
              </div>
            </td>
          )
      )}
    </>
  );
};
const StatusRenderer = ({
  cellKey,
  selectedColumns,
  rowData,
  handleClick,
}: CommomRendererProps) => {
  const findStausText = (statusId: any) => {
    switch (statusId) {
      case 0:
        return "Pending";
      case 1:
        return "Creation In Progress";
      case 2:
        return "Live";
    }
  };

  return (
    <>
      {selectedColumns.map(
        (column: string, index: number) =>
          column === cellKey && (
            <td
              key={index}
              className={`flex-1 px-[1%] text-left ${
                handleClick ? 'cursor-pointer' : 'cursor-default'
              }`}
              onClick={() => handleClick?.(rowData)}
            >
              <div className="w-full h-full flex justify-start items-center">
                <p className="text-[1.7rem] text-io-gray-66 dark:text-io-black-d1 font-inter font-normal">
                  {findStausText(rowData[cellKey])}
                </p>
              </div>
            </td>
          )
      )}
    </>
  );
};
const ClickableRenderer = ({
  rowData,
  handleClick,
  selectedColumns,
  cellKey,
}: any) => {
  return (
    <>
      {selectedColumns.map(
        (column: string, index: number) =>
          column === cellKey && (
            <td className="flex-1 px-[1%] text-left">
              <div className="w-full h-full flex justify-start items-center">
                <a
                  onClick={() => {
                    handleClick(rowData);
                  }}
                  className="underline text-blue-500 font-semi-bold text-lg cursor-pointer"
                  style={{ fontFamily: "inter" }}
                >
                  Click Here
                </a>
              </div>
            </td>
          )
      )}
    </>
  );
};
const NonClickableRenderer = ({
  rowData,
  handleClick,
  selectedColumns,
  cellKey,
}: any) => {
  return (
    <>
      {selectedColumns.map(
        (column: string, index: number) =>
          column === cellKey && (
            <td className="flex-1 px-[1%] text-left">
              <div className="w-full h-full flex justify-start items-center">
                <a
                  onClick={() => {
                    handleClick(rowData);
                  }}
                  className="underline text-grey-500 font-semi-bold text-lg"
                  style={{ fontFamily: "inter" }}
                >
                  Not Available
                </a>
              </div>
            </td>
          )
      )}
    </>
  );
};
const DataTypeRenderer = ({
  cellKey,
  selectedColumns,
  rowData,
}: CommomRendererProps) => {
  const findDataType = (dataType: any) => {
    switch (dataType) {
      case 1:
        return "Multiple Choice";
      case 2:
        return "Radio Button";
      case 3:
        return "DropDown";
      case 4:
        return "Text";
    }
  };

  return (
    <>
      {selectedColumns.map(
        (column: string, index: number) =>
          column === cellKey && (
            <td key={index} className="flex-1 px-[1%] text-left">
              <div className="w-full h-full flex justify-start items-center">
                <p className="text-[1.7rem] text-io-gray-66 dark:text-io-black-d1 font-inter font-normal">
                  {findDataType(rowData[cellKey])}
                </p>
              </div>
            </td>
          )
      )}
    </>
  );
};

const ToggleRenderer = ({
  cellKey,
  selectedColumns,
  rowData,
  onUpdate,
}: CommomRendererProps) => {
  const findToggleValue = (value: any) => {
    switch (value) {
      case 1:
        return true;
      case 0:
        return false;
    }
  };
  return (
    <>
      {selectedColumns.map(
        (column: string, index: number) =>
          column === cellKey && (
            <td key={index} className="flex-1 px-[1%] text-left">
              <div className="w-full h-full flex justify-start items-center">
                <ToggleButtonWithoutForm
                  id={`${cellKey}${rowData.id}`}
                  size="large"
                  isButtonChecked={findToggleValue(rowData[column])}
                  getIsChecked={(checked: any) =>
                    onUpdate?.(rowData, cellKey, checked)
                  }
                />
              </div>
            </td>
          )
      )}
    </>
  );
};

const InfoRenderer = ({
  rowData,
  moreOptions,
  handleOpenInfo,
}: InfoRendererProps) => {
  const isShowIcon = rowData.moreDetails.length > 0 ? true : false;
  return (
    <>
      {moreOptions && (
        <td className="w-[5rem]">
          <div className="w-full h-full flex justify-center items-center">
            <IconBtn
              size="w-[2rem]"
              icon={isShowIcon ? <ProcessMoreInfoIcon /> : ""}
              handleClick={() => handleOpenInfo(rowData)}
            />
          </div>
        </td>
      )}
    </>
  );
};

export {
  NameCellRenderer,
  MoreOptionsRenderer,
  DefaultNameCellRenderer,
  NameCellEditorRenderer,
  StudioStatusCellRenderer,
  SelectCellEditorRenderer,
  RowSelectionRenderer,
  AccessModeRenderer,
  FlagRenderer,
  StatusRenderer,
  ClickableRenderer,
  DataTypeRenderer,
  ToggleRenderer,
  ImageNameCellRenderer,
  InfoRenderer,
  StatusNameCellRenderer,
  NonClickableRenderer,
  DeleteOptionRenderer,
};
