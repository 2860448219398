import {
  ActiveStepIcon,
  CompletedStepIcon,
  DisableStepIcon,
  StepCustomMagicIcon,
} from "../../svg";

interface StepsProps {
  currentStep: number;
  steps: { label: string }[];
}
const Steps: React.FC<StepsProps> = ({ currentStep, steps }: StepsProps) => {
  return (
    <div className="w-full h-auto mb-[5rem]">
      <div className="w-full h-auto flex justify-center items-center">
        <div className="w-[56%] h-20">
          <div className="w-full h-auto flex justify-between items-start">
            {steps.map((step, i) => (
              <div key={i} className={`w-[10rem] h-auto steps-wrapper ${currentStep === i +1 ? "active" : ""} ${currentStep > i +1 ? "completed" : ""}`}>
                <div className="w-full h-auto text-center">
                    <div className={`w-[3rem] h-auto mx-auto mb-5 relative ${steps.length === 3  && "step-connector-3"} ${steps.length === 4  && "step-connector-4" } `}>
                        {currentStep === i +1 && <ActiveStepIcon /> }
                        {currentStep > i +1 && <CompletedStepIcon />}
                        {currentStep < i +1 && <DisableStepIcon />}
                    </div>
                    {(currentStep === i +1) ? <div className="w-[3.1rem] h-auto mx-auto"><StepCustomMagicIcon /></div> : <p className={`w-[90%] text-[1.1rem] font-medium font-inter ${currentStep < i +1 ? "text-io-gray-33 dark:text-io-white text-opacity-50 dark:text-opacity-50" : "text-io-black dark:text-io-white"}`}>{step.label}</p>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Steps;
