import { useNavigate, useParams } from "react-router-dom";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import NewSubProcessForm from "../new-sub-process-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function NewSubProcess({ subProcessUpdated }: any) {
  const navigate = useNavigate();
  const { logicId, id } = useParams();
  const { t } = useTranslation();
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (id !== "new") {
      setTitle(t("web.subtitle.label.editscreenmapping"));
    } else {
      setTitle(t("web.subtitle.label.addscreenmaping"));
    }
  }, [id, t]);
  const handleClose = () => {
    navigate(`/ai-logic-studio/process-logic/${logicId}`);
  };
  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex flex-col">
        <ModalPageHeader title={title} handleClose={handleClose} />
        <div className="w-full h-full flex-auto">
          <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
            <NewSubProcessForm subProcessUpdated={subProcessUpdated} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default NewSubProcess;
