import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../../../components/global-layout/page-header";
import { AIMagixContainerPageHeaderIcon } from "../../../components/svg";
import DeleteUser from "../../../components/section/user-management/delete-user";
import GridColumn from "../../../components/section/user-management/grid-columns";
import AIMagixContainerGrid from "../../../components/section/ai-magix-container/ai-magix-container-grid";
import GridToolBar from "../../../components/UI/grid-toolbar";
import AIMagixContainerCards from "../../../components/section/ai-magix-container/ai-magix-container-cards";

import excelExport from "../../../services/excelExport";
import { useTranslation } from "react-i18next";
import ErrorPopup from "../../../components/UI/error-popup";
import PageLoader from "../../../components/UI/page-loader";
import {
  containerDelete,
  containerList,
} from "../../../services/authFunctions";
import { useMyContext } from "../../../components/my-context/MyContext";

function AIMagixContainer({ containerUpdated, setContainerUpdated }: any) {
  const { isExternalUser } = useMyContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [startIndex, setStartIndex] = useState(0);
  const [containerData, setContainerData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeletonLoader, setIsSkeletonLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(20);
  const [initialLoading, setIntialLoading] = useState(true);
  const [finalFetch, setFinalFetch] = useState(false);
  const scrollGroundRef = useRef<any>(null);
  const [sorting, setSorting] = useState<number>(0);
  const [searchedInputVal, setSearchedInputVal] = useState("");
  const [sortingColumnName, setSortingColumnName] = useState<string>("");
  const [isServerError, setIsServerError] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [isGridView, setIsGridView] = useState(false);
  const [toolItems, setToolItems] = useState<any>([]);
  const [searchedQuery, setSearchedQuery] = useState<any>(undefined);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<any>(false);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [isRefreshed, setIsRefreshed] = useState<any>(false);
  const [isFetchMore, setIsFetchMore] = useState<any>(false);
  const [deleteData, setDeleteData] = useState<any>(null);
  const [resetSearch, setResetSearch] = useState<any>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    "name",
    "description",
    "createdAt",
  ]);
  const columnsData = [
    { label: "Container Name", token: "web.container.label.name", key: "name" },
    {
      label: "Container Description",
      token: "web.container.label.desc",
      key: "description",
    },
    {
      label: "Created Date",
      token: "web.common.label.createddate",
      key: "createdAt",
    },
  ];
  //#region Common Function
  const createContainer = () => {
    navigate("/ai-magix-container/magix-container/new");
  };
  const handleEdit = (items: any) => {
    navigate(`/ai-magix-container/magix-container/${items.uniqueId}`);
  };
  const handleNavigate = (cardData: any) => {
    navigate(`/ai-magix-container/container/${cardData.uniqueId}`);
  };
  const handleRefresh = () => {
    setContainerData([]);
    setStartIndex(0);
    setResetSearch(true);
    setSortingColumnName("");
    setSorting(0);
    setIsRefreshed((pre: any) => !pre);
    setPageLoader(false);
  };
  const handleDelete = async (items: any) => {
    setDeleteData(items);
    setShowDeletePopup(true);
  };
  const handleDataDelete = async (items: any) => {
    try {
      setShowDeletePopup(false);
      setPageLoader(true);

      const response = await containerDelete(items);
      if (response.success) {
        handleRefresh();
        setPageLoader(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setPageLoader(false);
    }
  };
  const handleCheckboxChange = (event: any, key: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedColumns((prevSelected) => [...prevSelected, key]);
    } else {
      setSelectedColumns((prevSelected) =>
        prevSelected.filter((item) => item !== key)
      );
    }
  };
  //#endregion
  //#region Use Effect
  useEffect(() => {
    const baseTools = ["search", "export", "refresh"];
    const tools = isGridView
      ? isExternalUser
        ? baseTools
        : ["create", ...baseTools]
      : isExternalUser
      ? [...baseTools, "columns"]
      : ["create", ...baseTools, "columns"];

    setToolItems(tools);
  }, [isGridView]);

  useEffect(() => {
    if (searchedQuery !== undefined) {
      setContainerData([]);
      setStartIndex(0);
      setSearchedInputVal(searchedQuery);
    }
  }, [searchedQuery]);
  useEffect(() => {
    if (containerUpdated === true) {
      setContainerData([]);
      setStartIndex(0);
      setIsUpdated((pre: any) => !pre);
    }
  }, [containerUpdated]);

  useEffect(() => {
    listFetch(searchedInputVal);
    setContainerUpdated(undefined);
    setResetSearch(false);
  }, [
    isFetchMore,
    sortingColumnName,
    sorting,
    searchedInputVal,
    isDeleted,
    isUpdated,
    isRefreshed,
  ]);
  //#endregion
  //#region list Fetch
  const listFetch = async (query: any) => {
    setIsLoading(true);
    setIsSkeletonLoader(true);
    setFinalFetch(false);
    setNoDataFound(false);
    const response: any = await containerList(
      query,
      startIndex,
      limit,
      sorting,
      sortingColumnName
    );
    if (response.success) {
      const responseData = response.data.listDetails;
      setContainerData((prevData: any) => [...prevData, ...responseData]);
      setTotalCount(response.data.count);
      setIsLoading(false);
      setIsSkeletonLoader(false);
      if (responseData?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
      if (startIndex < limit) {
        setIntialLoading(false);
      }
    } else {
      setIsLoading(false);
      setIsSkeletonLoader(false);
    }
  };
  //#endregion
  //#region Scroll

  const loadMore = (limit: any) => {
    if (!finalFetch) {
      setStartIndex((prevIndex) => prevIndex + limit);
      setIsFetchMore((pre: any) => !pre);
    }
  };

  const handleScroll = () => {
    const chatGround = scrollGroundRef.current;
    if (
      chatGround.scrollTop + 1 + chatGround.clientHeight >=
      chatGround.scrollHeight
    ) {
      console.log("Scroll", startIndex, totalCount);
      if (startIndex + limit < totalCount) {
        loadMore(limit);
      } else {
        setFinalFetch(true);
      }
    }
  };
  //#endregion
  //#region Sorting
  const handleSort = (column: any, value: number, columnName: string) => {
    setContainerData([]);
    setStartIndex(0);
    setSorting(value);
    setSortingColumnName(columnName);
  };
  //#endregion
  //#region  Excel Export
  const [isExcelLoading, setExcelLoading] = useState(false);
  const handleExcel = async () => {
    setExcelLoading(true);
    const screenName = "container";
    const fileName = "Container List";
    const sheetName = "Container_List";
    try {
      await excelExport.excelExport(
        screenName,
        fileName,
        sheetName,
        columnsData
      );
      setExcelLoading(false);
    } catch (error) {
      console.error("Export failed:", error);
      setExcelLoading(false);
    }
  };
  //#endregion
  //#region  Render Page
  return (
    <>
      {!pathname.includes("/ai-magix-container/container") && (
        <div className="w-full h-full flex flex-col">
          <PageHeader
            title={t("web.title.label.container")}
            icon={<AIMagixContainerPageHeaderIcon />}
          />
          <div className="w-full flex-1">
            <div className="w-full h-full flex flex-col">
              <GridToolBar
                columnCount={totalCount}
                gridType={t("web.title.label.container")}
                selectView={true}
                tools={toolItems}
                handleAddRow={createContainer}
                handleDelete={() => setShowDeletePopup(true)}
                handleColumns={() => setShowColumns(true)}
                isGridView={(isChecked: any) => setIsGridView(isChecked)}
                fetchData={(query: any) => setSearchedQuery(query)}
                getSearchedQuery={(query: any) => setSearchedQuery(query)}
                handleRefresh={handleRefresh}
                handleExcel={handleExcel}
                isExcelLoading={isExcelLoading}
                resetSearch={resetSearch}
                isSkeletonLoader={isSkeletonLoader}
              />
              <div className="w-full flex-1">
                {isGridView ? (
                  <AIMagixContainerCards
                    handleNavigate={handleNavigate}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    rowData={containerData}
                    scrollGroundRef={scrollGroundRef}
                    handleScroll={handleScroll}
                    initialLoading={initialLoading}
                    isLoading={isLoading}
                    limit={limit}
                    finalFetch={finalFetch}
                    noDataFound={noDataFound}
                  />
                ) : (
                  <AIMagixContainerGrid
                    selectedColumns={selectedColumns}
                    rowSelection={false}
                    moreOptions={true}
                    handleNavigate={handleNavigate}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    rowData={containerData}
                    scrollGroundRef={scrollGroundRef}
                    handleScroll={handleScroll}
                    initialLoading={initialLoading}
                    isLoading={isLoading}
                    limit={limit}
                    finalFetch={finalFetch}
                    sorting={sorting}
                    sortingColumnName={sortingColumnName}
                    handleSort={handleSort}
                    handleCheckboxChange={handleCheckboxChange}
                    noDataFound={noDataFound}
                  />
                )}
              </div>
            </div>
          </div>
          {/* <CreateFloatingButton
            icon={<CreateNewUserButtonIcon />}
            handleClick={createNewUser}
          /> */}
        </div>
      )}
      {showDeletePopup && (
        <DeleteUser
          handleCancel={() => setShowDeletePopup(false)}
          rowData={deleteData}
          handleDelete={handleDataDelete}
          actionFlag="container"
        />
      )}
      {showColumns && (
        <GridColumn
          handleClose={() => setShowColumns(false)}
          handleCheckboxChange={handleCheckboxChange}
          columnsData={columnsData}
          selectedColumns={selectedColumns}
        />
      )}{" "}
      {isServerError && (
        <ErrorPopup handleClose={() => setIsServerError(false)} />
      )}
      {pageLoader && <PageLoader />}
      <Outlet />
    </>
  );
  //#endregion
}
export default AIMagixContainer;
