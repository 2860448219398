import { useNavigate, useParams } from "react-router-dom";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import NewSurveyForm from "../new-survey-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function NewSurvey({ surveyUpdated }: any) {
  const navigate = useNavigate();
  const { logicId, surveyId } = useParams();
  const [title, setTitle] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (surveyId !== "new") {
      setTitle(t("web.subtitle.label.editscreencollection"));
    } else {
      setTitle(t("web.subtitle.label.addscreencollection"));
    }
  }, [surveyId, t]);
  const handleClose = () => {
    navigate(`/ai-logic-studio/process-logic/${logicId}`);
  };
  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex flex-col">
        <ModalPageHeader title={title} handleClose={handleClose} />
        <div className="w-full h-full flex-auto">
          <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
            <NewSurveyForm surveyUpdated={surveyUpdated} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default NewSurvey;
