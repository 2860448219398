import { InfoIcon } from "../../svg";
interface StepsHeaderProps {
  title: string;
}

function StepsHeader({ title }: StepsHeaderProps) {
  return (
    <div className="w-full h-auto py-4 border-b border-io-gray-c8 dark:border-io-black-48">
      <div className="w-full h-auto flex justify-start items-center gap-4">
        <h2 className="text-[2rem] text-io-black font-inter font-medium dark:text-io-white">
          {title}
        </h2>
        <div className="w-[2.1rem] h-auto group relative">
          <InfoIcon />
          <div className="w-auto h-auto absolute top-full right-[-5rem] pt-5 z-[5] hidden group-hover:block">
            <div className="w-[22rem] h-auto py-4 px-5 bg-io-white shadow-xl rounded-[0.3rem] relative dark:bg-io-black-1a">
              <p className="text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed{" "}
              </p>
              <div className="absolute top-[-2rem] right-[5rem] w-auto h-auto border-b-[1rem] border-b-io-white dark:border-b-io-black-1a border-t-[1rem] border-t-transparent border-s-[1rem] border-s-transparent border-e-[1rem] border-e-transparent shadow-2xl"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StepsHeader;
