import { Fragment, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import InfiniteScrollGrid from "../../../UI/infinite-scroll-grid";
import {
  ImageNameCellRenderer,
  InfoRenderer,
  NameCellRenderer,
  StatusNameCellRenderer,
} from "../../../UI/grid-cells";

function ProcessGrid({
  selectedColumns,
  rowSelection,
  moreOptions,
  handleNavigate,
  handleEdit,
  handleDelete,
  rowData,
  scrollGroundRef,
  handleScroll,
  initialLoading,
  isLoading,
  limit,
  finalFetch,
  sorting,
  sortingColumnName,
  handleSort,
  handleCheckboxChange,
  handleOpenMoreInfo,
  noDataFound,
}: any) {
  const [columnDefs, setColumnDefs] = useState<any>([
    {
      headerName: "Process Name",
      token: "web.process.label.name",
      field: "processName",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Logic Name",
      token: "web.logic.label.name",
      field: "processLogic",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Screen Mapping Name",
      token: "web.screenmapping.label.name",
      field: "subProcessLogic",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Inspected by",
      token: "web.common.label.inspectedby",
      field: "inspectedBy",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Verification Status",
      token: "web.common.label.verificationstatus",
      field: "verificationStatus",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Created Date",
      token: "web.common.label.createddate",
      field: "createdAt",
      width: "flex-1",
      align: "left",
    },
  ]);

  const handleCellClicked = (params: any) => {
    handleNavigate(params);
  };
  return (
    <>
      <div className="w-full h-full">
        <InfiniteScrollGrid
          columnDefs={columnDefs}
          rowData={rowData}
          initialLoading={initialLoading}
          searchedQuery=""
          handleScroll={handleScroll}
          chatGroundRef={scrollGroundRef}
          selectedColumns={selectedColumns}
          isLoading={isLoading}
          finalFetch={finalFetch}
          rowSelection={rowSelection}
          moreOptions={moreOptions}
          handleSort={handleSort}
          sorting={sorting}
          sortingColumnName={sortingColumnName}
          noDataFound={noDataFound}
        >
          {rowData?.map((rowData: any, i: number) => (
            <Fragment key={i}>
              <tr className="w-full h-auto flex justify-between p-[0.65rem] border-b border-[#EDEDED] dark:border-[#BDBDBF] hover:bg-io-gray-f7">
                {/* <RowSelectionRenderer
                rowData={rowData}
                rowSelection={rowSelection}
                handleCheckboxChange={handleCheckboxChange}
              /> */}
                <ImageNameCellRenderer
                  cellKey="processName"
                  selectedColumns={selectedColumns}
                  rowData={rowData}
                  handleClick={handleCellClicked}
                  actionType="process"
                />
                <NameCellRenderer
                  cellKey="processLogic"
                  selectedColumns={selectedColumns}
                  rowData={rowData}
                  handleClick={handleCellClicked}
                />
                <NameCellRenderer
                  cellKey="subProcessLogic"
                  selectedColumns={selectedColumns}
                  rowData={rowData}
                  handleClick={handleCellClicked}
                />
                <NameCellRenderer
                  cellKey="inspectedBy"
                  selectedColumns={selectedColumns}
                  rowData={rowData}
                  handleClick={handleCellClicked}
                />
                <StatusNameCellRenderer
                  cellKey="verificationStatus"
                  selectedColumns={selectedColumns}
                  rowData={rowData}
                  handleClick={handleCellClicked}
                />
                <NameCellRenderer
                  cellKey="createdAt"
                  selectedColumns={selectedColumns}
                  rowData={rowData}
                  handleClick={handleCellClicked}
                />
                <InfoRenderer
                  rowData={rowData}
                  moreOptions={moreOptions}
                  handleOpenInfo={handleOpenMoreInfo}
                />
              </tr>
            </Fragment>
          ))}
        </InfiniteScrollGrid>
      </div>
    </>
  );
}
export default ProcessGrid;
