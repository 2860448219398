import { useNavigate, useParams } from "react-router-dom";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import CreateUserMasterForm from "../create-user-master-form";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

function CreateUserMaster({ headerDetails, userMasterUpdated }: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [title, setTitle] = useState("");

  const getIdFromPathname = () => {
    const pathArray = window.location.pathname.split("/");
    return pathArray[pathArray.length - 1];
  };

  useEffect(() => {
    const currentId = getIdFromPathname();

    if (currentId !== "new") {
      setTitle(t("web.master.title.editmastervalues"));
    } else {
      setTitle(t("web.master.title.createmastervalues"));
    }
  }, [id, t]);

  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex flex-col">
        <ModalPageHeader title={title} handleClose={() => navigate(-1)} />
        <div className="w-full h-full flex-auto">
          <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
            <CreateUserMasterForm
              headerDetails={headerDetails}
              userMasterUpdated={userMasterUpdated}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateUserMaster;
