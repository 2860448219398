import { Fragment, useEffect, useRef, useState } from "react";
import "../../user-management/user-management-grid/user-management-grid.css";
import { useNavigate } from "react-router-dom";
import GridToolBar from "../../../UI/grid-toolbar";
import { masterAccess } from "../../../../services/InspectionOneServices";
import InfiniteScrollGrid from "../../../UI/infinite-scroll-grid";
import {
  DefaultNameCellRenderer,
  AccessModeRenderer,
  FlagRenderer,
  StatusRenderer,
  ClickableRenderer,
  MoreOptionsRenderer,
  NonClickableRenderer,
} from "../../../UI/grid-cells";
import { useTranslation } from "react-i18next";

function MasterDetailsGrid({
  handleDelete,
  selectedColumns,
  rowSelection,
  moreOptions,
  masterDetailsUpdated,
  setShowColumns,
  setMasterDetailsUpdated,
  handleDataDelete,
  setShowDeletePopup,
}: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [startIndex, setStartIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeletonLoader, setIsSkeletonLoader] = useState(false);
  const [finalFetch, setFinalFetch] = useState(false);
  const [limit] = useState(20);
  const [sorting, setSorting] = useState<number>(0);
  const [sortingColumnName, setSortingColumnName] = useState<string>("");
  const [masterAccessData, setMasterAccessData] = useState<any>([]);
  const [initialLoading, setIntialLoading] = useState(true);
  const chatGroundRef = useRef<any>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [toolItems, setToolItems] = useState<any>([
    "search",
    "addrow",
    // "import",
    // "gridfilter",
    // "export",
    "columns",
    "refresh",
  ]);
  const [isGridView, setIsGridView] = useState(false);
  const [searchedQuery, setSearchedQuery] = useState<any>(undefined);
  const [isFetchMore, setIsFetchMore] = useState<any>(false);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<any>(false);
  const [searchedInputVal, setSearchedInputVal] = useState("");
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const [resetSearch, setResetSearch] = useState<any>(false);
  const [isRefreshed, setIsRefreshed] = useState<any>(false);

  const handleRefresh = () => {
    setMasterAccessData([]);
    setStartIndex(0);
    setResetSearch(true);
    setSortingColumnName("");
    setSorting(0);
    setIsRefreshed((pre: any) => !pre);
  };

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      headerName: "Name",
      token: "web.master.label.name",
      field: "name",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Access Mode",
      token: "web.master.label.accessmode",
      field: "accessMode",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Grouping Available",
      token: "web.common.label.groupingAvailable",
      field: "isGrouping",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Status",
      token: "web.common.label.status",
      field: "statusId",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Column Details",
      token: "web.common.label.columnDetails",
      field: "columnDetails",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "View Details",
      token: "web.common.label.viewDetails",
      field: "editDetails",
      width: "flex-1",
      align: "left",
    },
  ]);

  useEffect(() => {
    if (searchedQuery !== undefined) {
      setStartIndex(0);
      setMasterAccessData([]);
      setSearchedInputVal(searchedQuery);
    }
  }, [searchedQuery]);

  useEffect(() => {
    if (masterDetailsUpdated === true) {
      setMasterAccessData([]);
      setStartIndex(0);
      setIsUpdated((pre: any) => !pre);
    }
  }, [masterDetailsUpdated]);

  useEffect(() => {
    masterAccessListFetch(searchedInputVal);
    setMasterDetailsUpdated(undefined);
    setResetSearch(false);
  }, [
    isFetchMore,
    sortingColumnName,
    searchedInputVal,
    isUpdated,
    isDeleted,
    isRefreshed,
  ]);

  const masterAccessListFetch = async (query: any) => {
    setIsLoading(true);
    setIsSkeletonLoader(true);
    setFinalFetch(false);
    setNoDataFound(false);
    try {
      const requestJson = {
        filterDetails: {
          searchValue: query,
          startIndex: startIndex,
          limit: limit,
          startDate: "",
          endDate: "",
          sorting: sorting,
          columnName: sortingColumnName,
        },
      };
      const response: any = await masterAccess.MasterAccessDetailsFetch(
        requestJson
      );
      const responseData = response.data.masterAccessDetails;
      if (response.success) {
        console.log(response);
        setMasterAccessData((prevData: any) => [...prevData, ...responseData]);
        setTotalCount(responseData.length);
        setIsLoading(false);
        setIsSkeletonLoader(false);
        if (responseData.length === 0) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
        }
        if (startIndex < limit) {
          setIntialLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setIsSkeletonLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const loadMore = (limit: any) => {
    if (!finalFetch) {
      setStartIndex((prevIndex) => prevIndex + limit);
      setIsFetchMore((pre: any) => !pre);
    }
  };

  const handleScroll = () => {
    const chatGround = chatGroundRef.current;
    if (
      chatGround.scrollTop + 1 + chatGround.clientHeight >=
      chatGround.scrollHeight
    ) {
      if (startIndex + limit < totalCount) {
        loadMore(limit);
      } else {
        setFinalFetch(true);
      }
    }
  };

  const handleSort = (column: any, value: number, columnName: string) => {
    console.log(column.field);
    setMasterAccessData([]);
    setStartIndex(0);
    setSorting(value);
    setSortingColumnName(columnName);
  };

  const handleColumnDetails = (data: any) => {
    navigate("/master-details/detail/" + data.id);
  };

  const handleDisabled = (data: any) => {
    navigate("");
  };

  const handleViewDetails = (data: any) => {
    navigate("/master-details/user-master/" + data.id);
  };

  const handleAddRow = (data: void) => {
    navigate("/master-details/new-master-details/new");
  };

  const selectedMoreOption = (option: string, row: any) => {
    if (option === "edit") {
      navigate("/master-details/new-master-details/" + row.id);
    } else if (option === "delete") {
      handleDelete(row);
    }
  };

  const handleNavigate = (rowData: any) => {
    navigate("/master-details/user-master/" + rowData.id);
  };

  const handleImport = (e: any) => {
    console.log("Handle Import");
  };

  const handleRowDataDelete = async (rowData: any) => {
    setIsLoading(true);
    setIsSkeletonLoader(true);
    setShowDeletePopup(false);
    try {
      const requestJson = {
        actionMode: 2,
        masterAccessDetails: [rowData],
      };
      console.log("requestJson", handleRowDataDelete);
      const response = await masterAccess.MasterAccessAction(requestJson);
      if (response.success) {
        setIsLoading(false);
        setIsSkeletonLoader(false);
        setMasterAccessData([]);
        setStartIndex(0);
        setIsDeleted((prev) => !prev);
      } else {
        setIsLoading(false);
        setIsSkeletonLoader(false);
      }
    } catch (error) {
      setIsLoading(false);
      setIsSkeletonLoader(false);
    }
  };

  useEffect(() => {
    if (handleDataDelete?.id) {
      handleRowDataDelete(handleDataDelete);
    }
  }, [handleDataDelete]);

  return (
    <div className="w-full h-full flex flex-col">
      <GridToolBar
        columnCount={totalCount}
        gridType={t("web.toolbar.label.masterdetails")}
        selectView={false}
        tools={toolItems}
        handleColumns={() => setShowColumns(true)}
        handleAddRow={handleAddRow}
        fetchData={(query: any) => setSearchedQuery(query)}
        getSearchedQuery={(query: any) => setSearchedQuery(query)}
        handleRefresh={handleRefresh}
        handleImport={handleImport}
        resetSearch={resetSearch}
        isSkeletonLoader={isSkeletonLoader}
      />
      <div className="w-full flex-1">
        <InfiniteScrollGrid
          columnDefs={columnDefs}
          rowData={masterAccessData}
          initialLoading={initialLoading}
          searchedQuery=""
          handleScroll={handleScroll}
          chatGroundRef={chatGroundRef}
          selectedColumns={selectedColumns}
          isLoading={isLoading}
          finalFetch={finalFetch}
          rowSelection={rowSelection}
          moreOptions={moreOptions}
          handleSort={handleSort}
          sorting={sorting}
          sortingColumnName={sortingColumnName}
          noDataFound={noDataFound}
        >
          {masterAccessData?.map((rowData: any, i: number) => (
            <Fragment key={i}>
              <tr className="w-full h-auto flex justify-between p-[0.65rem] border-b border-[#EDEDED] hover:bg-io-gray-f7">
                <DefaultNameCellRenderer
                  cellKey="name"
                  selectedColumns={selectedColumns}
                  rowData={rowData}
                  handleClick={handleNavigate}
                />
                <AccessModeRenderer
                  cellKey="accessMode"
                  selectedColumns={selectedColumns}
                  rowData={rowData}
                  handleClick={handleNavigate}
                />
                <FlagRenderer
                  cellKey="isGrouping"
                  selectedColumns={selectedColumns}
                  rowData={rowData}
                  handleClick={handleNavigate}
                />
                <StatusRenderer
                  cellKey="statusId"
                  selectedColumns={selectedColumns}
                  rowData={rowData}
                  handleClick={handleNavigate}
                />
                <ClickableRenderer
                  cellKey="columnDetails"
                  rowData={rowData}
                  handleClick={handleColumnDetails}
                  selectedColumns={selectedColumns}
                />
                {rowData.statusId === 2 ? (
                  <ClickableRenderer
                    cellKey="editDetails"
                    rowData={rowData}
                    handleClick={handleViewDetails}
                    selectedColumns={selectedColumns}
                  />
                ) : (
                  <NonClickableRenderer
                    cellKey="editDetails"
                    rowData={rowData}
                    handleClick={handleDisabled}
                    selectedColumns={selectedColumns}
                  />
                )}
                <MoreOptionsRenderer
                  rowData={rowData}
                  moreOptions={moreOptions}
                  selectedMoreOption={selectedMoreOption}
                />
              </tr>
            </Fragment>
          ))}
        </InfiniteScrollGrid>
      </div>
    </div>
  );
}
export default MasterDetailsGrid;
