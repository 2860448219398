import ModalPageWrapper from "../../../global-layout/modal-page-wrapper";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import ProcessMoreInfoForm from "../process-more-info-form";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

function ProcessMoreInfo({
  statusDetail,
  selectedItem,
  handleClose,
  processUpdated,
}: any) {
  const { t } = useTranslation();
  const labelValue = (selectedItem && selectedItem[0]?.processName) || "";

  // Creating the full title
  const title = `${t("web.logicresult.title.name")} ${labelValue}`;

  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <div className="w-full h-full">
          <div className="w-full h-full flex flex-col">
            <ModalPageHeader title={title} handleClose={handleClose} />
            <div className="w-full h-full flex-auto">
              <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
                <ProcessMoreInfoForm
                  statusDetail={statusDetail}
                  selectedItem={selectedItem}
                  handleClose={handleClose}
                  processUpdated={processUpdated}
                />
              </div>
            </div>
            <ToastContainer></ToastContainer>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default ProcessMoreInfo;
