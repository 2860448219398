import { Outlet } from "react-router-dom";
import ProcessScreens from "../../components/section/ai-logic-studio/process-screens";
import ProcessScreensNew from "../../components/section/ai-logic-studio/process-screens-new";
import { useState } from "react";

function ProcessWrapper() {

  const [currentTheme, setCurrentTheme] = useState(true)

  const getToggleIsChecked = (data:any) => {
    setCurrentTheme((pre) => !pre)
  }
  return (
    <>
      <div className="w-full h-full flex flex-col">
        {currentTheme ? <ProcessScreens getToggleIsChecked={getToggleIsChecked} currentTheme={currentTheme} /> :  <ProcessScreensNew getToggleIsChecked={getToggleIsChecked} currentTheme={currentTheme} />}

      </div>
      <Outlet />
    </>
  );
}
export default ProcessWrapper;
