import ModalPageWrapper from "../../../components/global-layout/modal-page-wrapper";
import InputParameters from "../../../components/section/ai-magix-container/input-parameters";

function InputParametersWrapper({
  inputParameterParams,
  closeInputParameter,
}: any) {
  return (
    <ModalPageWrapper>
      <div className="w-[56%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <InputParameters
          inputParameterParams={inputParameterParams}
          closeInputParameter={closeInputParameter}
        />
      </div>
    </ModalPageWrapper>
  );
}
export default InputParametersWrapper;
