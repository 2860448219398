import { useTranslation } from "react-i18next";
import IconTextBtn from "../../../form/icon-text-btn";
import { InputField } from "../../../form/input-fields";
import { SelectInput, MultiSelectInput } from "../../../form/select-input";
import {
  AddIcon,
  DeleteIcon,
  MakeAnCopyIcon,
  ResponseScannerIcon,
  ResponseTextIcon,
} from "../../../svg";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
interface OptionItem {
  value: any;
  label: any;
}
interface MappingProps {
  fields: any;
  control?: any;
  items?: any;
  register?: any;
  errors?: any;
  addNewMapping: () => void;
  // handleMakeACopy: (field: any) => void;
  handleRemove: (index: number) => void;
  data: {
    masterDetail: any;
    mappingDetails: any;
    surveyDetails: any;
    getStartedDetails: any;
  };
  fieldData: any;
}

interface SurveyDetail {
  uniqueId: string;
  name: string;
}

interface SurveyResponseOption {
  uniqueId: string;
  name: string;
}
function Mapping({
  fields,
  control,
  items,
  register,
  errors,
  addNewMapping,
  // handleMakeACopy,
  handleRemove,
  data,
  fieldData,
}: MappingProps) {
  const { t } = useTranslation();
  const surveyDetails: SurveyDetail[] = data?.surveyDetails ?? [];
  const surveyResponseOptions: SurveyResponseOption[] = surveyDetails.map(
    (item: SurveyDetail) => ({
      uniqueId: item.uniqueId,
      name: item.name,
    })
  );
  const renderFields = (field: any, index: any) => {
    return fieldData.map((dataItem: any, j: number) => {
      switch (dataItem.type) {
        case 5:
          return (
            <div key={j} className="w-full h-auto">
              <InputField
                labelName={dataItem.title}
                type="text"
                id={`mappingItems[${index}].${dataItem.uniqueId}`}
                isRequired={false}
                formHandle={register(
                  `mappingItems[${index}].${dataItem.uniqueId}`
                )}
                error={
                  errors?.mappingItems?.[index]?.[dataItem.uniqueId]?.message
                }
                fieldIcon={<ResponseScannerIcon />}
              />
            </div>
          );
        case 1:
        case 2:
        case 3:
        case 4:
        case 7:
          return (
            <div key={j} className="w-full h-auto">
              <InputField
                labelName={dataItem.title}
                type="text"
                id={`mappingItems[${index}].${dataItem.uniqueId}`}
                isRequired={false}
                formHandle={register(
                  `mappingItems[${index}].${dataItem.uniqueId}`
                )}
                error={
                  errors?.mappingItems?.[index]?.[dataItem.uniqueId]?.message
                }
                fieldIcon={<ResponseTextIcon />}
              />
            </div>
          );
        case 6:
          let option: OptionItem[] = [];
          if (
            dataItem.optionGroupId &&
            data?.masterDetail?.[dataItem.optionGroupId]
          ) {
            option = (data?.masterDetail[dataItem.optionGroupId] as any[]).map(
              (item: any) => ({
                value: item.uniqueId,
                label: item.value,
              })
            );
            option.unshift({ value: "All", label: "All" });
          }
          return (
            <div key={j} className="w-full h-auto">
              <SelectInput
                labelName={dataItem.title}
                error={
                  errors?.mappingItems?.[index]?.[dataItem.uniqueId]?.message
                }
                idName={`mappingItems[${index}].${dataItem.uniqueId}`}
                name={`mappingItems[${index}].${dataItem.uniqueId}`}
                control={control}
                isRequired={false}
                options={option}
              />
            </div>
          );
        default:
          return null;
      }
    });
  };
  return (
    <div className="w-full h-full overflow-y-auto pr-[2%]">
      {fields.map((field: any, index: number) => (
        <div key={index} className="w-full h-auto border-b border-io-gray-c8">
          <div className="w-full h-auto flex justify-between items-center mb-8">
            <h5 className="text-[2.4rem] font-inter font-semibold text-io-black dark:text-io-white">
              {t("Mapping")} {index + 1}
            </h5>
            <div className="w-auto flex justify-end items-center gap-10">
              {/* <div className="w-auto">
                <IconTextBtn
                  type="button"
                  label={t("web.logic.label.makeacopy")}
                  btnSize="small"
                  icon={<MakeAnCopyIcon />}
                  handleClick={() => handleMakeACopy(index)}
                />
              </div> */}
              <div className="w-auto">
                <IconTextBtn
                  type="button"
                  label={t("web.label.common.delete")}
                  btnSize="small"
                  icon={<DeleteIcon />}
                  handleClick={() => handleRemove(index)}
                />
              </div>
            </div>
          </div>
          <div className="w-full h-auto ">
            <div className="w-full h-auto ">
              {data?.getStartedDetails.length > 0 && (
                <div className="w-full h-auto pb-[3.5rem] mb-[3.5rem] border-b border-dashed border-io-gray-c8">
                  <div className="w-[85%] grid grid-cols-3 gap-[4.5rem] ">
                    {renderFields(field, index)}
                  </div>
                </div>
              )}
              <div className="w-[85%] grid grid-cols-3 pb-[3.5rem]">
                <div className="w-full h-auto ">
                  <MultiSelectInput
                    labelName={t("web.logic.label.survey")}
                    idName={`mappingItems[${index}].survey`}
                    isMulti={true}
                    name={`mappingItems[${index}].survey`}
                    control={control}
                    error={errors?.mappingItems?.[index]?.survey?.message}
                    isSearchable={false}
                    options={surveyResponseOptions}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.uniqueId}
                    isRequired={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {data?.getStartedDetails.length > 0 && (
        <div className="w-full h-auto py-8">
          <IconTextBtn
            type="button"
            label={t("web.logic.label.addnewmapping")}
            style="text-io-primary dark:text-io-primary !text-[1.5rem] !font-medium"
            btnSize="small"
            icon={<AddIcon />}
            handleClick={addNewMapping}
          />
        </div>
      )}
    </div>
  );
}
export default Mapping;
