import ModalPageWrapper from "../../../components/global-layout/modal-page-wrapper";
import CreateContainer from "../../../components/section/ai-magix-container/create-container";

function CreateContainerWrapper({ containerUpdated }: any) {
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white ml-auto max-md:w-[85%]">
        <CreateContainer containerUpdated={containerUpdated} />
      </div>
    </ModalPageWrapper>
  );
}
export default CreateContainerWrapper;
