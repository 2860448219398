import { useState } from "react";
import { useTranslation } from "react-i18next";

function ProcessTab({ selectedTab, selectedType, isLoading }: any) {
  const [selectedOption, setSelectedOption] = useState<string>(selectedTab);
  const { t } = useTranslation();
  const options = [
    {
      id: "startupscreen",
      label: "Startup Screen",
      token: "web.startup.title.name",
    },
    {
      id: "screencollection",
      label: "Screen Collection",
      token: "web.collection.title.name",
    },
    {
      id: "screenmapping",
      label: "Screen Mapping",
      token: "web.mapping.title.name",
    },
  ];

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = event.target.value;
    setSelectedOption(selectedId);
    const selectedOption = options.find((option) => option.id === selectedId);
    if (selectedOption) {
      selectedType(selectedId, selectedOption.token);
    }
  };

  return (
    <div className="w-auto h-auto flex justify-start items-center max-xl:w-full max-xl:mb-[3%] gap-[1.2rem] max-3xl:gap-[0.8rem] mb-4">
      {options.map((option) => (
        <div key={option.id}>
          <input
            type="radio"
            id={option.id}
            value={option.id}
            checked={selectedOption === option.id}
            onChange={handleOptionChange}
            className="hidden"
            disabled={isLoading}
          />
          <label
            htmlFor={option.id}
            className={`w-auto h-auto flex justify-center items-center cursor-pointer rounded-full font-inter font-normal text-[1.6rem] px-9 max-3xl:px-6 py-3 gap-2 ${
              selectedOption === option.id
                ? "bg-io-primary text-io-white"
                : "bg-io-gray-f7 text-io-black dark:bg-io-black-1a dark:text-io-white"
            }`}
          >
            {/* <div className="w-[2.4rem] h-[2.4rem]">
                    {option.icon}
                </div> */}
            {t(option.token)}
          </label>
        </div>
      ))}
    </div>
  );
}
export default ProcessTab;
