import { useEffect, useState } from "react";
import { InputFieldWithoutForm } from "../input-fields";
import { PageModalCloseIcon, TagCloseColorIcon } from "../../svg";
import IconBtn from "../icon-btn";
import { ToggleButtonWithoutForm } from "../toggle-btn";
import { SelectInputWithoutForm } from "../select-input";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
interface Tag {
  uniqueId: string;
  value: string;
  color: string;
  isDeleted: number;
}

interface ResponseValueEditorProps {
  groupData?: any;
  editorType: string;
  hasCloseBtn: boolean;
  editorKey?: Tag;
  handleDeleteChoice?: (val: string) => void;
  hasRadioBtn?: boolean;
  formHandle?: any;
  error?: any;
  idName?: number;
  chips?: any;
  schemaName?: any;
  updatedResponseValue?: any;
  getTags?: (tags: any, groupData: any) => void;
}

function ResponseValueEditor({
  groupData,
  editorType,
  hasCloseBtn,
  editorKey,
  handleDeleteChoice,
  hasRadioBtn,
  formHandle,
  error,
  idName,
  chips,
  schemaName,
  updatedResponseValue,
  getTags,
}: ResponseValueEditorProps) {
  const generateGuid = () => uuidv4();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>("");
  const [tags, setTags] = useState<Tag[]>([]);
  const [focus, setFocus] = useState<boolean>(false);
  const [selectedColor, setSelectedColor] = useState("red");
  const [editingTagIndex, setEditingTagIndex] = useState<number | null>(null);
  const [isChoiceSelected, setIsChoiceSelected] = useState<boolean>(false);
  const [selectedScreen, setSelectedScreen] = useState<any>();
  const [selectedQuestion, setSelectedQuestion] = useState<any>();

  const colorOptions = [
    { id: 5, color: "gray", name: "Gray" },
    { id: 4, color: "red", name: "Red" },
    { id: 3, color: "orange", name: "Orange" },
    { id: 6, color: "green", name: "Green" },
    { id: 1, color: "blue", name: "Blue" },
  ];

  const screenNameOptions = [
    { id: "screen1", value: "Screen 1" },
    { id: "screen2", value: "Screen 2" },
    { id: "screen3", value: "Screen 3" },
    { id: "screen4", value: "Screen 4" },
  ];
  const questionOptions = [
    { id: 201, value: "Question 1" },
    { id: 202, value: "Question 2" },
    { id: 203, value: "Question 3" },
  ];

  const handleInputChange = (value: string): void => {
    setInputValue(value);
  };

  const handleAddTag = (e: any): void => {
    if (inputValue.trim() !== "" && editingTagIndex === null) {
      const trimmedValue = generateGuid();
      if (!tags.some((tag) => tag.uniqueId === trimmedValue)) {
        const newTag = {
          uniqueId: trimmedValue,
          value: inputValue.trim(),
          color: selectedColor,
          isDeleted: 0,
        };
        setTags([...tags, newTag]);
      }
      setInputValue("");
    }
    if (editingTagIndex !== null && inputValue.trim() !== "") {
      const updatedTags = [...tags];
      updatedTags[editingTagIndex].value = inputValue.trim();
      updatedTags[editingTagIndex].color = selectedColor;
      setTags(updatedTags);
      setEditingTagIndex(null);
      setInputValue("");
    }
    setFocus(true);
  };

  const handleDeleteTag = (e: any, tagId: any): void => {
    const tagIndex = tags.findIndex((item) => item.uniqueId === tagId);
    if (tagIndex !== -1) {
      const updatedTags = [...tags];
      updatedTags[tagIndex].isDeleted = 1;
      setTags(updatedTags);
    }
    setEditingTagIndex(null);
  };

  const handleDoubleClick = (tag: Tag, index: number) => {
    setSelectedColor(tag.color);
    setInputValue(tag.value);
    setEditingTagIndex(index);
  };

  useEffect(() => {
    if (editorKey?.uniqueId) {
      setInputValue(editorKey.value);
      setSelectedColor(editorKey.color);
    }
  }, [editorKey]);

  useEffect(() => {
    setTags(chips);
  }, [chips]);

  useEffect(() => {
    if (inputValue) {
      const choiceValues: any = {
        id: editorKey?.uniqueId,
        value: inputValue,
        color: selectedColor,
      };

      if (selectedScreen) {
        choiceValues.isChoice = {
          screenId: selectedScreen.uniqueId,
          name: selectedScreen.value,
          description: selectedScreen.value,
          screenDetails: [],
        };
      }
      if (selectedQuestion) {
        choiceValues.isChoice.screenDetails = [
          {
            uniqueId: 1,
            name: "Question 1",
            placeholder: "Enter text",
            inputType: 1,
            inputSubType: 1,
            inputOptions: [],
            convertCase: "0",
            isMandatory: true,
            isEvidence: true,
            validation: [
              {
                type: 8,
                min: 0,
                max: 0,
                range: 100,
              },
              {
                type: 1,
                pattern: [],
              },
            ],
            bindValue: "@Unit_details",
            displayValue: ["@destination"],
          },
        ];
      }
      updatedResponseValue?.(choiceValues);
    }
  }, [
    inputValue,
    selectedColor,
    isChoiceSelected,
    selectedScreen,
    selectedQuestion,
  ]);

  const handleDelete = (data: any) => {
    setSelectedColor("red");
    setInputValue("");
    setIsChoiceSelected(false);
    setSelectedScreen(null);
    setSelectedQuestion(null);
    handleDeleteChoice?.(data);
  };

  useEffect(() => {
    if (tags?.length > 0) {
      getTags?.(tags, groupData);
    }
  }, [tags]);

  return (
    <div className="w-full h-auto mb-[2rem]">
      <div className="w-full h-auto flex justify-between items-center gap-2">
        <div className="w-auto h-auto">
          {/* <input type="checkbox" name="asd" id="asdas" /> */}
          {hasRadioBtn && (
            <input
              type="radio"
              id={idName}
              name={schemaName}
              value={idName}
              {...formHandle}
              className="accent-io-primary"
            />
          )}
        </div>
        <div className="flex-1 h-auto mx-[2rem]">
          <InputFieldWithoutForm
            labelName=""
            type="text"
            id="choice1"
            value={inputValue}
            onChange={handleInputChange}
            hasAddBtn={editorType === "addTag"}
            handleAdd={(e) => handleAddTag(e)}
            focus={focus}
            textColor={
              selectedColor === "red"
                ? "text-io-red"
                : selectedColor === "green"
                ? "text-io-green"
                : selectedColor === "orange"
                ? "text-io-orange"
                : selectedColor === "blue"
                ? "text-io-blue"
                : selectedColor === "gray"
                ? "text-io-gray-c8"
                : ""
            }
          />
        </div>
        <div className="w-auto h-auto flex justify-center items-center gap-[0.8rem]">
          {colorOptions.map((option) => (
            <label key={option.id} className="w-auto h-auto cursor-pointer">
              <input
                type="radio"
                name="color"
                value={option.id}
                checked={selectedColor === option.color}
                onChange={() => setSelectedColor(option.color)}
                className="hidden"
              />
              <div
                className={`w-[2.2rem] h-[2.2rem] p-[0.1rem] border ${
                  selectedColor === option.color
                    ? "border-io-primary"
                    : "border-transparent"
                } `}
              >
                <div
                  className={`w-full h-full rounded-[0.1rem] relative 
                                    ${option.color === "red" && "bg-io-red"}
                                    ${option.color === "green" && "bg-io-green"}
                                    ${
                                      option.color === "orange" &&
                                      "bg-io-orange"
                                    }
                                    ${option.color === "blue" && "bg-io-blue"}
                                    ${
                                      option.color === "gray" && "bg-io-gray-c8"
                                    }`}
                ></div>
              </div>
            </label>
          ))}
        </div>
        {hasCloseBtn && editorKey?.uniqueId && (
          <div className="w-auto h-auto ml-[3rem]">
            <IconBtn
              size="w-[1.6rem]"
              icon={<PageModalCloseIcon />}
              handleClick={() => handleDelete(editorKey.uniqueId)}
            />
          </div>
        )}
      </div>
      <div className="w-full h-auto pl-[6%] py-4">
        {editorType === "addTag" && (
          <div className="w-full h-auto ">
            {tags.length > 0 && (
              <div className="w-full h-auto cursor-pointer select-none">
                <div className="w-full h-auto flex justify-start items-center flex-wrap gap-[0.8rem]">
                  {tags.map(
                    (tag: Tag, index: number) =>
                      tag.isDeleted === 0 && (
                        <div
                          key={index}
                          className={`w-auto flex justify-center items-center gap-[0.7rem] px-[0.8rem] py-[0.5rem] rounded-full bg-opacity-10
                                    ${
                                      tag.color === "red" &&
                                      "bg-io-red text-io-red stroke-io-red"
                                    }
                                    ${
                                      tag.color === "green" &&
                                      "bg-io-green text-io-green stroke-io-green"
                                    }
                                    ${
                                      tag.color === "orange" &&
                                      "bg-io-orange text-io-orange stroke-io-orange"
                                    }
                                    ${
                                      tag.color === "blue" &&
                                      "bg-io-blue text-io-blue stroke-io-blue"
                                    }
                                    ${
                                      tag.color === "gray" &&
                                      "bg-io-gray-c8 text-io-gray-c8 stroke-io-gray-c8"
                                    }`}
                        >
                          <p
                            className="text-[1.3rem] font-inter font-normal text-inherit"
                            onDoubleClick={() => handleDoubleClick(tag, index)}
                          >
                            {tag.value}
                          </p>
                          <button
                            type="button"
                            className="w-4 h-auto"
                            onClick={(e) => handleDeleteTag(e, tag.uniqueId)}
                          >
                            <TagCloseColorIcon />
                          </button>
                        </div>
                      )
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {editorType === "choice" && editorKey && (
          <div className="w-full h-auto">
            <div className="w-full h-auto flex justify-start items-center gap-4">
              <ToggleButtonWithoutForm
                id={editorKey.uniqueId}
                size="small"
                getIsChecked={(isChecked) => setIsChoiceSelected(isChecked)}
              />
              {isChoiceSelected && (
                <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 leading-none">
                   {t("web.form.label.multiple.response.value1")}{" "}
                  <span
                    className={`
                                    ${selectedColor === "red" && "text-io-red"}
                                    ${
                                      selectedColor === "green" &&
                                      "text-io-green"
                                    }
                                    ${
                                      selectedColor === "orange" &&
                                      "text-io-orange"
                                    }
                                    ${
                                      selectedColor === "blue" && "text-io-blue"
                                    }
                                    ${
                                      selectedColor === "gray" &&
                                      "text-io-gray-c8"
                                    }`}
                  >
                    {editorKey.value}
                  </span>{" "}
                   {t("web.form.label.multiple.response.value2")}
                </p>
              )}
            </div>
            {isChoiceSelected && (
              <div className="w-[70%] h-auto mt-6">
                <div className="w-full h-auto flex justify-start items-center gap-8">
                  <div className="w-1/2 h-auto">
                    <SelectInputWithoutForm
                      labelName={t("web.logic.label.editscreen")}
                      id={`screenName${editorKey.value}`}
                      isRequired={true}
                      options={screenNameOptions}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.id}
                      getSelection={(data: any) => setSelectedScreen(data)}
                    />
                  </div>
                  <div className="w-1/2 h-auto">
                    <SelectInputWithoutForm
                      labelName={t("web.logic.label.question")}
                      id={`question${editorKey.value}`}
                      isRequired={true}
                      options={questionOptions}
                      getOptionLabel={(option: any) => option.value}
                      getOptionValue={(option: any) => option.id}
                      getSelection={(data: any) => setSelectedQuestion(data)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
export default ResponseValueEditor;
