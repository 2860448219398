import ModalPageWrapper from "../../components/global-layout/modal-page-wrapper";
import NewGetStarted from "../../components/section/ai-logic-studio/new-get-started";

function NewGetStartedWrapper({ navigateLink, getStartedUpdated }: any) {
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <NewGetStarted getStartedUpdated={getStartedUpdated} />
      </div>
    </ModalPageWrapper>
  );
}
export default NewGetStartedWrapper;
