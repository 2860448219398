import IconBtn from "../../form/icon-btn"
import { ImagePreviewCloseIcon } from "../../svg"
interface PreviewImageProps{
    handleClose: () => void;
}

function PreviewImage({handleClose}:PreviewImageProps){
    return(
        <div className="w-full h-auto absolute top-[50%] left-0 translate-y-[-50%]">
        <div className="w-[85%] h-auto mx-auto">
          <div className="w-full h-auto relative">
            <div className="w-full h-auto">
              <img src={require("../../../assets/images/pass-main.png")} alt="Pass Image" />
            </div>
            <div className="w-auto h-auto absolute top-[2%] right-[2%]">
              <IconBtn size="w-[3rem]" icon={<ImagePreviewCloseIcon />} handleClick={() => handleClose()} />
            </div>
          </div>
        </div>
      </div>
    )
}
export default PreviewImage