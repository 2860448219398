import { Outlet } from "react-router";
import PageHeader from "../../../components/global-layout/page-header";
import Banner from "../../../components/UI/banner";
import Explore from "../../../components/section/explore";
import CreateFloatingButton from "../../../components/form/create-floating-button";
import { useNavigate } from "react-router-dom";
import { CreateCustomMagicButtonIcon, DocumentPageHeaderIcon, VisionPageHeaderIcon } from "../../../components/svg";
import { useTranslation } from "react-i18next";

function Document() {
  const navigate = useNavigate()

  const createCustomMagic = () => {
    navigate("/ai-magic-studio/document/create")
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full h-auto">
        <PageHeader title={t("Document")} icon={<DocumentPageHeaderIcon />} />
        <Banner />
        <Explore />
        <CreateFloatingButton icon={<CreateCustomMagicButtonIcon />} handleClick={createCustomMagic} />
      </div>
      <Outlet />
    </>
  );
}
export default Document;