import {createService,  updateCommonDetails} from '../services/authService' 

const serviceHandler = createService();

const domainServices = {
  DomainDetails: (data: any) => serviceHandler.handleRequest("api.web.domainValidate", data),
};

const languageServices = {
  LanguageDetails: (data: any) => serviceHandler.handleRequest("api.web.language", data),
};

const loginServices = {
  LoginDetails: (data: any) => serviceHandler.handleRequest("api.web.loginValidate", data),
  AzureDetails: (data: any) => serviceHandler.handleRequest("api.web.loginAzure", data),
};

const userServices = {
  UserLists: (data: any) => serviceHandler.handleRequest("api.web.userLists", data),
  UserDetails: (data: any) => serviceHandler.handleRequest("api.web.usersDetails", data),
  UserCreation: (data: any) => serviceHandler.handleRequest("api.web.userCreation", data),
  UserUpdate: (data: any) => serviceHandler.handleRequest("api.web.userUpdate", data),
  DeleteUser: (data: any) => serviceHandler.handleRequest("api.web.userDelete", data),
  ChangePassword: (data: any) => serviceHandler.handleRequest("api.web.changePassword", data),
};

const blobServices = {
  BlobStorage: (data: any, fileType: any) => serviceHandler.handleRequest("api.web.blobstorage", data, "POST", fileType),
};

const magicServices = {
  MagicLists: (data: any) => serviceHandler.handleRequest("api.web.magicList", data),
  MagicAction: (data: any) => serviceHandler.handleRequest("api.web.magicAction", data),
  MagicDetails: (data: any) => serviceHandler.handleRequest("api.web.magicDetails", data),
  MagicAllList: (data: any) => serviceHandler.handleRequest("api.web.magicAllList", data),
};

const sidebarServices = {
  sidebarDetails: (data: any) => {
    const commonDetails = updateCommonDetails();
    commonDetails.siteId = data.siteId;
    return serviceHandler.handleRequest("api.web.sidebarDetails", data);
  },
};

const containerServices = {
  ContainerLists: (data: any) => serviceHandler.handleRequest("api.web.containerLists", data),
  ContainerAction: (data: any) => serviceHandler.handleRequest("api.web.containerAction", data),
  ContainerDetails: (data: any) => serviceHandler.handleRequest("api.web.containerDetails", data),
  ContainerDetailFetch: (data: any) => serviceHandler.handleRequest("api.web.containerDetails", data),
};

const logicServices = {
  LogicList: (data: any) => serviceHandler.handleRequest("api.web.logicList", data),
  LogicAction: (data: any) => serviceHandler.handleRequest("api.web.logicAction", data),
  LogicDetail: (data: any) => serviceHandler.handleRequest("api.web.logicDetail", data),
};

const screenCollectionServices = {
  ScreenCollectionList: (data: any) => serviceHandler.handleRequest("api.web.screenCollectionList", data),
  ScreenCollectionAction: (data: any) => serviceHandler.handleRequest("api.web.screenCollectionAction", data),
  ScreenCollectionDetail: (data: any) => serviceHandler.handleRequest("api.web.screenCollectionDetail", data),
};

const startupScreenServices = {
  StartupScreenList: (data: any) => serviceHandler.handleRequest("api.web.startupScreenList", data),
  StartupScreenAction: (data: any) => serviceHandler.handleRequest("api.web.startupScreenAction", data),
  StartupScreenDetail: (data: any) => serviceHandler.handleRequest("api.web.startupScreenDetail", data),
  StartupScreenLists: (data: any) => serviceHandler.handleRequest("api.web.startupScreenLists", data),
};
const screenMapping = {
  ScreenMappingList: (data: any) => serviceHandler.handleRequest("api.web.screenMappingList", data),
  ScreenMappingAction: (data: any) => serviceHandler.handleRequest("api.web.screenMappingAction", data),
  ScreenMappingDetail: (data: any) => serviceHandler.handleRequest("api.web.screenMappingDetail", data),
};

const questionServices = {
  questionAction: (data: any) => serviceHandler.handleRequest("api.web.questionAction", data),
  questionOption: (data: any) => serviceHandler.handleRequest("api.web.questionOption", data),
};

const processServices = {
  ProcessLists: (data: any) => serviceHandler.handleRequest("api.web.processLists", data),
  ProcessStatusAction: (data: any) => serviceHandler.handleRequest("api.web.processStatusAction", data),
  ProcessAuthTokenAction: (data: any) => serviceHandler.handleRequest("api.web.processAuthTokenAction", data),
};

const questionnaireOptions = {
  QuestionnaireOptions: (data: any) => serviceHandler.handleRequest("api.web.questionnaireOption", data),
  QuestionnaireOptionAction: (data: any) => serviceHandler.handleRequest("api.web.questionnaireOptionAction", data),
  QuestionnaireDetails: (data: any) => serviceHandler.handleRequest("api.web.questionnaireDetails", data),
  BindingDetails: (data: any) => serviceHandler.handleRequest("api.web.bindingDetails", data),
  ContainerMasterFetch: (data: any) => serviceHandler.handleRequest("api.web.containerMasterFetch", data),
};

const subProcessMapping = {
  LogicMappingAction: (data: any) => serviceHandler.handleRequest("api.web.logicMappingAction", data),
  LogicMappingDetails: (data: any) => serviceHandler.handleRequest("api.web.logicMappingDetails", data),
};

const masterAccess = {
  MasterAccessDetailsFetch: (data: any) => serviceHandler.handleRequest("api.web.masterAccessDetailsFetch", data),
  MasterAccessActionFetch: (data: any) => serviceHandler.handleRequest("api.web.masterAccessActionFetch", data),
  MasterAccessAction: (data: any) => serviceHandler.handleRequest("api.web.masterAccessAction", data),
  MasterColumnDetailsFetch: (data: any) => serviceHandler.handleRequest("api.web.masterColumnDetailsFetch", data),
  MasterColumnDetailsAction: (data: any) => serviceHandler.handleRequest("api.web.masterColumnDetailsAction", data),
  MasterColumnAction: (data: any) => serviceHandler.handleRequest("api.web.masterColumnAction", data),
  MasterFieldMapping: (data: any) => serviceHandler.handleRequest("api.web.masterFieldMapping", data),
  MasterDetailsActionFetch: (data: any) => serviceHandler.handleRequest("api.web.masterDetailsActionFetch", data),
  MasterDetailsExcelUpload: (data: any, fileType: any,id:any) => serviceHandler.handleRequest("api.web.masterDetailsExcelUpload", data, "POST", fileType, id),
  MasterDetailsAction: (data: any) => serviceHandler.handleRequest("api.web.masterDetailsAction", data),
  MasterDetailsFetch: (data: any) => serviceHandler.handleRequest("api.web.masterDetailsFetch", data),
  MasterBindingsFetch: (data: any) => serviceHandler.handleRequest("api.web.masterBindingsFetch", data),
  MasterColumnDetailsActionFetch: (data: any) => serviceHandler.handleRequest("api.web.masterColumnDetailsActionFetch", data), 
};

export {
  domainServices,
  loginServices,
  languageServices,
  userServices,
  blobServices,
  magicServices,
  sidebarServices,
  containerServices,
  logicServices,
  screenCollectionServices,
  startupScreenServices,
  screenMapping,
  questionServices,
  processServices,
  questionnaireOptions,
  subProcessMapping,
  masterAccess,
};
