interface NotificationCardProps {
  title: string;
  description: string;
  recievedTime: string;
  image: string;
}
function NotificationCard({ data }: { data: NotificationCardProps }) {
  return (
    <div className="w-full h-auto border-b border-io-gray-c8 dark:border-io-black-48">
      <div className="w-full h-auto flex justify-center items-start gap-7 py-[2.8rem]">
        <div className="w-[4rem] h-[4rem]">
          <img
            src={require("../../../assets/images/profile.png")}
            alt="ProfileImage"
          />
        </div>
        <div className="flex-1 h-auto">
          <div className="w-full h-auto flex justify-between items-center">
            <div className="w-auto">
              <h4 className="text-[2.2rem] font-inter font-medium text-io-black dark:text-io-white inline-block">
                {data.title}
              </h4>
              <span className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 inline-block ml-4">
                {data.recievedTime}
              </span>
            </div>
            <div className="w-auto">
              <div className="w-[1.1rem] h-[1.1rem] rounded-full bg-io-green"></div>
            </div>
          </div>
          <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
            {data.description}
          </p>
        </div>
      </div>
    </div>
  );
}
export default NotificationCard;
