import { Fragment, useEffect, useRef, useState } from "react";
import { InputField } from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import StepsHeader from "../../steps-header";
import KeywordCreationChip from "../../../UI/keyword-creation-chip";
import EnterTagsInput from "../../../form/enter-tags-input";
import { useTranslation } from "react-i18next";

interface CustomMagicStepOneProps {
  setCurrentStep: (step: number) => void;
  title: string;
}

function CustomMagicStepTwo({
  setCurrentStep,
  title,
}: CustomMagicStepOneProps) {
  const { t } = useTranslation();
  const [dynamicHeight, setDynamicHeight] = useState(0);
  const elementRef = useRef<any>(null);

  useEffect(() => {
    if (elementRef.current) {
      const height = elementRef.current.clientHeight - 20;
      setDynamicHeight(height);
    }
  }, [title]);

  const labelCreation = [
    { labelName: "Pass", isActive: true },
    { labelName: "Fail", isActive: false },
    { labelName: "Spell Check Vehicle Spectra", isActive: false },
  ];

  return (
    <div className="w-full h-full flex flex-col">
      <StepsHeader title={title} />
      <div
        className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large"
        ref={elementRef}
      >
        <div className="w-full relative">
          <div className="w-full h-full p-4">
            <div className="w-full h-full flex">
              <div className="w-[21.26%] h-full sticky top-4 max-md:w-[35%]">
                <div
                  className="w-full bg-io-gray-f8 dark:bg-io-black-1c pt-3 flex flex-col"
                  style={{ height: dynamicHeight }}
                >
                  <p className="text-[1.4rem] font-inter font-normal text-io-gray-33 dark:text-io-white dark:text-opacity-50 pl-6 mb-4">
                    {t("web.magix.label.outputcreation")} (3)
                  </p>
                  <div className="w-full flex-auto basis-0 pl-2 overflow-y-auto">
                    {labelCreation.map((label, i) => (
                      <Fragment key={i}>
                        <KeywordCreationChip
                          labelName={label.labelName}
                          isActive={label.isActive}
                        />
                      </Fragment>
                    ))}
                  </div>
                  <div className="w-full h-auto px-2 ">
                    <div className="w-full h-auto py-7 text-center border-t border-io-gray-c8 dark:border-io-black-48">
                      <button
                        type="button"
                        className="text-[1.4rem] font-inter font-medium text-io-primary"
                      >
                        {t("web.magix.button.addnewlabel")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[78.74%] h-auto ml-auto max-md:w-[65%]">
                <div className="w-full h-auto p-[4.5rem]">
                  <div className="w-[60%] h-auto max-md:w-full">
                    <div className="mb-[3.8rem]">
                      <InputField
                        labelName={t("web.magix.label.outputname")}
                        type="text"
                        id="LabelName"
                      />
                    </div>
                    <div className="mb-[3.8rem]">
                      <EnterTagsInput
                        labelName={t("web.magix.label.synonym")}
                        idName="synonym"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="steps"
        goToPrevious={() => setCurrentStep(1)}
        goToNext={() => setCurrentStep(3)}
      />
    </div>
  );
}
export default CustomMagicStepTwo;
