import React, { useState, useMemo, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import Select, { components, ActionMeta } from "react-select";
interface Option {
  value: string;
  label: string;
}

interface OptionType {
  label: string;
  value: string;
}

interface Props {
  options: OptionType[];
  //   getSelectValues: (selectedOptions: OptionType[]) => void;
  defaultValue?: OptionType[];
  optionsType?: string;
  labelName: string;
  IdName: string;
  isRequired: boolean;
}

interface SelectInputProps {
  labelName: string;
  idName: string;
  isRequired?: boolean;
  options?: any[];
  error: any;
  name: string;
  control: any;
  getOptionLabel?: any;
  getOptionValue?: any;
  isReset?: any;
  isDisabled?: boolean;
  isSearchable?: boolean;
  defaultValue?: any;
  key?: any;
}

interface SelectInputWithoutFormProps {
  labelName: string;
  id: string;
  isRequired: boolean;
  isSearchable?: boolean;
  options: any;
  getOptionLabel: any;
  getOptionValue: any;
  getSelection: (data: any) => void;
  defaultValue?: any;
  error?: any;
}

interface MultiSelectInputProps {
  labelName: string;
  idName: string;
  error: any;
  control: any;
  name: string;
  options: any;
  getOptionLabel?: any;
  getOptionValue?: any;
  isMulti: boolean;
  isDisabled?: boolean;
  isOptionDisabled?: any;
  isReset?: boolean;
  key?: any;
  isRequired?: boolean;
  isSearchable?: boolean;
}
interface MultiSelectInputWithoutFormProps {
  labelName: string;
  idName: string;
  error?: any;
  options: any;
  getOptionLabel?: any;
  getOptionValue?: any;
  isDisabled?: boolean;
  isOptionDisabled?: any;
  isReset?: boolean;
  isRequired?: boolean;
  defaultVal?: any;
  value?: any;
  onChange: (value: any) => void;
}

const SelectInput = ({
  labelName,
  idName,
  error,
  name,
  control,
  options,
  getOptionLabel,
  getOptionValue,
  isReset,
  isDisabled,
  defaultValue,
  isSearchable = false,
  isRequired,
  key,
}: SelectInputProps) => {
  // const [selectedValue, setSelectedValue] = useState("");
  // const [isFocused, setIsFocused] = useState(false);

  // const handleFocus = () => {
  //   setIsFocused(true);
  // };

  // const handleBlur = () => {
  //   setIsFocused(false);
  // };

  // const handleInputChange = (selectedOption: Option | null) => {
  //   if (selectedOption) {
  //     setIsFocused(true);
  //   } else {
  //     setIsFocused(false);
  //   }
  //   setSelectedValue(selectedOption ? selectedOption.value : "");
  // };
  const selectRef = useRef<any>(null);
  useEffect(() => {
    if (isReset) {
      selectRef?.current?.clearValue();
    }
  }, [isReset]);

  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }: any) => {
    return (
      <ValueContainer
        {...props}
        className=" !p-0 text-gray-400 dark:text-gray-300"
      >
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
          {isRequired && <span className="text-red-500">*</span>}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const customStyles = {
    input: (baseStyles: any) => ({
      ...baseStyles,
      marginTop: "-0.2rem",
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      textOverflow: "ellipsis",
      maxWidth: "100%",
      whiteSpace: "nowrap",
      display: "flex",
      fontSize: "1.2rem",
      fontWeight: "500",
      overflow: "visible",
      boxShadow: "none",
      height: "5rem",
      // marginBottom: "2rem",
      "&:focus": {
        borderBottom: "2px solid red",
      },
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      position: "absolute",
      top:
        state.hasValue || state.isFocused || state.selectProps.inputValue
          ? -8
          : "30%",
      backgroundColor:
        state.hasValue || state.selectProps.inputValue
          ? "white"
          : "transparent",
      transition: "top 0.4s, ",
    }),
  };
  return (
    <div className="w-full h-auto relative select-input">
      {/* <label
        className={`transform absolute  bg-io-white dark:bg-io-black-15 block px-2 origin-[0] left-[1rem] text-[1.2rem] text-io-gray-33 dark:text-io-black-72 duration-300 z-10 ${isFocused || selectedValue ? "top-[-20%] translate-y-[0] left-[1rem]" : "top-[50%] translate-y-[-50%]"
          }`}
      >
        {labelName}{isRequired && <span className="text-red-500">*</span>}
      </label> */}

      <Controller
        name={name}
        render={({ field }) => (
          <Select
            {...field}
            options={options}
            placeholder={labelName}
            classNamePrefix="cus-select"
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            menuPosition={"fixed"}
            ref={selectRef}
            isDisabled={isDisabled}
            defaultValue={defaultValue}
            styles={customStyles}
            key={key}
            isSearchable={isSearchable}
            components={{
              ValueContainer: CustomValueContainer,
            }}
            // onFocus={handleFocus}
            // onBlur={handleBlur}
            // onChange={handleInputChange}
          />
        )}
        control={control}
      />
      {error && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">
          {error}
        </p>
      )}
    </div>
  );
};

const SelectInputWithoutForm = ({
  labelName,
  id,
  isRequired,
  isSearchable,
  options,
  getOptionLabel,
  getOptionValue,
  defaultValue,
  getSelection,
  error,
}: SelectInputWithoutFormProps) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleInputChange = (selectedOption: any) => {
    if (selectedOption) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
    setSelectedValue(selectedOption ? selectedOption : "");
  };

  useEffect(() => {
    if (selectedValue) {
      getSelection(selectedValue);
    }
  }, [selectedValue]);

  return (
    <div className="w-full h-auto relative">
      <label
        className={`transform select-none pointer-events-none absolute  bg-io-white dark:bg-io-black-15 block px-2 origin-[0] left-[1rem] text-[1.2rem] text-io-gray-33 dark:text-io-black-72 duration-300 z-10 ${
          isFocused || selectedValue
            ? "top-[-20%] translate-y-[0] left-[1rem]"
            : "top-[50%] translate-y-[-50%]"
        }`}
      >
        {labelName}
        {isRequired && <span className="text-red-500">*</span>}
      </label>
      <Select
        options={options}
        placeholder=""
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleInputChange}
        classNamePrefix="cus-select"
        menuPosition={"fixed"}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        getOptionLabel={(option) => option?.label || ""}
        getOptionValue={(option) => option?.value || ""}
      />
      {error && (
        <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">
          {error}
        </p>
      )}
    </div>
  );
};

const MultiCheckedSelect: React.FC<Props> = ({
  options,
  //   getSelectValues,
  defaultValue,
  optionsType,
  labelName,
  IdName,
  isRequired,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const dynamicOption = useMemo<OptionType[]>(() => {
    if (optionsType) {
      if (optionsType === "coursesOption") {
        const filterData = [
          {
            value: "value 1",
            label: "value 1",
          },
        ];
        return filterData;
      }
    }
    return options;
  }, [optionsType, options]);

  const InputOption: React.FC<any> = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);

    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#0078D4";

    const style: React.CSSProperties = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };

    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <div className="flex gap-3 text-inherit ">
          <input
            type="checkbox"
            checked={isSelected}
            onChange={() => console.log("")}
          />
          {children}
        </div>
      </components.Option>
    );
  };

  const MoreSelectedBadge = ({ items }: any) => {
    const style = {
      display: "inline-block",
      color: "var(--io-primary)",
      fontSize: "1.4rem",
    };
    const title = items.join(", ");
    const length = items.length;
    const label = `+${length}`;
    return (
      <div style={style} title={title}>
        {label}
      </div>
    );
  };

  const MultiValue = ({ index, getValue, ...props }: any) => {
    const maxToShow = 2;
    const overflow = getValue()
      .slice(maxToShow)
      .map((x: any) => x.label);
    return index < maxToShow ? (
      <components.MultiValue className="dark:*:text-white" {...props} />
    ) : index === maxToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null;
  };

  const selectedVals = selectedOptions.map((x) => x.value);
  const hiddenOptions = selectedVals.length > 3 ? selectedVals.slice(0, 3) : [];
  const DataOptions = dynamicOption.filter(
    (x) => !hiddenOptions.includes(x.value)
  );

  useEffect(() => {}, [selectedOptions]);

  useEffect(() => {
    if (dynamicOption) {
      if (optionsType === "coursesOption") {
        setSelectedOptions(dynamicOption.slice(0, 4));
      }
    }
  }, [dynamicOption, optionsType]);

  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(false);
  };

  return (
    <div className="w-full h-auto relative">
      <label
        className={`transform absolute  bg-io-white dark:bg-io-black-15 block px-2 origin-[0] left-[1rem] text-[1.2rem] text-io-gray-33 dark:text-io-black-d1 duration-300 z-10 ${
          isInputFocused || selectedOptions.length > 0
            ? "top-[-25%] translate-y-[0] left-[1rem]"
            : "top-[50%] translate-y-[-50%]"
        }`}
      >
        {labelName}
        {isRequired && <span className="text-red-500">*</span>}
      </label>
      <Select
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={(options) => {
          if (Array.isArray(options)) {
            setSelectedOptions(options.map((opt) => opt));
          }
        }}
        onFocus={handleFocus}
        classNamePrefix="cus-select"
        onBlur={handleBlur}
        options={dynamicOption}
        defaultValue={optionsType ? dynamicOption.slice(0, 4) : defaultValue}
        placeholder=""
        menuPosition={"fixed"}
        components={{
          Option: InputOption,
          MultiValue: MultiValue,
        }}
      />
    </div>
  );
};

const MultiSelectInput = ({
  labelName,
  idName,
  error,
  name,
  control,
  options,
  isMulti,
  getOptionLabel,
  getOptionValue,
  isDisabled,
  isOptionDisabled,
  isReset,
  key,
  isRequired,
  isSearchable,
}: // handleMultiValueRemove,
MultiSelectInputProps) => {
  const selectRef = useRef<any>(null);
  useEffect(() => {
    if (isReset) {
      selectRef?.current?.clearValue();
    }
  }, [isReset]);

  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }: any) => {
    return (
      <ValueContainer
        {...props}
        className="w-[50%] !p-0 text-gray-400 dark:text-gray-300"
      >
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
          {isRequired && <span className="text-red-500">*</span>}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const customStyles = {
    input: (baseStyles: any) => ({
      ...baseStyles,
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      marginTop: "-0.2rem",
      position: "absolute",
      top: "15%",
      color: null,
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      textOverflow: "ellipsis",
      maxWidth: "100%",
      whiteSpace: "nowrap",
      display: "flex",
      fontSize: "1.2rem",
      fontWeight: "500",
      overflow: "visible",
      boxShadow: "none",
      // height: "4.8rem",
      // marginBottom: "3rem",
      "&:focus": {
        borderBottom: "2px solid red",
      },
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      // padding: "0 0rem",
      position: "absolute",
      top:
        state.hasValue || state.isFocused || state.selectProps.inputValue
          ? -8
          : "30%",
      backgroundColor:
        state.hasValue || state.selectProps.inputValue
          ? "white"
          : "transparent",
      transition: "top 0.4s, ",
    }),
  };

  // const MultiValueRemove = (props) => {
  //   return (
  //     <components.MultiValueRemove {...props}>
  //       <div
  //         className="pill-closemain"
  //         onClick={() =>
  //           handleMultiValueRemove ? handleMultiValueRemove(props) : null
  //         }
  //       >
  //         <Image src={closeIcon} alt="closeIcon" className="pill__close" />
  //       </div>
  //     </components.MultiValueRemove>
  //   );
  // };
  return (
    <>
      <div className="w-full relative hookform-select">
        {/* {labelName && <label htmlFor={idName}>{labelName}</label>} */}
        <Controller
          name={name}
          render={({ field }) => (
            <Select
              {...field}
              // cacheOptions
              options={options}
              // components={{ DropdownIndicator }}
              placeholder={labelName}
              classNamePrefix="cus-multi-select"
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              isMulti={isMulti}
              id="multipleselect"
              isClearable={false}
              menuPosition={"fixed"}
              isDisabled={isDisabled}
              isOptionDisabled={isOptionDisabled}
              ref={selectRef}
              key={key}
              styles={customStyles}
              components={{
                ValueContainer: CustomValueContainer,
              }}
              isSearchable={isSearchable}
            />
          )}
          control={control}
        />
        {error && (
          <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">
            {error}
          </p>
        )}
      </div>
    </>
  );
};

const MultiSelectInputWithoutForm = ({
  labelName,
  idName,
  error,
  options,
  getOptionLabel,
  getOptionValue,
  isDisabled,
  isOptionDisabled,
  isReset,
  isRequired,
  defaultVal,
  value,
  onChange,
}: MultiSelectInputWithoutFormProps) => {
  const selectRef = useRef<any>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState<any>([]);
  useEffect(() => {
    if (isReset) {
      selectRef?.current?.clearValue();
    }
  }, [isReset]);

  const handleOnChange = (selectedOptions: any) => {
    if (onChange) {
      setInputValue(selectedOptions);
      onChange(selectedOptions);
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    if (inputValue.length === 0) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    if (defaultVal) {
      setIsFocused(true);
    }
  }, [defaultVal]);

  useEffect(() => {
    if (value && value?.length > 0) {
      setIsFocused(true);
    }
  }, [value]);

  return (
    <>
      <div className="w-full h-auto relative">
        <label
          className={`transform select-none pointer-events-none absolute  bg-io-white dark:bg-io-black-15 block px-2 origin-[0] left-[1rem] text-[1.2rem] text-io-gray-33 dark:text-io-black-d1 duration-300 z-10 ${
            isFocused
              ? "top-[-16%] translate-y-[0] left-[1rem]"
              : "top-[50%] translate-y-[-50%]"
          }`}
        >
          {labelName}
          {isRequired && <span className="text-red-500">*</span>}
        </label>
        <Select
          options={options}
          closeMenuOnSelect={false}
          isSearchable={false}
          placeholder=""
          classNamePrefix="cus-multi-select"
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          isMulti={true}
          id="multipleselect"
          isClearable={false}
          menuPosition={"fixed"}
          isDisabled={isDisabled}
          isOptionDisabled={isOptionDisabled}
          ref={selectRef}
          // defaultValue={inputValue}
          value={value}
          onChange={handleOnChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />

        {error && (
          <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">
            {error}
          </p>
        )}
      </div>
    </>
  );
};

export {
  SelectInput,
  SelectInputWithoutForm,
  MultiCheckedSelect,
  MultiSelectInput,
  MultiSelectInputWithoutForm,
};
