import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import IconTextBtn from "../../form/icon-text-btn";
import { SecondaryTextBtn } from "../../form/text-btn";
import { DeleteIcon, MakeAnCopyIcon } from "../../svg";
import IconBtn from "../../form/icon-btn";
import uploadFile from "../../../services/uploadFile";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

function UploadTrainingDataSet({
  isViewOnly,
  data,
  setData,
  activeTabUniqueId,
}: any) {
  const { t } = useTranslation();
  const generateGuid = () => uuidv4();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const uploadGalleryFiles = useRef<HTMLInputElement>(null);

  const currentLabel = data.labels.find(
    (label: { uniqueId: any }) => label.uniqueId === activeTabUniqueId
  );

  const handleFileInputChange = async () => {
    setIsLoading(true);
    if (
      uploadGalleryFiles.current &&
      uploadGalleryFiles.current.files &&
      uploadGalleryFiles.current.files.length > 0
    ) {
      const files = uploadGalleryFiles.current.files;
      const newImages = [...currentLabel.uploadDetails];
      const uploadPromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = file.name;
        const fileType = fileName.split(".").pop();
        const extension = fileType ? fileType.toLowerCase() : "";
        if (!newImages.some((image) => image.name === file.name)) {
          uploadPromises.push(
            uploadFile(file, extension).then(({ response, fileUrl }) => {
              if (response.success) {
                newImages.push({
                  uploadId: 0,
                  uniqueId: generateGuid(),
                  name: file.name,
                  selected: false,
                  uploadUrl: response.data.fileUrl,
                });
              }
            })
          );
        }
      }
      try {
        await Promise.all(uploadPromises);
        handleAllImageData(newImages);
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    }
  };

  const handleAllImageData = (newImages: any[]) => {
    const updatedData = {
      ...data,
      labels: data.labels.map((label: any) => {
        if (label.uniqueId === activeTabUniqueId) {
          return {
            ...label,
            uploadDetails: newImages,
          };
        }
        return label;
      }),
    };
    setData(updatedData);
  };

  const handleDeleteImage = (index: number) => {
    const updatedImages = [...currentLabel.uploadDetails];
    updatedImages.splice(index, 1);
    handleAllImageData(updatedImages);
  };

  const handleSelectAllChange = () => {
    const allSelected = !selectAllChecked;
    const updatedImages = currentLabel.uploadDetails.map((image: any) => ({
      ...image,
      selected: allSelected,
    }));
    setSelectAllChecked(allSelected);
    handleAllImageData(updatedImages);
  };

  const handleImageSelection = (index: number) => {
    const updatedImages = [...currentLabel.uploadDetails];
    updatedImages[index].selected = !updatedImages[index].selected;

    const allSelected = updatedImages.every((image) => image.selected);
    setSelectAllChecked(allSelected);
    handleAllImageData(updatedImages);
  };

  const deleteSelectedFiles = () => {
    const updatedImages = currentLabel.uploadDetails.filter(
      (image: { selected: any }) => !image.selected
    );
    setSelectAllChecked(false);
    handleAllImageData(updatedImages);
  };

  useEffect(() => {
    setIsLoading(false);
    if (currentLabel.uploadDetails.length > 0) {
      const allSelected = currentLabel.uploadDetails.every(
        (image: { selected: any }) => image.selected
      );
      setSelectAllChecked(allSelected);
    }
  }, [currentLabel.uploadDetails]);

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto ">
        <div className="w-full h-auto">
          <div className="w-full h-auto flex justify-between items-center mb-8">
            <div className="w-auto">
              <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 inline-block dark:text-io-white">
                {t("web.profile.label.common.gallery")}
              </p>
              {!isViewOnly && (
                <span className="text-[1rem] font-inter font-normal text-io-gray-66 ml-2 dark:text-io-white dark:text-opacity-50">
                  (
                  {currentLabel?.uploadDetails?.length > 0
                    ? currentLabel?.uploadDetails?.length
                    : "0"}{" "}
                  {t("web.vision.label.picsfound")})
                </span>
              )}
            </div>
            {!isViewOnly && (
              <div className="w-auto">
                <input
                  type="file"
                  id="uploadGallery"
                  multiple={true}
                  onChange={handleFileInputChange}
                  ref={uploadGalleryFiles}
                  className="hidden w-0 h-0"
                  accept="image/*"
                />
                <label htmlFor="uploadGallery" className="w-auto h-auto block">
                  <div className="w-auto h-auto pointer-events-none">
                    <SecondaryTextBtn
                      label={t("web.common.addimage")}
                      btnType="button"
                    />
                  </div>
                </label>
              </div>
            )}
          </div>
          {!isViewOnly && (
            <div className="w-full h-auto flex justify-between items-center mb-8">
              <div className="w-auto flex items-center">
                <input
                  type="checkbox"
                  id="selectAllItems"
                  name="selectAllItems"
                  checked={selectAllChecked}
                  onChange={handleSelectAllChange}
                  disabled={
                    currentLabel?.uploadDetails?.length > 0 ? false : true
                  }
                />
                <label
                  htmlFor="selectAllItems"
                  className="text-[1.4rem] font-inter font-normal text-io-gray-66 inline-block ml-2 dark:text-io-white dark:text-opacity-50"
                >
                  {t("web.profile.label.common.selectall")}
                </label>
              </div>
              <div className="w-auto flex items-center gap-8">
                <div className="w-auto"></div>
                <div className="w-auto">
                  <IconTextBtn
                    label={t("web.label.common.delete")}
                    btnSize="small"
                    icon={<DeleteIcon />}
                    handleClick={() => deleteSelectedFiles()}
                    disabled={selectAllChecked === false ? true : false}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="w-full h-auto">
            <div className="w-full h-auto flex justify-start items-center flex-wrap gap-x-[1.6rem] gap-y-[1.6rem]">
              {currentLabel.uploadDetails.map((image: any, index: any) => (
                <div
                  key={index}
                  className="w-[14.2rem] h-auto p-2 bg-io-gray-f8 rounded-[5.6rem]"
                >
                  <div className="w-full h-auto mb-2 relative">
                    <div className="w-full h-[10rem]">
                      <img className="object-contain object-center" src={image?.uploadUrl} alt={`Uploaded`} />
                    </div>
                    <div className="w-auto h-auto absolute top-[2%] right-[2%]">
                                            <input className="accent-io-primary rounded-full" type="checkbox" name={image.name} id={image.name} checked={image.selected} onChange={() => handleImageSelection(index)} />
                                        </div>
                  </div>
                  <div className="w-full h-auto flex justify-between items-center">
                    <p className="w-full text-[1.1rem] line-clamp-1 font-inter font-medium text-io-black dark:text-io-white text-ellipsis overflow-hidden">
                      {image.name}
                    </p>
                    {!isViewOnly && (
                      <IconBtn
                        size="w-[1rem]"
                        icon={<DeleteIcon />}
                        handleClick={() => handleDeleteImage(index)}
                      />
                    )}
                  </div>
                </div>
              ))}
              {isLoading && (
                <Skeleton width={140} height={100} borderRadius={5} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UploadTrainingDataSet;
