import { InputField, TextAreaField } from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

interface CreateScreenFormProps {
  handleClose: () => void;
  handleCreateNewScreen: (data: any) => void;
}
const createScreenFormSchema = yup.object().shape({
  screenName: yup.string().required("Please enter the Screen Name"),
  screenDescription: yup.string(),
});

function CreateScreenForm({
  handleClose,
  handleCreateNewScreen,
}: CreateScreenFormProps) {
  const { t } = useTranslation();
  const generateGuid = () => {
    return uuidv4();
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({ resolver: yupResolver(createScreenFormSchema) });

  const handleFormSubmit = (data: any) => {
    handleCreateNewScreen(data);
  };

  const handleFormCancel = () => {
    handleClose();
  };

  return (
    <form
      className="w-full h-full flex flex-col"
      onSubmit={handleSubmit((data) => handleFormSubmit(data))}
    >
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[93%] h-auto">
          <div className="w-full h-auto flex justify-between items-start pt-4">
            <div className="w-[60%] ">
              <div className="w-full h-auto">
                <div className="mb-[3.8rem]">
                  <InputField
                    labelName={t("web.logic.label.editscreen")}
                    type="text"
                    id="screenName"
                    isRequired={true}
                    formHandle={register("screenName")}
                    error={errors?.screenName?.message}
                  />
                </div>
                <div className="mb-[3.8rem]">
                  <TextAreaField
                    labelName={t("web.logic.label.screendescription")}
                    id="screenDescription"
                    isRequired={true}
                    formHandle={register("screenDescription")}
                  />
                </div>
                {/* <div className="w-full h-auto">
                  <RadioBtns
                    options={editOptions}
                    name="editScreen"
                    formHandle={register("privateOrPublic")}
                    error={errors?.privateOrPublic?.message}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={() => handleFormCancel()}
      />
    </form>
  );
}
export default CreateScreenForm;
