import "./App.css";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Layout from "./pages/layout";
import NotFound from "./pages/not-found";
import Vision from "./pages/ai-magic-studio/magics/vision";
import VisionCreate from "./pages/ai-magic-studio/magics/vision-create";
import Document from "./pages/ai-magic-studio/magics/document";
import Dashboard from "./pages/dashboard";
import SiteManagement from "./pages/site-management";
import UserManagement from "./pages/user-management/user-management";
import AIMagicStudio from "./pages/ai-magic-studio/magics/ai-magic-studio";
import AILogicStudio from "./pages/ai-logic-studio/ai-logic-studio";
import PlanAndBilling from "./pages/plan-and-billing";
import Speech from "./pages/ai-magic-studio/magics/speech";
import Text from "./pages/ai-magic-studio/magics/text";
import DocumentCreate from "./pages/ai-magic-studio/magics/document-create";
import Login from "./pages/login";
import CreateNewUser from "./pages/user-management/create-new-user";
import AIMagixContainer from "./pages/ai-magic-studio/ai-magix-container/ai-magix-container";
import CreateContainerWrapper from "./pages/ai-magic-studio/ai-magix-container/create-container-wrapper";
import ContainerWrapper from "./pages/ai-magic-studio/ai-magix-container/container-wrapper";
import CreateProcessWrapper from "./pages/ai-logic-studio/create-process-wrapper";
import ProcessWrapper from "./pages/ai-logic-studio/process-wrapper";
import ErrorPopup from "./components/UI/error-popup";
import { useState } from "react";
import PageLoader from "./components/UI/page-loader";
import Process from "./pages/process/process";
import MasterDetails from "./pages/master-details/master-details";
import UserMaster from "./pages/user-master/user-master";
import AISubLogicStudioWrapper from "./pages/ai-logic-studio/ai-sub-logic-studio-wrapper";
import MasterDetailsDetail from "./pages/master-details/master-details-detail";
import "react-loading-skeleton/dist/skeleton.css";
import MappingWrapper from "./pages/ai-logic-studio/mapping-wrapper";
import NewSurveyWrapper from "./pages/ai-logic-studio/new-survey-wrapper";
import NewGetStartedWrapper from "./pages/ai-logic-studio/new-get-started-wrapper";
import NewSubProcessWrapper from "./pages/ai-logic-studio/new-sub-process-wrapper";
import CreateMasterDetailsWrapper from "./pages/master-details/create-master-details-wrapper";
import CreateUserMasterWrapper from "./pages/user-master/create-user-master-wrapper";
import CreateMasterDetailsDetailWrapper from "./pages/master-details/create-master-details-detail-wrapper";
import { SkeletonTheme } from "react-loading-skeleton";
import React from "react";
import ThemeHandler from "./lib/theme-handler";

function App() {
  const [isServerError, setIsServerError] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isConfetti, setIsConfetti] = useState<boolean>(false);
  const [logicUpdated, setLogicUpdated] = useState<any>(undefined);
  const [surveyUpdated, setSurveyUpdated] = useState<any>(undefined);
  const [userUpdated, setUserUpdated] = useState<any>(undefined);
  const [headerDetails, setHeaderDetails] = useState<any>(undefined);
  const [visionUpdated, setVisionUpdated] = useState<any>(undefined);
  const [containerUpdated, setContainerUpdated] = useState<any>(undefined);
  const [subProcessUpdated, setSubProcessUpdated] = useState<any>(undefined);
  const [getStartedUpdated, setGetStartedUpdated] = useState<any>(undefined);
  const [masterDetailsUpdated, setMasterDetailsUpdated] =
    useState<any>(undefined);
  const [userMasterUpdated, setUserMasterUpdated] = useState<any>(undefined);
  const [userColumnUpdated, setUserColumnUpdated] = useState<any>(undefined);
  const [isThemeToggled, setIsThemeToggled] = useState<any>(false);

  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
      errorElement: <NotFound />,
    },
    {
      path: "",
      element: (
        <Layout
          confettiVal={isConfetti}
          themeToggled={() => setIsThemeToggled((pre: any) => !pre)}
        >
          <ThemeHandler
            setIsDarkMode={setIsDarkMode}
            isThemeToggled={isThemeToggled}
          />
        </Layout>
      ),
      errorElement: <NotFound />,
      children: [
        {
          index: true,
          element: <Navigate to={"/login"} />,
        },
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "site-management",
          element: <SiteManagement />,
        },
        {
          path: "user-management",
          element: (
            <UserManagement
              userUpdated={userUpdated}
              setUserUpdated={(val: any) => setUserUpdated(val)}
            />
          ),
          children: [
            {
              path: "user/:id",
              element: (
                <CreateNewUser
                  navigateLink="/user-management"
                  userUpdated={() => setUserUpdated((pre: any) => !pre)}
                />
              ),
            },
          ],
        },
        {
          path: "master-details",
          element: (
            <MasterDetails
              masterDetailsUpdated={masterDetailsUpdated}
              setMasterDetailsUpdated={(val: any) =>
                setMasterDetailsUpdated(val)
              }
            />
          ),
          children: [
            {
              path: "new-master-details/:id",
              element: (
                <CreateMasterDetailsWrapper
                  masterDetailsUpdated={() =>
                    setMasterDetailsUpdated((pre: any) => !pre)
                  }
                />
              ),
            },
            {
              path: "user-master/:id",
              element: (
                <UserMaster
                  setHeaderDetails={setHeaderDetails}
                  userMasterUpdated={userMasterUpdated}
                  setUserMasterUpdated={(val: any) => setUserMasterUpdated(val)}
                />
              ),
              children: [
                {
                  path: "user-master-new/:id",
                  element: (
                    <CreateUserMasterWrapper
                      headerDetails={headerDetails}
                      userMasterUpdated={() =>
                        setUserMasterUpdated((pre: any) => !pre)
                      }
                    />
                  ),
                },
              ],
            },
            {
              path: "detail/:id",
              element: (
                <MasterDetailsDetail
                  userColumnUpdated={userColumnUpdated}
                  setUserColumnUpdated={(val: any) => setUserColumnUpdated(val)}
                />
              ),
              children: [
                {
                  path: "new-master-details/:id",
                  element: (
                    <CreateMasterDetailsDetailWrapper
                      userColumnUpdated={() =>
                        setUserColumnUpdated((pre: any) => !pre)
                      }
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "user-master/:id",
          element: <UserMaster setHeaderDetails={setHeaderDetails} />,
          children: [
            {
              path: "user-master-new/:id",
              element: (
                <CreateUserMasterWrapper headerDetails={headerDetails} />
              ),
            },
          ],
        },
        {
          path: "ai-magic-studio",
          element: <AIMagicStudio />,
          children: [
            {
              index: true,
              element: <Navigate to={"/ai-magic-studio/vision/:magicType"} />,
            },
            {
              path: "vision/:magicType",
              element: (
                <Vision
                  visionUpdated={visionUpdated}
                  setVisionUpdated={(val: any) => setVisionUpdated(val)}
                />
              ),
              children: [
                {
                  path: "create/:id",
                  element: (
                    <VisionCreate
                      navigateLink="/user-management"
                      visionUpdated={() => setVisionUpdated((pre: any) => !pre)}
                      setIsConfetti={setIsConfetti}
                    />
                  ),
                },
              ],
            },
            {
              path: "document",
              element: <Document />,
              children: [
                {
                  path: "create",
                  element: <DocumentCreate />,
                },
              ],
            },
            {
              path: "speech",
              element: <Speech />,
            },
            {
              path: "text",
              element: <Text />,
            },
          ],
        },
        {
          path: "ai-magix-container",
          element: (
            <AIMagixContainer
              containerUpdated={containerUpdated}
              setContainerUpdated={(val: any) => setContainerUpdated(val)}
            />
          ),
          children: [
            {
              path: "magix-container/:id",
              element: (
                <CreateContainerWrapper
                  navigateLink="/ai-magix-container"
                  containerUpdated={() =>
                    setContainerUpdated((pre: any) => !pre)
                  }
                />
              ),
            },
            {
              path: "container/:id",
              element: <ContainerWrapper />,
            },
          ],
        },
        {
          path: "ai-logic-studio",
          element: (
            <AILogicStudio
              logicUpdated={logicUpdated}
              setLogicUpdated={(val: any) => setLogicUpdated(val)}
            />
          ),
          children: [
            {
              path: "new-process-logic/:id",
              element: (
                <CreateProcessWrapper
                  navigateLink="/ai-logic-studio"
                  logicUpdated={() => setLogicUpdated((pre: any) => !pre)}
                />
              ),
            },
            {
              path: "process-logic/:logicId",
              element: (
                <AISubLogicStudioWrapper
                  surveyUpdated={surveyUpdated}
                  getStartedUpdated={getStartedUpdated}
                  subProcessUpdated={subProcessUpdated}
                  setSurveyUpdated={(val: any) => setSurveyUpdated(val)}
                  setSubProcessUpdated={(val: any) => setSubProcessUpdated(val)}
                  setGetStartedUpdated={(val: any) => setGetStartedUpdated(val)}
                />
              ),
              children: [
                {
                  path: "survey/:id",
                  element: <ProcessWrapper />,
                },
                {
                  path: "new-survey/:surveyId",
                  element: (
                    <NewSurveyWrapper
                      navigateLink="/ai-logic-studio"
                      surveyUpdated={(val: any) => setSurveyUpdated(val)}
                    />
                  ),
                },
                {
                  path: "get-started/:id",
                  element: <ProcessWrapper />,
                },
                {
                  path: "new-get-started/:id",
                  element: (
                    <NewGetStartedWrapper
                      navigateLink="/ai-logic-studio"
                      getStartedUpdated={(val: any) =>
                        setGetStartedUpdated(val)
                      }
                    />
                  ),
                },
                {
                  path: "mapping/:id",
                  element: <MappingWrapper />,
                },
                {
                  path: "new-sub-process/:id",
                  element: (
                    <NewSubProcessWrapper
                      navigateLink="/ai-logic-studio"
                      subProcessUpdated={(val: any) =>
                        setSubProcessUpdated(val)
                      }
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "process",
          element: <Process />,
        },
        {
          path: "plan-and-billing",
          element: <PlanAndBilling />,
        },
      ],
    },
  ]);
  return (
    <>
      <SkeletonTheme
        baseColor={isDarkMode === true ? "#232432" : "#ebebeb"}
        highlightColor={isDarkMode === true ? "#484952" : "#f5f5f5"}
      >
        <RouterProvider router={router}></RouterProvider>
      </SkeletonTheme>
      {isServerError && (
        <ErrorPopup handleClose={() => setIsServerError(false)} />
      )}
      {pageLoader && <PageLoader />}
    </>
  );
}

export default App;
