import ModalPageWrapper from "../../../global-layout/modal-page-wrapper";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import EditScreenForm from "../edit-screen-form";
import { useTranslation } from "react-i18next";

interface EditScreenWrapperProps {
  handleClose: () => void;
  editScreenValue: any;
  handleEditScreen: (data: any) => void;
}

function EditScreenWrapper({
  handleClose,
  editScreenValue,
  handleEditScreen,
}: EditScreenWrapperProps) {
  const { t } = useTranslation();
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white ml-auto max-md:w-[85%]">
        <div className="w-full h-full">
          <div className="w-full h-full flex flex-col">
            <ModalPageHeader
              title={t("web.logic.label.editnewscreen")}
              handleClose={() => handleClose()}
            />
            <div className="w-full h-full flex-auto">
              <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
                <EditScreenForm
                  handleClose={() => handleClose()}
                  editScreenValue={editScreenValue}
                  handleEditScreen={handleEditScreen}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default EditScreenWrapper;
