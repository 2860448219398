import { CurrentStatusIcon, DeleteIcon, RequiredErrorIcon } from "../../svg";

interface KeywordCreationChipProps {
  labelName: string;
  isActive: boolean;
  isCheckbox?: boolean;
  currentStatus?: boolean;
  onClick?: () => void; // Add onClick prop
  hasDeleteOption?: boolean;
  chipIndex?: string;
  handleDelete?: (index: any) => void;
  hasErrorOption?: boolean;
  errorIcon?: boolean;
}

function KeywordCreationChip({
  labelName,
  isActive,
  isCheckbox,
  currentStatus,
  onClick,
  hasDeleteOption = true,
  chipIndex,
  handleDelete,
  hasErrorOption = false,
  errorIcon = false,
}: KeywordCreationChipProps) {
  console.log("hasErrorOption", hasErrorOption);
  console.log("errorIcon", errorIcon);
  const handleClick = () => {
    if (onClick) {
      onClick(); // Call the onClick function if it exists
    }
  };

  return (
    <div
      className={`w-full h-auto pl-[1rem] py-[1.3rem] relative cursor-pointer ${
        isActive
          ? "bg-[#ECF4FB] dark:bg-[#2D3445] chip-arrow"
          : "bg-[#fbfbfb] dark:bg-io-black-23"
      } my-2`}
      onClick={handleClick}
    >
      <div className="w-full h-auto flex justify-between items-center">
        <div className="w-auto flex justify-start items-center gap-4">
          {isCheckbox && (
            <input type="checkbox" name={labelName} id={labelName} />
          )}
          <p className="text-[1.6rem] font-inter font-medium text-io-gray-33 dark:text-io-white cursor-pointer">
            {labelName}
          </p>
          {currentStatus && (
            <div className="w-[1.6rem] h-auto">
              <CurrentStatusIcon />
            </div>
          )}
        </div>
        {hasDeleteOption && !isCheckbox && isActive && (
          <button
            type="button"
            className="w-[1.4rem] h-auto mr-4"
            onClick={() => handleDelete?.(chipIndex)}
          >
            <DeleteIcon />
          </button>
        )}
        {hasErrorOption && errorIcon && (
          <div className="w-[2.2rem] h-auto">
            <RequiredErrorIcon />
          </div>
        )}
      </div>
    </div>
  );
}
export default KeywordCreationChip;
