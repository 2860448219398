import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  InputFieldWithoutForm,
  TextAreaField,
} from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import {
  containerCreate,
  containerEdit,
} from "../../../../services/authFunctions";

function CreateContainerForm({ containerUpdated }: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState("insert");
  const [containerData, setContainerData] = useState({
    name: "",
    description: "",
    containerId: 0,
  });
  useEffect(() => {
    if (id !== "new") {
      setMode("update");
      handleContainerEdit(id);
    }
  }, [id]);

  const [errors, setErrors] = useState({
    name: "",
  });

  const handleInputChange = (name: string, value: any) => {
    setContainerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleFormValidation = () => {
    const { name } = containerData;
    const isName = !name.trim();
    setErrors({
      name: isName ? t("web.error.title.namereq") : "",
    });
    return !isName;
  };

  const handleFormSubmit = async () => {
    if (handleFormValidation()) {
      setIsLoading(true);
      const response = await containerCreate(containerData, mode, id);
      if (response.success) {
        setTimeout(() => {
          navigate("/ai-magix-container");
          containerUpdated(true);
          setIsLoading(false);
        }, 1000);
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleContainerEdit = async (id: any) => {
    setIsLoading(true);
    const response = await containerEdit(id);
    if (response.success) {
      setContainerData(response.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleFormCancel = () => {
    navigate("/ai-magix-container");
  };
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[62%] h-auto pt-[1rem]">
          <div className="mb-[3.8rem]">
            {isLoading ? (
              <Skeleton width="100%" height={47} borderRadius={3} />
            ) : (
              <InputFieldWithoutForm
                labelName={t("web.container.label.name")}
                type="text"
                id="containerName"
                value={containerData.name}
                error={errors.name}
                isRequired={true}
                onChange={(e) => handleInputChange("name", e)}
              />
            )}
          </div>
          <div className="mb-[3.8rem]">
            {isLoading ? (
              <Skeleton width="100%" height={47} borderRadius={3} />
            ) : (
              <TextAreaField
                labelName={t("web.container.label.desc")}
                id="Containerdescription"
                value={containerData.description}
                isRequired={true}
                onChange={(e) => handleInputChange("description", e)}
              />
            )}
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={() => handleFormCancel()}
        handleSubmit={handleFormSubmit}
        isLoading={isLoading}
      />
    </div>
  );
}
export default CreateContainerForm;
