import ModalPageWrapper from "../../components/global-layout/modal-page-wrapper";
import CreateProcess from "../../components/section/ai-logic-studio/create-process";

function CreateProcessWrapper({ navigateLink, logicUpdated }: any) {
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <CreateProcess logicUpdated={logicUpdated} />
      </div>
    </ModalPageWrapper>
  );
}
export default CreateProcessWrapper;
