import { useTranslation } from "react-i18next";
import { ToastErrorIcon, ToastSuccessIcon, ToastWarningIcon } from "../../svg";

interface ToastProps {
  title: string;
  description: string;
}

const ToastSuccess = ({ title, description }: ToastProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-4">
        <div className="w-[5rem] h-auto">
          <ToastSuccessIcon />
        </div>
        <div className="flex-1 ">
          <p className="text-[1.8rem] font-inter font-semibold text-io-white">
            {t(title)}
          </p>
          <p className="text-[1.6rem] font-inter font-normal text-io-white">
            {t(description)}
          </p>
        </div>
      </div>
    </div>
  );
};
const ToastError = ({ title, description }: ToastProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-4">
        <div className="w-[5rem] h-auto">
          <ToastErrorIcon />
        </div>
        <div className="flex-1 ">
          <p className="text-[1.8rem] font-inter font-semibold text-io-white">
            {t(title)}
          </p>
          <p className="text-[1.6rem] font-inter font-normal text-io-white">
            {t(description)}
          </p>
        </div>
      </div>
    </div>
  );
};
const ToastWarning = ({ title, description }: ToastProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-4">
        <div className="w-[5rem] h-auto">
          <ToastWarningIcon />
        </div>
        <div className="flex-1 ">
          <p className="text-[1.8rem] font-inter font-semibold text-io-white">
            {t(title)}
          </p>
          <p className="text-[1.6rem] font-inter font-normal text-io-white">
            {t(description)}
          </p>
        </div>
      </div>
    </div>
  );
};
export { ToastSuccess, ToastError, ToastWarning };
