import IconBtn from "../../form/icon-btn";
import { EditIcon } from "../../svg";
import defaultIconImage from "../../../assets/images/ai-magic-no-image.png";

interface PreviewCreatedLabelProps {
  isEditable: boolean;
  fullScreenOption: boolean;
  data: any;
  activeTabUniqueId: any;
  viewOnFullScreen?: () => void;
}

function PreviewCreatedLabel({
  isEditable,
  fullScreenOption,
  data,
  activeTabUniqueId,
  viewOnFullScreen,
}: PreviewCreatedLabelProps) {
  const handleError = (e: any) => {
    e.target.src = defaultIconImage;
  };
  const currentLabel = data.labels.find(
    (label: { uniqueId: any }) => label.uniqueId === activeTabUniqueId
  );
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto pb-12 flex justify-between gap-8 border-b border-dashed border-io-gray-c8 dark:border-io-black-48 mb-[3rem]">
        <div className="w-[30%] h-auto ">
          <div className="w-full h-auto p-[0.6rem]">
            <div className="w-full h-auto relative">
              <div className="w-full h-auto mb-3">
                <img
                  src={`${
                    currentLabel?.magicUrl
                      ? currentLabel?.magicUrl
                      : require("../../../assets/images/ai-magic-no-image.png")
                  }`}
                  onError={(e) => handleError(e)}
                  alt={currentLabel?.name}
                />
              </div>
              {fullScreenOption && (
                <div
                  className="w-[2rem] h-auto absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] cursor-pointer"
                  onClick={viewOnFullScreen}
                >
                  <img
                    src={require("../../../assets/images/fullscreen.png")}
                    alt="Full Screen Icon"
                  />
                </div>
              )}
            </div>
            {/* <p className="text-[1.4rem] font-inter font-medium text-io-black dark:text-io-white">
              {currentLabel?.name}
            </p> */}
          </div>
        </div>
        <div className="w-[70%] h-auto">
          <div className="w-full h-auto">
            <div className="w-full h-auto">
              <div className="w-full h-auto flex justify-between items-center">
                <h4 className="text-[2rem] font-inter font-normal text-io-gray-33 mb-2 dark:text-io-white">
                  {currentLabel?.name}
                </h4>
                {isEditable && (
                  <IconBtn
                    size="w-[2rem]"
                    icon={<EditIcon />}
                    handleClick={() => console.log("asdasd")}
                  />
                )}
              </div>
              <p className="w-3/4 text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-white dark:text-opacity-50">
                {currentLabel?.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PreviewCreatedLabel;
