import { useTranslation } from "react-i18next";
import IconBtn from "../../form/icon-btn";
import { TextBtn } from "../../form/text-btn";
import ModalPageWrapper from "../../global-layout/modal-page-wrapper";
import { PageModalCloseIcon } from "../../svg";

interface ConfirmSiteSwitchProps {
    handleClose: () => void;
    handleSiteSwitchConfirm: () => void;
   
}
function ConfirmSiteSwitch({handleClose, handleSiteSwitchConfirm}:ConfirmSiteSwitchProps){
    const { t } = useTranslation();
    return(
        <ModalPageWrapper withoutOverlay={false} onClose={handleClose}>
                <div className="w-full h-full flex justify-center items-center">
        <div className="w-[40%] h-auto bg-io-white dark:bg-io-black-1a rounded-[2.2rem] max-md:w-[68%]">
          <div className="w-full h-auto p-12 text-center">
            <div className="w-full h-auto flex justify-end">
              <IconBtn
                size="w-[3rem]"
                icon={<PageModalCloseIcon />}
                handleClick={() => handleClose()}
              />
            </div>
            <div className="w-[12.5rem] h-auto mx-auto mb-[3.5rem] max-md:w-[10rem] max-md:mb-[2rem] max-3xl:mb-[2rem] max-3xl:w-[8rem]">
              {/* <img
                src={require("../../../../assets/images/delete-image.png")}
                alt="DeleteImage"
              /> */}
            </div>
            <div className="w-full h-auto mb-[5%]">
              <h1 className="text-[3.2rem] font-inter font-medium text-io-black dark:text-io-white mb-4 max-3xl:text-[2.6rem]">
              Are you sure you want to switch Site?
              </h1>
              {/* <div className="w-full h-auto relative ">
                <div className="text-[1.8rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                 
                 
                  
                </div>
              </div> */}
            </div>
            <div className="w-full h-auto flex justify-center items-center gap-4 mb-[4%]">
              <TextBtn
                label={t("web.common.button.cancel")}
                btnStyle="fill-secondary"
                btnType="button"
                handleClick={() => handleClose()}
              />
              <TextBtn
                label="Confirm"
                btnStyle="fill-primary"
                btnType="button"
                handleClick={() => handleSiteSwitchConfirm()}
              />
            </div>
          </div>
        </div>
      </div>
        </ModalPageWrapper>
    )
}
export default ConfirmSiteSwitch;