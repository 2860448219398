import { useTranslation } from "react-i18next";
import IconBtn from "../../form/icon-btn";
import { PageModalCloseIcon } from "../../svg";

interface ModalPageHeader {
  title: string;
  handleClose: () => void;
  notificationCount?: number;
}

function ModalPageHeader({
  title,
  handleClose,
  notificationCount,
}: ModalPageHeader) {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-between items-center px-[4.7rem] pt-[2rem] pb-[1.5rem] border-b border-io-gray-c8 dark:border-io-black-48">
        <div className="w-4/5 h-auto">
          <div className="w-full h-auto flex justify-start items-center gap-5">
            <h3 className="text-[2.8rem] font-inter font-medium text-io-black dark:text-io-white">
              {title}
            </h3>
            {notificationCount && (
              <div className="w-auto h-auto bg-[#ECF6E6] text-io-green text-[1.4rem] px-[1rem] py-[0.6rem] leading-none rounded-full">
                {notificationCount} {t("web.global.header.notify")}
              </div>
            )}
          </div>
        </div>
        <div className="w-1/5 h-auto">
          <div className="w-full h-auto flex justify-end items-center">
            <IconBtn
              size="w-[3.5rem]"
              icon={<PageModalCloseIcon />}
              handleClick={() => handleClose()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalPageHeader;
