import { useEffect, useLayoutEffect, useState } from "react";
import { DayIcon, NightIcon } from "../../svg";

function ThemeToggleButton({themeToggled}:any) {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    sessionStorage.setItem('isDarkMode', JSON.stringify(newMode));
    themeToggled()
  };

  useLayoutEffect(() => {
    const storedTheme = sessionStorage.getItem('isDarkMode');
    if (storedTheme !== null) {
      setIsDarkMode(JSON.parse(storedTheme));
    }
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <>
      <label htmlFor="themeToggle" className="w-auto cursor-pointer">
        <input
          type="checkbox"
          id="themeToggle"
          className="sr-only w-0 h-0"
          checked={isDarkMode}
          onChange={toggleTheme}
        />
        <div className="w-[2rem] h-auto">
        </div>
        {isDarkMode? <NightIcon /> : <DayIcon />}
      </label>
    </>
  );
}
export default ThemeToggleButton;
