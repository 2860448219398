import { useState, useEffect } from "react";
import Steps from "../../steps";
import CustomMagicStepOne from "../custom-magic-step-one";
import CustomMagicStepTwo from "../custom-magic-step-two";
import CustomMagicStepThree from "../custom-magic-step-three";
import CustomMagicStepFour from "../custom-magic-step-four";
import { magicServices } from "../../../../services/InspectionOneServices";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { magicCreate } from "../../../../services/authFunctions";
import { useTranslation } from "react-i18next";

function CreateCustomMagicSteps({ visionUpdated, setIsConfetti }: any) {
  const { id, magicType } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [mode, setMode] = useState("insert");
  const [currentStep, setCurrentStep] = useState<number>(1);
  const generateGuid = () => uuidv4();

  const steps = [
    { label: t("web.magix.label.defineyourmagic") },
    { label: t("web.magix.label.defineyourmagicoutput") },
    { label: t("web.magix.label.uploadtrainingdataset") },
    { label: t("web.magix.label.reviewandsubmit") },
  ];

  const stepsBluePrint = [
    { label: t("web.magix.label.defineyourmagic") },
    { label: t("web.magix.label.defineyourmagicpart") },
    { label: t("web.magix.label.reviewandsubmit") },
  ];
  const [data, setData] = useState({
    isDeleted: 0,
    type: magicType,
    isCompleted: 0,
    pillar: 1,
    definition: 2,
    magicId: 0,
    inputType: 0,
    uniqueId: generateGuid(),
    inputSubType: 0,
    name: "",
    description: "",
    tags: [],
    overlay: "",
    thumbnail: "",
    surfaceType: "",
    defectsType: [],
    labels: [],
    outputs: [],
  });
  const [imageEditorUpdatedData, setImageEditorUpdatedData] = useState();

  const handleSubmit = async (isFinalStep: any) => {
    setIsLoading(true);
    setData(data);
    if (isFinalStep) {
      data.isCompleted = 1;
    }
    const response = await magicCreate(data);
    if (response.success) {
      if (isFinalStep) {
        setTimeout(() => {
          navigate(`/ai-magic-studio/vision/${magicType}`);
          visionUpdated(true);
          setIsLoading(false);
          setIsConfetti(true);
        }, 1000);
      } else {
        navigate(`/ai-magic-studio/vision/${magicType}`);
        visionUpdated(true);
        setIsLoading(false);
      }
    }
  };
  const handleMagicEdit = async (magicUnique: any) => {
    try {
      setIsLoading(true);
      const requestJson = {
        uniqueId: magicUnique,
      };
      const response = await magicServices.MagicDetails(requestJson);
      if (response.success) {
        setIsLoading(false);
        const newData = { ...response.data.magicDetails };
        setData(newData);
      } else {
        setIsLoading(false);
        setTimeout(() => {}, 1000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  //#region Alert

  const [isLoading, setIsLoading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);

  //#endregion
  useEffect(() => {
    if (id !== "new") {
      setMode("update");
      handleMagicEdit(id);
    }
  }, [id]);

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <CustomMagicStepOne
            setCurrentStep={(step: any) => setCurrentStep(step)}
            title={steps[0].label}
            data={data}
            setData={setData}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        );
      case 2:
        return (
          <CustomMagicStepTwo
            setCurrentStep={(step: any) => setCurrentStep(step)}
            title={steps[1].label}
            data={data}
            setData={setData}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            setImageEditorUpdatedData={setImageEditorUpdatedData}
            imageEditorUpdatedData={imageEditorUpdatedData}
          />
        );
      case 3:
        return (
          <CustomMagicStepThree
            setCurrentStep={(step: any) => setCurrentStep(step)}
            title={steps[2].label}
            data={data}
            setData={setData}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        );
      case 4:
        return (
          <CustomMagicStepFour
            setCurrentStep={(step: any) => setCurrentStep(step)}
            title={steps[3].label}
            data={data}
            setData={setData}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        );
      default:
        return null;
    }
  };
  const renderStepBluePrintContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <CustomMagicStepOne
            setCurrentStep={(step: any) => setCurrentStep(step)}
            title={stepsBluePrint[0].label}
            data={data}
            setData={setData}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        );
      case 2:
        return (
          <CustomMagicStepTwo
            setCurrentStep={(step: any) => setCurrentStep(step)}
            title={stepsBluePrint[1].label}
            data={data}
            setData={setData}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            setImageEditorUpdatedData={setImageEditorUpdatedData}
            imageEditorUpdatedData={imageEditorUpdatedData}
          />
        );
      case 3:
        return (
          <CustomMagicStepFour
            setCurrentStep={(step: any) => setCurrentStep(step)}
            title={stepsBluePrint[2].label}
            data={data}
            setData={setData}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <Steps
        currentStep={currentStep}
        steps={magicType === "4" ? stepsBluePrint : steps}
      />
      <div className="w-full h-full flex-auto">
        {magicType === "4" ? renderStepBluePrintContent() : renderStepContent()}
      </div>
    </div>
  );
}
export default CreateCustomMagicSteps;
