import ModalPageWrapper from "../../components/global-layout/modal-page-wrapper";
import NewSurvey from "../../components/section/ai-logic-studio/new-survey";

function NewSurveyWrapper({ navigateLink, surveyUpdated }: any) {
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <NewSurvey surveyUpdated={surveyUpdated} />
      </div>
    </ModalPageWrapper>
  );
}
export default NewSurveyWrapper;
