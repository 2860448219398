interface TextBtnProps {
  label: string;
  btnStyle?: string;
  btnType: "submit" | "reset" | "button";
  handleClick?: () => void;
  btnSize?: string;
  isLoading?: boolean;
}
const TextBtn = ({
  label,
  btnStyle,
  btnType,
  handleClick,
  isLoading = false,
  btnSize
}: TextBtnProps) => {
  return (
    <button
      type={btnType}
      className={`${btnSize == "small" ? "w-[8rem] text-[1.1rem] py-[1rem]" : "w-[15.2rem] text-[1.8rem] py-[1rem]"}  font-inter font-medium rounded-[0.3rem]  leading-none uppercase text-center
        ${
          btnStyle === "fill-primary" &&
          "border border-io-primary text-io-white bg-io-primary"
        }
        ${
          btnStyle === "border-primary" &&
          "border border-io-primary text-io-primary bg-transparent"
        }
        ${
          btnStyle === "fill-secondary" &&
          "border border-io-gray-e9 text-io-black bg-io-gray-e9 dark:text-io-white dark:border-[#3F414A] dark:bg-[#3F414A]"
        }
        ${
          btnStyle === "fill-delete" &&
          "border border-io-red text-io-white bg-io-red "
        }
        `}
      onClick={() => (handleClick ? handleClick() : null)}
      disabled={isLoading}
    >
      {isLoading ? (
        <div className="w-full h-auto flex justify-center items-center gap-4">
          {" "}
          <div className="w-[2rem] h-[2rem]">
            <div
              className={` ${
                btnStyle === "border-primary" ? "btn-loader-opp" : "btn-loader"
              }`}
            ></div>
          </div>
          {label}{" "}
        </div>
      ) : (
        label
      )}
    </button>
  );
};
const SecondaryTextBtn = ({
  label,
  btnType,
  handleClick,
  btnSize,
  isLoading = false,
}: TextBtnProps) => {
  return (
    <button
      type={btnType}
      className={`w-auto ${
        btnSize === "small" ? "text-[1.2rem]" : "text-[1.4rem]"
      }  font-inter font-medium rounded-[0.3rem] py-[0.5rem] px-[0.9rem] leading-none text-center border border-io-primary text-io-primary bg-transparent`}
      onClick={() => (handleClick ? handleClick() : null)}
    >
      {isLoading ? (
        <div className="w-full h-auto flex justify-center items-center gap-4">
          {" "}
          <div className="w-[2rem] h-[2rem]">
            <div className="btn-loader"></div>
          </div>
          {label}{" "}
        </div>
      ) : (
        label
      )}
    </button>
  );
};
const LoginBtn = ({
  label,
  btnType,
  handleClick,
  isLoading = false,
}: TextBtnProps) => {
  return (
    <button
      type={btnType}
      className="w-full text-[1.8rem] font-inter font-medium rounded-[0.5rem] py-[1.2rem] px-[0.9rem] leading-none text-center border border-none text-io-white bg-io-primary uppercase"
      onClick={() => (handleClick ? handleClick() : null)}
      disabled={isLoading}
    >
      <div className="w-full h-auto flex justify-center items-center">
        {isLoading ? (
          <div className="w-full h-auto flex justify-center items-center gap-4">
            {" "}
            <div className="w-[2rem] h-[2rem]">
              <div className="btn-loader"></div>
            </div>
            {label}{" "}
          </div>
        ) : (
          label
        )}
      </div>
    </button>
  );
};

export { TextBtn, SecondaryTextBtn, LoginBtn };
