import { useState } from "react";

interface CardMoreOptionsProps {
  onSelectOptions: (selectedOptions: Option[]) => void;
}
interface Option {
  id: string;
  label: string;
}

const FilterOptions: React.FC<CardMoreOptionsProps> = ({
  onSelectOptions
}) => {
  const options: Option[] = [
    { id: "filterSelectAll", label: "Select All" },
    { id: "filterPredefined", label: "Predefined" },
    { id: "filterCustom", label: "Custom" },
  ];

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const handleOptionSelect = (option: Option) => {
    const index = selectedOptions.findIndex((selectedOption) => selectedOption.id === option.id);
    // need to fix the Functionlity Bug
    if (index === -1) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions.splice(index, 1);
      setSelectedOptions(newSelectedOptions);
    }
    onSelectOptions([...selectedOptions, option]);
  };

  return (
    <div className="relative">
      <div className="w-auto pl-[1.9rem] pr-[4rem] py-[0.5rem] bg-io-white dark:bg-io-black-1a shadow-xl rounded-[0.6rem] ">
        <ul className="w-full h-auto">
          {options.map((option, index) => (
            <li key={index} className="w-full h-auto flex justify-start items-center gap-4 group cursor-pointer">
              <input
                type="checkbox"
                className="accent-io-primary"
                id={option.id}
                checked={selectedOptions.some((selectedOption) => selectedOption.id === option.id)}
                onChange={() => handleOptionSelect(option)}
              />
              <label htmlFor={option.id} className="text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-gray-e9 text-nowrap py-3">
                {option.label}
              </label>
            </li>
          ))}
        </ul>
      </div>
      <div className="absolute top-[-2rem] right-4 w-auto h-auto border-b-[1rem] border-b-io-white dark:border-b-io-black-1a border-t-[1rem] border-t-transparent border-s-[1rem] border-s-transparent border-e-[1rem] border-e-transparent shadow-2xl"></div>
    </div>
  );
};
export default FilterOptions;
