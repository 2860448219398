import { ReactNode, useEffect, useState } from "react";
import IconBtn from "../../form/icon-btn";
import RadioBtns from "../../form/radio-btns";
import {
  DeleteIcon,
  DragTwelveDotIcon,
  FormSettingsIcon,
  MakeAnCopyIcon,
  ResponseAcknowledgementIcon,
  ResponseAudioIcon,
  ResponseDateTimeIcon,
  ResponseImageIcon,
  ResponseInformationIcon,
  ResponseLocationIcon,
  ResponseMagicContainerIcon,
  ResponseMultipleChoiceIcon,
  ResponseNavigationalLogicIcon,
  ResponseNumberIcon,
  ResponsePersonNameIcon,
  ResponseScannerIcon,
  ResponseSignatureIcon,
  ResponseSiteNameIcon,
  ResponseTenantNameIcon,
  ResponseTextIcon,
  ResponseToggleIcon,
  ResponseVideoIcon,
  VerticalMoreBlackIcon,
} from "../../svg";
import TabThumbMoreOptions from "../tab-thumb-more-options";
import { useTranslation } from "react-i18next";

interface Item {
  id: string;
  order: number;
  label: string;
  icon: ReactNode;
}
interface QuestionCardProps {
  item: any;
  items: any;
  index: number;
  openResponseSettings: (item: Item) => void;
  handleMakeAnCopy: (item: Item, index: number) => void;
  handleDelete: (item: Item, index: number) => void;
  snapshot: any;
  responseMultipleChoiceOptions: any;
  responseDateTimeOptions: any;
  responseScannerOptions: any;
  responseImageOptions: any;
  responseTextOptions: any;
  responseSettingsType: any;
}

interface Option {
  id: string;
  label: string;
  icon: ReactNode;
}
function QuestionCard({
  item,
  items,
  index,
  openResponseSettings,
  handleMakeAnCopy,
  handleDelete,
  snapshot,
  responseMultipleChoiceOptions,
  responseDateTimeOptions,
  responseScannerOptions,
  responseImageOptions,
  responseTextOptions,
  responseSettingsType,
}: QuestionCardProps) {
  const { t } = useTranslation();
  const renderResponseComponent = (item: any) => {
    const componentMap: { [key: string]: any } = {
      1: <ResponseTenantName questionData={item} />,
      2: <ResponseSiteName questionData={item} />,
      3: <ResponsePersonName questionData={item} />,
      4: <ResponseLocation questionData={item} />,
      5: (
        <ResponseScanner
          questionData={item}
          responseScannerOptions={responseScannerOptions}
        />
      ),
      6: (
        <ResponseScanner
          questionData={item}
          responseScannerOptions={responseScannerOptions}
        />
      ),
      7: (
        <ResponseScanner
          questionData={item}
          responseScannerOptions={responseScannerOptions}
        />
      ),
      8: (
        <ResponseScanner
          questionData={item}
          responseScannerOptions={responseScannerOptions}
        />
      ),
      9: (
        <ResponseMultipleChoice
          questionData={item}
          responseMultipleChoiceOptions={responseMultipleChoiceOptions}
        />
      ),
      10: (
        <ResponseMultipleChoice
          questionData={item}
          responseMultipleChoiceOptions={responseMultipleChoiceOptions}
        />
      ),
      11: (
        <ResponseMultipleChoice
          questionData={item}
          responseMultipleChoiceOptions={responseMultipleChoiceOptions}
        />
      ),
      12: (
        <ResponseText
          questionData={item}
          responseTextOptions={responseTextOptions}
        />
      ),
      13: (
        <ResponseText
          questionData={item}
          responseTextOptions={responseTextOptions}
        />
      ),
      14: <ResponseNumber questionData={item} />,
      15: (
        <ResponseDateTime
          questionData={item}
          responseDateTimeOptions={responseDateTimeOptions}
        />
      ),
      16: (
        <ResponseDateTime
          questionData={item}
          responseDateTimeOptions={responseDateTimeOptions}
        />
      ),
      17: (
        <ResponseDateTime
          questionData={item}
          responseDateTimeOptions={responseDateTimeOptions}
        />
      ),
      18: <ResponseAcknowledgement questionData={item} />,
      19: <ResponseInformation questionData={item} />,
      20: <ResponseSignature questionData={item} />,
      21: (
        <ResponseImage
          questionData={item}
          responseImageOptions={responseImageOptions}
        />
      ),
      22: <ResponseImageWithInputs />,
      23: <ResponseVideo />,
      24: <ResponseMagicContainer questionData={item} />,
      25: <ResponseAudio />,
      26: <ResponseToggle />,
      27: (
        <ResponseDateTime
          questionData={item}
          responseDateTimeOptions={responseDateTimeOptions}
        />
      ),
      28: (
        <ResponseMultipleChoice
          questionData={item}
          responseMultipleChoiceOptions={responseMultipleChoiceOptions}
        />
      ),
    };

    return (
      componentMap[item.questionTypeId] || <p>{t("web.label.Notfound")}</p>
    );
  };

  const moreOptions: Option[] = [
    { id: "delete", label: "Delete", icon: <DeleteIcon /> },
    { id: "makeancopy", label: "Make An Copy", icon: <MakeAnCopyIcon /> },
  ];

  const handleSelectedValue = (id: string) => {
    if (id === "makeancopy") {
      handleMakeAnCopy(item, index);
    }
    if (id === "delete") {
      handleDelete(item, index);
    }
  };

  return (
    <div
      className={`w-full h-auto rounded-[1.5rem] px-[2.5rem] py-[2.5rem] select-none border-b border-dashed border-io-gray-c8 dark:border-io-black-48   overflow-hidden relative before:content-[''] before:w-[0.4rem] before:h-full before:bg-io-primary before:absolute before:top-0 before:left-0 ${
        snapshot.isDragging ? "shadow-lg" : ""
      } ${
        responseSettingsType?.id === item?.id
          ? "before:block bg-[#F4F9FD] dark:bg-io-black-1c"
          : "before:hidden group-hover:before:block bg-io-white dark:bg-io-black-15 group-hover:bg-[#F4F9FD] dark:group-hover:bg-io-black-1c"
      }`}
      onClick={(e: any) => {
        openResponseSettings(item);
      }}
    >
      <div className="w-[4rem] h-auto absolute top-2 left-[50%] translate-x-[-50%] hidden group-hover:block">
        <DragTwelveDotIcon />
      </div>
      <div className="w-full h-auto flex justify-between items-center mb-[2rem]">
        <p className="text-[1.8rem] font-inter font-medium text-io-black dark:text-io-white">
          {item.orderNo}. {item.title}
          {item.isMandatory && <span className="text-red-500">*</span>}
        </p>
        <div className="w-auto flex justify-end items-center gap-8">
          <div className="w-auto">
            <IconBtn
              size="w-[1.7rem]"
              icon={<FormSettingsIcon />}
              handleClick={(e) => {
                e.stopPropagation();
                openResponseSettings(item);
              }}
            />
          </div>
          <div className="w-auto">
            <TabThumbMoreOptions
              options={moreOptions}
              selectedValue={handleSelectedValue}
              icon={<VerticalMoreBlackIcon />}
            />
          </div>
        </div>
      </div>
      <div className="w-full h-auto">{renderResponseComponent(item)}</div>
    </div>
  );
}

const ResponseTenantName = ({ questionData }: any) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
        <div className="w-[1.8rem] h-auto">
          <ResponseTenantNameIcon />
        </div>
        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
          {t("web.logic.label.tenantname")}
        </p>
      </div>
    </div>
  );
};
const ResponseSiteName = ({ questionData }: any) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
        <div className="w-[1.8rem] h-auto">
          <ResponseSiteNameIcon />
        </div>
        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
          {t("web.logic.label.sitename")}
        </p>
      </div>
    </div>
  );
};
const ResponsePersonName = ({ questionData }: any) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
        <div className="w-[1.8rem] h-auto">
          <ResponsePersonNameIcon />
        </div>
        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
          {t("web.logic.label.personname")}
        </p>
      </div>
    </div>
  );
};
const ResponseLocation = ({ questionData }: any) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
        <div className="w-[1.8rem] h-auto">
          <ResponseLocationIcon />
        </div>
        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
          {t("web.logic.label.location")}
        </p>
      </div>
    </div>
  );
};
const ResponseMultipleChoice = ({
  questionData,
  responseMultipleChoiceOptions,
}: any) => {
  const selectedOption = responseMultipleChoiceOptions.find(
    (option: any) => option.id === questionData?.inputSubType
  );
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      {selectedOption ? (
        <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
          <div className="w-[1.8rem] h-auto">
            <ResponseMultipleChoiceIcon />
          </div>
          <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
            {selectedOption.label}
          </p>
        </div>
      ) : (
        <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
          <div className="w-[1.8rem] h-auto">
            <ResponseMultipleChoiceIcon />
          </div>
          <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
            {t("web.logic.label.multiplechoice")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseText = ({ questionData, responseTextOptions }: any) => {
  const selectedOption = responseTextOptions.find(
    (option: any) => option.id === questionData?.inputSubType
  );
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      {selectedOption ? (
        <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
          <div className="w-[1.8rem] h-auto">
            <ResponseTextIcon />
          </div>
          <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
            {selectedOption.label}
          </p>
        </div>
      ) : (
        <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
          <div className="w-[1.8rem] h-auto">
            <ResponseTextIcon />
          </div>
          <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
            {t("web.logic.label.text")}
          </p>
        </div>
      )}
    </div>
  );
};

const ResponseDateTime = ({ questionData, responseDateTimeOptions }: any) => {
  const selectedOption = responseDateTimeOptions.find(
    (option: any) => option.id === questionData?.inputSubType
  );
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      {selectedOption ? (
        <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
          <div className="w-[1.8rem] h-auto">
            <ResponseDateTimeIcon />
          </div>
          <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
            {selectedOption.label}
          </p>
        </div>
      ) : (
        <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
          <div className="w-[1.8rem] h-auto">
            <ResponseDateTimeIcon />
          </div>
          <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
            {t("web.logic.label.datetime")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseSignature = ({ questionData }: any) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
        <div className="w-[1.8rem] h-auto">
          <ResponseSignatureIcon />
        </div>
        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
          {t("web.logic.label.signature")}
        </p>
      </div>
    </div>
  );
};
const ResponseScanner = ({ questionData, responseScannerOptions }: any) => {
  const selectedOption = responseScannerOptions.find(
    (option: any) => option.id === questionData?.inputSubType
  );
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      {selectedOption ? (
        <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
          <div className="w-[1.8rem] h-auto">
            <ResponseScannerIcon />
          </div>
          <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
            {selectedOption.label}
          </p>
        </div>
      ) : (
        <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
          <div className="w-[1.8rem] h-auto">
            <ResponseScannerIcon />
          </div>
          <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
            {t("web.logic.label.scanner")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseNumber = ({ questionData }: any) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
        <div className="w-[1.8rem] h-auto">
          <ResponseNumberIcon />
        </div>
        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
          {t("web.logic.label.number")}
        </p>
      </div>
    </div>
  );
};
const ResponseImage = ({ questionData, responseImageOptions }: any) => {
  const selectedOption = responseImageOptions.find(
    (option: any) => option.id === questionData?.inputSubType
  );
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      {selectedOption ? (
        <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
          <div className="w-[1.8rem] h-auto">
            <ResponseImageIcon />
          </div>
          <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
            {selectedOption.label}
          </p>
        </div>
      ) : (
        <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
          <div className="w-[1.8rem] h-auto">
            <ResponseTextIcon />
          </div>
          <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
            {t("web.logic.label.image")}
          </p>
        </div>
      )}
    </div>
  );
};
const ResponseMagicContainer = ({ questionData }: any) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
        <div className="w-[1.8rem] h-auto">
          <ResponseMagicContainerIcon />
        </div>
        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
          {t("web.logic.label.magiccontainer")}
        </p>
      </div>
    </div>
  );
};
const ResponseAcknowledgement = ({ questionData }: any) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
        <div className="w-[1.8rem] h-auto">
          <ResponseAcknowledgementIcon />
        </div>
        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
          {t("web.logic.label.acknowledgment")}
        </p>
      </div>
    </div>
  );
};
const ResponseInformation = ({ questionData }: any) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
        <div className="w-[1.8rem] h-auto">
          <ResponseInformationIcon />
        </div>
        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
          {t("web.logic.label.information")}
        </p>
      </div>
    </div>
  );
};
const ResponseVideo = ({ questionData }: any) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
        <div className="w-[1.8rem] h-auto">
          <ResponseVideoIcon />
        </div>
        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
          {t("web.logic.label.video")}
        </p>
      </div>
    </div>
  );
};
const ResponseImageWithInputs = ({ questionData }: any) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
        <div className="w-[1.8rem] h-auto">
          <ResponseInformationIcon />
        </div>
        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
          {t("web.logic.label.imagewithInputs")}
        </p>
      </div>
    </div>
  );
};
const ResponseAudio = ({ questionData }: any) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
        <div className="w-[1.8rem] h-auto">
          <ResponseAudioIcon />
        </div>
        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
          {t("web.logic.label.audio")}
        </p>
      </div>
    </div>
  );
};
const ResponseToggle = ({ questionData }: any) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-start items-center gap-[1.5rem] p-[0.8rem] rounded-[0.4rem] bg-io-white dark:bg-io-black-1c">
        <div className="w-[1.8rem] h-auto">
          <ResponseToggleIcon />
        </div>
        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 select-none">
          {t("web.logic.label.toggle")}
        </p>
      </div>
    </div>
  );
};
export default QuestionCard;
