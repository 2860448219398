import { useTranslation } from "react-i18next";
import Authentication from "../../../auth/Authentication";

interface SettingsOptionsProps {
  goToLanguage: () => void;
  goToPassword: () => void;
}

function SettingsOptions({ goToLanguage, goToPassword }: SettingsOptionsProps) {
  const authService = Authentication();
  const { t } = useTranslation();
  const selectedLanguage = authService.decryptData(
    "selectedLanguageDetails",
    2
  ) || { id: 1, code: "en", value: "English" };
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto py-[3.3rem] border-b border-io-gray-e9 dark:border-io-black-48">
        <div className="w-full h-auto">
          <div className="w-full h-auto flex justify-between items-center mb-3">
            <h5 className="text-[2.2rem] font-inter font-medium text-io-black dark:text-io-white">
              {t("web.settings.label.language")}
            </h5>
            <button
              className="bg-none border-none text-[1.6rem] font-inter font-normal text-io-primary"
              onClick={() => goToLanguage()}
            >
              {t("web.settings.label.change")}
            </button>
          </div>
          <p className="text-[1.8rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
            {selectedLanguage.value}
          </p>
        </div>
      </div>
      <div className="w-full h-auto py-[3.3rem]">
        <div className="w-full h-auto">
          <div className="w-full h-auto flex justify-between items-center mb-3">
            <h5 className="text-[2.2rem] font-inter font-medium text-io-black dark:text-io-white">
              {t("web.login.text.password")}
            </h5>
            <button
              className="bg-none border-none text-[1.6rem] font-inter font-normal text-io-primary"
              onClick={() => goToPassword()}
            >
              {t("web.settings.label.change")}
            </button>
          </div>
          <p className="text-[1.8rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
            ********
          </p>
        </div>
      </div>
    </div>
  );
}
export default SettingsOptions;
