import ModalPageHeader from "../../global-layout/modal-page-header";
import ModalPageWrapper from "../../global-layout/modal-page-wrapper";
import ViewAccountForm from "../view-account-form";
import { useTranslation } from "react-i18next";

interface ViewAccountProps {
  handleClose: () => void;
}

function ViewAccount({ handleClose }: ViewAccountProps) {
  const { t } = useTranslation();
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-2xl:w-[50%] max-md:w-[85%]">
        <div className="w-full h-full flex flex-col">
          <ModalPageHeader
            title={t("web.subtitle.label.profile")}
            handleClose={() => handleClose()}
          />
          <div className="w-full h-full flex-auto">
            <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
              <ViewAccountForm handleClose={() => handleClose()} />
            </div>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default ViewAccount;
