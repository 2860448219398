import { Fragment, useEffect, useRef, useState } from "react";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import StepsHeader from "../../steps-header";
import KeywordCreationChip from "../../../UI/keyword-creation-chip";
import PreviewCreatedLabel from "../../preview-created-label";
import UploadTrainingDataSet from "../../upload-training-dataset";
import { useNavigate } from "react-router-dom";
import PreviewKeyWords from "../../preview-keywords";
import { useTranslation } from "react-i18next";

interface CustomMagicStepOneProps{
    setCurrentStep: (step:number) => void;
    title: string;
}

function CustomMagicStepFour({setCurrentStep, title}:CustomMagicStepOneProps){
  const navigate =  useNavigate()
  const [dynamicHeight, setDynamicHeight] = useState(0);
  const elementRef = useRef<any>(null);

  useEffect(() => {
    if (elementRef.current) {
      const height = elementRef.current.clientHeight - 20;

      setDynamicHeight(height);
    }
  }, [title]);

  const labelCreation = [
    { labelName: "Pass", isActive: false },
    { labelName: "Fail", isActive: false },
    { labelName: "Spell Check Vehicle Spectra", isActive: false },
  ]
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex flex-col">
      <StepsHeader title={title} />
      <div className="w-full h-auto my-3">
        <div className="w-full h-auto flex justify-start items-center my-3">
          <p className="w-[10%] max-md:w-[15%] text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white dark:text-opacity-50">{t("web.magix.label.magicname")}</p>
          <p className="w-[90%] max-md:w-[85%] text-[1.6rem] font-inter font-normal text-io-black dark:text-io-white">{t("web.magix.label.mysticmind")}</p>
        </div>
        <div className="w-full h-auto flex justify-start items-center my-3">
          <p className="w-[10%] max-md:w-[15%] text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-white dark:text-opacity-50">{t("web.magix.label.description")}</p>
          <p className="w-[90%] max-md:w-[85%] text-[1.6rem] font-inter font-normal text-io-black dark:text-io-white">{t("web.magix.label.spectra")} </p>
        </div>
      </div>
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large" ref={elementRef}>
        <div className="w-full relative">
          <div className="w-full h-full p-4">
            <div className="w-full h-full flex">
              <div className="w-[21.26%] h-full sticky top-4 max-md:w-[35%]">
                <div className="w-full bg-io-gray-f8 dark:bg-io-black-1c pt-3 flex flex-col" style={{ height: dynamicHeight }}>
                  <div className="w-full flex-auto basis-0 pl-2 overflow-y-auto">
                    {labelCreation.map((label, i) => (
                      <Fragment key={i}>
                        <KeywordCreationChip labelName={label.labelName} isActive={label.isActive} />
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-[78.74%] h-auto ml-auto max-md:w-[65%]">
                <div className="w-full h-auto px-[4.5rem] py-[3.5rem] max-md:p-[3rem]">
                  <PreviewKeyWords isEditable={true}/>
                  <UploadTrainingDataSet isViewOnly={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
      footerType="steps"
        goToPrevious={() => setCurrentStep(3)}
        goToNext={() => navigate('/ai-magic-studio/document')}
        isFinalStep={true}
      />
    </div>
  )
}
export default CustomMagicStepFour