import ModalPageWrapper from "../../components/global-layout/modal-page-wrapper";
import User from "../../components/section/user-management/new-user";

function CreateNewUser({ userUpdated }: any) {
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <User userUpdated={userUpdated} />
      </div>
    </ModalPageWrapper>
  );
}
export default CreateNewUser;
