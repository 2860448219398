import Header from "../components/global-layout/header";
import SideNav from "../components/global-layout/sidenav";
import MainArea from "../components/global-layout/main-area";
import Confetti from "../lib/confetti";
import { useEffect, useState } from "react";
import Notification from "../components/section/notification";
import HelpAndSupport from "../components/section/help-and-support";
import Settings from "../components/section/settings";
import ViewAccount from "../components/section/view-account";
import Profile from "../components/section/profile";
import { ToastContainer } from "react-toastify";
import ConfirmSiteSwitch from "../components/section/confirm-site-switch";

interface LayoutProps {
  confettiVal: boolean;
  themeToggled: () => void;
  children: any;
}

function Layout({ confettiVal, themeToggled, children }: LayoutProps) {
  const [sideNavState, setSideNavState] = useState<boolean>(true);
  const [headerModal, setHeaderModal] = useState<string>("");
  const [isConfetti, setIsConfetti] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [siteSwitchModal, setSiteSwitchModal] = useState(false);
  const [confirmSiteSwitch, setConfirmSiteSwitch] = useState(false);


  useEffect(() => {
    setIsConfetti(confettiVal);
  }, [confettiVal]);

  const handleChangePassword = () => {
    setHeaderModal("settings");
    setShowChangePassword(true);
  }
  

  return (
    <>
      {children}
      <div className="w-full h-screen overflow-hidden">
        <div className="w-full h-full flex flex-col">
          <Header
            activeModal={headerModal}
            handleClick={(data) => setHeaderModal(data)}
            themeToggled={themeToggled}
          />
          <div className="w-full flex-1 relative">
            <div className="w-full h-full">
              <div
                className={`w-full h-full flex justify-between items-center layout-wrapper ${
                  sideNavState ? "expanded" : "collapsed"
                } `}
              >
                {isConfetti && <Confetti numberOfPieces={400} />}
                <SideNav sideNavState={(data: any) => setSideNavState(data)} />
                <MainArea />
              </div>
            </div>
            {headerModal === "notification" && (
              <Notification handleClose={() => setHeaderModal("")} handleChangePassword={handleChangePassword} />
            )}
            {headerModal === "helpandsupport" && (
              <HelpAndSupport handleClose={() => setHeaderModal("")} />
            )}
            {headerModal === "viewaccount" && (
              <ViewAccount handleClose={() => setHeaderModal("")} />
            )}
            {headerModal === "settings" && (
              <Settings handleClose={() => setHeaderModal("")} showChangePassword={showChangePassword} />
            )}
            {headerModal === "profile" && (
              <Profile
                handleViewAccount={() => setHeaderModal("viewaccount")}
                handleClose={() => setHeaderModal("")}
                setSiteSwitchModal={(data) => setSiteSwitchModal(data)}
                confirmSiteSwitch={confirmSiteSwitch}
              />
            )}
            {siteSwitchModal && <ConfirmSiteSwitch handleClose={() => setSiteSwitchModal(false)} handleSiteSwitchConfirm={() => setConfirmSiteSwitch(true)}  />}
            
            {/* <Alert /> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default Layout;
