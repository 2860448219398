import { useState } from "react";
import PageHeader from "../../components/global-layout/page-header";
import MasterDetailsGrid from "../../components/section/master-details/master-details-grid";
import { MasterDetailsPageHeaderIcon } from "../../components/svg";
import DeleteUser from "../../components/section/user-management/delete-user";
import GridColumn from "../../components/section/user-management/grid-columns";
import { Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MasterDetails({ masterDetailsUpdated, setMasterDetailsUpdated }: any) {
  const { pathname } = useLocation();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    "name",
    "accessMode",
    "isGrouping",
    "statusId",
    "columnDetails",
    "editDetails",
  ]);
  const [deleteData, setDeleteData] = useState<any>(null);
  const [handleDataDelete, setHandleDataDelete] = useState<any>(null);

  const columnsData = [
    { label: "Name", token: "web.master.label.name", key: "name" },
    {
      label: "Access Mode",
      token: "web.master.label.accessmode",
      key: "accessMode",
    },
    {
      label: "Grouping Available",
      token: "web.common.label.groupingAvailable",
      key: "isGrouping",
    },
    { label: "Status", token: "web.common.label.status", key: "statusId" },
    {
      label: "Column Details",
      token: "web.common.label.columnDetails",
      key: "columnDetails",
    },
    {
      label: "View Details",
      token: "web.common.label.viewDetails",
      key: "editDetails",
    },
  ];

  const handleCheckboxChange = (event: any, key: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedColumns((prevSelected) => [...prevSelected, key]);
    } else {
      if (selectedColumns.length === 1) return;
      setSelectedColumns((prevSelected) =>
        prevSelected.filter((item) => item !== key)
      );
    }
  };

  const handleDelete = (rowData: any) => {
    setDeleteData(rowData);
    setShowDeletePopup(true);
  };
  const { t } = useTranslation();
  return (
    <>
      {!pathname.includes("/master-details/detail") &&
        !pathname.includes("/master-details/user-master") && (
          <div className="w-full h-full flex flex-col">
            <PageHeader
              title={t("Master Details")}
              icon={<MasterDetailsPageHeaderIcon />}
            />
            <MasterDetailsGrid
              handleDelete={handleDelete}
              handleColumns={() => setShowColumns(true)}
              selectedColumns={selectedColumns}
              rowSelection={false}
              moreOptions={true}
              masterDetailsUpdated={masterDetailsUpdated}
              setShowColumns={() => setShowColumns(true)}
              setMasterDetailsUpdated={setMasterDetailsUpdated}
              handleDataDelete={handleDataDelete}
              setShowDeletePopup={(val: boolean) => setShowDeletePopup(val)}
            />
          </div>
        )}
      {showDeletePopup && (
        <DeleteUser
          rowData={deleteData}
          handleCancel={() => setShowDeletePopup(false)}
          handleDelete={(rowData: any) => setHandleDataDelete(rowData)}
          actionFlag="masterDetails"
        />
      )}
      {showColumns && (
        <GridColumn
          handleClose={() => setShowColumns(false)}
          handleCheckboxChange={handleCheckboxChange}
          columnsData={columnsData}
          selectedColumns={selectedColumns}
        />
      )}
      <Outlet />
    </>
  );
}
export default MasterDetails;
