import { useState } from "react";
import IconBtn from "../../../form/icon-btn";
import {
  ExpandIcon,
  PageModalCloseIcon,
  TabThumbDesktop,
  TabThumbMobile,
  TabThumbTablet,
} from "../../../svg";
import { useTranslation } from "react-i18next";

interface ProcessPreviewProps {
  processData: any;
  questionDetails: any;
  handleExpandPreview: () => void;
  handleHidePreview: () => void;
}

function ProcessPreview({
  processData,
  questionDetails,
  handleExpandPreview,
  handleHidePreview,
}: ProcessPreviewProps) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("mobile");
  const tabs = [
    { id: "mobile", label: "Mobile", icon: <TabThumbMobile /> },
    { id: "tablet", label: "Tablet", icon: <TabThumbTablet /> },
    { id: "desktop", label: "Desktop", icon: <TabThumbDesktop /> },
  ];
  const handleTabChange = (tabId: string) => {
    setSelectedTab(tabId);
  };
  const renderSelectedDevice = () => {
    switch (selectedTab) {
      case "mobile":
        return (
          <div className="w-full h-auto">
            <div className="w-[25.3rem] h-[51.4rem] mx-auto relative">
              <div className="w-full h-full z-[0]">
                <div className="w-[94%] mx-auto h-[20%] bg-io-primary rounded-t-[5rem] rounded-b-[2rem]"></div>
              </div>

              <div className="w-full h-full absolute top-0 left-0  z-[2]">
                <img
                  src={require("../../../../assets/images/preview-mobile.png")}
                  alt="MobileImage"
                />
              </div>
              <div className="w-full h-full absolute top-0 left-0 z-[3]">
                <div className="w-[80%] h-full mx-auto pb-[1rem] flex justify-center items-end">
                  <div className="w-full h-[90%] bg-io-white rounded-[2rem] overflow-hidden">
                    <div className="w-full h-full flex flex-col">
                      <div className="w-full h-auto">
                        <div className="w-full h-auto px-3 py-3 border-b border-io-gray-c8">
                          <p className="text-[1rem] font-inter font-normal text-io-black">
                            {t("web.logic.automaticinspection")}
                          </p>
                        </div>
                        <div className="w-full h-auto px-2 py-2">
                          <div className="w-full h-auto flex justify-between items-center">
                            <p className="text-[1rem] font-inter font-normal text-io-primary">
                              1/2{" "}
                              <span className="text-[0.8rem] text-io-black">
                                {t("web.logic.questions")}
                              </span>
                            </p>
                            <p className="text-[1rem] font-inter font-normal text-io-primary">
                              &lt; &gt; &gt; &gt;
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-full p-2 flex-auto basis-0 overflow-y-auto scrollbar-small">
                        <div className="w-full h-full ">
                          {questionDetails.map((items: any, i: any) => (
                            <p
                              key={i}
                              className="text-[1rem] font-inter font-normal text-io-black py-8"
                            >
                              {items.name}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "tablet":
        return (
          <div className="w-full h-auto">
            <div className="w-[25rem] h-[34.5rem] mx-auto relative">
              <div className="w-full h-full z-[0]">
                <div className="w-[94%] mx-auto h-[20%] bg-io-primary rounded-t-[1rem] rounded-b-[1rem]"></div>
              </div>

              <div className="w-full h-full absolute top-0 left-0  z-[2]">
                <img
                  src={require("../../../../assets/images/preview-tablet.png")}
                  alt="MobileImage"
                />
              </div>
              <div className="w-full h-full absolute top-0 left-0 z-[3]">
                <div className="w-[80%] h-full mx-auto pb-[1.2rem] flex justify-center items-end">
                  <div className="w-full h-[90%] bg-io-white rounded-[2rem] overflow-hidden">
                    <div className="w-full h-full flex flex-col">
                      <div className="w-full h-auto">
                        <div className="w-full h-auto px-3 py-3 border-b border-io-gray-c8">
                          <p className="text-[1rem] font-inter font-normal text-io-black">
                            {t("web.logic.label.automaticinspection")}
                          </p>
                        </div>
                        <div className="w-full h-auto px-2 py-2">
                          <div className="w-full h-auto flex justify-between items-center">
                            <p className="text-[1rem] font-inter font-normal text-io-primary">
                              1/2{" "}
                              <span className="text-[0.8rem] text-io-black">
                                {t("web.logic.label.questions")}
                              </span>
                            </p>
                            <p className="text-[1rem] font-inter font-normal text-io-primary">
                              &lt; &gt; &gt; &gt;
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-full p-2 flex-auto basis-0 overflow-y-auto scrollbar-small">
                        <div className="w-full h-full ">
                          {questionDetails.map((items: any, i: any) => (
                            <p
                              key={i}
                              className="text-[1rem] font-inter font-normal text-io-black py-8"
                            >
                              {items.name}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "desktop":
        return (
          <div className="w-full h-auto">
            <div className="w-[25rem] h-[15.5rem] mx-auto relative">
              <div className="w-full h-full overflow-y-auto scrollbar-none absolute top-0 left-0 px-10 pt-[15%] z-[1] ">
                {questionDetails.map((items: any, i: any) => (
                  <p
                    key={i}
                    className="text-[1.6rem] font-inter font-normal text-io-black py-8"
                  >
                    {items.name}
                  </p>
                ))}
              </div>
              <div className="w-full h-auto  z-0">
                <img
                  src={require("../../../../assets/images/preview-desktop.png")}
                  alt="DesktopImage"
                />
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full h-full">
      <div className="w-full h-auto flex justify-end items-center gap-6 px-6 mb-[2rem]">
        <IconBtn
          size="w-[1.6rem]"
          icon={<ExpandIcon />}
          handleClick={handleExpandPreview}
        />
        <IconBtn
          size="w-[1.6rem]"
          icon={<PageModalCloseIcon />}
          handleClick={handleHidePreview}
        />
      </div>
      <div className="w-full h-auto">
        <div className="w-auto flex justify-center items-center mb-[2rem]">
          {tabs.map((tab) => (
            <button
              type="button"
              key={tab.id}
              className={`w-auto h-auto px-[1rem] py-[0.6rem] first:rounded-l-[0.5rem] last:rounded-r-[0.5rem]  ${
                selectedTab === tab.id
                  ? "bg-io-primary text-io-white fill-io-white"
                  : "bg-io-white text-io-gray-66 fill-io-gray-66 dark:fill-io-white dark:bg-transparent"
              }`}
              onClick={() => handleTabChange(tab.id)}
            >
              <div className="w-auto flex justify-center items-center gap-3">
                <div className="w-auto h-[1.8rem]">{tab.icon}</div>
                <p className="text-[1.4rem] font-inter font-normal text-inherit">
                  {tab.label}
                </p>
              </div>
            </button>
          ))}
        </div>
      </div>
      {renderSelectedDevice()}
    </div>
  );
}
export default ProcessPreview;
