import React, { useEffect, useState } from "react";
import { useMyContext } from "../../my-context/MyContext";
import { languageServices } from "../../../services/InspectionOneServices";
import i18n from "../../../utils/localization/i18n";
import Authentication from "../../../auth/Authentication";
import { languageDetails } from "../../../utils/common";
import { useTranslation } from "react-i18next";
function LoginFooter() {
  const authService = Authentication();
  const { setLanguage, language, isExternalUser } = useMyContext();
  const [languages, setLanguages] = useState<any[]>(languageDetails);

  const [selectedLanguage, setSelectedLanguage] = useState("");

  // useEffect(() => {
  //   const fetchLanguages = async () => {
  //     var requestData = {};
  //     try {
  //       const response = await languageServices.LanguageDetails(requestData);
  //       if (response.success) {
  //         setLanguages(response.data);
  //         authService.encryptData(response.data, "languageDetails", 2);
  //       } else {
  //       }
  //     } catch (error) {
  //       console.error("Error fetching languages:", error);
  //     }
  //   };

  //   fetchLanguages();
  // }, []);
  const handleLanguageChange = (event: any) => {
    const selectedLanguage = event.target.value;
    const selectedLanguageDetails = languages.find(
      (lang) => lang.code === selectedLanguage
    );
    setSelectedLanguage(selectedLanguage);
    setLanguage(selectedLanguage);
    authService.encryptData(
      selectedLanguageDetails,
      "selectedLanguageDetails",
      2
    );
    i18n.changeLanguage(selectedLanguage);
  };
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto flex justify-between items-center">
        <div className="w-auto h-auto">
          <select
            name="language"
            id="language"
            className="bg-none border-none text-[1.6rem] font-inter font-normal text-io-gray-66"
            value={selectedLanguage}
            onChange={handleLanguageChange}
          >
            {languages.map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.value}
              </option>
            ))}
          </select>
        </div>
        <div className="w-auto h-auto">
          <p className="text-[1.6rem] font-inter font-normal text-io-gray-66">
            &copy; {t("web.loginfooter.label.copyright")}
          </p>
        </div>
      </div>
    </div>
  );
}
export default LoginFooter;
