import React, { useState, useRef } from "react";
import Tippy from "@tippyjs/react";
import { DeleteIcon, EditIcon, GridMoreIcon } from "../../../svg";
import IconBtn from "../../../form/icon-btn";
import { useNavigate } from "react-router";
import { CustomCellRendererProps } from "ag-grid-react";
import { useTranslation } from "react-i18next";

interface DeletePopupCellRendererProps {
    rowData: any;
    selectedMoreOption: (option: string, row: any) => void;
  }

  const DeletePopupCellRenderer = ({
    rowData,
    selectedMoreOption,
  }: DeletePopupCellRendererProps) => {
    const tippyRef = useRef<any>(null);
    const [visible, setVisible] = useState(false);
    const show = () => setVisible(true);
    const hide = () => setVisible(false);
    const { t } = useTranslation();

    const dropDownContent = (
        <div className="relative">
          <div className="w-auto h-auto bg-io-white dark:bg-io-black-1a px-[2rem] py-[1.5rem] shadow-lg rounded-[0.6rem]">            
            <div
              onClick={() => onClickHandler("delete", rowData)}
              className="w-auto flex justify-start items-center gap-4 py-1 cursor-pointer"
            >
              <div className="w-[2rem] h-auto">
                <DeleteIcon />
              </div>
              <span className="text-[1.8rem] font-inter text-io-gray-66 dark:text-io-black-d1">
                {t("web.title.common.delete")}
              </span>
            </div>
          </div>
          <div className="absolute top-[50%] left-full translate-y-[-50%] w-auto h-auto border-b-[1rem] border-b-transparent border-t-[1rem] border-t-transparent border-s-[1rem] border-s-io-white dark:border-s-io-black-1a border-e-[1rem] border-e-transparent"></div>
        </div>
      );
      
      const onClickHandler = (option: string, row: any) => {
        hide();
        selectedMoreOption(option, row);
      };

      return (
        <Tippy
          ref={tippyRef}
          content={dropDownContent}
          visible={visible}
          onClickOutside={hide}
          allowHTML={true}
          arrow={false}
          appendTo={document.body}
          interactive={true}
          placement="left"
        >
          <div className="w-full h-full flex justify-center items-center">
            <IconBtn
              size="w-[2rem]"
              icon={<GridMoreIcon />}
              handleClick={visible ? hide : show}
            />
          </div>
        </Tippy>
      );
  };

  export default DeletePopupCellRenderer;