import { useTranslation } from "react-i18next";
import IconTextBtn from "../icon-text-btn";

interface ResponseValueProps {
  goToResponseValue: () => void;
  selectedResVal: ResponseValueItem[];
}

interface ResponseValueItem {
  id: string;
  value: string;
  color: string;
}

function ResponseValue({
  goToResponseValue,
  selectedResVal,
}: ResponseValueProps) {
  const { t } = useTranslation();
  return (
    <div className="w-full h-auto mb-[3rem]">
      <div className="w-full h-auto flex justify-between items-center ">
        <p className="text-[1.4rem] font-inter font-normal text-io-black dark:text-io-white mb-4">
           {t("web.form.label.multiple.response")}
        </p>
        <IconTextBtn
          btnSize="large"
          style="text-io-primary dark:text-io-primary"
          label={t("web.form.label.select")}
          handleClick={() => goToResponseValue()}
        />
      </div>
      <div className="w-full h-auto">
        <div className="w-full h-auto flex justify-start items-center gap-[0.7rem]">
          {selectedResVal?.map((resVal, i) => (
            <div
              key={i}
              className={`w-auto h-auto px-3 py-[0.1rem] text-[1.3rem] rounded-full bg-opacity-10 
                        ${resVal.color === "red" && "text-io-red bg-io-red"}
                        ${
                          resVal.color === "green" &&
                          "text-io-green bg-io-green"
                        }
                        ${
                          resVal.color === "orange" &&
                          "text-io-orange bg-io-orange"
                        }
                        ${resVal.color === "blue" && "text-io-blue bg-io-blue"}
                        ${
                          resVal.color === "gray" &&
                          "text-io-gray-c8 bg-io-gray-c8"
                        }
                        `}
            >
              {resVal.value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default ResponseValue;
