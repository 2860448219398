import { useTranslation } from "react-i18next";
import IconBtn from "../../../form/icon-btn";
import { TextBtn } from "../../../form/text-btn";
import ModalPageWrapper from "../../../global-layout/modal-page-wrapper";
import { PageModalCloseIcon } from "../../../svg";

function DeleteUser({ handleCancel, rowData, handleDelete, actionFlag }: any) {
  const { t } = useTranslation();
  const handleDeleteValue = (items: any) => {
    handleDelete(items);
  };
  return (
    <ModalPageWrapper>
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[40%] h-auto bg-io-white dark:bg-io-black-1a rounded-[2.2rem] max-md:w-[68%]">
          <div className="w-full h-auto p-12 text-center">
            <div className="w-full h-auto flex justify-end">
              <IconBtn
                size="w-[3rem]"
                icon={<PageModalCloseIcon />}
                handleClick={() => handleCancel()}
              />
            </div>
            <div className="w-[12.5rem] h-auto mx-auto mb-[3.5rem] max-md:w-[10rem] max-md:mb-[2rem] max-3xl:mb-[2rem] max-3xl:w-[8rem]">
              <img
                src={require("../../../../assets/images/delete-image.png")}
                alt="DeleteImage"
              />
            </div>
            <div className="w-full h-auto mb-[5%]">
              <h1 className="text-[3.2rem] font-inter font-medium text-io-black dark:text-io-white mb-4 max-3xl:text-[2.6rem]">
                {(() => {
                  switch (actionFlag) {
                    case "user":
                      return t("web.toolbar.label.delete.users");
                    case "magic":
                      return t("web.toolbar.label.delete.magicitems");
                    case "container":
                      return t("web.toolbar.label.delete.container");
                    case "process":
                      return t("web.toolbar.label.delete.process");
                    case "survey":
                      return t("web.toolbar.label.delete.screencollection");
                    case "startupscreen":
                      return t("web.toolbar.label.delete.startupscreen");
                    case "screenmapping":
                      return t("web.toolbar.label.delete.screenmapping");
                    case "vision":
                      return t("web.toolbar.label.delete.vision");
                    case "masterDetails":
                      return t("web.toolbar.label.delete.masterdetails");
                    case "masterColumnDetails":
                      return t("web.toolbar.label.delete.masterdetails");
                    case "masterValueDetails":
                      return t("web.toolbar.label.delete.masterdetails");
                    case "aiLogicStudio":
                      return t("web.toolbar.label.delete.ailogicstudio");
                    default:
                      return t("web.toolbar.label.delete.users");
                  }
                })()}
                ?
              </h1>
              <div className="w-full h-auto relative ">
                <div className="text-[1.8rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                  {(() => {
                    switch (actionFlag) {
                      case "user":
                        return rowData.firstName;
                      case "magic":
                        return rowData.magicname;
                      case "container":
                        return rowData.name;
                      case "process":
                        return rowData.name;
                      case "survey":
                        return rowData.name;
                      case "screencollection":
                        return rowData.name;
                      case "startupscreen":
                        return rowData.name;
                      case "screenmapping":
                        return rowData.name;
                      case "vision":
                        return rowData.name;
                      case "masterDetails":
                        return rowData.name;
                      case "masterColumnDetails":
                        return rowData.name;
                      case "masterValueDetails":
                        return rowData.name;
                      case "aiLogicStudio":
                        return rowData.name;
                      default:
                        return "";
                    }
                  })()}
                  <div className="text-io-primary inline-block cursor-pointer group">
                    {/* 5+{" "} */}
                    <div className="absolute top-full right-0 max-w-[50rem] px-4 py-2 bg-io-white dark:bg-io-black-23 shadow-lg hidden group-hover:block">
                      <p className="text-[1.8rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1">
                        {(() => {
                          switch (actionFlag) {
                            case "user":
                              return rowData.firstName;
                            case "magic":
                              return rowData.magicname;
                            case "container":
                              return rowData.name;
                            case "process":
                              return rowData.name;
                            case "screencollection":
                              return rowData.name;
                            case "survey":
                              return rowData.name;
                            case "startupscreen":
                              return rowData.name;
                            case "screenmapping":
                              return rowData.name;
                            case "masterDetails":
                              return rowData.name;
                            case "masterColumnDetails":
                              return rowData.name;
                            case "masterValueDetails":
                              return rowData.name;
                            case "aiLogicStudio":
                              return rowData.name;
                            default:
                              return rowData.name;
                          }
                        })()}
                      </p>
                    </div>
                  </div>
                  {/* ) */}
                </div>
              </div>
            </div>
            <div className="w-full h-auto flex justify-center items-center gap-4 mb-[4%]">
              <TextBtn
                label={t("web.common.button.cancel")}
                btnStyle="fill-secondary"
                btnType="button"
                handleClick={() => handleCancel()}
              />
              <TextBtn
                label={t("web.label.common.delete")}
                btnStyle="fill-delete"
                btnType="button"
                handleClick={() => handleDeleteValue(rowData)}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default DeleteUser;
