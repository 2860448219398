import ModalPageWrapper from "../../components/global-layout/modal-page-wrapper";
import CreateMasterDetailsDetail from "../../components/section/master-details/create-master-details-detail";

function CreateMasterDetailsDetailWrapper({ userColumnUpdated }: any) {
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <CreateMasterDetailsDetail userColumnUpdated={userColumnUpdated} />
      </div>
    </ModalPageWrapper>
  );
}
export default CreateMasterDetailsDetailWrapper;
