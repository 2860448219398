import { useEffect, useState } from "react";
import ModalPageHeader from "../../global-layout/modal-page-header";
import ModalPageWrapper from "../../global-layout/modal-page-wrapper";
import LanguageSettings from "../language-settings";
import SettingsOptions from "../settings-options";
import PasswordSettings from "../password-settings";
import { useTranslation } from "react-i18next";

interface SettingProps {
  handleClose: () => void;
  showChangePassword: any
}

function Settings({ handleClose, showChangePassword }: SettingProps) {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<string>("settings");
  const renderStepContent = () => {
    switch (currentStep) {
      case "settings":
        return (
          <SettingsOptions
            goToLanguage={() => setCurrentStep("language")}
            goToPassword={() => setCurrentStep("password")}
          />
        );
      case "language":
        return (
          <LanguageSettings
            goToSettings={() => setCurrentStep("settings")}
            handleClose={handleClose}
          />
        );
      case "password":
        return (
          <PasswordSettings
            goToSettings={() => setCurrentStep("settings")}
            handleClose={handleClose}
          />
        );
    }
  };

  useEffect(() => {
    if(showChangePassword){
      setCurrentStep("password")
    }
  },[showChangePassword])
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-2xl:w-[50%] max-md:w-[85%]">
        <div className="w-full h-full flex flex-col">
          <ModalPageHeader
            title={t("web.settings.label.settings")}
            handleClose={() => handleClose()}
          />
          <div className="w-full h-full flex-auto">
            <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
              {renderStepContent()}
            </div>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default Settings;
