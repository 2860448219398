import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import NewUserForm from "../new-user-form";
import { useTranslation } from "react-i18next";

function User({ userUpdated }: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (id !== "new") {
      setTitle(t("web.subtitle.label.edituser"));
    } else {
      setTitle(t("web.subtitle.label.adduser"));
    }
  }, [id, t]);
  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex flex-col">
        <ModalPageHeader
          title={title}
          handleClose={() => navigate("/user-management")}
        />
        <div className="w-full h-full flex-auto">
          <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
            <NewUserForm userUpdated={userUpdated} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default User;
