import EnterTagsInput from "../../../form/enter-tags-input";
import { InputField, TextAreaField } from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import StepsHeader from "../../steps-header";
import { useTranslation } from "react-i18next";

interface CustomMagicStepOneProps {
  setCurrentStep: (step: number) => void;
  title: string;
}

function CustomMagicStepOne({
  setCurrentStep,
  title,
}: CustomMagicStepOneProps) {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex flex-col">
      <StepsHeader title={title} />
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[42.35%] h-auto py-[3rem] max-md:w-[60%]">
          <div className="w-full h-auto">
            <form>
              <div className="mb-[3.8rem]">
                <InputField
                  labelName={t("web.magix.text.magicname")}
                  type="text"
                  id="MagicName"
                />
              </div>
              <div className="mb-[3.8rem]">
                <TextAreaField
                  labelName={t("web.magix.label.description")}
                  id="description"
                />
              </div>
              <div className="mb-[3.8rem]">
                <EnterTagsInput
                  labelName={t("web.magix.label.entertags")}
                  idName="documentEnterTags"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="steps"
        backbtn={true}
        goToPrevious={() => setCurrentStep(1)}
        goToNext={() => setCurrentStep(2)}
      />
    </div>
  );
}
export default CustomMagicStepOne;
