import { toast } from "react-toastify";

const ToastifyMessage = () => {
  const ToastifyHandle = (msgText, type) => {
    toast(msgText, {
      hideProgressBar: true,
      icon: false,
      autoClose: 1000,
      type: type || "success",
      position: "top-right", //top-right | top-center
      closeButton: false,
    });
  };
  return { ToastifyHandle };
};
export default ToastifyMessage;
