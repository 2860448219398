import * as CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import EncryptionKey from "../auth/EncryptionKey";
 

const generateGuid = (): string => {
  return uuidv4();
};

const generateCheckSumValue = (dataToHash: string, secretKey: string): string => {
  const modifiedString = dataToHash.substring(3, dataToHash.length - 3).trim();
  const oddIndexCharacters = getCharactersAtOddIndices(modifiedString);
  const checkSumKey = CryptoJS.HmacSHA256(oddIndexCharacters, secretKey).toString(CryptoJS.enc.Hex);
  return checkSumKey;
};

const getCharactersAtOddIndices = (str: string): string => {
  const characters = str.split("");
  const oddIndexCharacters = characters.filter((_, index) => (index + 1) % 2 !== 0);
  return oddIndexCharacters.join("");
};

const apiKey = "Ei3mlxMuHUoIHeCbvI9BBbUNV3jZAmEX";
const allowedOrigins = ["http://localhost:3000"];

interface HeaderValues {
  getHeaders: (fileType: string) => {
    "Content-Type": string;
    "X-API-Key": string;
    "X-Random-Key": string;
    "X-CheckSumValue-Key": string;
    "Master-Access-Id": string;
    "Site-Id": any;
    "File-Type": string;
  };
}

const headerValues: HeaderValues = {
  getHeaders: (fileType: string) => {
    const generatedGuid = generateGuid();
    return {
      "Content-Type": "application/json",
      "X-API-Key": apiKey,
      "X-Random-Key": generatedGuid,
      "X-CheckSumValue-Key": generateCheckSumValue(generatedGuid, "3eccd18b7229454ca1ffd6705c93da83"),
      "Site-Id": sessionStorage.getItem("siteId"),
      "Master-Access-Id": "",
      "File-Type": fileType,
    };
  },
};

const updateCommonDetails = () => {
  return {
    lat: "",
    long: "",
    sourceId: "1",
    sourceDetails: "Web",
    languageToken: "en",
    tenantId: sessionStorage.getItem("tenantId"),
    siteId: sessionStorage.getItem("siteId"),
    siteUserId: sessionStorage.getItem("userId"),
    "Master-Access-Id": "",
  };
};

const requestDetails = (serviceDetails: any) => ({
  commonDetails: updateCommonDetails(),
  serviceDetails,
});

const makeRequest = async (url: string, method: string, data: any, fileType = "", id = "0") => {
  try { 
    const headers = headerValues.getHeaders(fileType);
    headers["Master-Access-Id"] = id;
    let body;
    if (data.serviceDetails instanceof ArrayBuffer) { 
      body =  data.serviceDetails ;
    } else {
      body = JSON.stringify(data);
    } 
    const response = await fetch(url, {
      method,
      headers,
      body
    });

    if (response.status === 200) {
      const responseData = await response.json();
      if (responseData.responseCode === "200") {
        return { success: true, data: responseData.responseResult };
      } else {
        const message = responseData.responseMessage.toString().trim().replace(/^"|"$/g, '') ;
        return { success: false, message: message };
      }
    } else {
      return { success: false, message: "Something went wrong. Please contact the administrator." };
    }
  } catch (error) {
    return { success: false, message: "Something went wrong. Please contact the administrator." };
  }
};

const createService = () => ({ 
  handleRequest: async (endpointKey: string, data: any, method = "POST", fileType = "", id = "0") => {
    const apiBaseURLs=await getApiBaseURLs();
    let baseURL:any;
    if(endpointKey==="api.web.domainValidate"){
      baseURL= "https://webgatewaydevelopment.azurewebsites.net/DomainValidation";
    }
   else if(endpointKey==="api.web.language"){
      baseURL = "https://webgatewaydevelopment.azurewebsites.net/GetLanguageMaster"
    } 
    else{
        baseURL = apiBaseURLs[endpointKey];
    }
    const requestData = requestDetails(data);
     
    return makeRequest(baseURL, method, requestData, fileType, id);
  }
});
const getApiBaseURLs = async () => { 
  try {
    const encryptedData = localStorage.getItem("serviceDetails");
    if (!encryptedData) {
      console.warn("No encrypted data found in localStorage.");
      return;
    }
    const primaryKey = EncryptionKey();
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, primaryKey);
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
    if (!decryptedData) {
      console.warn("Decryption failed or returned an empty result.");
      return;
    }
    return JSON.parse(decryptedData);
  } catch (error) {
    console.error("Error fetching API base URLs:", error);
  }
};

export { headerValues,  createService, getApiBaseURLs,updateCommonDetails, requestDetails, makeRequest };
