import ModalPageHeader from "../../../global-layout/modal-page-header";
import CreateMasterDetailsForm from "../create-master-details-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

interface CreateMasterDetailsProps {
  masterDetailsUpdated: (val: boolean) => void;
}
function CreateMasterDetails({
  masterDetailsUpdated,
}: CreateMasterDetailsProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams(); // Ensure id is correctly typed as string
  const [token, setToken] = useState("");
  useEffect(() => {
    if (id !== "new") {
      setToken(t("web.common.title.editmasterdtls"));
    } else {
      setToken(t("web.common.title.createmasterdtls"));
    }
  }, [id, t]);

  return (
    <div className="w-full h-full">
      <div className="w-full h-full flex flex-col">
        <ModalPageHeader
          title={token}
          handleClose={() => navigate("/master-details")}
        />
        <div className="w-full h-full flex-auto">
          <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
            <CreateMasterDetailsForm
              masterDetailsUpdated={masterDetailsUpdated}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateMasterDetails;
