import { useTranslation } from "react-i18next";
import { CardsNoDataFound } from "../../svg";

function NoDataFound() {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="w-[40%] h-auto">
        <div className="w-[25rem] h-auto mx-auto mb-8">
          <CardsNoDataFound />
        </div>
        <p className="text-[2.7rem] text-center font-inter font-normal text-[#b8b8b8]">
          {t("web.toolbar.label.card.nodata")}
        </p>
      </div>
    </div>
  );
}
export default NoDataFound;
