import { useNavigate, useParams } from "react-router-dom";
import {
  InputFieldWithoutForm,
  TextAreaField,
} from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import UploadImage from "../../../form/upload-image";
import { useEffect, useState } from "react";
import { logicServices } from "../../../../services/InspectionOneServices";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { logicCreate, logicEdit } from "../../../../services/authFunctions";

function CreateProcessForm({ logicUpdated }: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [mode, setMode] = useState("insert");
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    icon: "",
    imageUrl: "",
  });
  const [errors, setErrors] = useState({ name: "" });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (id !== "new") {
      setMode("update");
      handlelogicEdit(id);
    }
  }, [id]);
  const handleInputChange = (name: string, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleFormValidation = () => {
    const isNameEmpty = formData.name.trim() === "";
    setErrors({
      name: isNameEmpty ? t("web.user.alert.fnamerequired") : "",
    });
    return !isNameEmpty;
  };

  const handleFormSubmit = async () => {
    if (handleFormValidation()) {
      setIsLoading(true);
      const response = await logicCreate(formData, mode, id);
      if (response.success) {
        setTimeout(() => {
          navigate("/ai-logic-studio");
          logicUpdated(true);
          setIsLoading(false);
        }, 1000);
      } else {
        setIsLoading(false);
      }
    }
  };
  const handlelogicEdit = async (uniqueIdId: any) => {
    setIsLoading(true);
    const response = await logicEdit(uniqueIdId);
    if (response.success) {
      setFormData({
        ...response.data,
        imageUrl: response.imageUrl,
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleFormCancel = () => {
    navigate("/ai-logic-studio");
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[93%] h-auto">
          <div className="w-full h-auto flex justify-between items-start pt-4">
            <div className="w-[60%] ">
              <div className="w-full h-auto">
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.logic.label.name")}
                      type="text"
                      id="processName"
                      isRequired={true}
                      value={formData.name}
                      error={t(errors.name)}
                      onChange={(e) => handleInputChange("name", e)}
                    />
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <TextAreaField
                      labelName={t("web.logic.label.desc")}
                      id="processDescription"
                      isRequired={true}
                      value={formData.description}
                      onChange={(e) => handleInputChange("description", e)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="w-[32%] h-auto">
              <div className="w-full h-auto">
                {isLoading ? (
                  <Skeleton width="100%" height={190} borderRadius={3} />
                ) : (
                  <UploadImage
                    formData={formData}
                    setFormData={setFormData}
                    isReadOnly={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={handleFormCancel}
        handleSubmit={handleFormSubmit}
        isLoading={isLoading}
      />
    </div>
  );
}
export default CreateProcessForm;
