import { useState } from "react";

import MasterDetailsDetailGrid from "../../components/section/master-details/master-details-detail-grid";
import GridColumn from "../../components/section/user-management/grid-columns";
import DeleteUser from "../../components/section/user-management/delete-user";
import { Outlet } from "react-router-dom";

function MasterDetailsDetail({ userColumnUpdated, setUserColumnUpdated }: any) {
  const [masterTableName, setMasterTableName] = useState<string>("");

  const breadcrumbs = [
    { label: "Master Details", link: "/master-details" },
    { label: masterTableName, link: undefined },
  ];
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    "name",
    "type",
    "isPrimaryValue",
    "isMandatory",
  ]);
  const [deleteData, setDeleteData] = useState<any>(null);
  const [handleDataDelete, setHandleDataDelete] = useState<any>(null);

  const columnsData = [
    { label: "Name", token: "web.master.label.name", key: "name" },
    { label: "Data Type", token: "web.master.label.datatype", key: "type" },
    {
      label: "Primary Value",
      token: "web.master.label.primaryvalue",
      key: "isPrimaryValue",
    },
    {
      label: "Mandatory",
      token: "web.logic.label.mandatory",
      key: "isMandatory",
    },
  ];

  const handleCheckboxChange = (event: any, key: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedColumns((prevSelected) => [...prevSelected, key]);
    } else {
      if (selectedColumns.length === 1) return;
      setSelectedColumns((prevSelected) =>
        prevSelected.filter((item) => item !== key)
      );
    }
  };
  const handleDelete = (rowData: any) => {
    setDeleteData(rowData);
    setShowDeletePopup(true);
  };
  return (
    <>
      <div className="w-full h-full flex flex-col">
        <MasterDetailsDetailGrid
          handleDelete={handleDelete}
          handleColumns={() => setShowColumns(true)}
          selectedColumns={selectedColumns}
          moreOptions={true}
          rowSelection={false}
          setMasterTableName={setMasterTableName}
          userColumnUpdated={userColumnUpdated}
          setUserColumnUpdated={setUserColumnUpdated}
          handleDataDelete={handleDataDelete}
          setShowDeletePopup={(val: boolean) => setShowDeletePopup(val)}
        />
      </div>
      {showDeletePopup && (
        <DeleteUser
          rowData={deleteData}
          handleCancel={() => setShowDeletePopup(false)}
          handleDelete={(rowData: any) => setHandleDataDelete(rowData)}
          actionFlag="masterColumnDetails"
        />
      )}
      {showColumns && (
        <GridColumn
          handleClose={() => setShowColumns(false)}
          handleCheckboxChange={handleCheckboxChange}
          columnsData={columnsData}
          selectedColumns={selectedColumns}
        />
      )}
      <Outlet />
    </>
  );
}
export default MasterDetailsDetail;
