import * as XLSX from "xlsx";

interface Column {
  label: string;
  key?: string;
}

const s2ab = (s: string): ArrayBuffer => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

const generateExcelFile = async (
  data: Array<any>,
  filename: string,
  sheetName: string,
  columns: Array<Column>
): Promise<void> => {
  const exportToExcel = () => {
    if (data) {
      const customizedData = data.map((item) => {
        const rowData: Record<string, any> = {};
        columns.forEach((column) => {
          rowData[column.label] = column.key ? item[column.key] : "";
        });
        return rowData;
      });

      const ws = XLSX.utils.json_to_sheet(customizedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      const arrayBuffer = s2ab(wbout);
      const wbBlob = new Blob([arrayBuffer], {
        type: "application/octet-stream",
      });
      const wbUrl = URL.createObjectURL(wbBlob);

      const anchor = document.createElement("a");
      anchor.href = wbUrl;
      anchor.download = `${filename}.xlsx`;
      anchor.dispatchEvent(new MouseEvent("click"));

      URL.revokeObjectURL(wbUrl);
    }

  };

  exportToExcel();
};

export default generateExcelFile;
