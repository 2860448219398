import ModalPageWrapper from "../../components/global-layout/modal-page-wrapper";
import NewSubProcess from "../../components/section/ai-logic-studio/new-sub-process";

function NewSubProcessWrapper({ subProcessUpdated }: any) {
  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <NewSubProcess subProcessUpdated={subProcessUpdated} />
      </div>
    </ModalPageWrapper>
  );
}
export default NewSubProcessWrapper;
