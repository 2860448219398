import { useState } from "react";
import IconBtn from "../../../form/icon-btn";
import ModalPageWrapper from "../../../global-layout/modal-page-wrapper";
import { PageModalCloseIcon, TabThumbDesktop, TabThumbMobile, TabThumbTablet } from "../../../svg";

interface ProcessPreviewModalProps {
    processData: any;
    questionDetails: any;
    responseContainerItems: any
    handleCancel: () => void;
}

function ProcessPreviewModal({ responseContainerItems,  handleCancel, processData, questionDetails }: ProcessPreviewModalProps) {
    const [selectedTab, setSelectedTab] = useState('mobile');

    const tabs = [
        { id: 'mobile', label: 'Mobile', icon: <TabThumbMobile /> },
        { id: 'tablet', label: 'Tablet', icon: <TabThumbTablet /> },
        { id: 'desktop', label: 'Desktop', icon: <TabThumbDesktop /> }
    ];

    const handleTabChange = (tabId: string) => {
        setSelectedTab(tabId);
    };

    const renderSelectedDevice = () => {
        switch (selectedTab) {
            case "mobile":
                return (
                    <div className="w-full h-auto">
                    <div className="w-[33rem] h-[65rem] max-3xl:w-[28rem] max-3xl:h-[55rem] max-2xl:w-[26rem] max-2xl:h-[49rem] mx-auto relative">
                        <div className="w-full h-full overflow-y-auto scrollbar-none absolute top-0 left-0 px-10 z-[1]">
                            {questionDetails.map((items: any, i: any) => (
                                <p key={i} className="text-[1.6rem] font-inter font-normal text-io-black py-8">{items.name}</p>
                            ))}
                        </div>
                        <div className="w-full h-auto  z-0">
                            <img src={require("../../../../assets/images/preview-mobile-large.png")} alt="Mobile Image" />
                        </div>
                    </div>
                </div>
                );
            case "tablet":
                return (
                   <div className="w-full h-auto">
                    <div className="w-[47.4rem] h-[65rem] max-3xl:w-[41rem] max-3xl:h-[55rem] max-2xl:w-[36rem] max-2xl:h-[49rem] mx-auto relative">
                        <div className="w-full h-full overflow-y-auto scrollbar-none absolute top-0 left-0 px-10 z-[1]">
                            {questionDetails.map((items: any, i: any) => (
                                <p key={i} className="text-[1.6rem] font-inter font-normal text-io-black py-8">{items.name}</p>
                            ))}
                        </div>
                        <div className="w-full h-auto  z-0">
                            <img src={require("../../../../assets/images/preview-tablet-large.png")} alt="Tablet Image" />
                        </div>
                    </div>
                </div>
                );
            case "desktop":
                return (
                    <div className="w-full h-auto">
                    <div className="w-[76.6rem] h-[65rem] max-3xl:w-[65rem] max-3xl:h-[55rem] max-2xl:w-[58rem] max-2xl:h-[49rem] mx-auto relative">
                        <div className="w-full h-[48rem] max-3xl:h-[41rem] max-2xl:h-[37rem] overflow-y-auto scrollbar-none absolute top-0 left-0 px-10 z-[1]">
                            {questionDetails.map((items: any, i: any) => (
                                <p key={i} className="text-[1.6rem] font-inter font-normal text-io-black py-8">{items.name}</p>
                            ))}
                        </div>
                        <div className="w-full h-auto  z-0">
                            <img src={require("../../../../assets/images/preview-desktop-large.png")} alt="Desktop Image" />
                        </div>
                    </div>
                </div>
                );
            default:
                return null;
        }
    };

    return (
        <ModalPageWrapper>
            <div className="w-full h-full flex justify-center items-center">
                <div className="w-[85%] h-auto bg-io-white dark:bg-io-black-1a rounded-[2.2rem] max-md:w-[90%]">
                    <div className="w-full h-full py-[4%]">
                        <div className="w-full h-auto flex justify-end mb-4 px-12">
                            <IconBtn
                                size="w-[3rem]"
                                icon={<PageModalCloseIcon />}
                                handleClick={() => handleCancel()}
                            />
                        </div>
                        <div className="w-full h-auto">
                            <div className="w-full h-auto flex justify-between items-center gap-4">
                                <div className="w-[17.5rem] h-auto">
                                    <div className="w-full h-auto flex flex-col justify-center items-start">
                                        {tabs.map(tab => (
                                            <button type="button" key={tab.id} className={`w-full h-[17rem] relative before:content-[''] before:w-[0.7rem] before:h-full before:bg-io-primary before:absolute before:top-0 before:left-0 rounded-[0.8rem] overflow-hidden ${selectedTab === tab.id ? 'bg-[#EDF8FF] text-io-primary fill-io-primary before:block dark:bg-io-black-29' : 'bg-io-white text-io-gray-66 fill-io-gray-66 before:hidden dark:bg-transparent dark:text-io-black-d1 dark:fill-io-white'}`} onClick={() => handleTabChange(tab.id)}>
                                                <div className="w-auto flex flex-col justify-center items-center gap-3">
                                                <div className="w-auto h-[5rem]">
                                                    {tab.icon}
                                                </div>
                                                    <p className="text-[2.6rem] font-inter font-normal text-inherit">{tab.label}</p>
                                                </div>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex-1 h-auto">
                                    <div className="w-full h-auto">
                                        {renderSelectedDevice()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalPageWrapper>
    )
}
export default ProcessPreviewModal;